import React, { useCallback, useState } from "react";
import { 
    Typography, 
    Grid, 
    Autocomplete, 
    TextField, 
    Stack,
    Accordion, 
    AccordionDetails, 
    AccordionSummary,  
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarginCountrySettingsForm from "../components/MarginCountrySettingsForm"
import MarginBankHolidaysSettings from "../components/MarginBankHolidaysSettings";

export default function MarginSettingsForm(props) {
    const getCountriesSettingsStatus = props.getCountriesSettingsStatus;
    const countriesSettings = props.countriesSettings;
    const [initializeData, setInitializeData] = useState(true)
    const [countriesSettingsFormValues, setCountriesSettingsFormValues] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState();
    const [open, setOpen] = useState(true);
    const countrySettings = countriesSettingsFormValues.find(country => country.id === selectedCountryId);

    // Créer un tableau d'états pour chaque accordéon
    // On stocke un state qui enregistre les cartes ventes qui sont ouvertes
    const handleToggleCards = () => {
        setOpen(!open);
    };
    
    let countriesNames = []
    if (getCountriesSettingsStatus === "fulfilled"){
      countriesNames = countriesSettings.map(item => item.id);
    }

    if (initializeData && getCountriesSettingsStatus === "fulfilled") {
        setCountriesSettingsFormValues(countriesSettings);
        setSelectedCountryId(countriesNames[0])
        setInitializeData(false);
    }
    

    const handleCountryChange = (event, newValue) => {
        setSelectedCountryId(newValue);
    };
    
    const handleChangeParent = useCallback((event, selectedCountryId) => {
        let { name, value } = event.target;
        setCountriesSettingsFormValues(currentValues => 
            currentValues.map(country => {
                if (country.id === selectedCountryId) {
                    return {...country, margin_settings: {...country.margin_settings, [name]: value}};
                }
                return country;
            })
        );
    }, []);

    return (
        <Grid container display='flex' alignItems="center" my={5}>
            <Grid item>
            <Typography variant="body2" mr={2}>
                Select a country :
            </Typography>
            </Grid>
        <Grid item style={{width:"25%"}}>
          <Autocomplete
            options={countriesNames}
            defaultValue={countriesNames[0]}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                size="small"
                sx={{ my: 1 }}
              />
            )}
          />
        </Grid>
        {
            selectedCountryId ?
            (
            <Grid container my={3}>
            <Stack
                direction={"column"}
                spacing={2}
                width="100%"
            >
                <Accordion 
                    defaultExpanded={true} 
                    onChange={handleToggleCards} 
                    elevation={0} 
                    sx={{ border: '1px solid lightgray'}}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item>
                            <Typography variant="h6">
                                Margin General Settings
                            </Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MarginCountrySettingsForm 
                            selectedCountryId={selectedCountryId}
                            countrySettingsFormValues={countrySettings}
                            handleChangeParent={handleChangeParent}
                            setInitializeData={setInitializeData}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    defaultExpanded={true} 
                    onChange={handleToggleCards} 
                    elevation={0} 
                    sx={{ border: '1px solid lightgray'}}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item>
                            <Typography variant="h6">
                                Bank Holidays Parameters
                            </Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <MarginBankHolidaysSettings 
                            selectedCountryId={selectedCountryId}
                            countriesSettingsFormValues={countriesSettingsFormValues}
                            setInitializeData={setInitializeData}
                        />
                    </AccordionDetails>

                </Accordion>
            </Stack>
            </Grid>
            ) : null
        }
        </Grid>
    )
};

