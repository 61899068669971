/*
ce composant n'est pas affiché dans l'application
c'est un composant enfant de FormEB.js

c'est un fichier HTML qui formate l'Engagement Brief au format PDF pour pouvoir le télécharger s'il est signé
à chaque signature on enregistre le fichier dans le storage

ce composant est à améliorer en fonction des besoins du client

pour le moment, on affiche que les inputs importantes

*/

import React from "react";
import moment from "moment";
import logoEStarter from "../../../media/MPLogo.png";

const PdfEngagementBrief = (props) => {
  const inputs = props.inputs;
  const project = props.project;
  const engagement = props.engagement;
  const engagementBrief = props.engagementBrief
  const latestSavedVersionValues = props.latestSavedVersionValues;

  return (
    <div
      id="pdf-content"
      style={{
        position: "absolute",
        left: "-99999px",
        width: "210mm",
        minHeight: "297mm",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "15mm",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <div style={{ fontSize: "20px" }}>
          {/* <!-- Title Text --> */}
          <div
            style={{
              fontWeight: "bold",
              color: "rgb(39,36,91)",
              fontSize: "20px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr style={{ padding: "10px" }}>
                  <td style={{ width: "50%", verticalAlign: "top" }}>
                    <span
                      style={{
                        fontFamily: "Wingdings",
                        marginRight: "10px",
                        color: "rgb(2,169,219)",
                      }}
                    >
                      v
                    </span>
                    ENGAGEMENT BRIEF
                  </td>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                      textAlign: "right",
                    }}
                  >
                    <img 
                      src ={logoEStarter} 
                      alt="MI-GSO | PCUBED"
                      style={{
                        width: "60px", 
                        height: "60px",
                        borderRadius:"5px"
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <!-- MO Information ID / OTP / BU --> */}
          <div
            style={{
              backgroundColor: "rgb(39,36,91)",
              color: "#ffffff",
              fontSize: "14px",
              padding: "7px 0px 7px 10px",
              marginTop: "5px",
            }}
          >
            <span style={{ color: "rgb(252,198,8)", marginRight: "5px" }}>
              <b>ID</b>
            </span>
            {engagement.consultant_id}
            <span style={{ marginLeft: "5px" }}>|</span>
            <span style={{ color: "rgb(252,198,8)", margin: "0 5px" }}>
              <b>OTP</b>
            </span>
            {project.project_id}
            <span style={{ marginLeft: "5px" }}>|</span>
            <span style={{ color: "rgb(252,198,8)", margin: "0 5px" }}>
              <b>BU</b>
            </span>{" "}
            {project.project_department}
            <span style={{ marginLeft: "5px" }}>|</span>
            <span style={{ color: "rgb(252,198,8)", margin: "0 5px" }}>
              <b>ENG.CODE</b>
            </span>{" "}
            {engagement.engagement_integration_key}
            <span style={{ marginLeft: "5px" }}>|</span>
            <span style={{ color: "rgb(252,198,8)", margin: "0 5px" }}>
              <b>SIGNED ON</b>
            </span>{" "}
              {engagementBrief.latest_saved_version?.signature_date}
          </div>
        </div>

        {/* <!-- Block MISSION & CONSULTANT  --> */}
        <div
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "rgb(210,210,210)",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              borderBottom: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "rgb(230,230,230)",
              color: "rgb(39,36,91)",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 0px 5px 10px",
              marginBottom: "5px",
            }}
          >
            <span style={{ color: "rgb(2,169,219)", marginRight: "5px" }}>
              #
            </span>{" "}
            Project & Team
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr style={{ padding: "10px" }}>
                <td
                  style={{
                    width: "100%",
                    verticalAlign: "top",
                    p: 0,
                    display: "inline-flex",
                  }}
                >
                  <div style={{ width: "50%", fontSize: "12px", p: "0px 8px" }}>
                    <div
                      style={{
                        margin: "3px 0px",
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Project</b>
                      </span>
                      {project.project_name} -{" "}
                      {engagement.project_legal_description}
                    </div>
                    <div
                      style={{
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b> Start Date</b>
                      </span>
                      {moment(engagement.engagement_start_date).format(
                        "DD/MM/YYYY",
                      )}
                      <span
                        style={{
                          color: "#344BC1",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                      >
                        <b> End Date</b>
                      </span>{" "}
                      {moment(engagement.engagement_end_date).format("DD/MM/YYYY")}

                    </div>
                        <div
                          style={{
                            lineHeight: "10px",
                            borderBottom: "1px",
                            fontSize: "8px",
                            fontWeight: "normal",
                            borderBottomStyle: "solid",
                            borderBottomColor: "rgb(230,230,230)",
                            padding: "2px 5px",
                          }}
                        >
                      <span style={{  marginRight: "5px" }}>
                        <b>* This Engagement brief will remain valid in case of engagement period extension</b>
                      </span>
                      </div>
                    {/* <div style={{margin:'5px 0px',lineHeight:"20px",padding:'2px 5px',}}>
                        <span style={{color:"#344BC1",marginRight:'5px'}}><b>Description</b></span>{latestSavedVersionValues.Description}
                    </div> */}
                  </div>
                  {/* </td>
            <td style={{width:'50%',verticalAlign:'top',padding:"0px 8px"}}> */}
                  <div
                    style={{
                      width: "50%",
                      fontSize: "12px",
                      padding: "0px 8px",
                      borderLeft: "1px",
                      borderLeftStyle: "dashed",
                      borderLeftColor: "rgb(200,200,200)",
                    }}
                  >
                    <div
                      style={{
                        margin: "3px 0px",
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Consultant</b>
                      </span>{" "}
                      {engagement.consultant_full_name}
                      {latestSavedVersionValues.engagement_brief_status === "SIGNED" ? (
                        <div
                          style={{ color: "rgb(0,176,80)", fontSize: "11px" }}
                        >
                          <b>{latestSavedVersionValues.engagement_brief_status} </b>
                          on {latestSavedVersionValues.signature_date}
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        margin: "5px 0px",
                        lineHeight: "20px",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>RC</b>
                      </span>{" "}
                      {engagement.project_commercial_owner_name}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr style={{ padding: "10px" }}>
                <td
                  style={{
                    width: "100%",
                    verticalAlign: "top",
                    padding: "0px 8px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px 0px",
                      lineHeight: "20px",
                      padding: "2px 5px",
                    }}
                  >
                    <span style={{ color: "#344BC1", marginRight: "5px" }}>
                      <b>Description</b>
                    </span>
                    <div style={{ fontSize: "12px" }}>
                      {latestSavedVersionValues.description}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <!-- Block CUSTOMER  --> */}
        <div
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "rgb(210,210,210)",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              borderBottom: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "rgb(230,230,230)",
              color: "rgb(39,36,91)",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 0px 5px 10px",
              marginBottom: "5px",
            }}
          >
            <span style={{ color: "rgb(2,169,219)", marginRight: "5px" }}>
              #
            </span>{" "}
            Customer
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              {/* <!-- LEFT SIDE --> */}
              <tr style={{ padding: "10px" }}>
                <td style={{ width: "50%", verticalAlign: "top", p: 0 }}>
                  <div style={{ fontSize: "12px", padding: "0px 8px" }}>
                    <div
                      style={{
                        margin: "3px 0px",
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Name</b>
                      </span>
                      {project.customer_name}
                    </div>
                    <div
                      style={{
                        margin: "5px 0px",
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>On Site Contact</b>
                      </span>{" "}
                      {engagement.project_contact}
                    </div>
                    <div
                      style={{
                        margin: "5px 0px",
                        lineHeight: "20px",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Site Name</b>
                      </span>{" "}
                      {engagement.prestation_site}
                    </div>
                  </div>
                </td>

                {/* <!-- RIGHT SIDE --> */}
                <td style={{ width: "50%", verticalAlign: "top", p: 0 }}>
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "0px 8px",
                      borderLeft: "1px",
                      borderLeftStyle: "dashed",
                      borderLeftColor: "rgb(200,200,200)",
                    }}
                  >
                    <div
                      style={{
                        margin: "3px 0px",
                        lineHeight: "20px",
                        borderBottom: "1px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "rgb(230,230,230)",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Department</b>
                      </span>
                      {project.project_department}
                    </div>
                    <div
                      style={{
                        margin: "5px 0px",
                        lineHeight: "20px",
                        padding: "2px 5px",
                      }}
                    >
                      <span style={{ color: "#344BC1", marginRight: "5px" }}>
                        <b>Engagement Address</b>
                      </span>{" "}
                      {engagement.customer_full_address}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <!-- Block SPECIFICITES --> */}
        <div
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "rgb(210,210,210)",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              borderBottom: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "rgb(230,230,230)",
              color: "rgb(39,36,91)",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 0px 5px 10px",
            }}
          >
            <span style={{ color: "rgb(2,169,219)", marginRight: "5px" }}>
              #
            </span>{" "}
            Specificities
          </div>

          <table style={{ width: "100%" }}>
            <tbody>
              {inputs
                .sort()
                .filter((input) => input.inputName !== "Description")
                .map((input) => (
                  <tr style={{ padding: "10px" }} key={input.inputName}>
                    <td style={{ width: "50%", verticalAlign: "top", p: 0 }}>
                      <div
                        style={{
                          fontSize: "12px",
                          padding: "0px 8px",
                          borderRight: "1px",
                          borderRightStyle: "dashed",
                          borderRightColor: "rgb(200,200,200)",
                        }}
                      >
                        <div
                          style={{
                            margin: "3px 0px",
                            lineHeight: "20px",
                            borderBottom: "1px",
                            borderBottomStyle: "solid",
                            borderBottomColor: "rgb(230,230,230)",
                            padding: "2px 5px",
                          }}
                        >
                          <span
                            style={{ color: "#344BC1", marginRight: "5px" }}
                          >
                            <b>{input.inputLabel}</b>
                          </span>
                          {latestSavedVersionValues[input.inputName]}{" "}
                          {input.inputName?.includes("WT") ? "%" : ""}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* <!-- MO Information ID / OTP / BU --> */}
        <div
          style={{
            backgroundColor: "rgb(39,36,91)",
            color: "#ffffff",
            fontSize: "14px",
            padding: "7px 0px 7px 10px",
            marginTop: "10px",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr style={{ padding: "10px" }}>
                <td
                  style={{
                    verticalAlign: "top",
                    textAlign: "right",
                    fontSize: "11px",
                    padding: "2px 10px 0px 0px",
                  }}
                >
                  © MI-GSO | PCUBED – Confidential – e-Starter Web Application
                  – {moment().format("YYYY")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default PdfEngagementBrief;
