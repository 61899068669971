/*
c'est le composant enfant de ProjectList.js
tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à ce dialog (SAUF LE CONSULTANT DE LA MISSION SELECTIONNEE)

dans ce composant, on affiche un dialog avec une radio Yes/No pour savoir si l'engagement a besoin d'un plan de prevention (APPLICABLE/NOT APPLICABLE)

ensuite l'utilisateur sélectionne dans une liste de plan de prévention qui a le meme client que l'engagement sélectionné dans ProjectList.js

on peut selectionner qu'un seul prevention plan par engagement


*/

import React, { useState, useContext } from "react";
import {
  Grid,
  Chip,
  List,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  ListItem,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Divider,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import {
  useGetFileContentMutation,
  useGetProjectsMutation,
  useSubmitPreventionPlanAssignmentMutation,
} from "../../api/eStarterApi";
import moment from "moment";
import { truncateText } from "../../commonFunction";
import { CustomLoading } from "../../components/CustomLoading";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EngagementsActiveOnlyContext from "../../contexts/EngagementsActiveOnlyContext";

export function DialogPreventionPlanAssignment(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const engagement_integration_key = props.engagement_integration_key

  //infomration du store
  const PreventionPlanFiles = useSelector(
    (state) =>
      state.api.mutations["prevention_plan_files"]
        ? state.api.mutations["prevention_plan_files"].status === "fulfilled"
          ? state.api.mutations["prevention_plan_files"].data.prevention_plan_files
          : []
        : [],
    _.isEqual,
  );

  const crm_projects = useSelector(
    (state) =>
      state.api.mutations["crm_projects"]
        ? state.api.mutations["crm_projects"].status === "fulfilled"
          ? state.api.mutations["crm_projects"].data
          : []
        : [],
    _.isEqual,
  );

  const filteredProject = crm_projects.filter((project) => {
    return project.engagements.some((engagement) => engagement.engagement_integration_key === engagement_integration_key);
  });

  const currentProject = filteredProject[0];

  const filteredEngagement = currentProject.engagements.filter((engagement) => engagement.engagement_integration_key === engagement_integration_key);
      
  const currentEngagement = filteredEngagement[0];

  // const currentProject = useSelector(
  //   (state) => state.selectedProject,
  //   _.isEqual,
  // );
  // const currentEngagement = useSelector(
  //   (state) => state.selectedEngagement,
  //   _.isEqual,
  // );

  //changement du state
  const [checkApplicable, setCheckApplicable] = useState(
    currentEngagement.prevention_plan_status === "NOT APPLICABLE" ? "No" : "Yes",
  );
  const [checkAssignment, setCheckAssignment] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [dowloading, setDownloading] = useState(false);

  //api
  const dispatch = useDispatch();
  const [postSubmitPreventionPlanAssignment] = useSubmitPreventionPlanAssignmentMutation();
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [getFileContent] = useGetFileContentMutation();

  // fermeture du dialog
  const handleClose = () => {
    props.setOpenDialog(false);
  };

  // changement du state pour le filtre
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const handleClearClick = () => {
    setSearch("");
  };
  // changement du state pour la selection d'un plan de prévention (la selection est unique ici (un engagement ne peut avoir qu'un seul plan de prevention))
  const handleCheck = (value) => {
    setCheckAssignment(value);
  };
  // changement du state pour informer si l'engagement a besoin d'un prevention plan
  const handleCheckApplicable = (event) => {
    setCheckApplicable(event.target.value);
  };

  // sauvegarde de l'assignation
  const handleSubmit = async (action) => {
    setLoading(true);
    try {
      let resultApi = null;
      let submitCase = null;
      // on traite aussi le cas des not applicable,
      let preventionPlanAssignment = {
        prevention_plan_id: checkApplicable === "Yes" ? String(checkAssignment) : "NOT APPLICABLE",
        engagement_ids: [String(currentEngagement.engagement_integration_key)],
        prevention_plan_status:
          checkApplicable === "Yes" ? "READY TO BE SIGNED" : "NOT APPLICABLE",
      };
      if (action === "Save") {
        submitCase = "Save"
      }
      resultApi = await postSubmitPreventionPlanAssignment({prevention_plan_assignment: preventionPlanAssignment, case: submitCase}).unwrap();
      //on met à jour les données dans le store, on affiche la notification et on ferme le dialog
      postProjects({ remove_closed: true });
      setEngagementsActiveOnly(true);
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultApi.status,
          snackbarMessage: resultApi.msg,
        },
      });
      props.setOpenDialog(false);
    } catch (error) {
      //affichage des erreurs
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (file) => {
    setDownloading(true);
    if (file) {
      try {
        const response = await getFileContent({ blob: file.blob });

        if (response.data) {
          const content = response.data.content;
          const mime_type = response.data.mime_type;
          const fileUrl = `data:${mime_type};base64,${content}`;

          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(`Error fetching blob content: ${response.statusText}`);
        }
      } catch (error) {
        console.error("Error fetching file from Azure function:", error);
      } finally {
        setDownloading(false);
      }
    }
  };

  return loading || dowloading ? (
    <Dialog open={props.openDialog} maxWidth="lg" fullWidth>
      <CustomLoading loadingText={dowloading ? "Downloading" : "Saving..."} />
    </Dialog>
  ) : (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogContent>
        <Typography variant={isSmallScreen ? "h5" : "h3"} mt={2} sx={isSmallScreen ? {} : {ml:4}}>
          Assign Prevention Plan
        </Typography>
        <Typography variant={isSmallScreen ? "h6" : "h5"} mt={2} sx={isSmallScreen ? {} : {ml:4}}>
          {currentProject.project_id && currentProject.project_name
            ? `${currentProject.project_id} | ${currentProject.project_name} | ${truncateText(currentEngagement.project_legal_description, 30)}`
            : null}
        </Typography>

        <FormControl mt={2} sx={isSmallScreen ? {} : {ml:4}}>
          <FormLabel>Does this engagement need a Prevention Plan?
            <RadioGroup value={checkApplicable} onChange={handleCheckApplicable}>
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormLabel>
        </FormControl>
        <Divider sx={{ mt: 1, mb: 1 }} />

        {checkApplicable === "Yes" ? ( // on affiche les pp files que si c'est applicable
          <Box>
            <Box>
              <Grid container alignItems="center">
                <Typography variant="body1" sx={{mb: 2 }} >
                  Please assign a Prevention Plan available below. If it doesn't appear,
                  please check if the Prevention Plan validity date is expired or contact your GBU{" "}
                </Typography>
                {/*____________________________________________ barre de recherche dans les PreventionPlanFiles */}
                <Grid item xs={10} sm={10} md={10}>
                  <TextField
                    label="Search"
                    size="small"
                    value={search}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          sx={{
                            visibility: search !== "" ? "visible" : "hidden",
                          }}
                          size="small"
                          onClick={handleClearClick}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ height: 250, overflowX: "hidden" }}>
              <List>
                {PreventionPlanFiles.slice()
                  .filter((preventionPlan) => (preventionPlan.customer_name === currentProject.customer_name) && (moment(preventionPlan.validity_date, "YYYY-MM-DD").isAfter(moment(), "day"))) // on filtre sur les pp files du meme client que l'engagement selectionné
                  .filter(
                    (preventionPlan) =>
                      search === "" ||
                      preventionPlan.prevention_plan_name?.toLowerCase().includes(
                        search.toLowerCase(),
                      ) ||
                      preventionPlan.customer_site?.toLowerCase().includes(
                        search.toLowerCase(),
                      ),
                  )
                  .map((preventionPlan, index) => (
                    <ListItem 
                      key={index} 
                      style={
                        isSmallScreen
                          ? 
                          {
                            backgroundColor: index % 2 === 0
                              ? listItemStyles.backgroundColorEven
                              : listItemStyles.backgroundColorOdd,
                          }
                          :
                          {}
                      }
                    >
                      <Grid container 
                        display="flex" 
                        alignItems={isSmallScreen ? "flex-end" : "center"}
                        flexDirection={isSmallScreen ? "column" : "row"}
                        sx={{flexWrap:'nowrap'}} 
                      >
                        <Grid container spacing={1}>
                          <Grid item>
                            <Tooltip title={`Prevention plan name: ${preventionPlan.prevention_plan_name}`}>
                              <Chip
                                sx={isSmallScreen ? {maxWidth:200} : {}}
                                label={preventionPlan.prevention_plan_name}
                                size="small"
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title={`Customer: ${preventionPlan.customer_name}`}>
                              <Chip
                                sx={isSmallScreen ? {maxWidth:200} : {}}
                                label={preventionPlan.customer_name}
                                size="small"
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip>
                              <Chip
                                sx={isSmallScreen ? {maxWidth:200} : {}}
                                label={preventionPlan.customer_site}
                                size="small"
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Validity Date">
                              <Chip
                                label={moment(preventionPlan.validity_date, "YYYY-MM-DD)").format("DD/MM/YYYY")}
                                icon={<CalendarMonthIcon />}
                                size="small"
                                color={
                                  moment(preventionPlan.validity_date, "YYYY-MM-DD").isBefore(
                                    moment(),
                                    "day"
                                  )
                                    ? "error"
                                    : "default"
                                }
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption">
                              {preventionPlan.files ? (
                                preventionPlan.files.length === 0 ? (
                                  "No file attached, impossible to assign!"
                                ) : (
                                  <Tooltip title="Attach files (click to dowload)">
                                    <Chip
                                      label={preventionPlan.files.length}
                                      size="small"
                                      onClick={() => {
                                        preventionPlan.files.map((file) =>
                                          downloadFile(file),
                                        );
                                      }}
                                      icon={<AttachFileIcon />}
                                      color={"default"}
                                    />
                                  </Tooltip>
                                )
                              ) : (
                                "No file attached, impossible to assign!"
                              )}
                            </Typography>
                          </Grid>

                        </Grid>
                        <Grid
                          item
                          justifyContent="flex-end"
                        >
                          <FormControlLabel
                            disabled={(preventionPlan.files ? preventionPlan.files.length === 0 : true) || (moment(preventionPlan.validity_date, "YYYY-MM-DD").isBefore(moment(), "day") ? true : false)}
                            // on peut assigner un plan de prevention que si celui ci a au moins un fichier joint
                            control={
                              <Checkbox
                                checked={checkAssignment === preventionPlan.id}
                                onChange={() => handleCheck(preventionPlan.id)}
                                color="secondary"
                                size="small"
                              />
                            }
                            label="Assign"
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit("Save")}
          autoFocus
          color="success"
          disabled={
            checkApplicable === "No" ? false : checkAssignment ? false : true
          }
        >
          {checkApplicable === "Yes"
            ? "Save assignment"
            : "Save Not Applicable"}
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const listItemStyles = {
  backgroundColorEven: "#f4f4f4",
  backgroundColorOdd: "#f9f9f9",
};