import React, { useState } from "react";
import { Typography, Box, CssBaseline, Container, Grid, useMediaQuery, Tabs, Tab } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomLoading } from "../../components/CustomLoading";
import _ from "underscore";
import Notification from "../../components/Notification";
import MarginSettingsForm from "../components/MarginSettingsForm";
import SpecificUsersSettings from "../components/SpecificUsersSettingsForm";
export function AdministratorSettings() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [value, setValue] = useState(0);

  const countriesSettings = useSelector(
    (state) =>
    state.api.mutations["countries_settings"]
    ? state.api.mutations["countries_settings"].status === "fulfilled"
    ? state.api.mutations["countries_settings"].data
    : []
    : [],
    _.isEqual,
  );

  const getCountriesSettingsStatus = useSelector((state) =>
    state.api.mutations["countries_settings"]
    ? state.api.mutations["countries_settings"].status
    : "loading",
  );

  const specificUsers = useSelector(
    (state) =>
    state.api.mutations["specific_users"]
    ? state.api.mutations["specific_users"].status === "fulfilled"
    ? state.api.mutations["specific_users"].data
    : []
    : [],
    _.isEqual,
  );

  const getSpecificUsersStatus = useSelector((state) =>
    state.api.mutations["specific_users"]
    ? state.api.mutations["specific_users"].status
    : "loading",
  );
    
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex" }} my={isSmallScreen && 4}>
      <CssBaseline />
      {getCountriesSettingsStatus !== "fulfilled" || getSpecificUsersStatus !== "fulfilled" ? (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Notification />
          <CustomLoading loadingText={"Loading Settings Data..."} />
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Notification />
          <Grid item mt={5} mb={2}>
            <Grid display="flex" alignItems="center">
              <Typography variant="h4">
                  Welcome to the Administrator settings
              </Typography>
            </Grid>
          </Grid>
          <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
            <Tab label="Margin Settings" />
            <Tab label="Specific Users Settings" />
          </Tabs>
          {value === 0 && (
            <MarginSettingsForm 
              getCountriesSettingsStatus={getCountriesSettingsStatus}
              countriesSettings={countriesSettings}
            />
          )} 
          {value === 1 && (
            <SpecificUsersSettings
              getSpecificUsersStatus={getSpecificUsersStatus}
              specificUsers={specificUsers}
            />
          )}
        </Container>
      )}
    </Box>
  );
  }    