/*
c'est le composant enfant de PreventionPlanMgt.js
il faut etre soit un specific soit un admin pour pouvoir accéder à ce dialog

dans ce dialog, on recoit les informations du pp selectionné et on modifie les metadonnées du pp
on peut également créer un nouveau prevention plan (à condition de remplir tous les champs)

tous les fichiers ajoutés vont se stocker dans le storage, dans un dossier PreventionPlan/PreventionPlanName pour que ce soit plus simple à retrouver

si l'utilisateur édite un prevention plan et qu'il ajoute des nouveaux fichiers, on relance les signatures pour tous les engagements assignés à ce pp


*/

import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import {
  useGetPreventionPlanFilesMutation,
  useSubmitPreventionPlanFilesMutation,
  useCreateFilesMutation,
} from "../../api/eStarterApi";
import Notification from "../../components/Notification";
import { CustomLoading } from "../../components/CustomLoading";
import { AttachmentInput } from "../../projects/components/AttachmentInput";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";

export function DialogPreventionPlanFile(props) {
  const inputsPreventionPlanFile = [
    { label: "Name", name: "prevention_plan_name" },
    { label: "Customer", name: "customer_name" },
    { label: "Customer Site", name: "customer_site" },
    { label: "Validity Date", name: "validity_date", type: "date" },
    { label: "Fichier", name: "files", type: "file" },
  ];

  // information du store
  const currentPreventionPlanFile = useSelector((state) => state.selectedPreventionPlanFile, _.isEqual);
  const engagementsPreventionPlanLoading = useSelector((state) =>
    state.api.mutations["prevention_plan_assignments"]
      ? state.api.mutations["prevention_plan_assignments"].status !== "fulfilled"
      : true,
  );

  // changement du state
  const [updatedInputValues, setUpdatedInputValues] = useState(currentPreventionPlanFile);
  const [filesToCreate, setFilesToCreate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validityDate, setValidityDate] = useState(dayjs(updatedInputValues["validity_date"]));
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  // API
  const dispatch = useDispatch();
  const [postSubmitPreventionPlanFile] = useSubmitPreventionPlanFilesMutation();
  const [getPreventionPlanFiles] = useGetPreventionPlanFilesMutation({ fixedCacheKey: "prevention_plan_files" });
  const [apiCreateFiles] = useCreateFilesMutation();

  //fermeture du dialog
  const handleClose = () => {
    props.setOpenDialog(false);
  };

  //changement du state pour les données du formulaire
  const handleChange = (inputName, inputValue) => {
    if (inputName === "files") {
      setFilesToCreate(inputValue.filter((v) => v instanceof File));
      setUpdatedInputValues({
        ...updatedInputValues,
        [inputName]: inputValue.filter((v) => !(v instanceof File)),
      });
    } else {
      setUpdatedInputValues({ ...updatedInputValues, [inputName]: inputValue });
    }
    setUnsavedChanges(true);
  };
  // sauvegarde du prevention plan
  const handleSubmit = async () => {
    setLoading(true);
    try {
      let resultsPreventionPlan = null;
      let resultfiles = null;

      //on teste d'abord si on a des fichiers à créer (si on edite uniquement la date de validité par exemple, pas besoin de recréer les fichiers)
      if (filesToCreate.length > 0) {
        //create files in azure storage
        resultfiles = await apiCreateFiles({
          files: filesToCreate,
          folderName: "PreventionPlan/" + updatedInputValues.prevention_plan_name,
        });
        resultsPreventionPlan = await postSubmitPreventionPlanFile({
          ...updatedInputValues,
          files: updatedInputValues.files.concat(resultfiles.data)
        }).unwrap();
        // un fois que l'on a sauvegarder les assignements, on save pp files in cosmos db
      } else {
        //si on n'a pas changé les fichiers, alors on peut juste sauvegarder l'édition
        resultsPreventionPlan = await postSubmitPreventionPlanFile(updatedInputValues).unwrap();
      }
      //et enfin, on met à jour le state, le store, on affiche la notification, on réinitialise les fichiers à créer et on ferme le dialog
      getPreventionPlanFiles({ id: "ALL", case: "Management" });
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultsPreventionPlan.status,
          snackbarMessage: resultsPreventionPlan.msg,
        },
      });
      setFilesToCreate([]);
      handleClose();
    } catch (error) {
      // en cas d'erreur, on affiche la notification
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
        },
      });
    } finally {
      // dans tous les cas, on arrête le chargement des données et on
      setLoading(false);
    }
  };

  const getUnfilledRequiredInputs = () => {
    // Trouver les inputs nécessaires qui n'ont pas de valeur mise à jour non vide
    const unfilledRequiredInputs = inputsPreventionPlanFile.filter((requiredInput) => {
      const updatedValue = updatedInputValues[requiredInput.name];
      return !updatedValue || updatedValue === "" || updatedValue === null;
    });

    return unfilledRequiredInputs;
  };

  const setDate = (newValue) => {
    setValidityDate(newValue);
    const formattedDate = newValue.format("YYYY-MM-DD");
    setUpdatedInputValues({ ...updatedInputValues, "validity_date": formattedDate })
    setUnsavedChanges(true);
  }

  return loading || (engagementsPreventionPlanLoading && props.editMod === "EDIT") ? ( //affichage de la barre de chargement
    <Dialog open={props.openDialog} maxWidth="md" fullWidth>
      <CustomLoading
        loadingText={engagementsPreventionPlanLoading ? "Loading..." : "Saving..."}
      />
    </Dialog>
  ) : (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Notification />

      <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
        {props.editMod} Prevention Plan
      </Typography>

      <DialogContent>
        <FormControl fullWidth>
          {inputsPreventionPlanFile.map((input, index) =>
            input.type === "file" ? (
              // pour les fichiers, on affiche le custom input file qui est commun à tous les composants qui ajoutent des fichiers
              <AttachmentInput
                editMod="EDIT"
                onChange={(e) => handleChange(input.name, e)}
                initialFiles={updatedInputValues["files"]}
                key={index}
              />
            ) : input.name === "customer_name" ? (
              // pour les clients, on met une liste déroulante

              <Autocomplete
                key={index}
                options={props.allCustomers}
                size="small"
                sx={{ mt: 1 }}
                value={updatedInputValues[input.name] || null}
                onChange={(e, newValue) => handleChange(input.name, newValue)}
                disabled={props.editMod === "Edit"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    size="small"
                    required
                    error={
                      updatedInputValues[input.name] === null ||
                      updatedInputValues[input.name] === undefined
                    }
                  />
                )}
              />
            ) : input.name === "validity_date" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  key={index} 
                  size="small"
                  format="DD/MM/YYYY"
                  label="Validity Date"
                  value={validityDate}
                  onChange={(e) => setDate(e)}
                  sx={{marginBlock:1}}
                  />
              </LocalizationProvider>
            )
            :
            (
              <TextField
                key={index}
                size="small"
                required={true}
                label={input.label}
                variant="outlined"
                fullWidth
                margin="normal"
                value={
                  updatedInputValues[input.name] || ""
                }
                onChange={(e) => handleChange(input.name, e.target.value)}
              />
            ),
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          autoFocus
          color="success"
          //il faut que tous les inputs soit remplit
          disabled={
            getUnfilledRequiredInputs().length > 0
          }
        >
          Save
        </Button>
        {unsavedChanges ? 
          <Button onClick={() => setConfirmDialogOpen(true)} color="error">
            Cancel
          </Button> 
          :<Button onClick={handleClose} color="error">
            Cancel
          </Button>
        }
        {confirmDialogOpen ? (
          <ConfirmDialog
            title="Are you sure you want to cancel ?"
            description="Any unsaved changes will be lost."
            mod="unsavedChanges"
            open={confirmDialogOpen}
            setOpen={setConfirmDialogOpen}
            onConfirm={handleClose} // Pass the handleClose function
          />
        ): null }
      </DialogActions>

    </Dialog>
  );
}
