import React from "react";

import { Box, Grid, Container, CssBaseline } from "@mui/material";

export function NotFoundPage(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            Error 404: Page or resource not found, please go back to the home page.
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
