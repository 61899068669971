/*
c'est le composant enfant de PreventionPlan.js
tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à ce dialog

dans ce composant, on affiche un dialog avec une checkbox à checker pour pouvoir confirmer la signature du plan de prévention

ensuite, on exécute l'api submitPPAssignemnt pour changer le statut et informer que le pp est signé

*/

import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { FormControlLabel, Typography, Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  useGetProjectsMutation,
  useSubmitPreventionPlanAssignmentMutation,
  useGetPreventionPlanAssignmentDataMutation,
} from "../../../api/eStarterApi";
import { CustomLoading } from "../../../components/CustomLoading";
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";
import moment from "moment";

export function DialogPreventionPlanSignature(props) {
  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  //changement du state
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  //information du store
  // const currentPreventionPlan = useSelector((state) => state.selectedPreventionPlanFile, _.isEqual);
  // const currentEngagement = useSelector(
  //   (state) => state.selectedEngagement,
  //   _.isEqual,
  // );
  const currentPreventionPlan = props.currentPreventionPlan; 
  const currentEngagement = props.currentEngagement; 

  //api
  const dispatch = useDispatch();
  const [postSubmitPreventionPlanAssignment] = useSubmitPreventionPlanAssignmentMutation();
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [getPreventionPlanAssignmentData] = useGetPreventionPlanAssignmentDataMutation({ fixedCacheKey: "prevention_plan_assignment_data" });


  //fermeture du dialog
  const handleClose = () => {
    props.setOpenDialog(false);
  };

  //confirmation de la lecture des elements du prévention plan
  const handleCheck = (event) => {
    setCheck(event.target.checked);
  };

  //sauvegarde de la signature
  const handleSubmit = async (action) => {
    //affichage de la barre de chargement
    setLoading(true);
    try {
      let resultApi = null;
      let submitCase = null;
      //body de l'api (mettre l'id de l'engagement en string, l'id du pp et le statut = signed)
      let sendPreventionPlanAssignment = {
        engagement_ids: [String(currentEngagement.engagement_integration_key)],
        prevention_plan_id: currentPreventionPlan.id,
        prevention_plan_status: "SIGNED"
      };

      if (action === "Signature") {
        sendPreventionPlanAssignment = {
          engagement_ids: [String(currentEngagement.engagement_integration_key)],
          prevention_plan_id: currentPreventionPlan.id,
          prevention_plan_status: "SIGNED",
          signature_date: String(moment().format("YYYY-MM-DD"))
        }
        submitCase = "Signature"
      } 

      // on fait appel à l'api qui met à jour la cosmos db container ppassignment
      resultApi = await postSubmitPreventionPlanAssignment({prevention_plan_assignment: sendPreventionPlanAssignment, case: submitCase}).unwrap();
      //on affiche la notification
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultApi.status,
          snackbarMessage: resultApi.msg,
        },
      });
      //on met à jour le state
      dispatch({
        type: "selectedPreventionPlanFileReducer/selectPreventionPlanFile",
        payload: { ...currentPreventionPlan, prevention_plan_status: "SIGNED", signature_date: String(moment().format("YYYY-MM-DD")) },
      });
      //on met à jour les projets
      postProjects({ remove_closed: true });
      setEngagementsActiveOnly(true);
      //on ferme le dialog
      props.setOpenDialog(false);
      // on remet à jour le prevention_plan
      await getPreventionPlanAssignmentData({
        engagement_integration_key: String(currentEngagement.engagement_integration_key),
      }).unwrap();
    } catch (error) {
      //affichage des erreurs dans la notifcation
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
        },
      });
    } finally {
      //on arrete l'affichage de la barre de chargement
      setLoading(false);
    }
  };

  return loading ? (
    <Dialog open={props.openDialog} maxWidth="md" fullWidth>
      <CustomLoading loadingText="Saving..." />
    </Dialog>
  ) : (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Typography variant="h3" sx={{ mt: 2, ml: 2 }}>
        {" "}
        Sign a Prevention Plan{" "}
      </Typography>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* le consultant doit checker la checkbox avant de signer */}
          <FormControlLabel
            control={
              <Checkbox
                checked={check}
                onChange={handleCheck}
                color="primary"
              />
            }
            label="I have read and acknowlegdged all the files of this Prevention Plan, and by ticking this I am committing to these terms and conditions"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit("Signature")}
          autoFocus
          color="success"
          disabled={!check}
        >
          Sign
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
