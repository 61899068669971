//Se connecter à l’aide de redirections

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../params/authConfig";
import Button from "@mui/material/Button";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <>
      <Button
        variant="contained"
        size="large"
        style={{ color: "grey", backgroundColor: "white" }}
        sx={{paddingLeft: 3}}
        onClick={() => handleLogin(instance)}
      >
        {" "}
        Signin{" "}
      </Button>
    </>
  );
};
