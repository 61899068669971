/*
FICHIER POUR LES FONCTIONS COMMUNES A PLUSIEURS COMPOSANTS
*/

// pour afficher que la maxlength d'un txt trop long
export function truncateText(text, maxLength) {
  if (text === undefined || text === null) {
    return "";
  } else if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

// Function to determine the month for a given date
export function getPresentMonth(date) {
    return monthList[date.getMonth()];
}

export function getWorkingDaysInRange(year, month, startDay, endDay) {
  let workingDays = 0;
  for (let day = startDay; day <= endDay; day++) {
      const currentDate = new Date(year, month, day);
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          workingDays++;
      }
  }
  return workingDays;
}
const monthList = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"];

export function calculateWorkingDaysPerMonth(startDate, endDate, currentProject, countrySettings) {
    const workingDaysPerMonth = {};
    const projectHolidays = []
    const projectDepartment = currentProject.project_department;

    countrySettings.holidays.general.forEach(holiday => {
        if (!projectHolidays.includes(holiday.day)){
            projectHolidays.push(holiday.day)
        }
    })
    countrySettings.holidays.business_unit.forEach(businessUnit => {
        if (businessUnit.name === projectDepartment){
            businessUnit.days.forEach((day) => {
                if (!projectHolidays.includes(day.day)){
                    projectHolidays.push(day.day)
                }
            })
        }
    })

    // Loop through months between start and end dates
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const presentMonth = getPresentMonth(currentDate);
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const startDay = currentDate.getDate();
        const endDay = (currentDate.getMonth() === endDate.getMonth() && currentDate.getFullYear() === endDate.getFullYear()) ? endDate.getDate() : daysInMonth;
        let workingDays = getWorkingDaysInRange(currentDate.getFullYear(), currentDate.getMonth(), startDay, endDay);
        
        // Reduce the working days count for holidays that are working days
        projectHolidays.forEach(holiday => {
            const holidayDate = new Date(holiday);
            if (holidayDate.getMonth() === currentDate.getMonth() && holidayDate.getFullYear() === currentDate.getFullYear()) {
                const dayOfWeek = holidayDate.getDay();
                if (!(dayOfWeek === 0 || dayOfWeek === 6)) {
                    if (holidayDate.getDate() >= startDay && holidayDate.getDate() <= endDay) {
                        workingDays--;
                    }
                }
            }
        });
        
        if (!workingDaysPerMonth[presentMonth]) {
            workingDaysPerMonth[presentMonth] = 0;
        }
        workingDaysPerMonth[presentMonth] += workingDays;
        
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1); // Ensure we start from the first day of the next month
    };
    return workingDaysPerMonth;
}
