import React from "react";
import {
  CssBaseline,
  Box,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";

//barre de chargement pour tous les composants
//besoin de loadingText en propriété

export function CustomLoading(props) {
  const appBarHeight = 128;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={`calc(100vh - ${appBarHeight}px)`}
      sx={{width:"100%"}}
    >
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mb: 5 }}>
        <Box justifyContent="center" alignItems="center">
          <LinearProgress />
          <Typography
            variant="body1"
            component="div"
            align="center"
            color="gray.main"
            sx={{ mt: 4 }}
          >
            {props.loadingText}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
