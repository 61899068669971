import React, { useState, useEffect } from 'react';
import { Box, Tooltip, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MarginDrafts from '../MarginDrafts';
import { CustomLoading } from '../../components/CustomLoading';
import { useSelector } from 'react-redux';
import _ from "underscore";

export function MarginDraftsDialog(props) {
    const { handleCalculatorClose, open, handleClickOpen } = props;

    const [filteredMarginDrafts, setFilteredMarginDrafts] = useState([]);
    const [filteredCountrySettings, setFilteredCountrySettings] = useState([]);
    const [initializeDraftData, setInitializeDraftData] = useState(true);

    const [selectedMarginDraftTab, setSelectedMarginDraftTab ] = useState("Draft 1");

    const getMarginDraftsStatus = useSelector((state) =>
        state.api.mutations["margin_drafts"] ? state.api.mutations["margin_drafts"].status : "loading",
    );

    const marginDraftsByUserId = useSelector((state) => {
        const mutationStatus = state.api.mutations["margin_drafts"];
        return mutationStatus ? (mutationStatus.status === "fulfilled" ? mutationStatus.data : []) : [];
    }, _.isEqual);

    const countriesSettings = useSelector(
        (state) => state.api.mutations["countries_settings"] ? (state.api.mutations["countries_settings"].status === "fulfilled" ? state.api.mutations["countries_settings"].data : []) : [],
        _.isEqual,
    );

    const getCountriesSettingsStatus = useSelector((state) =>
        state.api.mutations["countries_settings"] ? state.api.mutations["countries_settings"].status : "loading",
    );

        if (getMarginDraftsStatus === "fulfilled" && getCountriesSettingsStatus === "fulfilled" && initializeDraftData && selectedMarginDraftTab) {
          const initialMarginDrafts = marginDraftsByUserId[0]?.drafts.filter(
            (draft) => draft.slot === selectedMarginDraftTab
          );
          const initialCountrySettings = countriesSettings.filter(
            (country) => country.id === initialMarginDrafts[0]?.country
          );
          
          if (initialMarginDrafts && initialMarginDrafts.length > 0) {
            setFilteredMarginDrafts(initialMarginDrafts[0]);
            setFilteredCountrySettings(initialCountrySettings[0]);
            }
            setInitializeDraftData(false);
        }

    useEffect(() => {
    }, [filteredMarginDrafts, filteredCountrySettings]);

    const performTabChange = (newValue) => {
      setSelectedMarginDraftTab(newValue);

      const newMarginDrafts = marginDraftsByUserId[0]?.drafts.filter(
        (draft) => draft.slot === selectedMarginDraftTab
      );

      const newCountrySettings = countriesSettings.filter(
        (country) => country.id === newMarginDrafts[0]?.country
      );
      setFilteredMarginDrafts(newMarginDrafts[0]);
      setFilteredCountrySettings(newCountrySettings[0]);
  };

    useEffect(() => {
        if (selectedMarginDraftTab) {
            setInitializeDraftData(true);
        }
    }, [selectedMarginDraftTab]);

    return (
        <Box>
            <Tooltip title="Margin Estimation Calculator">
                <DialpadIcon
                    sx={{ color: 'white', border: '2px solid white', padding: "3px", mt: 1, mr: 1, borderRadius: 1, cursor: "pointer" }}
                    onClick={() => handleClickOpen()}
                />
            </Tooltip>
            <Dialog open={open} sx={{ '& .MuiDialog-paper': { width: '1200px', maxWidth: 'none' } }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        Margin Estimations Calculator
                        <Tooltip title="Here, you can create teams and add as many consultants as you need. You can create up to 5 different estimations. The estimations are not connected to any margins or projects therefore cannot be imported into a margin.">
                            <IconButton size="medium">
                                <HelpOutlineIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {getMarginDraftsStatus === "fulfilled" || !initializeDraftData ? (
                        <MarginDrafts
                            filteredMarginDrafts={filteredMarginDrafts}
                            filteredCountrySettings={filteredCountrySettings}
                            handleCalculatorClose={handleCalculatorClose}
                            setInitializeDraftData={setInitializeDraftData}
                            setFilteredCountrySettings={setFilteredCountrySettings}
                            selectedMarginDraftTab={selectedMarginDraftTab}
                            setSelectedMarginDraftTab={setSelectedMarginDraftTab}
                            performTabChange={performTabChange}
                        />
                    ) : (
                        <CustomLoading loadingText={"Loading drafts data..."} />
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
}
