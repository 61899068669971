/*
    c'est le composant qui vient apres INDEX.JS / APP.JS 

    il est le parent de MobileNavBar et de WebNavBar



*/
import React, { useContext, useEffect, useState } from "react";
import { CssBaseline, Box, Toolbar, useMediaQuery } from "@mui/material";

import { AppRouter } from "./AppRouter";
import {
  useGetCountriesSettingsMutation,
  useGetMarginDraftsByUserIdMutation,
  useGetPreventionPlanFilesMutation,
  useGetProjectsMutation,
  useGetSpecificUsersMutation,
  useGetUserInfosMutation,
} from "./api/eStarterApi";
import { useSelector } from "react-redux";
import _ from "underscore";
import { CustomLoading } from "./components/CustomLoading";
import MobileNavBar from "./components/navbars/MobileNavBar";
import { WebNavBar } from "./components/navbars/WebNavBar";
import EngagementsActiveOnlyContext from "./contexts/EngagementsActiveOnlyContext";
import UserContext from "./contexts/UserContext";

export const AppLayout = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const { setUserContext } = useContext(UserContext);
  const [ initializeApp, setInitializeApp ] = useState(true)
  const [ initializeData, setInitializeData ] = useState(false)

  const user = useSelector(
    (state) =>
      state.api.mutations["UserInfos"]
        ? state.api.mutations["UserInfos"].status === "fulfilled"
          ? state.api.mutations["UserInfos"].data
          : ""
        : "",
    _.isEqual,
  );
  setUserContext(user);


  const [getuser] = useGetUserInfosMutation({ fixedCacheKey: "UserInfos" });
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [postPreventionPlanFiles] = useGetPreventionPlanFilesMutation({ fixedCacheKey: "prevention_plan_files" });
  const [getMarginDraftsByUserId] = useGetMarginDraftsByUserIdMutation({ fixedCacheKey: "margin_drafts" });  
  const [getCountriesSettings] = useGetCountriesSettingsMutation({ fixedCacheKey: "countries_settings" });
  const [getSpecificUsers] = useGetSpecificUsersMutation({ fixedCacheKey: "specific_users" });


  useEffect(() => {
    if (initializeApp) {
      setInitializeData(true);
      setInitializeApp(false);
    }
    if (initializeData){
      getuser();
      postProjects({ remove_closed: true });
      setEngagementsActiveOnly(true);
      postPreventionPlanFiles({ id: "ALL", case: "Management" });
      getMarginDraftsByUserId();
      setInitializeData(false);
      getCountriesSettings();
      getSpecificUsers();
    }
  }, [getuser, postProjects, initializeData, postPreventionPlanFiles, setEngagementsActiveOnly, getMarginDraftsByUserId, initializeApp, setInitializeApp, getCountriesSettings, getSpecificUsers]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Barre de navigation qui dépend de la taille de l'écran */}
      {isSmallScreen ? (
        <MobileNavBar />
      ) : (
          <WebNavBar accounts={props.accounts}/>
      )}

      <Box
        component="main"
        sx={{ flexGrow: 1, height: "100vh", overflow: "auto" }}
      >
        {isSmallScreen ? null : <Toolbar />}
        {/* tant que l'on n'a pas récupérer le rôle, on affiche une barre de chargement définie dans component/CustomLoading */}
        {user.user_role !== "" ? (
          <AppRouter user_role={user.user_role} />
        ) : (
          <CustomLoading loadingText={"Loading data..."} />
        )}
      </Box>
    </Box>
  );
};
