import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {
  const { title, description, open, setOpen, onConfirm, mod, yesButtonText } = props;
  const [confirm, setConfirm] = useState(false);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    if (confirm) {
      onConfirm();
    }
  }, [confirm, onConfirm, setOpen]);

  const handleNoClick = () => {
    setConfirm(false);
    setOpen(false);
  };

  const handleYesClick = () => {
    setConfirm(true);
  };

  useEffect(() => {

    if (open && confirm) {
      handleConfirm();
    }
  }, [confirm, open, handleConfirm]);

  return (
    <Dialog
      open={open}
      onClose={handleConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{backgroundColor: 'rgba(0, 0, 1, 0.5)'}}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      {
        mod === "unsavedChanges" ?
        <DialogActions>
          <Button onClick={handleNoClick}>Stay</Button>
          <Button color="error" onClick={handleYesClick}>Leave anyway</Button>
        </DialogActions>
      :
        <DialogActions>
          <Button onClick={handleYesClick}>{yesButtonText}</Button>
          <Button color="error" onClick={handleNoClick}>Cancel</Button>
        </DialogActions>
      }
    </Dialog>
  );
}
