import React, { useState, useEffect } from "react";
import { 
Box, 
Button,
Chip,
Grid,  
Accordion,
AccordionSummary,
AccordionDetails, 
CssBaseline, 
Typography, 
FormControl, 
FormLabel, 
RadioGroup, 
FormControlLabel, 
Radio,
Tooltip} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RevenueBreakdownMonthCard from "./RevenueBreakdownMonthCard";
import { useParams } from "react-router-dom";
import { calculateWorkingDaysPerMonth, getPresentMonth } from "../../../commonFunction";

const RevenueBreakdown = React.memo((props) => {
  const [ initializeRevenueBreakdown, setInitializeRevenueBreakdown ] = useState(true)
  const [ revenueBreakdownFormValues, setRevenueBreakdownFormValues ] = useState({})
  const [ deltaAmount, setDeltaAmount ] = useState(0)
  const [ deltaStateValidity, setDeltaStateValidity ] = useState(props.deltaValidity)
  const [ revenueBreakdownValuesSum, setRevenueBreakdownValuesSum ] = useState(0)
  const [ needPaymentPlan, setNeedPaymentPlan ] = useState(false);

  const {year} = useParams();
  const mode = props.mode;
  const authorizedDelta = props.totalDailyRate
  const projectMargins = props.projectMargins
  const currentProject = props.currentProject
  const countrySettings = props.countrySettings
  const teamsRevenue = props.teamTotalRevenue

  const projectCurrency = countrySettings.currency

  const hasExistingPaymentPlan = projectMargins[0].engagement_margin.finance.need_payment_plan
  const paymentPlan = projectMargins[0].engagement_margin.finance.payment_plan
  
  
  const teams = projectMargins[0].engagement_margin.team

  const monthList = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
    
    // Function to determine the list of months in which you have team dates on the whole project
    function determineActiveMonths() {
        const activeMonths = [];
        teams.forEach(team => {
            const startDate = new Date(team.start_date);
            startDate.setDate(1);
            const endDate = new Date(team.end_date);
        
            for (let date = new Date(startDate); date <= endDate; date.setMonth(date.getMonth() + 1)) {
                const presentMonth = getPresentMonth(date);
                if (!activeMonths.includes(presentMonth)) {
                    activeMonths.push(presentMonth);
                }
            }
        });

        if (mode === "Revenue Breakdown") {
            return activeMonths;

        } else if (mode === "Payment Plan") {
            return monthList;

        } else {
            return []
        }
    }

let revenueBreakdownValues = null; 
if (mode === "Revenue Breakdown") {
    revenueBreakdownValues = projectMargins[0].engagement_margin.finance.revenue_breakdown
}
if (mode === "Payment Plan") {
    revenueBreakdownValues = projectMargins[0].engagement_margin.finance.payment_plan
}

const activeMonthsList = determineActiveMonths();
if (mode === "Revenue Breakdown") {
}

// const revenueBreakdownFormValues = {};
if (initializeRevenueBreakdown) {
    if (mode === "Payment Plan" && hasExistingPaymentPlan) {
        setNeedPaymentPlan(true);
    }
    for (const month in revenueBreakdownValues) {
            revenueBreakdownFormValues[month] = activeMonthsList.includes(month) ? revenueBreakdownValues[month] : null;
    }
    setRevenueBreakdownValuesSum(sumObjectValues(revenueBreakdownFormValues));
    setDeltaAmount(teamsRevenue - revenueBreakdownValuesSum);
    setInitializeRevenueBreakdown(false);
}


function getEarliestStartDateAndLatestEndDate() {
    if (teams.length === 0) {
        return null; // Return null if the array is empty
    }

    let earliestStartDate = new Date(teams[0].start_date);
    let latestEndDate = new Date(teams[0].end_date);

    teams.forEach(team => {
        const startDate = new Date(team.start_date);
        const endDate = new Date(team.end_date);

        if (startDate < earliestStartDate) {
            earliestStartDate = startDate;
        }

        if (endDate > latestEndDate) {
            latestEndDate = endDate;
        }
    });

    return {
        earliestStartDate: earliestStartDate,
        latestEndDate: latestEndDate
    };
}

    
    
    
    function sumObjectValues(obj) {
        let total = 0;
        for (const key in obj) {
            if (typeof obj[key] === 'number') {
            total += obj[key];
        }
    }
    return total;
}

const handleChange = (month, newValue) => {
    setRevenueBreakdownFormValues(prevState => ({
        ...prevState,
        [month]: newValue
    }));
    props.setUnsavedChanges(true);
};

const handleMonthlyClick = () => {
    const updatedRevenueBreakdown = { ...revenueBreakdownFormValues };
    
    const activeMonthsCount = activeMonthsList.length;
    const monthlyRevenue = teamsRevenue / activeMonthsCount;
    
    
    const lastMonthIndex = activeMonthsCount - 1
    const lastMonth = activeMonthsList[lastMonthIndex]
    
    const deltaRoundSum = teamsRevenue - (monthlyRevenue.toFixed(2) * activeMonthsCount)
    const lastMonthValue = monthlyRevenue + deltaRoundSum
    
    
    
    activeMonthsList.forEach(month => {
        updatedRevenueBreakdown[month] = monthlyRevenue.toFixed(2);
        if (lastMonth !== undefined && month === lastMonth) {
            updatedRevenueBreakdown[month] = lastMonthValue.toFixed(2);
        }
    });
    
    
    const newRevenueBreakdownFormValues = { ...updatedRevenueBreakdown };
    
    setRevenueBreakdownFormValues(newRevenueBreakdownFormValues);
    props.setUnsavedChanges(true);
};


const handleQuarterlyClick = () => {
    const updatedRevenueBreakdown = { ...revenueBreakdownFormValues };
    
    let quarterMonthsList = []
    let quarterCount = 0;
    
    const quarterPaymentMonthsList = ['March', 'June', 'September', 'December']
    
    quarterPaymentMonthsList.forEach((quarterMonth) => {
        if (activeMonthsList.includes(quarterMonth)) {
            quarterMonthsList.push(quarterMonth);
            quarterCount++;
        }
    })
    
    let quarterRevenue = 0;
    
    if (quarterCount !== 0) {
        quarterRevenue = teamsRevenue / quarterCount;
    }
    
    const lastQuarterMonthIndex = quarterCount - 1;
    const lastQuarterMonth = quarterMonthsList[lastQuarterMonthIndex]
    const deltaRoundSum = teamsRevenue - (quarterRevenue.toFixed(2) * quarterCount)
    const lastQuarterValue = quarterRevenue + deltaRoundSum

    
    activeMonthsList.forEach(month => {
        if (quarterPaymentMonthsList.includes(month)) {
            updatedRevenueBreakdown[month] = quarterRevenue.toFixed(2);
            if (lastQuarterMonth !== undefined && month === lastQuarterMonth) {
                updatedRevenueBreakdown[month] = lastQuarterValue.toFixed(2);
            }
        } else {
            updatedRevenueBreakdown[month] = 0;
        }
    });
    
    const newRevenueBreakdownFormValues = { ...updatedRevenueBreakdown };
    
    setRevenueBreakdownFormValues(newRevenueBreakdownFormValues);
    props.setUnsavedChanges(true);
};


let workingDaysPerMonth = 0;

if (mode === "Revenue Breakdown"){
    const { earliestStartDate, latestEndDate } = getEarliestStartDateAndLatestEndDate(teams);
    workingDaysPerMonth = calculateWorkingDaysPerMonth(earliestStartDate, latestEndDate, currentProject, countrySettings);
}
if (mode === "Payment Plan"){
    const earliestStartDate = new Date(year + "-01-01");
    const latestEndDate = new Date(year + "-12-31");
    workingDaysPerMonth = calculateWorkingDaysPerMonth(earliestStartDate, latestEndDate, currentProject, countrySettings);
}

const handleWorkingDaysClick = () => {
    const updatedRevenueBreakdown = { ...revenueBreakdownFormValues };

    let totalWorkingDays = 0;

    activeMonthsList.forEach(month => {
        totalWorkingDays += workingDaysPerMonth[month];
    });

    let revenuePerWorkingDay = 0;
    if (totalWorkingDays !== 0) {
        revenuePerWorkingDay = teamsRevenue / totalWorkingDays;
    }

    const activeMonthsCount = activeMonthsList.length;
    const lastMonthIndex = activeMonthsCount - 1;
    const lastMonth = activeMonthsList[lastMonthIndex];

    let accumulatedRevenue = 0;

    activeMonthsList.forEach(month => {
        const workingDaysResult = revenuePerWorkingDay * workingDaysPerMonth[month];
        updatedRevenueBreakdown[month] = workingDaysResult.toFixed(2);        
        accumulatedRevenue += Number(workingDaysResult.toFixed(2));        

        if (lastMonth !== undefined && month === lastMonth) {
            accumulatedRevenue -= Number(workingDaysResult.toFixed(2))
            const remainingRevenue = teamsRevenue - accumulatedRevenue;
            updatedRevenueBreakdown[lastMonth] = remainingRevenue.toFixed(2);
        }
    });

    const newRevenueBreakdownFormValues = { ...updatedRevenueBreakdown };    
    setRevenueBreakdownFormValues(newRevenueBreakdownFormValues);
    props.setUnsavedChanges(true);
};

const formatDeltaAmount = (amount) => {
    const number = parseFloat(amount);
    if (isNaN(number)) {
        return "0.00";
    }
    const absoluteNumber = Math.abs(number);
    return absoluteNumber.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const formattedDeltaAmount = formatDeltaAmount(deltaAmount);

useEffect(() => {
    // Filter out null values and convert remaining values to numbers
    const filteredRevenueBreakdownValues = Object.values(revenueBreakdownFormValues)
    .filter(value => value !== null)
    .map(value => Number(value));
    
    props.setMarginFinanceValues(revenueBreakdownFormValues);
    
    // Calculate newRevenueBreakdownValuesSum
    const newRevenueBreakdownSum = filteredRevenueBreakdownValues.reduce((acc, curr) => acc + curr, 0);
    setRevenueBreakdownValuesSum(newRevenueBreakdownSum);
    
    // Calculate deltaAmount
    const newDeltaAmount = teamsRevenue - newRevenueBreakdownSum;
    setDeltaAmount(newDeltaAmount);

    // Check the Delta Validity for MarginForm Submit
    if (Math.abs(deltaAmount) <= authorizedDelta) {
        props.setDeltaValidity(true);
        setDeltaStateValidity(true);
    } else {
        props.setDeltaValidity(false);
        setDeltaStateValidity(false);
    }
    // Check if the form needs a Payment Plan
    if (mode === "Payment Plan") {
        props.formNeedPaymentPlan(needPaymentPlan)
    }
    // Reset Payment Plan
    if (mode === "Payment Plan" && !needPaymentPlan) {
        setRevenueBreakdownFormValues(paymentPlan)
        props.setDeltaValidity(true)
    }
}, [revenueBreakdownFormValues, teamsRevenue, needPaymentPlan, setRevenueBreakdownFormValues, paymentPlan, mode, authorizedDelta, deltaAmount, props]);

return (
    <Box sx={{ my: 2}}>
      <CssBaseline />
        <Grid item xs={12} sm={12} md={12}>
            <Accordion
                defaultExpanded={true}
                sx={{border: '1px solid lightgray', height: "auto", width: "100%"}}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="baseline">
                        <Tooltip title={mode === "Revenue Breakdown" ? "Evenly allocates the total revenue from the KPIs to estimate the margins" : "Customer's payment schedule, to fill only if the payment is different from the revenue breakdown"}>
                            <Grid item sx={{display:"flex", alignItems:"center"}}>
                                <Typography variant="h6">{mode.toUpperCase()}</Typography>
                                {mode === "Revenue Breakdown" 
                                    ? <DateRangeOutlinedIcon fontSize="small" sx={{marginLeft:1}}/>
                                    : <CalendarMonthOutlinedIcon fontSize="small" sx={{marginLeft:1}}/>
                                }
                                {mode === "Revenue Breakdown" || needPaymentPlan ? 
                                (!deltaStateValidity && <span style={{ color: 'red', marginLeft: 2 }}>*</span>) 
                                : null}
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid item sx={{ mr: 2 }}>
                            <Chip
                                size="medium"
                                label={
                                    <Typography variant="subtitle2">
                                        {`REVENUE: \u00A0 ${parseFloat(teamsRevenue).toLocaleString('en-US', {minimumFractionDigits: 2})} ${projectCurrency.symbol}`}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container p={1} >
                        {mode === "Payment Plan" ? 
                            (
                                <Grid item pl={0.5} mb={2}>
                                    <Tooltip title="If your payment plan is different from your revenue breakdown, please select 'Yes' and indicate the payment due dates.">
                                        <FormControl>
                                            <FormLabel sx={{mb: 2}} >Is payment plan different?</FormLabel>
                                            <RadioGroup
                                                row
                                                value={needPaymentPlan ? "true" : "false"}
                                                onChange={(event) => {
                                                    setNeedPaymentPlan(event.target.value === 'true');
                                                    props.setUnsavedChanges(true);
                                                }}
                                            >
                                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                            ) : null 
                        }
                        <Grid container spacing={3} >
                            {mode === "Revenue Breakdown" || needPaymentPlan ? (
                                Object.keys(revenueBreakdownFormValues).map((month) => (
                                    <Grid item xs={6} sm={6} md={4} lg={2} xl={2} key={month}>
                                        <RevenueBreakdownMonthCard 
                                            name={month}
                                            value={revenueBreakdownFormValues[month]}
                                            disabled={!activeMonthsList.includes(month)}
                                            handleChange={handleChange}
                                            workingDays={workingDaysPerMonth[month]}
                                            currencySymbol={projectCurrency.symbol}
                                        />
                                    </Grid>
                                ))
                                ) : null}
                            {mode === "Payment Plan" && !needPaymentPlan ? null : 
                            <Grid item xs={12} sx={{ display: "flex", justifyContents:"space-between", alignItems:"flex-start", mt:2}}>
                                <Grid item xs={12} display="flex" alignItems="center">
                                    <Grid item>
                                        <Typography>
                                            Dispatch Team Revenue :
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{mx: 2, display: "flex", alignItems: "center", gap: 1}}>
                                        <Button variant="outlined" onClick={handleMonthlyClick}>
                                            Monthly
                                        </Button>
                                    {mode === "Payment Plan" ?                         
                                        <Button variant="outlined" onClick={handleQuarterlyClick}>
                                            Quarterly
                                        </Button> 
                                        : null }
                                        <Button variant="outlined" onClick={handleWorkingDaysClick}>
                                            Working Days
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{display: "flex", alignItems: "flex-end", flexDirection: "column", gap: 1}}>
                                    <Typography variant="body1" sx={{ fontWeight:"bold", color: revenueBreakdownValuesSum === teamsRevenue ? "green" : "black"}}>{`TOTAL: ${parseFloat(revenueBreakdownValuesSum).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} ${projectCurrency.symbol}`}</Typography>
                                    <Tooltip title={`The ${mode} shall not differ from the total revenue by more than the global daily rate (${authorizedDelta} ${projectCurrency.symbol}).`}>
                                        <Typography variant="body1" sx={{ fontWeight:"bold", color: Math.abs(deltaAmount) <= authorizedDelta ? "green" : "red"}}>{`DELTA: ${formattedDeltaAmount} ${projectCurrency.symbol}`}</Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                        
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </Grid>
    </Box>
  );
}, (prevProps, nextProps) => {

    return prevProps.totalDailyRate === nextProps.totalDailyRate &&
       prevProps.teamTotalRevenue === nextProps.teamTotalRevenue && 
       prevProps.deltaValidity === nextProps.deltaValidity;
  });
  
  export default RevenueBreakdown
