/*

Navbar pour les utilisateurs qui ont un grand écran
il y a des liens vers les différents compostants si on a les bons accès
les détails des bons accès sont dans Approuter

*/

import React, {useState} from "react";
import {
  Avatar,
  Box,
  AppBar,
  Button,
  Typography,
  Toolbar,
} from "@mui/material";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import _ from "underscore";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logoEStarter from "../../media/MPLogo.png";
import {MarginDraftsDialog} from "../../drafts/dialogs/MarginDraftsDialog";

export const WebNavBar = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;


  const userInfos = props.accounts.map((user) => user.idTokenClaims)[0];
  const userInitials = userInfos?.name
  .split(" ")
  .map((n) => n[0])
  .join("");
  
  const user = useSelector(
    (state) =>
    state.api.mutations["UserInfos"]
    ? state.api.mutations["UserInfos"].status === "fulfilled"
    ? state.api.mutations["UserInfos"].data
    : ""
    : "",
    _.isEqual,
  );
  


  // MARGIN DRAFTS
  const marginLocked = false;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleCalculatorClose = (event, reason) => {
    if (reason && reason === "backdropClick")   return; 
    setOpen(false);
  };


  return (
    <AppBar position="fixed" sx={{boxShadow:'none'}}>
      <Toolbar>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Link to="/">
              <img
                src={logoEStarter}
                height="50px"
                alt="al"
                style={{ marginRight: "15px" }}
              />
            </Link>

            {user.user_role === "" ? null : (
              <Link to={"/projects"} sx={{ mr: 2 }}>
                <Button
                  variant="text"
                  sx={{
                    textDecoration:
                      currentPath.startsWith("/engagementbrief") ||
                      currentPath.startsWith("/margin") ||
                      currentPath.startsWith("/preventionplan/")
                        ? "underline"
                        : "none",
                    color:
                      currentPath === "/" ||
                      currentPath === "/projects" ||
                      currentPath.startsWith("/engagementbrief") ||
                      currentPath.startsWith("/margin") ||
                      currentPath.startsWith("/preventionplan/")
                        ? "secondary.main"
                        : "white",
                  }}
                >
                  Projects
                </Button>
              </Link>
            )}
            <Typography variant="subtitle2" color="secondary">
              {" "}
              {currentPath.startsWith("/engagementbrief")
                ? " | Engagement Brief"
                : currentPath.startsWith("/preventionplan/")
                ? " | Prevention Plan "
                : 
                marginLocked
                ?
                null
                :
                currentPath.startsWith("/margin")
                ? " | Margin "
                : null}{" "}
            </Typography>

            {user.user_role === "Specific" ||
            user.user_role === "Admin" ||
            user.user_role === "Business" ? (
              <Link to={"/preventionplanmgt"} sx={{ ml: 2 }}>
                <Button
                  variant="text"
                  sx={{
                    color:
                      currentPath === "/preventionplanmgt"
                        ? "secondary.main"
                        : "white",
                  }}
                >
                  Prevention Plan Management
                </Button>
              </Link>
            ) : null}
            <Link to={"/help"} sx={{ ml: 2 }}>
              <Button
                variant="text"
                sx={{
                  color: currentPath.includes("help")
                    ? "secondary.main"
                    : "white",
                }}
              >
                Help
              </Button>
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
          {
            marginLocked ? 
            null : 
            ((user.user_role === "Admin" || user.user_role === "Business") ? 
            <MarginDraftsDialog               
              handleCalculatorClose={handleCalculatorClose}
              handleClickOpen={handleClickOpen}
              open={open}
            /> : null)
          }

            {
              user.user_role === "Admin" ?
              <Link to="/administratorsettings">
                <SettingsIcon 
                  sx={{
                    mt:1, 
                    mx:2,
                    color:
                      currentPath === "/administratorsettings" 
                        ? "secondary.main"
                        : "white",
                  }}
                />
              </Link>
              : null

            }
            <Link target="blank" to='https://app.powerbi.com/groups/dc349f72-a194-4c4d-a6fa-8d7b0c7802bb/reports/131234aa-9b6c-463f-8838-9b0a5c941657/ReportSection4485bb76a5a99fadddeb?language=fr-FR&experience=power-bi'>
                <QueryStatsIcon sx={{color:'white', mt:1, mr:2}}/>
            </Link>
            <Avatar sx={{ bgcolor: "secondary.main" }} size="small">
              {userInitials}
            </Avatar>
            {user.user_role !== "" ? (
              <Typography sx={{ mx: 1 }} variant="caption">
                {user.user_role + " access"}
              </Typography>
            ) : null}
            -
            <Typography sx={{ ml: 1 }} variant="caption">
              {userInfos?.name}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
