import React, { useCallback, useEffect, useState } from "react";
import { 
    Typography, 
    Accordion, 
    AccordionSummary, 
    Grid, 
    AccordionDetails,
    FormControl,
    TextField,
    Autocomplete,
    Stack,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    useMediaQuery,
    Tooltip,
    InputAdornment,
    createTheme,
    ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import ConfirmDialog from "../../components/dialog/ConfirmDialog";



const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: 'initial', // Resets the border color upon focus
                        },
                        '&:hover fieldset': {
                            borderColor: 'initial', // Resets the border color on hover
                        }
                    }
                }
            }
        }
    }
});


const TeamCardDraft = React.memo((props) => {

    // ________________________________________________________________________ Props ________________________________________________________________________
    const countrySettings = props.countrySettings
    const teamCard = props.teamCard
    const currencySymbol = countrySettings.currency.symbol
    // const updateTotalRevenue = props.updateTotalRevenue
    const handleChangeParent = props.handleChangeParent
    const teamCardIndex = props.index
    // const mode = props.mode
    const handleDeleteDraftCard = props.handleDeleteDraftCard
    const handleChangeDraftCardName = props.handleChangeDraftCardName
    // ________________________________________________________________________ State ________________________________________________________________________
    const [updatedInputValues, setUpdatedInputValues] = useState(props.teamCard)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(teamCard.name);
    
    // ________________________________________________________________________ Accordions & CSS ________________________________________________________________________
    const [open, setOpen] = useState(false)
    const handleToggleTeamCards = useCallback(() => {
        setOpen(!open);
      }, [open]);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
 
    // ________________________________________________________________________ Selector ________________________________________________________________________
    const selectTimeValue= ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"]

    // ________________________________________________________________________ Calculations ________________________________________________________________________
    const marginSettings = countrySettings.margin_settings
    const dailyAnnualBasis = marginSettings.day_annual_basis
    const monthlyAnnualBasis = marginSettings.month_annual_basis
    const chargesCoefficient = marginSettings.charges_coefficient

    const soldDays = updatedInputValues.sold_days

    const workedDays = updatedInputValues.worked_days
    
    const dailySaleRate = updatedInputValues.daily_sale_rate
    
    const monthlyGrossSalary = parseFloat(updatedInputValues.monthly_gross_salary);
    
    const annualBonus = parseFloat(updatedInputValues.annual_bonus);
    
    const monthlyFees = parseFloat(updatedInputValues.monthly_fees);
    const dailyFees = parseFloat(updatedInputValues.daily_fees);
    
    const dailyITCosts = updatedInputValues.daily_it_costs;
    
    const dailyDeskCosts = parseFloat(updatedInputValues.daily_desk_costs);
    
    const subcontractorCost = parseFloat(updatedInputValues.daily_purchase_rate);
    
    const officePresence = parseFloat(updatedInputValues.office_presence);

    const dailyProductionCosts = ((((monthlyGrossSalary * monthlyAnnualBasis) + annualBonus) * chargesCoefficient ) / dailyAnnualBasis) + dailyFees + ((monthlyFees * 12) / dailyAnnualBasis);
    const officeCosts = dailyITCosts + (dailyDeskCosts * (officePresence/100));

    // On calcule les coûts de production pour les consultants et les sous-traitants 
    const marginProductionCostConsultant = dailyProductionCosts + officeCosts
    const revenue = (soldDays * dailySaleRate).toFixed(2);

    const profit = (revenue - ((subcontractorCost ? subcontractorCost : marginProductionCostConsultant ) * workedDays))
    
    const engagementMargin = (profit / revenue * 100).toFixed(1)
    
    const refRate = (dailyProductionCosts/0.65).toFixed(2)
    
    const handleChangeChild = useCallback((event) => {
        const { name, value } = event.target;
        const regex = /^-?\d*\.?\d*$/;

        if (name === "office_presence" || name === "es3") {
            handleChangeParent(event, teamCard.id);
        }
        else {
            if (regex.test(value) || value === "") { 
                handleChangeParent(event, teamCard.id);
            } else {
                console.log("Invalid input for", name);
            }
        }
    }, [handleChangeParent, teamCard.id]);



const isRevenueAvailable = useCallback(() => {
        return updatedInputValues.sold_days > 0 && updatedInputValues.daily_sale_rate > 0;
}, [updatedInputValues.sold_days, updatedInputValues.daily_sale_rate]); 


    const handleOpenConfirmationDialog = () => {
        setConfirmDialogOpen(true);
      };

    useEffect(() => {
        setUpdatedInputValues(props.teamCard)
    }, [revenue, props.teamCard]);


    return(
        <ThemeProvider theme={theme}>

        <Accordion 
            expanded={open} onChange={handleToggleTeamCards} elevation={0}
            sx={{border:'1px solid lightgray'}}
            >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon />} 
                style={{userSelect: 'text', backgroundColor: isEditing ? '#ffffff' : 'inherit'}}
                >
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item flexDirection="column"  minWidth={"50%"}>
                        <Grid item display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="h6">
                            {isEditing ? (
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleChangeDraftCardName(teamCard.id, editName);
                                            setIsEditing(false);
                                            e.target.blur();

                                        } else if (e.key === 'Escape') {
                                            setIsEditing(false);
                                            e.target.blur();
                                        }
                                    }}
                                    autoFocus
                                    inputProps={{
                                        name: "name", 
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'initial',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'initial',
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                teamCard.name === "" ? teamCard.daily_purchase_rate === null ? `Consultant#${teamCardIndex + 1}` : teamCard.es3.toString() === "true" ? `ES3#${teamCardIndex + 1}` : `Subcontractor#${teamCardIndex + 1}` : teamCard.name
                            )}
                            </Typography>
                                <Typography variant="subtitle2" ml={1}>
                                {teamCard.daily_purchase_rate !== null ? teamCard.es3 ? "(ES3)" : "(Subcontractor)" : null}
                            </Typography> 
                                {/* {mode === "Margin" ? !props.isCardComplete && <span style={{ color: 'red', marginLeft: 2 }}>*</span> : null} */}
                            <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(!isEditing);
                            }}
                            >
                            <EditIcon 
                              size="small" 
                              />
                          </IconButton>
                            <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOpenConfirmationDialog();
                            }}
                            >
                            <DeleteIcon 
                              color="error" 
                              size="small" 
                              />
                          </IconButton>
                            {confirmDialogOpen ? 
                                <ConfirmDialog
                                    title="Are you sure you want to delete this card?"
                                    description=''
                                    mod="Delete"
                                    yesButtonText="Delete"
                                    open={confirmDialogOpen}
                                    setOpen={setConfirmDialogOpen}
                                    onConfirm={() => handleDeleteDraftCard(teamCard.id)}
                                />
                                : null } 
                        </Grid>
                    </Grid>
                    <Tooltip title="Engagement Margin = (Profit = Revenue - Consultant costs) / Revenue">
                        <Grid item display='flex' alignItems="center">
                            <Typography variant="subtitle2" mx={2}>
                                Eng.margin :
                            </Typography>
                            <Typography variant="h6">
                            {props.isCardComplete && !isNaN(engagementMargin) 
                            ? engagementMargin
                            : "--"} %
                            </Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title="Revenue = Daily Rate * Sold Days">
                        <Grid item display='flex' alignItems="center">
                            <Typography variant="subtitle2" mx={2}>
                                Revenue :
                            </Typography>
                            <Typography variant="h6">
                                {
                                    isRevenueAvailable() ?
                                    parseFloat(revenue).toLocaleString('en-US', {minimumFractionDigits: 2}) 
                                    : 0
                                } {currencySymbol}
                            </Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl fullWidth>
                    <Grid container display="flex" alignItems="center" width="100%">
                        <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            divider={
                                <Divider orientation="vertical" flexItem sx={{height: "120px"}}/>
                            }
                            spacing={2}
                            width="100%"
                            >
                            <Grid item display='flex'justifyContent="flex-start" xs={12} sm={12} md={4}>
                                <Grid item flexDirection="column" display='flex' alignItems="flex-start" pl={3}>
                                    <Grid container fullwidth>
                                        <FormControl >
                                            <Grid item display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid width="250px">
                                                    <TextField
                                                        key={teamCard.id}
                                                        size="small"
                                                        label="Worked days"
                                                        name="worked_days"
                                                        sx={{ my: 1 }}
                                                        required
                                                        fullWidth
                                                        value={updatedInputValues.worked_days}
                                                        onChange={handleChangeChild}
                                                        error={!updatedInputValues.worked_days} 
                                                        />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <Grid width="250px">
                                                <TextField
                                                    key={teamCard.id}
                                                    fullWidth
                                                    size="small"
                                                    label="Sold days"
                                                    sx={{ my: 1 }}
                                                    required
                                                    name="sold_days"
                                                    value={updatedInputValues.sold_days}
                                                    onChange={handleChangeChild}
                                                    error={!updatedInputValues.sold_days || isNaN(updatedInputValues.sold_days)}
                                                    />
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid >
                                        <FormControl>
                                        <Grid item display="flex" justifyContent="space-between" alignItems="center">
                                            <Tooltip title="The sale rate will only be taken into account if the provided value is the daily sale rate. Monthly or hourly sale rates will not be displayed">
                                                <Grid item width="250px">
                                                    <TextField
                                                        key={teamCard.id}
                                                        size="small"
                                                        fullWidth
                                                        label="Daily rate"
                                                        defaultValue={updatedInputValues.daily_sale_rate ? updatedInputValues.daily_sale_rate : 0}
                                                        value={updatedInputValues.daily_sale_rate}
                                                        name="daily_sale_rate"
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                        isNaN(updatedInputValues.daily_sale_rate) || 
                                                        updatedInputValues.daily_sale_rate === ""  
                                                        }
                                                    />
                                                </Grid>
                                            </Tooltip>
                                                {updatedInputValues.daily_purchase_rate !== null ?
                                                    null :
                                                    (
                                                        <Tooltip title="Tref: Reference rate calculated according to the consultant's costs to reach the required 35% margin rate">
                                                            <Grid item display="flex" flexDirection="column" alignItems="center" pl={2}>
                                                                <Typography variant="caption">
                                                                    Tref: 35%
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {
                                                                        updatedInputValues.office_presence &&
                                                                        updatedInputValues.daily_fees !== '' &&
                                                                        !isNaN(updatedInputValues.daily_fees) &&
                                                                        updatedInputValues.monthly_fees !== '' &&
                                                                        !isNaN(updatedInputValues.monthly_fees) &&
                                                                        updatedInputValues.monthly_gross_salary !== '' &&
                                                                        !isNaN(updatedInputValues.monthly_gross_salary) &&
                                                                        updatedInputValues.annual_bonus !== '' &&
                                                                        !isNaN(updatedInputValues.annual_bonus)
                                                                        ? refRate
                                                                        : "-- "
                                                                    }
                                                                    {currencySymbol}
                                                                </Typography>
                                                            </Grid>
                                                        </Tooltip>
                                                    )
                                                }
                                            </Grid>

                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {updatedInputValues.daily_purchase_rate !== null
                                ? (
                                    <Stack
                                    direction={isSmallScreen ? "column" : "row"}
                                    divider={<Divider orientation="vertical" flexItem sx={{height: "120px"}} />}
                                    spacing={2}
                                    width="100%"
                                    alignItems="flex-start"
                                    >
                                        <Grid item display='flex' alignItems="center" justifyContent="space-evenly" xs={12} sm={12} md={3}>
                                            <Grid item display="flex" flexDirection="column" alignItems="center">
                                                <FormLabel sx={{mx:2}} >Is Subcontractor an ES3 ? </FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={updatedInputValues.es3}
                                                    name="es3"
                                                    onChange={(event) => handleChangeChild(event)}
                                                    >
                                                    <FormControlLabel value='true' control={<Radio />} label="Yes" />
                                                    <FormControlLabel value='false' control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item display='flex' alignItems="center" justifyContent="space-evenly" xs={12} sm={12} md={3}>
                                            <Tooltip title="The purchase rate will only be taken into account if the provided value is the daily purchase rate. Monthly or hourly purchase rates will not be displayed">
                                                <Grid item>
                                                    <FormControl>
                                                        <TextField
                                                            size="small"
                                                            label="Daily Purchase Rate"
                                                            defaultValue={updatedInputValues.daily_purchase_rate ? updatedInputValues.daily_purchase_rate : 0}
                                                            name="daily_purchase_rate"
                                                            value={updatedInputValues.daily_purchase_rate}
                                                            onChange={handleChangeChild}
                                                            sx={{ my: 1 }}
                                                            required
                                                            InputProps={{
                                                                endAdornment: 
                                                                <InputAdornment position="end">
                                                                        {currencySymbol}
                                                                    </InputAdornment>
                                                            }}
                                                            error={
                                                                updatedInputValues.daily_purchase_rate === "" || 
                                                                updatedInputValues.daily_purchase_rate === null 
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Stack> 

                                ) : (
                                    <Stack
                                        direction={isSmallScreen ? "column" : "row"}
                                        divider={<Divider orientation="vertical" flexItem sx={{height: "120px"}}/>}
                                        spacing={2}
                                        width="100%"
                                        alignItems="flex-start"
                                        >
                                        <Grid item flexDirection="column" display="flex" alignItems="center"xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl fullwidth>

                                                    <TextField
                                                        size="small"
                                                        required
                                                        label="Monthly gross salary"
                                                        defaultValue={updatedInputValues.monthly_gross_salary ? updatedInputValues.monthly_gross_salary : 0}
                                                        name="monthly_gross_salary"
                                                        value={updatedInputValues.monthly_gross_salary}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                            !updatedInputValues.monthly_gross_salary && updatedInputValues.monthly_gross_salary === ""
                                                        }
                                                        />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl fullwidth>
                                                    <TextField
                                                        size="small"
                                                        label="Annual bonus"
                                                        defaultValue={updatedInputValues.annual_bonus ? updatedInputValues.annual_bonus : 0}
                                                        name="annual_bonus"
                                                        value={updatedInputValues.annual_bonus}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                        !updatedInputValues.annual_bonus && updatedInputValues.annual_bonus === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        >
                                    
                                                    <TextField
                                                        size="small"
                                                        label="Daily fees"
                                                        defaultValue={updatedInputValues.daily_fees ? updatedInputValues.daily_fees : 0}
                                                        name="daily_fees"
                                                        value={updatedInputValues.daily_fees}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                        !updatedInputValues.daily_fees && 
                                                        updatedInputValues.daily_fees === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl>
                                                    <TextField
                                                        size="small"
                                                        label="Monthly fees"
                                                        defaultValue={updatedInputValues.monthly_fees ? updatedInputValues.monthly_fees : 0}
                                                        name="monthly_fees"
                                                        value={updatedInputValues.monthly_fees}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                        updatedInputValues.monthly_fees === undefined ||
                                                        updatedInputValues.monthly_fees === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid  item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl fullwidth>
                                               
                                                    <TextField
                                                        size="small"
                                                        label="Daily IT costs"
                                                        defaultValue={updatedInputValues.daily_it_costs ? updatedInputValues.daily_it_costs : 0}
                                                        name="daily_it_costs"
                                                        value={updatedInputValues.daily_it_costs}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={
                                                        !updatedInputValues.daily_it_costs && 
                                                        updatedInputValues.daily_it_costs === ""
                                                        }
                                                        />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                            <FormControl fullwidth>
                                                <TextField
                                                    size="small"
                                                    label="Daily desk costs"
                                                    defaultValue={updatedInputValues.daily_desk_costs ? updatedInputValues.daily_desk_costs : 0}
                                                    name="daily_desk_costs"
                                                    value={updatedInputValues.daily_desk_costs}
                                                    onChange={handleChangeChild}
                                                    sx={{ my: 1 }}
                                                    required
                                                    InputProps={{
                                                        endAdornment: 
                                                        <InputAdornment position="end">
                                                                {currencySymbol}
                                                            </InputAdornment>
                                                    }}
                                                    error={
                                                        !updatedInputValues.daily_desk_costs &&
                                                        updatedInputValues.daily_desk_costs === ""
                                                    }
                                                    />
                                            </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item display='flex' alignItems="center" justifyContent="center" xs={12} sm={12} md={4}>
                                            <Grid item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={12}>
                                                <Grid item width="100%">
                                                    <FormControl fullWidth >
                                                        <Autocomplete
                                                            options={selectTimeValue}
                                                            value={updatedInputValues.office_presence}
                                                            onChange={(event, newValue) => handleChangeChild({ target: { name: "office_presence", value: newValue } })}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                {...params}
                                                                label="Production from M|P site"
                                                                size="small"
                                                                required
                                                                sx={{ my: 1 }}
                                                                error={!updatedInputValues.office_presence}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                )
                            }
                        </Stack>
                    </Grid>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    </ThemeProvider>
    );
}, (prevProps, nextProps) => {
        return prevProps.countrySettings === nextProps.countrySettings &&
        prevProps.teamCard.name === nextProps.teamCard.name &&
        prevProps.teamCard.worked_days === nextProps.teamCard.worked_days &&
        prevProps.teamCard.id === nextProps.teamCard.id &&
        prevProps.teamCard.sold_days === nextProps.teamCard.sold_days &&
        prevProps.teamCard.daily_desk_costs === nextProps.teamCard.daily_desk_costs &&
        prevProps.teamCard.daily_fees === nextProps.teamCard.daily_fees &&
        prevProps.teamCard.daily_it_costs === nextProps.teamCard.daily_it_costs &&
        prevProps.teamCard.daily_sale_rate === nextProps.teamCard.daily_sale_rate &&
        prevProps.teamCard.daily_purchase_rate === nextProps.teamCard.daily_purchase_rate &&
        prevProps.teamCard.annual_bonus === nextProps.teamCard.annual_bonus &&
        prevProps.teamCard.monthly_fees === nextProps.teamCard.monthly_fees &&
        prevProps.teamCard.monthly_gross_salary === nextProps.teamCard.monthly_gross_salary &&
        prevProps.teamCard.office_presence === nextProps.teamCard.office_presence &&
        prevProps.teamCard.es3 === nextProps.teamCard.es3;
        });

export default TeamCardDraft;