import React, { useState } from "react";
import {
    Box,
    InputAdornment,
    IconButton,
    FormControl,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Button,
    TextField,
    Typography
  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DialogSpecificUsers from "../dialogs/DialogSpecificUsers";
import { Search } from "@mui/icons-material";
import { ClearIcon } from "@mui/x-date-pickers";

export default function SpecificUsersSettings(props) {

    const specificUsers = props.specificUsers.specific_users_list;
    const allEmails = props.specificUsers.all_employees_emails_list;
    const filteredDepartments = props.specificUsers.departments_by_country;

    const [editMod, setEditMod] = useState("Add");
    const [search, setSearch] = useState('');
    const [openDialogSpecificUser, setOpenDialogSpecificUser] = useState(false);
    const [specificUserForUpdate, setSpecificUserForUpdate] = useState(null);

    const filteredSpecificUsers = specificUsers.filter((specificUser) => {
        const upperedCaseSearch = search.toUpperCase();
        const matchesName = specificUser.name.toUpperCase().includes(upperedCaseSearch);
        const matchesBusinessUnit = specificUser.business_units.some(bu => bu.toUpperCase().includes(upperedCaseSearch));
        return matchesName || matchesBusinessUnit;
    });

    const handleClearClick= () => {
        setSearch("")
    }


    return(
        <Box>
            <Grid container my={3}>
                {
                    openDialogSpecificUser ? 
                    (
                        <DialogSpecificUsers 
                            editMod={editMod}
                            filteredDepartments={filteredDepartments}
                            allEmails={allEmails}
                            openDialog={openDialogSpecificUser}
                            setOpenDialog={setOpenDialogSpecificUser}
                            specificUserForUpdate={specificUserForUpdate}
                            setSpecificUserForUpdate={setSpecificUserForUpdate}
                        />
                    ) : null
                }
                <FormControl fullWidth>
                    <Grid container display="flex" alignItems="center" my={3} spacing={2}>
                        <Grid item>
                            <TextField
                                style={{ width: "300px" }}
                                size="small"
                                label="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Search />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <IconButton
                                        sx={{
                                          visibility: search !== "" ? "visible" : "hidden",
                                        }}
                                        size="small"
                                        onClick={handleClearClick}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ),
                                  }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setEditMod("Add")
                                    setOpenDialogSpecificUser(true);
                                }}
                            >
                                + Add a new specific user
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl fullWidth>
                    <TableContainer >
                        <Table aria-label="simple table" style={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="400px" align="center"> Name </TableCell>
                                    <TableCell width="400px" align="center"> Attributed Business Units </TableCell>
                                    <TableCell width="300px" align="center"> Attributed Countries </TableCell>
                                    <TableCell width="200px" align="center"> Receiving notifications </TableCell>
                                    <TableCell width="100px" align="center"></TableCell>
                                    <TableCell width="100px" align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredSpecificUsers.map((specificUser, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">  
                                            <Grid item > 
                                                <Typography>
                                                    {specificUser.name} 
                                                </Typography>
                                            </Grid>
                                            <Grid item > 
                                                <Typography variant="caption">
                                                    {specificUser.id} 
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center"> {specificUser.business_units.length > 0 ? specificUser.business_units.join(' - ') : "--"} </TableCell>
                                        <TableCell align="center"> {specificUser.countries.length > 0 ? specificUser.countries.join(' - ') : "--"} </TableCell>
                                        <TableCell align="center"> {specificUser.receive_declaratives ? "Yes" : "No"} </TableCell>
                                        <TableCell align="center"> 
                                            <EditIcon 
                                                color = "primary"
                                                cursor="pointer" 
                                                onClick={() => {
                                                    setEditMod("Edit")
                                                    setSpecificUserForUpdate(specificUser)
                                                    setOpenDialogSpecificUser(true);
                                                }}
                                            /> 
                                        </TableCell>
                                        <TableCell align="center"> 
                                            <DeleteIcon 
                                                color = "error"
                                                cursor="pointer" 
                                                onClick={() => {
                                                    setEditMod("Delete")
                                                    setSpecificUserForUpdate(specificUser)
                                                    setOpenDialogSpecificUser(true);
                                                }}
                                            /> 
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </FormControl>
            </Grid>
        </Box>
    )
}