/* 
      ___________________________________________________________ INDEX.JS
      c'est le composant parent de l'application
      
      fichier configuré de manière standard avec un store et service worker


*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./connexion/params/authConfig";

import { store } from "./store";
import { Provider } from "react-redux";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
);
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
reportWebVitals();
