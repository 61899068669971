import { useState } from "react";
import { 
    Autocomplete, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    FormControl, 
    TextField, 
    Typography,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid 
} from "@mui/material";
import { useGetSpecificUsersMutation, useSubmitSpecificUsersMutation } from "../../api/eStarterApi";
import { useDispatch } from "react-redux";
import { CustomLoading } from "../../components/CustomLoading";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";

export default function DialogSpecificUsers(props) {
    const filteredDepartments = props.filteredDepartments;
    const allEmails = props.allEmails;

    const [newSpecificUserName, setNewSpecificUserName] = useState("");
    const [newSpecificUserMail, setNewSpecificUserMail] = useState("");
    const [newSpecificUserCountry, setNewSpecificUserCountry] = useState([]);
    const [newSpecificUserBusinessUnits, setNewSpecificUserBusinessUnits] = useState([]);
    const [newSpecificUserDeclaratives, setNewSpecificUserDeclaratives] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initializeDataToUpdate, setInitializeDataToUpdate] = useState(true)
    const dispatch = useDispatch();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@(migso-pcubed|MIGSO-PCUBED)\.com$/i;
    const isValidEmail = (email) => emailRegex.test(email);

    const [postSpecficUsers] = useSubmitSpecificUsersMutation();
    const [getSpecificUsers] = useGetSpecificUsersMutation({fixedCacheKey: "specific_users"});

    const countriesList = filteredDepartments.map(country => country.country);
    
    const departmentsList = filteredDepartments.reduce((accumulator, country) => {
        country.departments.forEach(department => {
            if (!accumulator.includes(department)) {
                accumulator.push(department);
            }
        });
        return accumulator;
    }, []);
    

    if ((props.editMod === "Delete" || props.editMod === "Edit") && initializeDataToUpdate) {
        setNewSpecificUserName(props.specificUserForUpdate.name);
        setNewSpecificUserBusinessUnits(props.specificUserForUpdate.business_units);
        setNewSpecificUserCountry(props.specificUserForUpdate.countries);
        setNewSpecificUserMail(props.specificUserForUpdate.id);
        setNewSpecificUserDeclaratives(props.specificUserForUpdate.receive_declaratives)
        setInitializeDataToUpdate(false);
    }

    const handleClose = () => {
        props.setOpenDialog(false);
        props.setSpecificUserForUpdate(null);
        setNewSpecificUserBusinessUnits([]);
        setNewSpecificUserCountry([]);
        setNewSpecificUserName("");
        setNewSpecificUserMail("");
        setNewSpecificUserDeclaratives(false)
    };

    const handleBusinessUnitsChange = (event, newValue) => {
        setNewSpecificUserBusinessUnits(newValue);
        setUnsavedChanges(true);
    };

    const handleCountriesChange = (event, newValue) => {
        setNewSpecificUserCountry(newValue);
        setUnsavedChanges(true);
    };

    const handleNameChange = (event) => {
        setNewSpecificUserName(event.target.value);
        setUnsavedChanges(true);
    };

    const handleMailChange = (event, newValue) => {
        setNewSpecificUserMail(newValue || "");
    };

    const handleChangeDeclaratives = (event) => {
        let value = event.target.value
        if (value === "true") {
            setNewSpecificUserDeclaratives(true);
        } else {
            setNewSpecificUserDeclaratives(false)
        }
        setUnsavedChanges(true)
    }
    
    const handleSubmit = async (action) =>{ 
        setLoading(true);
        let response = {}
        let submitCase = null
        if (action === "Submit") {
            submitCase = "Submit"
        } else if (action === "Delete") {
            submitCase = "Delete"
        } else {
            submitCase ="Edit"
        }
        if (!isValidEmail(newSpecificUserMail)) {
            alert("Please enter a valid email address that ends with @MIGSO-PCUBED.COM");
            return;
        }
        try { 
            const upperCasedNewSpecificUserMail = newSpecificUserMail.toUpperCase();
            const upperCasedNewSpecificUserName = newSpecificUserName.toUpperCase();
            response = await postSpecficUsers({
                case: submitCase,
                id: upperCasedNewSpecificUserMail,
                name: upperCasedNewSpecificUserName,
                countries: newSpecificUserCountry,
                business_units: newSpecificUserBusinessUnits,
                receive_declaratives: newSpecificUserDeclaratives
            }).unwrap();
            getSpecificUsers();
            setInitializeDataToUpdate(true)
            setUnsavedChanges(false)
            dispatch({
                type: "snackbarInfo/setNotification",
                payload: {
                snackbarOpen: true,
                snackbarType: response.status,
                snackbarMessage: response.msg,
                },
            });
        } catch (error) {
            console.log(error)
            dispatch({
                type: "snackbarInfo/setNotification",
                payload: {
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage:
                    "An error occurred during saving. Please try again. Error: " +
                    error.data.msg,
                },
            });
        }  finally {
            // dans tous les cas, on arrête le chargement des données et on
            setLoading(false);
        }
    };

    return loading ? (
        <Dialog open={props.openDialog} maxWidth="md" fullWidth>
            <CustomLoading loadingText={"Saving..."} />
        </Dialog>
    ) : (
        <Dialog
            open={props.openDialog}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            py={1}
        >
            <Notification />
            
            <DialogContent>
                <Typography variant="h4" my={2}>
                    {props.editMod} a specific user here
                </Typography>
                    <FormControl sx={{ mt: 2 }} fullWidth>
                        <TextField
                            size="small"
                            required
                            sx={{ my: 1.5 }}
                            label="Full name"
                            variant="outlined"
                            disabled={props.editMod === "Delete"}
                            value={newSpecificUserName}
                            onChange={handleNameChange}
                            error={!newSpecificUserName}
                        />
                        {props.editMod === "Edit" ? 
                            <Typography variant="caption" color="error">
                                Mail addresses cannot be updated.
                            </Typography>
                        : null
                        }
                        <Autocomplete
                            options={allEmails}
                            size="small"
                            sx={{ my: 1.5 }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            disabled={props.editMod !== "Add"}
                            value={newSpecificUserMail}
                            onChange={handleMailChange}
                            filterOptions={(options, state) => {
                                if (state.inputValue.length >= 3) {
                                    return options.filter((option) =>
                                        (option || "").toUpperCase().includes(state.inputValue.toUpperCase())
                                    );
                                }
                                return [];
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Mail address"
                                    size="small"
                                    error={!newSpecificUserMail || !isValidEmail(newSpecificUserMail)}
                                />
                            )}
                        />
                        <Autocomplete
                            options={departmentsList}
                            required
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option === value}
                            multiple
                            size="small"
                            sx={{ my: 1.5 }}
                            disabled={props.editMod === "Delete"}
                            value={newSpecificUserBusinessUnits}
                            onChange={handleBusinessUnitsChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Attribute business units"
                                    size="small"
                                    error={newSpecificUserBusinessUnits.length === 0 && newSpecificUserCountry.length === 0}
                                />
                            )}
                        />
                        <Autocomplete
                            options={countriesList}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option === value}
                            multiple
                            size="small"
                            sx={{ my: 1.5 }}
                            disabled={props.editMod === "Delete"}
                            value={newSpecificUserCountry}
                            onChange={handleCountriesChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Attribute countries (optionnal)"
                                    size="small"
                                    error = {newSpecificUserBusinessUnits.length === 0 && newSpecificUserCountry.length === 0}
                                />
                            )}
                        />
                            <Grid item display="flex" alignItems="center" sx={{ mt: 1.5 }}>
                                <FormLabel required sx={{mx:2}} > Will this user receive declaratives ? </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={newSpecificUserDeclaratives}
                                    name="es3"
                                    onChange={handleChangeDeclaratives}
                                >
                                    <FormControlLabel value='true' control={<Radio disabled={props.editMod === "Delete"}/>} label="Yes" />
                                    <FormControlLabel value='false' control={<Radio disabled={props.editMod === "Delete"}/>} label="No" />
                                </RadioGroup>
                            </Grid>
                    </FormControl>  
            </DialogContent>
            {
                props.editMod === "Add" || props.editMod === "Edit" ? (
                    <DialogActions>
                        {
                            props.editMod === "Add" &&
                                <Button
                                    onClick={()=> {handleSubmit("Submit")}}
                                    autoFocus
                                    color="primary"
                                    disabled={
                                        (newSpecificUserCountry.length === 0 && newSpecificUserBusinessUnits.length === 0) || 
                                        !newSpecificUserMail || 
                                        !isValidEmail(newSpecificUserMail) || 
                                        !newSpecificUserName
                                    }
                                >
                                    Save
                                </Button>
                        }
                        {
                            props.editMod === "Edit" &&
                            <Button
                                onClick={()=> {handleSubmit("Edit")}}
                                autoFocus
                                color="primary"
                                disabled={
                                    (newSpecificUserCountry.length === 0 && newSpecificUserBusinessUnits.length === 0) || 
                                    !newSpecificUserMail|| 
                                    !isValidEmail(newSpecificUserMail) || 
                                    !newSpecificUserName
                                }
                            >
                                Update
                            </Button>
                        }
                        {unsavedChanges ? 
                            <Button onClick={() => setConfirmDialogOpen(true)} color="error">
                                Cancel
                            </Button> 
                            :<Button onClick={handleClose} color="error">
                                Cancel
                            </Button>
                        }
                        {confirmDialogOpen ? (
                            <ConfirmDialog
                                mod="unsavedChanges"
                                title="Are you sure you want to cancel ?"
                                description="Any unsaved changes will be lost."
                                open={confirmDialogOpen}
                                setOpen={setConfirmDialogOpen}
                                onConfirm={handleClose}
                            />
                            ): null 
                        }
                    </DialogActions>
                ) : null
            }
            
            { props.editMod === "Delete"?
                (
                    <DialogActions>
                        <Button
                            onClick={()=> {setConfirmDialogOpen(true)}}
                            autoFocus
                            color="primary"
                            disabled={
                                (newSpecificUserCountry.length === 0 && newSpecificUserBusinessUnits.length === 0) || 
                                !newSpecificUserMail || 
                                !newSpecificUserName
                            }
                        >
                            Delete
                        </Button>
                        <Button onClick={handleClose} color="error">
                            Cancel
                        </Button>

                        {confirmDialogOpen ? (
                            <ConfirmDialog
                                title="Are you sure you want to delete this user's role?"
                                description="This action is permanent."
                                mod="Delete"
                                yesButtonText="DELETE"
                                open={confirmDialogOpen}
                                setOpen={setConfirmDialogOpen}
                                onConfirm={()=> {handleSubmit("Delete")}}
                            />
                            ): null 
                        }
                    </DialogActions>

                ):null
            }
        </Dialog>
    )
}
