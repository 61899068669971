/*

composant pour joindre des fichiers et les stocker dans le storage 
en fonciton de la propriété editMod, si c'est en mode EDIT, on peut ajouter des fichiers, sinon on ne peut que les télécharger pour les lire

comme c'est un input différent des autres, j'ai fait un composant à part que je réutilise pour les plans de prévention et engagemnt brief

*/

import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useGetFileContentMutation } from "../../api/eStarterApi";
import { CustomLoading } from "../../components/CustomLoading";
import { truncateText } from "../../commonFunction";

export const AttachmentInput = (props) => {
  const editMod = props.editMod;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(props.initialFiles ||[]);
  const [getFileContent] = useGetFileContentMutation();

  const handleButtonClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = handleFileChange;
    input.click();
  };

  const handleFileChange = (event) => {
    //size limit 50Mo pour eviter les trop gros fichiers (bien que c'est possible de stocker jusqu'à 4To/fichier)
    if (event.target.files[0].size < 50 * 1024 * 1024) {
      const newFiles = Array.from(event.target.files);
      setSelectedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...newFiles];
        if (props.onChange) {
          props.onChange(updatedFiles);
        }
        return updatedFiles;
      });
    } else {
      setErrorSize(true);
    }
  };

  const onDrop = (acceptedFiles) => {
    // Traitement des fichiers déposés
    const newFiles = acceptedFiles.filter(file => file.size < 50 * 1024 * 1024);
    if (newFiles.length !== acceptedFiles.length) {
      setErrorSize(true); // Gérer l'erreur si un fichier est trop grand
    }
    setSelectedFiles(prevFiles => {
      const updatedFiles = [...prevFiles, ...newFiles];
      if (props.onChange) {
        props.onChange(updatedFiles);
      }
      return updatedFiles;
    });
  };

  const { getRootProps, getInputProps } = useDropzone({onDrop});

  const uploadFile = async (file) => {
    setLoading(true);
    if (file) {
      try {
        const response = await getFileContent({ blob: file.blob });

        if (response.data) {
          const content = response.data.content;
          const mime_type = response.data.mime_type;
          const fileUrl = `data:${mime_type};base64,${content}`;

          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(`Error fetching blob content: ${response.statusText}`);
        }
      } catch (error) {
        console.error("Error fetching file from Azure function:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove),
    );
    if (props.onChange) {
      props.onChange(
        selectedFiles.filter((_, index) => index !== indexToRemove),
      );
    }
  };

  const handleClose = () => {
    setErrorSize(false);
  };

  return errorSize ? (
    <Dialog open={errorSize} fullWidth onClose={handleClose}>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          You cannot add files that are larger than 50 MB.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  ) : loading ? (
    <CustomLoading loadingText={"Dowloading..."} />
  ) : (
    <Grid container spacing={1} direction="row" justifyContent="center">     
      {/* Zone de drop */}
      {editMod === "EDIT" ? (
      <Grid item xs={12} sm={12} md={12} >
        <Grid item {...getRootProps()}  sx={{ border: '1px dashed gray', p: 4, mt: 2 }}>
          <input {...getInputProps()}/>
          Drag and drop files here, or click to upload files
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ mt: 1 }} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleButtonClick}
            size="small"
            startIcon={<UploadIcon />}
          >
            Upload
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="flex-end">
          {selectedFiles.length === 0 ? (
            <Typography variant="body1" sx={{ mt: 2 }}>
              No file attached
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ mt: 2 }}>
              {selectedFiles.length} file{selectedFiles.length > 1 ? "s" : ""}{" "}
              attached
            </Typography>
          )}
        </Grid>
      </Grid>
      ) : null}
      
      <Grid item xs={12} sm={12} md={8} sx={{ mt: 2 }}>
        {selectedFiles.length > 0 && selectedFiles[0]?.name !== undefined && (
          <Box>
            <Paper>
              <List>
                {selectedFiles.map((file, index) => (
                  <ListItem
                    key={index}
                    style={{ marginLeft: 4, paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Grid 
                      container 
                      spacing={1}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                      >
                        <Typography variant="body1">
                          {truncateText(file.name ? file.name : "", isSmallScreen ? 20 : 30)}
                        </Typography>
                      </Grid>
                      <Grid item 
                        xs={2} sm={2} md={2} 
                        display='flex' 
                        flexDirection={isSmallScreen ? "column" : "row"} 
                        justifyContent="flex-end"
                      >
                        <Grid>
                          {file.blob ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                onClick={() => uploadFile(file)}
                              >
                                <VisibilityIcon color="primary" size="small" />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item>
                          {editMod === "EDIT" ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={() => removeFile(index)}
                              >
                                <DeleteIcon color="error" size="small" />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        )}
      </Grid>
      {/* {editMod === "EDIT" ? (
        <Grid item xs={10} sm={10} md={10}>
          {selectedFiles.length === 0 ? (
            <Typography variant="body1" sx={{ mt: 2 }}>
              No file attached
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ mt: 2 }}>
              {selectedFiles.length} file{selectedFiles.length > 1 ? "s" : ""}{" "}
              attached
            </Typography>
          )}
        </Grid>
      ) : null} */}
    </Grid>
  );
};
