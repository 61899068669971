/*
c'est le composant enfant de FormEB.
c'est le dialog de signature des engagement brief

l'utilisateur qui est le consultant de l'engagement sélectionné dans FormEB a accès à ce composant

*/

import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FormControlLabel, Typography, Checkbox } from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "underscore";
import {
  useSubmitEngagementBriefMutation,
  useGetProjectsMutation,
} from "../../../api/eStarterApi";
import { CustomLoading } from "../../../components/CustomLoading";
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";

export function DialogEngagementBriefSignature(props) {
  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  //changement du state
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  //information du store
  const currentEngagementBrief = useSelector((state) => state.selectedEngagementBrief, _.isEqual);
  const currentEngagement = props.currentEngagement

  //api
  const dispatch = useDispatch();
  const [postSubmitEngagementBrief] = useSubmitEngagementBriefMutation();
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });

  //fermeture du dialog
  const handleClose = () => {
    props.setOpenDialog(false);
  };

  //changement du state => validation de la checkbox du consultant
  const handleCheck = (event) => {
    setCheck(event.target.checked);
  };

  //sauvegarde de la signature
  const handleSubmit = async () => {
    setLoading(true);
    try {
      let resultApi = null;
      // comme on fait un upsert et non un patch, il faut répéter les information de l'engagement brief et ajouté la signature
      const sendEngagementBrief = {
        ...currentEngagementBrief,
        latest_saved_version: {
          ...currentEngagementBrief["latest_saved_version"],
          send_notification: false,
          engagement_brief_status: "SIGNED",
          signature_date: moment().format("DD/MM/YYYY"),
        },
        latest_signed_version: {
          ...currentEngagementBrief["latest_signed_version"],
          engagement_brief_status: "SIGNED",
          signature_date: moment().format("DD/MM/YYYY"),
          engagement_brief_details: currentEngagementBrief["engagement_brief_details"]
        }, //ne pas oublier d'écraser la nouvelle version signée
        engagement_brief_details: currentEngagementBrief["engagement_brief_details"],
      };
      // on exécute l'api de sauvegarde
      resultApi = await postSubmitEngagementBrief({engagement_brief: sendEngagementBrief, case: "Signature"}).unwrap();
      // on sauvegarde l'eb en pdf (dans FormEB) dans le storage
      props.saveEngagementBrief();
      //on met à jour le state  et on affiche la notification
      const updateEngagement = {
        ...currentEngagement,
        engagement_brief_status: sendEngagementBrief.latest_saved_version?.engagement_brief_status,
      };
      dispatch({ type: "selectedEngagementBriefReducer/selectEngagementBrief", payload: sendEngagementBrief });
      dispatch({
        type: "selectedEngagementReducer/selectEngagement",
        payload: updateEngagement,
      });
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: resultApi.msg,
        },
      });
      props.setOpenDialog(false);
      postProjects({ remove_closed: true });
      setEngagementsActiveOnly(true);
    } catch (error) {
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during signature. Please try again. Error: " +
            error.data.msg,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Dialog open={props.openDialog} maxWidth="md" fullWidth>
      <CustomLoading loadingText="Signature..." />
    </Dialog>
  ) : (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <Typography variant="h2" sx={{ mt: 2, ml: 2 }}>
        {" "}
        Signature Validation{" "}
      </Typography>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" marginBottom={2}>
            You are about to sign your Engagement Brief.
          </Typography>
          <Typography variant="body1" marginBottom={4}>
          I have read and acknowlegdge all the terms and conditions of this Engagement Brief and I am committing to them. If my engagement is extended, I understand that my validation will stay effective for the entire engagement period.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={check}
                onChange={handleCheck}
                color="primary"
              />
            }
            label="I have read and acknowledged all the terms and conditions."
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          autoFocus
          color="success"
          disabled={!check}
        >
          {" "}
          Sign{" "}
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
