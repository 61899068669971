import React, { useState , useContext, useEffect } from "react";
import { Box,
  Grid,
  Typography, 
  CssBaseline, 
  Container,   
  Accordion,
  AccordionSummary,
  TextField,
  Button,
  useMediaQuery,
  ButtonGroup, Menu, MenuItem,
  AccordionDetails,
  Tooltip
} from "@mui/material";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { CustomLoading } from "../../../components/CustomLoading";
import { useSelector } from "react-redux";
import RevenueBreakdown from "./RevenueBreakdown";
import { AttachmentInput } from "../../components/AttachmentInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarginSynthesis from "./MarginSynthesis";
import Team from "./Team";
import _ from "underscore"; 
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import { useNavigate  } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { 
  useSubmitYearlyMarginMutation, 
  useCreateFilesMutation, 
  useGetMarginByProjectMutation
} from "../../../api/eStarterApi";
import { useDispatch } from "react-redux";
import MarginStatusContext from "../../../contexts/MarginStatusContext";


export function MarginForm(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [postSubmitYearlyMargin] = useSubmitYearlyMarginMutation();
  const [apiCreateFiles] = useCreateFilesMutation(); 
  const [getMarginByProject] = useGetMarginByProjectMutation({ fixedCacheKey: "project_margins"});
  
  const { marginStatus, setMarginStatus } = useContext(MarginStatusContext);

  const currentProject = props.currentProject;
  const projectMargins = props.projectMargins;
  const countrySettings = props.countrySettings;
  const deliveryManagers = props.marginDeliveryManagers

  const marginSynthesis = projectMargins[0].engagement_margin.synthesis
  const revenueBreakdown = projectMargins[0].engagement_margin.finance.revenue_breakdown
  const paymentPlan = projectMargins[0].engagement_margin.finance.payment_plan
  const needPaymentPlan = projectMargins[0].engagement_margin.finance.need_payment_plan
  const files = projectMargins[0].files;
  const comments = projectMargins[0].comments;
  const engagementTeams = projectMargins[0].engagement_margin.team;
  const offerMarginTeams = projectMargins[0].offer_margin.team;

  const [marginSynthesisValues, setMarginSynthesisValues] = useState(marginSynthesis);
  const [teamTotalRevenue, setTeamTotalRevenue] = useState(0)

  const [totalDailyRate, setTotalDailyRate] = useState(0)
  const [engagementTeamValues, setEngagementTeamValues] = useState(engagementTeams);
  const [revenueBreakdownValues, setRevenueBreakdownValues] = useState(revenueBreakdown);
  const [paymentPlanValues, setPaymentPlanValues] = useState(paymentPlan);
  const [formNeedPaymentPlan, setFormNeedPaymentPlan] = useState(needPaymentPlan);
  const [revenueBreakdownDeltaValidity, setRevenueBreakdownDeltaValidity] = useState(null);
  const [paymentPlanDeltaValidity, setPaymentPlanDeltaValidity] = useState(null);
  const [filesToCreate, setFilesToCreate] = useState([]);
  const [updatedFiles, setUpdatedFiles] = useState(files);
  const [commentsValue, setCommentsValue] = useState(comments);

  const [ loading, setLoading ] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [updatedMarginStatus, setUpdatedMarginStatus] = useState(true);
  
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const anchorRef = React.useRef(null);
  
  
  const getMarginStatus = useSelector((state) =>
  state.api.mutations["project_margins"]
  ? state.api.mutations["project_margins"].status
  : "loading",
  );
  
  const currentUser = useSelector(
    (state) =>
    state.api.mutations["UserInfos"]
    ? state.api.mutations["UserInfos"].status === "fulfilled"
    ? state.api.mutations["UserInfos"].data
    : []
    : [],
    _.isEqual,
    );
    
    const handleClose = () => {
      navigate("/");
    };

    function isMarginSynthesisComplete(marginSynthesisObject) {
      if (
        (marginSynthesisObject.rebillable_bills === null || marginSynthesisObject.rebillable_bills === "") || 
        (marginSynthesisObject.note === null || marginSynthesisObject.note === "") ||
        (marginSynthesisObject.contract === null || marginSynthesisObject.contract === "") ||
        (marginSynthesisObject.margin_type === null || marginSynthesisObject.margin_type === "")
        ) {
        return false;
      } else {
        return true;
      }
    }

    function isTeamCardComplete(card) {
      if ((card.worked_days === null || card.worked_days === "") || (card.sold_days === null || card.sold_days === "")) {
        return false;
      }
    
      // ES3 / SUBCONTRACTOR
      if (card.daily_purchase_rate.crm !== null || card.daily_purchase_rate.margin !== null) {
        if (
          card.es3 === null || 
          ((card.daily_sale_rate.crm === null && card.daily_sale_rate.margin === null) || card.daily_sale_rate.margin === "") || 
          ((card.daily_purchase_rate.crm === null && card.daily_purchase_rate.margin === null) || card.daily_purchase_rate.margin === "")
          ) {
          return false;
        }
      } 
      
      // CONSULTANT 
      else {
        if (
          ((card.daily_desk_costs.country_params === null && card.daily_desk_costs.margin === null) || card.daily_desk_costs.margin === "") ||
          ((card.daily_fees.country_params === null && card.daily_fees.margin === null) || card.daily_fees.margin === "") ||
          ((card.daily_it_costs.country_params === null && card.daily_it_costs.margin === null) || card.daily_it_costs.margin === "") ||
          ((card.sale_time_unit === "Jour prest" ? card.daily_sale_rate.crm === null && card.daily_sale_rate.margin === null : card.daily_sale_rate.margin === null) || card.daily_sale_rate.margin === "") ||
          ((card.employee.salary.annual_bonus.sap === null && card.employee.salary.annual_bonus.margin === null) || card.employee.salary.annual_bonus.margin === "") ||
          ((card.employee.salary.monthly_gross_salary.sap === null && card.employee.salary.monthly_gross_salary.margin === null) || card.employee.salary.monthly_gross_salary.margin === "") ||
          ((card.monthly_fees.country_params === null && card.monthly_fees.margin === null) || card.monthly_fees.margin === "") ||
          (card.office_presence === null || card.office_presence === "")
        ) {
          return false;
        }
      }
      return true;
    }
    
    function isTeamComplete(team) {
      let result = []
      team.forEach((card) => result.push(isTeamCardComplete(card)))
      return !result.includes(false);
    }
    
    
    const isFormReady =
      isMarginSynthesisComplete(marginSynthesisValues) &&
      isTeamComplete(engagementTeamValues) &&
      revenueBreakdownDeltaValidity &&
      paymentPlanDeltaValidity;
    
    
    
    const projectMarginStatus = projectMargins[0].status
    const projectBusinessManagerList = currentProject.rls_project_business_manager
    const projectCommercialOwnerId = currentProject.project_commercial_owner_id
    const commercialOwnerHierarchyList = projectBusinessManagerList.filter((businessManagerId) => businessManagerId !== projectCommercialOwnerId);
    const userRole = currentUser.user_role
    
    
    const userCanSubmit = userRole === "Admin" ? true : projectBusinessManagerList.includes(Number(currentUser.id)); 
    const userCanValidate = userRole === "Admin" ? true : commercialOwnerHierarchyList.includes(Number(currentUser.id));
    
    const submitActions = [
      { label: "Save & Submit", action: () => handleSubmit("SUBMIT") },
      { label: "Submit as Offer Margin", action: () => handleSubmit("OFFER_MARGIN") }
    ];
    
  const handleFileChange = (value) => {
    setFilesToCreate(value.filter((v) => v instanceof File));
    setUpdatedFiles(value.filter((v) => !(v instanceof File)));
    setUnsavedChanges(true);
  };
  
  const handleCommentsChange = (value) => {
    setCommentsValue(value);
    setUnsavedChanges(true);
  };

  const handleMarginSynthesisChange = (event) => {
    const {name, value} = event.target
    if (name === "rebillable_bills") {
      const billsValue = value === "true" ? true : false;
      setMarginSynthesisValues(prevState => ({
        ...prevState,
        [name]: billsValue
      }));
    } else {
      setMarginSynthesisValues(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  // Fetch data from team cards for the operations :
  const marginSettings = countrySettings.margin_settings;
  const dailyAnnualBasis = marginSettings.day_annual_basis;
  const monthlyAnnualBasis = marginSettings.month_annual_basis;
  const chargesCoefficient = marginSettings.charges_coefficient;

  const getDailySaleRate = (card) => {
    if (card.daily_sale_rate.margin) {
      return card.daily_sale_rate.margin
    } else if (card.daily_sale_rate.margin === "" || card.daily_sale_rate.margin === 0) {
      return 0
    } else {
      if (card.sale_time_unit === "Jour prest") {
        return card.daily_sale_rate.crm
      } 
    }
  };

  const getAllSubcontractorsCosts = (card) => {
    if (card.daily_purchase_rate.margin) {
      return card.daily_purchase_rate.margin
    } else if (card.daily_purchase_rate.margin  === "" || card.daily_purchase_rate.margin === 0) {
      return 0
    } else {
      if (card.purchase_time_unit === "Jour prest") {
        return card.daily_purchase_rate.crm
      } else {
        return 0
      }
    }
  };

  const getMonthlyGrossSalary = (card) => {
    if (card.employee.salary.monthly_gross_salary.margin) {
        return card.employee.salary.monthly_gross_salary.margin
    } else if (card.employee.salary.monthly_gross_salary.margin === "" || card.employee.salary.monthly_gross_salary.margin === 0) {
        return 0
    } else {
        return card.employee.salary.monthly_gross_salary.sap
    }
  };

  const getAnnualBonus = (card) => {
    if (card.employee.salary.annual_bonus.margin) {
        return card.employee.salary.annual_bonus.margin
    } else if (card.employee.salary.annual_bonus.margin === "" || card.employee.salary.annual_bonus.margin === 0) {
        return 0
    } else {
        return card.employee.salary.annual_bonus.sap
    }
  };

  const getMonthlyFees = (card) => {
    if (card.monthly_fees.margin) {
        return card.monthly_fees.margin
    } else if (card.monthly_fees.margin === "" || card.monthly_fees.margin === 0) {
        return 0
    } else {
        return card.monthly_fees.country_params
    }
  };

  const getDailyFees = (card) => {
    if (card.daily_fees.margin) {
        return card.daily_fees.margin
    } else if (card.daily_fees.margin === "" || card.daily_fees.margin === 0) {
        return 0
    } else {
        return (card.daily_fees.crm + card.daily_fees.country_params)
    }
  };

  const getDailyITCosts = (card) => {
    if (card.daily_it_costs.margin) {
        return card.daily_it_costs.margin
    } else if (card.daily_it_costs.margin === "" || card.daily_it_costs.margin === 0) {
        return 0
    } else {
        return card.daily_it_costs.country_params
    }
  };

  const getDailyDeskCosts = (card) => {
    if (card.daily_desk_costs.margin) {
        return card.daily_desk_costs.margin
    } else if (card.daily_desk_costs.margin === "" || card.daily_desk_costs.margin === 0) {
        return 0
    } else {
        return card.daily_desk_costs.country_params
    }
  };

  const processMargin = (marginTeams, margin) => {
    let offerMarginResults = {es3SoldDays: 0, subcontractorsSoldDays: 0, consultantsSoldDays: 0, es3Revenues: 0, subcontractorsRevenues: 0, consultantsRevenues: 0, es3Costs: 0, subcontractorsCosts: 0, consultantsCosts: 0};
    let engagementMarginResults = {es3SoldDays: 0, subcontractorsSoldDays: 0, consultantsSoldDays: 0, es3Revenues: 0, subcontractorsRevenues: 0, consultantsRevenues: 0, es3Costs: 0, subcontractorsCosts: 0, consultantsCosts: 0};
  
    marginTeams.forEach((teamCard) => {
      let result = margin === "offerMargin" ? offerMarginResults : engagementMarginResults;
      if (margin !== "offerMargin" && !isTeamCardComplete(teamCard)) {
        return;
      }
      let rate, purchaseRate, monthlyGrossSalary, annualBonus, monthlyFees, dailyFees, dailyITCosts, dailyDeskCosts, officePresence;
  
      if (margin === "offerMargin") {
        rate = teamCard.daily_sale_rate;
        purchaseRate = teamCard.daily_purchase_rate;
        monthlyGrossSalary = teamCard.employee.salary.monthly_gross_salary;
        annualBonus = teamCard.employee.salary.annual_bonus;
        monthlyFees = teamCard.monthly_fees;
        dailyFees = teamCard.daily_fees;
        dailyITCosts = teamCard.daily_it_costs;
        dailyDeskCosts = teamCard.daily_desk_costs;
        officePresence = parseFloat(teamCard.office_presence);
      } else {
        rate = getDailySaleRate(teamCard);
        purchaseRate = getAllSubcontractorsCosts(teamCard);
        monthlyGrossSalary = getMonthlyGrossSalary(teamCard);
        annualBonus = getAnnualBonus(teamCard);
        monthlyFees = getMonthlyFees(teamCard);
        dailyFees = getDailyFees(teamCard);
        dailyITCosts = getDailyITCosts(teamCard);
        dailyDeskCosts = getDailyDeskCosts(teamCard);
        officePresence = parseFloat(teamCard.office_presence);
      }
      const employeeCosts = ((((monthlyGrossSalary * monthlyAnnualBasis) + annualBonus) * chargesCoefficient ) / dailyAnnualBasis) + dailyFees  + ((monthlyFees * 12) / dailyAnnualBasis);
      const officeCosts =  dailyITCosts + (dailyDeskCosts * (officePresence/100));
      const productionCostConsultant = employeeCosts + officeCosts;
      if (purchaseRate) {
        if (teamCard.es3) {
          result.es3Revenues += (rate * teamCard.sold_days);
          result.es3SoldDays += teamCard.sold_days;
          result.es3Costs += (purchaseRate * teamCard.worked_days);
        } else {
          result.subcontractorsRevenues += (rate * teamCard.sold_days);
          result.subcontractorsSoldDays += teamCard.sold_days;
          result.subcontractorsCosts += (purchaseRate * teamCard.worked_days);
        }
      } else {
        result.consultantsRevenues += (rate * teamCard.sold_days);
        result.consultantsSoldDays += teamCard.sold_days;
        result.consultantsCosts += (productionCostConsultant * teamCard.worked_days);
      }
    });  
    return margin === "offerMargin" ? offerMarginResults : engagementMarginResults;
  };

  

  const calculateMarginRates = (margin) => {
    let subcontractorsDailyRateAverage = 0;
    let es3DailyRateAverage = 0;
    let consultantDailyRateAverage = 0;

    let subcontractorsRevenues = 0;
    let es3Revenues = 0;
    let consultantsRevenues = 0;

    let subcontractorsMarginAverage = 0;
    let es3MarginAverage = 0;
    let consultantsMarginAverage = 0;
    
    let totalRate = 0;
    let totalRevenue= 0;
    let totalCost = 0;
    let totalMarginRate = 0;

    // DAILY RATE
    subcontractorsDailyRateAverage = margin.subcontractorsSoldDays > 0 ? (margin.subcontractorsRevenues / margin.subcontractorsSoldDays) : 0;
    es3DailyRateAverage = margin.es3SoldDays > 0 ? (margin.es3Revenues / margin.es3SoldDays) : 0;
    consultantDailyRateAverage = margin.consultantsSoldDays > 0 ? (margin.consultantsRevenues / margin.consultantsSoldDays) : 0;

    // REVENUE
    subcontractorsRevenues = margin.subcontractorsRevenues;
    es3Revenues = margin.es3Revenues;
    consultantsRevenues = margin.consultantsRevenues;

    // ENGAGEMENT MARGIN
    subcontractorsMarginAverage = (margin.subcontractorsRevenues - margin.subcontractorsCosts) / margin.subcontractorsRevenues * 100;
    es3MarginAverage = (margin.es3Revenues - margin.es3Costs) / margin.es3Revenues * 100;
    consultantsMarginAverage = (margin.consultantsRevenues - margin.consultantsCosts) / margin.consultantsRevenues * 100;

    // TOTAL
    totalRate = ((margin.subcontractorsRevenues + margin.es3Revenues + margin.consultantsRevenues) / (margin.subcontractorsSoldDays + margin.es3SoldDays + margin.consultantsSoldDays)).toFixed(2);
    totalRevenue = (subcontractorsRevenues + es3Revenues + consultantsRevenues).toFixed(2);
    totalCost = (margin.es3Costs + margin.subcontractorsCosts + margin.consultantsCosts).toFixed(2);
    totalMarginRate = ((totalRevenue - totalCost) / totalRevenue) * 100;
    return {
      subcontractorsDailyRateAverage,
      es3DailyRateAverage,
      consultantDailyRateAverage,
      subcontractorsRevenues,
      es3Revenues,
      consultantsRevenues,
      subcontractorsMarginAverage,
      es3MarginAverage,
      consultantsMarginAverage,
      totalRate,
      totalRevenue,
      totalCost,
      totalMarginRate,
    };
  }

  // PROCEED TO CALCULATE
const engagementMargin = processMargin(engagementTeamValues, "engagementMargin");
const engagementMarginResults = calculateMarginRates(engagementMargin);
let offerMargin = {};
let offerMarginResults = {};
if (offerMarginTeams && offerMarginTeams.length > 0) {
  offerMargin = processMargin(offerMarginTeams, "offerMargin");
  offerMarginResults = calculateMarginRates(offerMargin);
}

const formatTeamValues = (teamValues) => {
  return teamValues.map(teamCard => {
    const workedDays = teamCard.worked_days;
    const soldDays = teamCard.sold_days;
 
    const dailySaleRateMargin = teamCard.daily_sale_rate.margin;
    const dailySaleRateCrm = teamCard.daily_sale_rate.crm;
    const dailySaleRate = dailySaleRateMargin ?? dailySaleRateCrm;
 
    const dailyPurchaseRateMargin = teamCard.daily_purchase_rate.margin;
    const dailyPurchaseRateCrm = teamCard.daily_purchase_rate.crm;
    const dailyPurchaseRate = dailyPurchaseRateMargin ?? dailyPurchaseRateCrm;
 
    const dailyDeskCostsMargin = teamCard.daily_desk_costs.margin;
    const dailyDeskCostsCountryParams = teamCard.daily_desk_costs.country_params;
    const dailyDeskCosts = dailyDeskCostsMargin ?? dailyDeskCostsCountryParams;
 
    const dailyFeesMargin = teamCard.daily_fees.margin;
    const dailyFeesCrm = teamCard.daily_fees.crm;
    const dailyFeesCountryParams = teamCard.daily_fees.country_params;
 
    let dailyFees;
    if (dailyFeesMargin !== undefined && dailyFeesMargin !== '' && dailyFeesMargin !== null) {
      dailyFees = dailyFeesMargin;
    } else if (dailyFeesCrm !== undefined && dailyFeesCountryParams !== undefined) {
      dailyFees = dailyFeesCrm + dailyFeesCountryParams;
    } else if (dailyFeesCrm !== undefined) {
      dailyFees = dailyFeesCrm;
    } else {
      dailyFees = dailyFeesCountryParams;
    }
 
    const dailyItCostsMargin = teamCard.daily_it_costs.margin;
    const dailyItCostsCountryParams = teamCard.daily_it_costs.country_params;
    const dailyItCosts = dailyItCostsMargin ?? dailyItCostsCountryParams;
 
    const annualBonusMargin = teamCard.employee.salary.annual_bonus.margin;
    const annualBonusSap = teamCard.employee.salary.annual_bonus.sap;
    const annualBonus = annualBonusMargin ?? annualBonusSap;
 
    const monthlyGrossSalaryMargin = teamCard.employee.salary.monthly_gross_salary.margin;
    const monthlyGrossSalarySap = teamCard.employee.salary.monthly_gross_salary.sap;
    const monthlyGrossSalary = monthlyGrossSalaryMargin ?? monthlyGrossSalarySap;
 
    const monthlyFeesMargin = teamCard.monthly_fees.margin;
    const monthlyFeesCountryParams = teamCard.monthly_fees.country_params;
    const monthlyFees = monthlyFeesMargin ?? monthlyFeesCountryParams;
 
    const officePresence = teamCard.office_presence;
 
    return {
      id: teamCard.id,
      engagement_sales_id: teamCard.engagement_sales_id,
      es3: teamCard.es3,
      year: teamCard.year,
      start_date: teamCard.start_date,
      end_date: teamCard.end_date,
      handover_start_date: teamCard.handover_start_date,
      handover_end_date: teamCard.handover_end_date,
      worked_days: parseFloat(workedDays),
      sold_days: parseFloat(soldDays),
      employee: {
        id: teamCard.employee.id,
        last_name: teamCard.employee.last_name,
        first_name: teamCard.employee.first_name,
        employee_site: teamCard.employee.employee_site,
        salary: {
          currency: teamCard.employee.salary.currency,
          monthly_gross_salary: parseFloat(monthlyGrossSalary),
          annual_bonus: parseFloat(annualBonus)
        }
      },
      daily_sale_rate: parseFloat(dailySaleRate),
      sale_time_unit: teamCard.sale_time_unit,
      daily_purchase_rate: dailyPurchaseRate ? parseFloat(dailyPurchaseRate) : dailyPurchaseRate,
      purchase_time_unit: teamCard.purchase_time_unit,
      monthly_fees: monthlyFees ? parseFloat(monthlyFees) : monthlyFees,
      daily_fees: dailyFees ? parseFloat(dailyFees) : dailyFees,
      daily_it_costs: dailyItCosts ? parseFloat(dailyItCosts) : dailyItCosts,
      daily_desk_costs: dailyDeskCosts ? parseFloat(dailyDeskCosts) : dailyDeskCosts,
      currency: teamCard.currency,
      office_presence: officePresence
    };
  });
}


const handleSubmit = async (submitCase) => {
  setLoading(true);
  
  try {
    let resultMargin = null;
    let resultfiles = null;
    
    const teamValuesFormatted = formatTeamValues(engagementTeamValues);
    let sendYearlyMargin = {
      id: projectMargins[0].id,
      project_id: projectMargins[0].project_id,
      year: projectMargins[0].year,
      status: projectMargins[0].status,
      signature_date: projectMargins[0].signature_date,
      synthesis_values: marginSynthesisValues,
      team_values: teamValuesFormatted,
      revenue_breakdown_values: revenueBreakdownValues,
      payment_plan_values: paymentPlanValues,
      need_payment_plan: formNeedPaymentPlan,
      comments: commentsValue,
      submitCase: submitCase,
      margin_rate: engagementMarginResults.totalMarginRate
    };

    
    if (filesToCreate.length > 0) {
      resultfiles = await apiCreateFiles({
        files: filesToCreate,
        folderName: String(projectMargins[0].project_id) + "/Margins" + String(projectMargins[0].year) + String(projectMargins[0].id),
      });
      sendYearlyMargin = { ...sendYearlyMargin, files: updatedFiles.concat(resultfiles.data) };
    } else {
      sendYearlyMargin = { ...sendYearlyMargin, files: updatedFiles };
    }
    resultMargin = await postSubmitYearlyMargin({margin: sendYearlyMargin, case: submitCase}).unwrap();
    getMarginByProject({ id: projectMargins[0].project_id }).unwrap();
    // postProjects({ remove_closed: engagementsActiveOnly });
    setFilesToCreate([]);
    setUnsavedChanges(false);
    dispatch({
      type: "snackbarInfo/setNotification",
      payload: {
        snackbarOpen: true,
        snackbarType: resultMargin.status,
        snackbarMessage: resultMargin.msg,
      },
    });
   
  } catch (error) {
    console.log("error : ", error)
    //on affiche les erreurs
    dispatch({
      type: "snackbarInfo/setNotification",
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage:
          "An error occurred during submission. Please try again. Error: " +
          (error.data ? error.data.msg : "No error message available"),
      },
    });
    
  } finally {
    props.setInitalizeTabSelector(true)
    props.setFetchMarginData(true)
    props.setMarginDataReceived(false)
    setLoading(false);
  }
};
useEffect(()=> {
  if(updatedMarginStatus) {
    const updatedMarginStatus = {
      id: projectMargins[0].project_id,
      year: projectMargins[0].year,
      status: projectMargins[0].status
    }
    const existingIndex = marginStatus.findIndex(item => item.id === updatedMarginStatus.id && item.year === updatedMarginStatus.year);
    if (existingIndex !== -1) {
      const updatedMarginStatusArray = marginStatus.map((item, index) =>
          index === existingIndex ? {...item, ...updatedMarginStatus} : item
      );
      setMarginStatus(updatedMarginStatusArray);
      setUpdatedMarginStatus(false);
    } else {
      setMarginStatus([...marginStatus, updatedMarginStatus]);
      setUpdatedMarginStatus(false);
    }
  }
},[updatedMarginStatus, projectMargins, marginStatus, setMarginStatus])

return (
    <Box sx={{ display: "flex" , padding: 0, margin: 0}}>
      <CssBaseline />
        {getMarginStatus !== "fulfilled" || loading ? ( 
          <Container sx={{ mt: 2, mb: 2 }}>
            <CustomLoading loadingText={ loading ? "Saving Margin Data..." : "Loading Margin Form Data..."} />
          </Container>
        ) : (
          <Box maxWidth="xl">

            <MarginSynthesis
              mode={"Margin"}
              projectMargins={projectMargins}
              countrySettings={countrySettings}
              engagementTeamValues={engagementTeamValues}
              deliveryManagers={deliveryManagers}
              marginSynthesisValues={marginSynthesisValues}
              handleChange={handleMarginSynthesisChange}
              setUnsavedChanges={setUnsavedChanges}
              setTotalDailyRate={setTotalDailyRate}
              engagementMarginResults={engagementMarginResults}
              offerMarginResults={offerMarginResults}
              />

            <Team 
              mode={"Margin"}
              currentProject={currentProject}
              projectMargins={projectMargins}
              countrySettings={countrySettings}
              setTeamTotalRevenue={setTeamTotalRevenue}
              setUnsavedChanges={setUnsavedChanges}
              isTeamComplete={isTeamComplete}
              isTeamCardComplete={isTeamCardComplete} 
              engagementTeamValues={engagementTeamValues}
              setEngagementTeamValues={setEngagementTeamValues}
            />

            <RevenueBreakdown 
              mode={"Revenue Breakdown"}
              currentProject={currentProject}
              projectMargins={projectMargins}
              countrySettings={countrySettings}
              teamTotalRevenue={teamTotalRevenue}
              setMarginFinanceValues={setRevenueBreakdownValues}
              deltaValidity={revenueBreakdownDeltaValidity}
              setDeltaValidity={setRevenueBreakdownDeltaValidity}
              setUnsavedChanges={setUnsavedChanges}
              totalDailyRate={totalDailyRate}
              />

            <RevenueBreakdown 
              mode={"Payment Plan"}
              currentProject={currentProject}
              projectMargins={projectMargins}
              countrySettings={countrySettings}
              teamTotalRevenue={teamTotalRevenue}            
              setMarginFinanceValues={setPaymentPlanValues}
              deltaValidity={paymentPlanDeltaValidity}
              setDeltaValidity={setPaymentPlanDeltaValidity}
              formNeedPaymentPlan={setFormNeedPaymentPlan}
              setUnsavedChanges={setUnsavedChanges}
              totalDailyRate={totalDailyRate}
              />
            
            <Grid container sx={{display: "flex", justifyContent: "space-between",  mb: 2}}>
              <Grid item xs={12} sm={12} md={12}>
                <Accordion
                    defaultExpanded={true}
                    sx={{border: '1px solid lightgray'}}
                    elevation={0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid display="flex" alignItems="center">
                      <Typography variant="h6">ATTACHMENTS & COMMENTS</Typography>
                      <AttachFileOutlinedIcon fontSize="small" sx={{marginLeft:1}}/>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container sx={{display: "flex", alignItems: "flex-start"}} spacing={5}>
                      {/* ATTACHMENTS */}
                      <Grid item xs={6} sm={6} md={6} pl={2} >
                        <Typography variant="h6">Attachments</Typography>
                        <AttachmentInput
                          editMod={"EDIT"}
                          onChange={(e) => handleFileChange(e)}
                          initialFiles={updatedFiles}
                        />
                      </Grid>

                      {/* COMMENTS */}
                      <Grid item xs={6} sm={6} md={6} pr={2} >
                        <Typography variant="h6">Comments</Typography>
                        <TextField
                          size="small"
                          label={"Additional Margin information"}
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={commentsValue !== null ? commentsValue : ""}
                          onChange={e => handleCommentsChange(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>



              <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mb: 2}} >
                  <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                    { unsavedChanges ?
                      <Button color="error" sx={{ mr: isSmallScreen ? 0 : 3 }} onClick={() => setConfirmDialogOpen(true)}>
                        {isSmallScreen ? "Cancel" : "Cancel and go back"}
                      </Button>
                      :
                      <Button color="error" sx={{ mr: isSmallScreen ? 0 : 3 }} onClick={() => handleClose()}>
                        {isSmallScreen ? "Cancel" : "Cancel and go back"}
                      </Button>
                    }

                    <Button
                      onClick={() => handleSubmit("SAVE")}
                      color="success"
                      disabled={!userCanSubmit || !unsavedChanges}
                    >
                      {" "}
                      Save
                    </Button>
                    <ButtonGroup variant="outlined" ref={anchorRef} sx={{ml: 3}}>
                      <Tooltip title={!isFormReady ? "You cannot submit your margin because either some values are missing in at least one module or the revenue breakdown and/or payment plan doesn't respect the authorized Delta": ""}>
                          <Tooltip title={submitActions[selectedIndex].label === "Submit as Offer Margin" ? "Save a 'screenshot' of your first created margin to let you monitor the margin evolution" : "Submit your Engagement Margin for validation"}>
                            <Button 
                              onClick={submitActions[selectedIndex].action}
                              disabled={!isFormReady}>
                              {submitActions[selectedIndex].label}
                            </Button>
                          </Tooltip>
                        <Button
                          onClick={() => setOpen((prev) => !prev)}
                          >
                          <ArrowDropDownIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    <Menu
                      anchorEl={anchorRef.current}
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      {submitActions.map((action, index) => (
                        <MenuItem
                          key={action.label}
                          selected={index === selectedIndex}
                          onClick={() => {
                            setSelectedIndex(index);
                            setOpen(false);
                          }}
                        >
                          {action.label}
                        </MenuItem>
                      ))}
                    </Menu>

                      {projectMarginStatus === "SUBMITTED" && userCanValidate ? 
                        <Button
                          onClick={() => handleSubmit("VALIDATE")}
                          color="success"
                          disabled={projectMarginStatus !== "SUBMITTED" || !userCanValidate}
                          sx={{ml: 3}}
                        >
                          {" "}
                          Validate
                        </Button> : null }
                    
                    {confirmDialogOpen ? (
                      <ConfirmDialog
                      title="Are you sure you want to cancel ?"
                      description="Any unsaved changes will be lost."
                      mod="unsavedChanges"
                      open={confirmDialogOpen}
                      setOpen={setConfirmDialogOpen}
                      onConfirm={handleClose} // Pass the handleClose function
                    />
                    ): null }
                  </Grid>
              </Grid>
          </Box>
        )}
    </Box>
  );
}
