/*
PAGE HELP
il faut éditer les questions dans le fichier param/paramHelp.json

on affiche toutes les questions / reponses par module
il y a une barre de recherche pour les modules différents de Global
*/

import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Link,
  InputAdornment,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import paramHelp from "../params/paramHelp.json";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import _ from "underscore";

export const Help = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const user_role = useSelector(
    (state) =>
      state.api.mutations["UserInfos"]
        ? state.api.mutations["UserInfos"].status === "fulfilled"
          ? state.api.mutations["UserInfos"].data.user_role
          : ""
        : "",
    _.isEqual,
  );
  //changement du state
  const [moduleValue, setModuleValue] = useState("Global");
  const [search, setSearch] = useState("");

  //chnagement du state pour le filtre
  const handleClearClick = () => {
    setSearch("");
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  //changement du state pour la navigation
  const handleChange = (event, newValue) => {
    setModuleValue(newValue);
    setSearch("");
  };

  //liste des modules uniques pour naviguer
  const allModules = paramHelp.reduce((uniqueModule, question) => {
    const moduleShouldBeIncluded = question.module !== "Margin" || (question.module === "Margin" && (user_role === "Admin" || user_role === "Business"));
    if (moduleShouldBeIncluded && (!uniqueModule.includes(question.module))) {
      uniqueModule.push(question.module);
    }
    return uniqueModule.sort();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="stretch"
      minHeight={`calc(100% - 128px)`}
    >
      <CssBaseline />
      <Container maxWidth="lg">
        {isSmallScreen ? null : (
          <Typography variant="h2" component="div" sx={{ mt: 4, mb: 4 }}>
            Help & Support
          </Typography>
        )}
        <Grid container sx={{ mt: isSmallScreen ? 4 : 0 , mb:3}}>
          <Grid item xs={12} md={12} lg={12}>
            <Tabs
              value={moduleValue}
              onChange={handleChange}
              textColor="primary"
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons
              indicatorColor="primary"
              sx={{ ml: -5 }}
            >
              {allModules
                .slice()
                .sort((a, b) => {
                  if (a === "Global") return -1;
                  if (b === "Margin") return -1;
                  return 1;
                })
                .map((module, index) => (
                  <Tab value={module} label={module} key={index} />
                ))}
            </Tabs>
          </Grid>
          {moduleValue !== "Contacts" && moduleValue !== "Mobile app" ? (
            <TextField
              sx={{ mt: 2 }}
              label="Search"
              size="small"
              value={search}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    sx={{ visibility: search !== "" ? "visible" : "hidden" }}
                    size="small"
                    onClick={handleClearClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          ) : null}
          {paramHelp
            .filter((el) => el.module === moduleValue)
            .filter(
              (el) =>
              search === "" ||
              el.question.toLowerCase().includes(search.toLowerCase()) ||
              el.answer.toLowerCase().includes(search.toLowerCase(),
              ),
              
              )
              .map((el, index) => (
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 3}} key={index}>
                  <Typography variant="h6" component="div">
                    {el.question}
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    {el.answer}
                  </Typography>
                  {el.link ? (
                    <Link href={el.link} target="blank">
                      <Button sx={{backgroundColor:"#27245b", color: "white", py:1, px:2, mt: 3, borderRadius:1}}>
                        Report a bug or request a feature
                      </Button>
                    </Link>
                  ) : null}
                  {el.image ? (
                    isSmallScreen ? null :
                    <img
                      src={require(`../../media/${el.image}`)} 
                      style={{ marginTop:'20px', width: '75%', borderRadius: '15px', objectFit: 'contain'}}
                      alt="Logigramme"/>
                  ) : null}
                </Grid>
              ))}
          <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};
