import React from "react";
import { CssBaseline, Box, Grid, Container } from "@mui/material";
import { Button } from "@mui/material";

export function ErrorPage(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} mb={4} textAlign="center">
            <h1>Error 500: There was an error during loading, please try both actions below.</h1>
          </Grid>
          <Grid item xs={12} sm={12} md={12} mb={8} textAlign="center">
            <Button variant="contained" onClick={() => window.location.reload()} sx={{ fontSize: "1.5rem", padding: "10px 25px" }}>
              REFRESH
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} textAlign="center">
            If the problem persists, try <h2>CTRL + MAJ + R</h2>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
