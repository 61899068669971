import React from "react";
import { 
Box, 
Grid,  
CssBaseline, 
Typography, 
TextField,
InputAdornment
} from "@mui/material";

const RevenueBreakdownMonthCard = React.memo((props) => {
  const { workingDays, disabled, name, value, currencySymbol, handleChange } = props;

const handleChangeLocally = (newValue) => {
  let numericValue = newValue;
  const regex = /^-?\d*\.?\d*$/;
  
  if (regex.test(newValue) || newValue === '') {
    numericValue = newValue;
  } else {
    numericValue = parseFloat(newValue);
    if (isNaN(numericValue)) {
        numericValue = ''; 
    }
  }
  if (!disabled) {
    handleChange(name, numericValue); 
  }
};


  return (
    <Box >
      <CssBaseline />
      <Grid container p={2} sx={{width: "100%", border: '1px solid lightgray', borderRadius: 1, backgroundColor: disabled ? 'lightgray' : 'white'}}>
        {/* Grid container to align items in a column */}
        <Grid item sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Typography variant="button" sx={{ textAlign: 'center' }}>{`${name}`}</Typography>
          <Typography variant="caption" color="grey" sx={{ textAlign: 'center' }}>{`WD: ${!disabled ? workingDays : '--'}`}</Typography>
          <TextField
            sx={{
              input: {textAlign: "center"}, 
              borderRadius: 1, 
              width: "auto", 
              backgroundColor: () => (disabled ? "lightgray" : "white"),
              mt:2
            }}
            disabled={disabled}
            value={value !== null ? value : "--"}
            error={value < 0}
            size="small"
            onChange={(e) => handleChangeLocally(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {currencySymbol}
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}, (prevProps, nextProps) => {

  return prevProps.workingDays === nextProps.workingDays &&
         prevProps.disabled === nextProps.disabled &&
         prevProps.name === nextProps.name &&
         prevProps.value === nextProps.value;
});

export default RevenueBreakdownMonthCard

