import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../connexion/params/authConfig";

export async function getToken() {
  const msalInstance = new PublicClientApplication(msalConfig);
  
  // Initialiser l'application cliente publique
  await msalInstance.initialize();

  const account = msalInstance.getAllAccounts()[0];
  const request = { ...loginRequest, account };

  return await msalInstance
    .acquireTokenSilent(request)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      // Call your API with token

      return accessToken;
    })
    .catch(function (error) {
      // Acquire token silent failure
      console.log(error);
    });
}
