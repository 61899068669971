/*
c'est le composant enfant de ProjectList.js
il est le parent de DialogPPSignature.js

tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à cette page

dans ce composant, on affiche les informations du projet et de l'engagement et du plan de prevention assigné à cet engagement.
il est possible de télécharger les fichiers du plan de prevention pour les lire (pas de visualisation direct dans l'application pour pouvoir charger tous les types de fichiers)

tant que le statut n'est pas égal à READY TO BE SIGNED, on ne peut pas signer
seul le consultant peut signer

si une autre affectation à lieu ou si un fichier est ajouté ou modifié au plan de prévention attaché, alors le consultant devra signer une nouvelle fois (définie dans PreventionPlanMgt.js)


affichage des fichiers en mode view uniquement (on ne peut pas modifier la liste des fichiers ici). voir le composant dans components/AttachmentInput.js

*/

import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  Chip,
  Typography,
  Button,
  Skeleton,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PlaceIcon from "@mui/icons-material/Place";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PersonIcon from "@mui/icons-material/Person";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { DialogPreventionPlanSignature } from "../dialogs/DialogPreventionPlanSignature";
import Notification from "../../../components/Notification";
import { AttachmentInput } from "../../components/AttachmentInput";
import { CustomLoading } from "../../../components/CustomLoading";
import { truncateText } from "../../../commonFunction";
import { useParams, useNavigate } from "react-router-dom";
import { 
  useGetPreventionPlanAssignmentDataMutation, 
  useGetProjectsMutation,
  useGetEngagementsPreventionPlanAssignmentMutation,
  useRemovePreventionPlanAssignmentMutation
 } from "../../../api/eStarterApi"
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";


export function PreventionPlan(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { engagementsActiveOnly, setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const { engagement_integration_key } = useParams();
  const [getPreventionPlanAssignmentData] = useGetPreventionPlanAssignmentDataMutation({ fixedCacheKey: "prevention_plan_assignment_data" });
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [fetchPreventionPlanAssignmentData, setFetchPreventionPlanAssignmentData] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedEngagementForDeletion, setSelectedEngagementForDeletion] = useState(null);    
  const [removePreventionPlanAssignment] = useRemovePreventionPlanAssignmentMutation();
  const [loading, setLoading] = useState(false);

  const [getEngagementsPreventionPlanAssignment] = useGetEngagementsPreventionPlanAssignmentMutation({
    fixedCacheKey: "prevention_plan_assignments",
  });

  const crm_projects = useSelector(
    (state) =>
    state.api.mutations["crm_projects"]
    ? state.api.mutations["crm_projects"].status === "fulfilled"
    ? state.api.mutations["crm_projects"].data
    : []
    : [],
    _.isEqual,
    );
    
    let currentProject = [];
    let currentEngagement = [];
    let isCurrentUserIsCurrentConsultant = null;
    
    const filteredProject = crm_projects.filter((project) => {
      return project.engagements.some((engagement) => engagement.engagement_integration_key.toString() === engagement_integration_key);
    });

    
    const matchingProject = filteredProject.length === 0 ? false : true;

    const getProjectStatus = useSelector((state) =>
    state.api.mutations["crm_projects"]
      ? state.api.mutations["crm_projects"].status
      : "loading",
  );
    
    const initializePreventionPlanAssignmentData = useCallback(async () => {
      try {
        
        if (engagementsActiveOnly && !matchingProject) {
          postProjects({ remove_closed: false }).unwrap();
          setEngagementsActiveOnly(false);
        }
        
        let resultPreventionPlanAssignmentData = await getPreventionPlanAssignmentData({
          engagement_integration_key: String(engagement_integration_key),
        }).unwrap();
        
        dispatch({
          type: "selectedPreventionPlanAssignmentDataReducer/selectPreventionPlanAssignmentData",
          payload: resultPreventionPlanAssignmentData,
        });
        
      } catch (error) {
        // setErrorStatus(error.status);
        if (error.status === 401) {
          navigate("/accessdenied")
        } else if (error.status === 404) {
          navigate("/404")
        } else {
          dispatch({
            type: "snackbarInfo/setNotification",
            payload: {
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
              "An error occurred during initializing the data. Please try again. Error: " +
              error.data.msg,
            },
          });
        }
    } finally {
      setFetchPreventionPlanAssignmentData(false);
    }
  }, [engagement_integration_key, getPreventionPlanAssignmentData, dispatch, setFetchPreventionPlanAssignmentData, postProjects, engagementsActiveOnly, setEngagementsActiveOnly, matchingProject, navigate]);
  

    useEffect(() => {
      if (fetchPreventionPlanAssignmentData && getProjectStatus) {
      initializePreventionPlanAssignmentData();
    }
    }, [getProjectStatus, fetchPreventionPlanAssignmentData, initializePreventionPlanAssignmentData]);
    
    //changement du state
    const currentUser = useSelector(
      (state) =>
      state.api.mutations["UserInfos"]
      ? state.api.mutations["UserInfos"].status === "fulfilled"
      ? state.api.mutations["UserInfos"].data
      : []
      : [],
      _.isEqual,
      );

      const [openDialog, setOpenDialog] = useState(false);
      //information du store
      const apiStatus = useSelector((state)=> 
      state.api.mutations["prevention_plan_assignment_data"]
      ? state.api.mutations["prevention_plan_assignment_data"].status
      : "loading",
      );
      
      
      const prevention_plan_assignment_data = useSelector(
        (state) =>
        state.api.mutations["prevention_plan_assignment_data"]
        ? state.api.mutations["prevention_plan_assignment_data"].status === "fulfilled"
        ? state.api.mutations["prevention_plan_assignment_data"].data
        : []
        : [],
        _.isEqual,
        );

        if (matchingProject) {
          currentProject = filteredProject[0];

          const filteredEngagement = currentProject.engagements.filter((engagement) => engagement.engagement_integration_key.toString() === engagement_integration_key);
          
          currentEngagement = filteredEngagement[0];
          
          isCurrentUserIsCurrentConsultant =
          currentUser.id === String(currentEngagement.consultant_id);
        }

  const handleOpenConfirmationDialog = (engagement_id) => {
    setConfirmDialogOpen(true);
    setSelectedEngagementForDeletion(engagement_id);
  };
  const fetchAssignments = useCallback(async () => {
    try {
      const prevention_plan_id = prevention_plan_assignment_data.id
      let customer_name = ""

      if (prevention_plan_assignment_data.prevention_plan_status === "NOT APPLICABLE"){
        customer_name = currentProject.customer_name
      } else {
        customer_name = prevention_plan_assignment_data.customer_name
      }

      await getEngagementsPreventionPlanAssignment({
        prevention_plan_id: prevention_plan_id,
        customer_name: customer_name,
        remove_closed: false,
      });
      // Update state or perform other actions with the fetched data
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  }, [currentProject.customer_name, prevention_plan_assignment_data.prevention_plan_status, prevention_plan_assignment_data.id, prevention_plan_assignment_data.customer_name, getEngagementsPreventionPlanAssignment]);

  const handleUnassign = async (engagement_id) => {
    setLoading(true);
    let apiStatus = null
    try {
      apiStatus = await removePreventionPlanAssignment({ engagement_id: engagement_id }).unwrap();
      // Call fetchAssignments after removing assignment
      await fetchAssignments();
      await postProjects({remove_closed: true})
      setEngagementsActiveOnly(true);
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: apiStatus.status,
          snackbarMessage: apiStatus.msg,
        },
      });
    } catch (error) {
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
        },
      });
      console.log("error", error)
    } finally {
      navigate('/projects')
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection:"column" }} mb={isSmallScreen ? 15 : 4}>
      <CssBaseline />
        {apiStatus === "pending"  || loading? (
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <CustomLoading loadingText={loading? "Saving data..." : "Loading data..."} />
          </Container>
        ) :  apiStatus === "loading" 
        ? (
          <Notification />
          ) : (
          <Container maxWidth="xl" py={2} mb={10}>
                <Notification />
            <Grid >

            <Grid container my={3} >
              {isSmallScreen ? null : (
                // <Grid item>
                  <Typography variant="h4">
                    {currentEngagement ? (
                      <>
                      Prevention Plan |{" "}
                        {currentEngagement.consultant_full_name +
                          " - " +
                          truncateText(
                            currentEngagement.project_legal_description,
                            50
                            )}
                      </>
                    ) : (
                      <Skeleton width={200} />
                      )}
                  </Typography>
                // </Grid>
              )} 
              <Grid item xs={12} sm={12} md={12} >
                <Typography variant="h5">
                  {currentProject.project_id !== undefined && currentProject.project_name !== undefined ? (
                    <>
                      {currentProject.project_id +
                        " | " +
                        currentProject.project_name}
                    </>
                    ) : (
                      <Skeleton width={200} />
                      )}
                </Typography>
              </Grid>
            </Grid>

            <Grid container p={0} my={2} justifyContent='flex-start'> 
              {openDialog ? (
                <DialogPreventionPlanSignature
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                currentEngagement={currentEngagement}
                currentPreventionPlan={prevention_plan_assignment_data}
                />
                ) : null}
              {/* ______________________________________________________________________________________________________________________information du projet et de l'engagement */}
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title={`Consultant: ${currentEngagement?.consultant_full_name}`}>
                    <Chip
                      label={
                        currentEngagement ? (
                          currentEngagement.consultant_full_name
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          icon={<PersonIcon />}
                          size="small"
                          color={isCurrentUserIsCurrentConsultant ? "success" : "default"}
                          />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`RC: ${currentEngagement?.project_commercial_owner_name}`}>
                    <Chip
                      label={
                        currentEngagement ? (
                          currentEngagement.project_commercial_owner_name
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          icon={<SupervisorAccountIcon />}
                          size="small"
                          />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Project Department: ${currentProject?.project_department}`}>
                    <Chip
                      label={
                        currentProject.project_department !== undefined ? (
                          currentProject.project_department
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          icon={<LocationCityIcon />}
                          size="small"
                          />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title="Validity date">
                    <Chip
                     label={`${moment(currentEngagement?.engagement_start_date).format(
                       isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                       )} 
                       - ${moment(currentEngagement?.engagement_end_date).format(
                         isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                         )}`}
                         icon={<CalendarMonthIcon />}
                         size="small"
                         />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Status: ${currentEngagement.engagement_status}`}>
                    <Chip
                      label={currentEngagement.engagement_status}
                      size="small"
                      />
                  </Tooltip>
                </Grid >
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title={`Customer's name: ${currentProject.customer_name}`}>
                    <Chip 
                      label={currentProject.customer_name !== undefined ? currentProject.customer_name : <Skeleton width={100} />} 
                      size="small" 
                      />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Customer's full address">
                    <Chip
                      label={currentEngagement ? currentEngagement.customer_full_address : <Skeleton width={200} />}
                      icon={<PlaceIcon color="error" />}
                      component="a"
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        currentProject?.customer_name +
                        ", " +
                        currentEngagement?.customer_full_address
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{cursor: "pointer", maxWidth: 150}}
                      size="small"
                      />
                  </Tooltip>
                </Grid>
                {isSmallScreen ? null : (
                  <Grid item>
                  <Tooltip title={`Project Contact: ${currentEngagement?.project_contact}`}>
                    <Chip
                      label={currentEngagement ? currentEngagement.project_contact : <Skeleton width={100} />}
                      icon={<ContactPhoneIcon />}
                      size="small"
                      />
                  </Tooltip>
              </Grid>
                )}
                {isSmallScreen ? null : (
                  <Grid item>
                    <Tooltip title={`Activity sector: ${currentEngagement?.engagement_activity_sector}`}>
                      <Chip
                        label={currentEngagement ? currentEngagement.engagement_activity_sector : <Skeleton width={100} />}
                        size="small"
                        />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
                </Grid>
            <Grid container spacing={1} p={0} mt={2} mb={3} justifyContent='flex-start' alignItems='center'> 
              <Grid item display='flex' flexDirection='row' alignItems='center'>
                {/* ______________________________________________________________________information du plan de prévention assigné à l'engagement sélectionné */}
                <Typography variant="subtitle1">
                  Status:
                </Typography>
                <Typography variant="button">
                  <Tooltip title={currentEngagement.prevention_plan_status}>
                    <Chip
                      size="small"
                      color={currentEngagement?.prevention_plan_status?.replace(/ /g, "")}
                      label={currentEngagement 
                        ? currentEngagement.prevention_plan_status === "SIGNED"
                        ? prevention_plan_assignment_data.signature_date
                        ? `${currentEngagement.prevention_plan_status} ON ${moment(prevention_plan_assignment_data?.signature_date, "YYYY-MM-DD").format("DD/MM/YYYY")}`
                        : currentEngagement.prevention_plan_status
                        : currentEngagement.prevention_plan_status
                        : <Skeleton width={100} />}
                      sx={{ ml: 1 }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              {prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ?
              <>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="subtitle1">
                  Name:
                  <Typography variant="button">
                    <Chip
                      label={prevention_plan_assignment_data?.prevention_plan_name}
                      size="small"
                      sx={{ ml: 1, maxWidth: 200 }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="subtitle1">
                  Validity Date:
                  <Typography variant="button">
                    <Chip
                      label={moment(prevention_plan_assignment_data?.validity_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="subtitle1">
                  Files (must be read before signing):
                </Typography>
              </Grid>
              </>  : null }
            </Grid>
            {/* ___________________________________________________________________________________________________________________________________________________affichage des fichiers (en édition uniquement) */}          
            <AttachmentInput
              editMod="VIEW"
              initialFiles={prevention_plan_assignment_data?.files}
            />
            { currentUser === undefined || isCurrentUserIsCurrentConsultant ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                sx={{ mr: 4, mt: 1, mb: 1, p: 0 }}
              >
                {/* __________________________________________________________________affichage du bouton de signature uniquement si l'utilisateur est le consultant de l'engagement sélectionné */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  mt={3}
                >
                  <Button
                    onClick={() => setOpenDialog(true)}
                    variant="contained"
                    disabled={prevention_plan_assignment_data?.prevention_plan_status !== "READY TO BE SIGNED"}
                    startIcon={<EditAttributesIcon />}
                  >
                    Sign my Prevention Plan
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3} mb={isSmallScreen && 10}>
                <Button 
                 sx={{
                    mr: isSmallScreen ? 0 : 2      
                  }}
                  color={prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ? 'error' : 'primary'}
                  variant={prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ? 'contained' : 'outlined'}
                  onClick={() => handleOpenConfirmationDialog(currentEngagement.engagement_integration_key)}>
                  {prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ? (isSmallScreen ? "DISPATCH" : "Detach this prevention plan") : "APPLICABLE ?"}
                </Button>
                  {confirmDialogOpen && selectedEngagementForDeletion === currentEngagement.engagement_integration_key ? (
                  <ConfirmDialog
                    title={prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ? "Are you sure you want to detach this prevention plan?" : "Are you sure you want to set this prevention plan to applicable ?"}
                    description={prevention_plan_assignment_data.prevention_plan_status !== "NOT APPLICABLE" ? 'By detaching this prevention plan from this engagement, its status will be set to "TO BE ASSIGNED" and you will need to assign a new prevention plan.' : 'By setting this prevention plan as applicable, its status will be set to "TO BE ASSIGNED" and you will be able to assign a prevention plan.'}
                    mod="Detach"
                    yesButtonText="Detach"
                    open={confirmDialogOpen}
                    setOpen={setConfirmDialogOpen}
                    onConfirm={() => handleUnassign(currentEngagement.engagement_integration_key.toString())}
                  />
                  ) : null 
                }
            </Box>)}
          </Container>
        )}
      </Box>
    )}
