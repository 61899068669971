import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./getToken";
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const eStarterApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async (headers) => {
      const access_token = await getToken();

      if (access_token) {
        headers.set("Content-Type", "application/json");
        headers.set("Access-Control-Allow-Origin", true);
        headers.set("Access-Control-Allow-Credentials", true);
        headers.set("Authorization", `Bearer ${access_token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserInfos: builder.mutation({
      query: () =>
        `GetUserInfos?code=${process.env.REACT_APP_BACKEND_KEY}`,
    }),
    getProjects: builder.mutation({
      query: (body) => ({
        url: `GetProjects?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    getEngagementBrief: builder.mutation({
      query: (body) => ({
        url: `GetEB?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),

    getEngagementBriefFormParams: builder.mutation({
      query: (body) => ({
        url: `GetEBFormParams?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),

    submitEngagementBrief: builder.mutation({
      query: (body) => ({
        url: `SubmitEB?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),

    getEngagementsPreventionPlanAssignment: builder.mutation({
      query: (body) => ({
        url: `GetEngagementsPPAssignment?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    getPreventionPlanFiles: builder.mutation({
      query: (body) => ({
        url: `GetPPFiles?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    getPreventionPlanAssignmentData: builder.mutation({
      query: (body) => ({
        url: `GetPPAssignmentData?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    submitPreventionPlanAssignment: builder.mutation({
      query: (body) => ({
        url: `SubmitPPAssignment?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    removePreventionPlanAssignment: builder.mutation({
      query: (body) => ({
        url: `RemovePPAssignment?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    submitPreventionPlanFiles: builder.mutation({
      query: (body) => ({
        url: `SubmitPPFiles?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    getFileContent: builder.mutation({
      query: (body) => ({
        url: `GetFileContent?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    getMarginByProject: builder.mutation({
      query: (body) => ({
        url: `GetMarginByProject?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    }),
    getMarginDraftsByUserId: builder.mutation({
      query: (body) => ({
        url: `GetMarginDraftsByUserId?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    submitDraft: builder.mutation({
      query: (body) => ({
        url: `SubmitDraft?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    submitYearlyMargin: builder.mutation({
      query: (body) => ({
        url: `SubmitYearlyMargin?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    getCountriesSettings: builder.mutation({
      query: (body) => ({
        url: `GetCountriesSettings?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    submitCountriesSettings: builder.mutation({
      query: (body) => ({
        url: `SubmitCountriesSettings?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    getSpecificUsers: builder.mutation({
      query: (body) => ({
        url: `GetSpecificUsers?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    submitSpecificUsers: builder.mutation({
      query: (body) => ({
        url: `SubmitSpecificUsers?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      })
    }),
    validateMarginViaEmail: builder.mutation({
      query: (url, body) => ({
        url: `validateMarginViaEmail?code=${process.env.REACT_APP_BACKEND_KEY}`,
        method: "POST",
        body: body,
      }),
    })
  }),
});

//must be format "use" + endointName + "Query"/"Mutation"  GetTimesheetData
export const {
  useGetUserInfosMutation,
  useGetProjectsMutation,
  useGetEngagementBriefMutation,
  useGetEngagementBriefFormParamsMutation,
  useSubmitEngagementBriefMutation,
  useGetEngagementsPreventionPlanAssignmentMutation,
  useGetPreventionPlanFilesMutation,
  useGetPreventionPlanAssignmentDataMutation,
  useRemovePreventionPlanAssignmentMutation,
  useSubmitPreventionPlanAssignmentMutation,
  useSubmitPreventionPlanFilesMutation,
  useGetFileContentMutation,
  useGetMarginByProjectMutation,
  useGetMarginDraftsByUserIdMutation,
  useSubmitDraftMutation,
  useSubmitYearlyMarginMutation,
  useGetCountriesSettingsMutation,
  useSubmitCountriesSettingsMutation,
  useGetSpecificUsersMutation,
  useSubmitSpecificUsersMutation,
  useValidateMarginViaEmailMutation,
} = eStarterApi;

export const { getProjects } = eStarterApi.endpoints;

//api différente pour la creation des fichiers car pas de 'Content-Type' dans les headers et le process est un peu différent
export const apiCreateFiles = createApi({
  reducerPath: "apiCreateFiles",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async (headers) => {
      const access_token = await getToken();

      if (access_token) {
        headers.set("Access-Control-Allow-Origin", true);
        headers.set("Access-Control-Allow-Credentials", true);
        headers.set("Authorization", `Bearer ${access_token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    CreateFiles: builder.mutation({
      query: ({ files, folderName }) => {
        const formData = new FormData();

        files.forEach((file) => {
          const pathWithFolder = `${folderName}/${file.name}`;
          formData.append("files", file, pathWithFolder);
        });

        return {
          url: `/CreateFiles?code=${process.env.REACT_APP_BACKEND_KEY}`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useCreateFilesMutation } = apiCreateFiles;
