import React, { useCallback, useEffect, useState } from "react";
import { 
    Typography, 
    Accordion, 
    AccordionSummary, 
    Grid, 
    AccordionDetails,
    FormControl,
    TextField,
    Autocomplete,
    Stack,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    useMediaQuery,
    Tooltip,
    InputAdornment,
    createTheme,
    ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { calculateWorkingDaysPerMonth, truncateText } from "../../../commonFunction";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";



const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: 'initial', // Resets the border color upon focus
                        },
                        '&:hover fieldset': {
                            borderColor: 'initial', // Resets the border color on hover
                        }
                    }
                }
            }
        }
    }
});


const TeamCard = React.memo((props) => {

    // ________________________________________________________________________ Props ________________________________________________________________________
    const countrySettings = props.countrySettings
    const teamCard = props.teamCard
    const currencySymbol = countrySettings.currency.symbol
    const currentProject= props.currentProject
    const updateTotalRevenue = props.updateTotalRevenue
    const handleChangeParent = props.handleChangeParent
    const teamCardIndex = props.index
    const mode = props.mode
    const handleDeleteDraftCard = mode === "Draft" ? props.handleDeleteDraftCard : null
    const handleChangeDraftCardName = mode === "Draft" ? props.handleChangeDraftCardName : null
    // ________________________________________________________________________ State ________________________________________________________________________
    const [updatedInputValues, setUpdatedInputValues] = useState(props.teamCard)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(teamCard.name);
    
    // ________________________________________________________________________ Accordions & CSS ________________________________________________________________________
    const [open, setOpen] = useState(false)
    const handleToggleTeamCards = useCallback(() => {
        setOpen(!open);
      }, [open]);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
 
    // ________________________________________________________________________ Selector ________________________________________________________________________
    const selectTimeValue= ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"]

    // ________________________________________________________________________ Calculations ________________________________________________________________________
    const marginSettings = countrySettings.margin_settings
    const dailyAnnualBasis = marginSettings.day_annual_basis
    const monthlyAnnualBasis = marginSettings.month_annual_basis
    const chargesCoefficient = marginSettings.charges_coefficient
    const soldDays = mode === "Margin" ? updatedInputValues.sold_days 
        ? parseFloat(updatedInputValues.sold_days) 
        : ""
        : updatedInputValues.sold_days

    const workedDays = mode === "Margin" ? updatedInputValues.worked_days 
    ? parseFloat(updatedInputValues.worked_days) 
    : ""
    :updatedInputValues.worked_days

    const dailySaleRate =  mode === "Margin" ? (updatedInputValues.daily_sale_rate.margin || updatedInputValues.daily_sale_rate.margin === 0) 
    ? updatedInputValues.daily_sale_rate.margin
    : updatedInputValues.sale_time_unit === "Jour prest"
    ? parseFloat(updatedInputValues.daily_sale_rate.crm)
    :  0
    : updatedInputValues.daily_sale_rate
    
    const monthlyGrossSalary =  mode === "Margin" ? parseFloat(
        (updatedInputValues.employee.salary.monthly_gross_salary.margin || updatedInputValues.employee.salary.monthly_gross_salary.margin === 0)
        ? updatedInputValues.employee.salary.monthly_gross_salary.margin
        : updatedInputValues.employee.salary.monthly_gross_salary.sap
        ? updatedInputValues.employee.salary.monthly_gross_salary.sap
        : 1
    ) : parseFloat(updatedInputValues.monthly_gross_salary);
    
    const annualBonus =  mode === "Margin" ? parseFloat(
        (updatedInputValues.employee.salary.annual_bonus.margin || updatedInputValues.employee.salary.annual_bonus.margin === 0)
        ? updatedInputValues.employee.salary.annual_bonus.margin
        : updatedInputValues.employee.salary.annual_bonus.sap 
        ? updatedInputValues.employee.salary.annual_bonus.sap
        : 0
    ) : parseFloat(updatedInputValues.annual_bonus);
    
    const monthlyFees =    mode === "Margin" ? parseFloat(
        (updatedInputValues.monthly_fees.margin || updatedInputValues.monthly_fees.margin === 0)
        ? updatedInputValues.monthly_fees.margin
        : updatedInputValues.monthly_fees.country_params 
        ? updatedInputValues.monthly_fees.country_params
        : 0
    ) : parseFloat(updatedInputValues.monthly_fees);
    const dailyFees =  mode === "Margin" ? parseFloat(
        (updatedInputValues.daily_fees.margin || updatedInputValues.daily_fees.margin === 0)
        ? updatedInputValues.daily_fees.margin 
        : updatedInputValues.daily_fees.crm || updatedInputValues.daily_fees.country_params
        ? (updatedInputValues.daily_fees.crm + updatedInputValues.daily_fees.country_params)
        : 0
    ) : parseFloat(updatedInputValues.daily_fees);
    
    const dailyITCosts =  mode === "Margin" ? parseFloat(
        (updatedInputValues.daily_it_costs.margin || updatedInputValues.daily_it_costs.margin === 0)
        ? updatedInputValues.daily_it_costs.margin 
        : updatedInputValues.daily_it_costs.country_params 
        ? updatedInputValues.daily_it_costs.country_params
        : 0
    ) : updatedInputValues.daily_it_costs;
    
    const dailyDeskCosts =  mode === "Margin" ? parseFloat(
        (updatedInputValues.daily_desk_costs.margin || updatedInputValues.daily_desk_costs.margin === 0)
        ? updatedInputValues.daily_desk_costs.margin 
        : updatedInputValues.daily_desk_costs.country_params 
        ? updatedInputValues.daily_desk_costs.country_params
        : 0
    ) : parseFloat(updatedInputValues.daily_desk_costs);
    
    const subcontractorCost =  mode === "Margin" ? parseFloat(
        (updatedInputValues.daily_purchase_rate.margin || updatedInputValues.daily_purchase_rate.margin === 0)
        ? updatedInputValues.daily_purchase_rate.margin
        : updatedInputValues.daily_purchase_rate.crm
        ? updatedInputValues.daily_purchase_rate.crm
        : 0
    ) : parseFloat(updatedInputValues.daily_purchase_rate);
    
    const officePresence = parseFloat(updatedInputValues.office_presence);
    // Generating the number of working days to display
    const startDate = new Date(updatedInputValues.start_date)
    const endDate = new Date(updatedInputValues.end_date)
    const workingDaysPerMonthObject = mode === "Margin" ? calculateWorkingDaysPerMonth(startDate, endDate, currentProject, countrySettings) : null;
    const workingDaysIndicator = mode === "Margin" ? Object.values(workingDaysPerMonthObject).reduce((acc, currentValue) => acc + currentValue, 0) : null;
    //  (salaire mensuel *référentiel mois + bonus annuel) * coeff charge / référentiel jours + frais journaliers + frais mensuels * 12 / référentiel jours
    const dailyProductionCosts = ((((monthlyGrossSalary * monthlyAnnualBasis) + annualBonus) * chargesCoefficient ) / dailyAnnualBasis) + dailyFees + ((monthlyFees * 12) / dailyAnnualBasis);
    const officeCosts = dailyITCosts + (dailyDeskCosts * (officePresence/100));

    // On calcule les coûts de production pour les consultants et les sous-traitants 
    const marginProductionCostConsultant = dailyProductionCosts + officeCosts
    const revenue = (soldDays * dailySaleRate).toFixed(2);

    const profit = (revenue - ((subcontractorCost ? subcontractorCost : marginProductionCostConsultant ) * workedDays))
    
    const engagementMargin = (profit / revenue * 100).toFixed(1)
    
    const refRate = (dailyProductionCosts/0.65).toFixed(2)
    
    const handleChangeChild = useCallback((event) => {
        const { name, value } = event.target;
        const regex = /^-?\d*\.?\d*$/;
        // const cardIdOrIndex = mode === "Draft" ? teamCardIndex : teamCard.id;

        if (name === "office_presence" || name === "es3") {
            handleChangeParent(event, teamCard.id);
        }
        else {
            if (regex.test(value) || value === "") { 
                handleChangeParent(event, teamCard.id);
            } else {
                console.log("Invalid input for", name);
            }
        }
    }, [handleChangeParent, teamCard.id]);



const isRevenueAvailable = useCallback(() => {
    if (mode === 'Margin') {
        return updatedInputValues.sold_days > 0 && updatedInputValues.daily_sale_rate.margin !== '';
    } 
    if (mode === 'Draft') {
        return updatedInputValues.sold_days > 0 && updatedInputValues.daily_sale_rate > 0;
    }
}, [updatedInputValues.sold_days, updatedInputValues.daily_sale_rate, mode]); // mode is now a dependency


    const handleOpenConfirmationDialog = () => {
        setConfirmDialogOpen(true);
      };

    useEffect(() => {
        setUpdatedInputValues(props.teamCard)
        if (isRevenueAvailable()) {
            updateTotalRevenue(teamCard.id, parseFloat(revenue));
        } else {
            updateTotalRevenue(teamCard.id, 0);
        }
    }, [updateTotalRevenue, updatedInputValues, revenue, teamCard.id, props.teamCard, isRevenueAvailable]);


    return(
        <ThemeProvider theme={theme}>

        <Accordion 
            expanded={open} onChange={handleToggleTeamCards} elevation={0}
            sx={{border:'1px solid lightgray'}}
            >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon />} 
                style={{userSelect: 'text', backgroundColor: isEditing ? '#ffffff' : 'inherit'}}
                >
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item flexDirection="column"  minWidth={"50%"}>
                        <Grid item display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="h6">
                            {isEditing ? (
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleChangeDraftCardName(teamCard.id, editName);
                                            setIsEditing(false);
                                            e.target.blur();

                                        } else if (e.key === 'Escape') {
                                            setIsEditing(false);
                                            e.target.blur();
                                        }
                                    }}
                                    autoFocus
                                    inputProps={{
                                        name: "name", 
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'initial',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'initial',
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                mode === "Margin" ? `${teamCard.employee.first_name} ${truncateText(teamCard.employee.last_name, 15)}` : teamCard.name === "" ? teamCard.daily_purchase_rate === null ? `Consultant#${teamCardIndex + 1}` : teamCard.es3.toString() === "true" ? `ES3#${teamCardIndex + 1}` : `Subcontractor#${teamCardIndex + 1}` : teamCard.name
                            )}
                            </Typography>
                            {mode === "Margin" ? 
                            <Typography variant="subtitle2" ml={1}>
                                {teamCard["daily_purchase_rate"]["crm"] ? teamCard["es3"] ? "(ES3)" : "(Subcontractor)" : null}
                            </Typography> 
                                : 
                                <Typography variant="subtitle2" ml={1}>
                                {teamCard.daily_purchase_rate !== null ? teamCard.es3 ? "(ES3)" : "(Subcontractor)" : null}
                            </Typography> 
                            }
                                {mode === "Margin" ? !props.isCardComplete && <span style={{ color: 'red', marginLeft: 2 }}>*</span> : null}
                        {mode === "Draft" ? 
                        <>
                            <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(!isEditing);
                            }}
                            >
                            <EditIcon 
                              size="small" 
                              />
                          </IconButton>
                            <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOpenConfirmationDialog();
                            }}
                            >
                            <DeleteIcon 
                              color="error" 
                              size="small" 
                              />
                          </IconButton>
                            {confirmDialogOpen ? 
                                <ConfirmDialog
                                title="Are you sure you want to delete this card?"
                                description=''
                                mod="Delete"
                                yesButtonText="Delete"
                                open={confirmDialogOpen}
                                setOpen={setConfirmDialogOpen}
                                onConfirm={() => handleDeleteDraftCard(teamCard.id)}
                                />
                                : null } 
                                </>
                          : null }
                        </Grid>
                        {mode === "Margin" ? 
                        <Grid item>
                            <Tooltip title={ teamCard.handover_start_date && teamCard.handover_end_date ? `Handover period : from ${teamCard.handover_start_date} to ${teamCard.handover_end_date}` : "No handover period"}>
                                <Typography>
                                    {moment(teamCard.start_date).format("DD MMMM YYYY") } - {moment(teamCard.end_date).format("DD MMMM YYYY")}
                                </Typography>
                            </Tooltip>
                        </Grid> : null}
                        <Grid item>
                        {mode === "Margin" ?
                            <Tooltip title={ `Employee site : ${teamCard.employee.employee_site}`}>
                                <Typography>
                                    {teamCard.employee.employee_site}
                                </Typography>
                            </Tooltip> : null}
                        </Grid>
                    </Grid>
                    <Tooltip title="Engagement Margin = (Profit = Revenue - Consultant costs) / Revenue">
                        <Grid item display='flex' alignItems="center">
                            <Typography variant="subtitle2" mx={2}>
                                Eng.margin :
                            </Typography>
                            <Typography variant="h6">
                            {props.isCardComplete && !isNaN(engagementMargin) 
                            ? engagementMargin
                            : "--"} %
                            </Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title="Revenue = Daily Rate * Sold Days">
                        <Grid item display='flex' alignItems="center">
                            <Typography variant="subtitle2" mx={2}>
                                Revenue :
                            </Typography>
                            <Typography variant="h6">
                                {
                                    isRevenueAvailable() ?
                                    parseFloat(revenue).toLocaleString('en-US', {minimumFractionDigits: 2}) 
                                    : 0
                                } {currencySymbol}
                            </Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl fullWidth>
                    <Grid container display="flex" alignItems="center" width="100%">
                        <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            divider={
                                <Divider orientation="vertical" flexItem sx={{height: "120px"}}/>
                            }
                            spacing={2}
                            width="100%"
                            >
                            <Grid item display='flex'justifyContent="flex-start" xs={12} sm={12} md={4}>
                                <Grid item flexDirection="column" display='flex' alignItems="flex-start" pl={3}>
                                    <Grid container fullwidth>
                                        <FormControl >
                                            <Typography
                                                variant="caption"
                                                color="error"
                                                >
                                                {mode === "Margin" ?   
                                                    (updatedInputValues.worked_days === "")
                                                    || (updatedInputValues.sold_days === "")
                                                    || (!updatedInputValues.daily_sale_rate.margin && !updatedInputValues.daily_sale_rate.crm)
                                                    ? "Please enter only digits" 
                                                    : ""
                                                    :
                                                    !updatedInputValues.worked_days
                                                    || !updatedInputValues.sold_days
                                                    || (!updatedInputValues.daily_sale_rate)
                                                    ? "Please enter only digits" 
                                                    : ""
                                                    
                                                }
                                            </Typography>
                                            <Grid item display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid width="250px">
                                                    <TextField
                                                        key={teamCard.id}
                                                        size="small"
                                                        label="Worked days"
                                                        name="worked_days"
                                                        sx={{ my: 1 }}
                                                        required
                                                        fullWidth
                                                        value={updatedInputValues.worked_days}
                                                        onChange={handleChangeChild}
                                                        error={updatedInputValues.worked_days === ""} 
                                                        />
                                                </Grid>
                                                {mode === "Margin" ?                                                
                                                <Grid item pl={2} display="flex" justifyContent="flex-end">
                                                    <Tooltip title={`WD: Number of Working Days from ${moment(teamCard.start_date).format("DD MMMM YYYY")} to ${moment(teamCard.end_date).format("DD MMMM YYYY")}`}>
                                                        <Typography variant="caption">
                                                            WD: {workingDaysIndicator}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid> : null}
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <Grid width="250px">
                                                <TextField
                                                    key={teamCard.id}
                                                    fullWidth
                                                    size="small"
                                                    label="Sold days"
                                                    sx={{ my: 1 }}
                                                    required
                                                    name="sold_days"
                                                    value={updatedInputValues.sold_days}
                                                    onChange={handleChangeChild}
                                                    error={updatedInputValues.sold_days === ""}
                                                    />
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid >
                                        <FormControl>
                                        <Grid item display="flex" justifyContent="space-between" alignItems="center">
                                            <Tooltip title="The sale rate will only be taken into account if the provided value is the daily sale rate. Monthly or hourly sale rates will not be displayed">
                                                <Grid item width="250px">
                                                    <TextField
                                                        key={teamCard.id}
                                                        size="small"
                                                        fullWidth
                                                        label="Daily rate"
                                                        defaultValue={mode === "Margin" ? updatedInputValues.daily_sale_rate.crm && updatedInputValues.sale_time_unit === "Jour prest" ? updatedInputValues.daily_sale_rate.crm : "" : updatedInputValues.daily_sale_rate}
                                                        value={mode === "Margin" ? updatedInputValues.daily_sale_rate.margin : updatedInputValues.daily_sale_rate}
                                                        name="daily_sale_rate"
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                        isNaN(updatedInputValues.daily_sale_rate.margin) || 
                                                        updatedInputValues.daily_sale_rate.margin === "" || 
                                                        (updatedInputValues.sale_time_unit !== "Jour prest" && updatedInputValues.daily_sale_rate.margin === null) 
                                                        :
                                                        isNaN(updatedInputValues.daily_sale_rate) || 
                                                        updatedInputValues.daily_sale_rate === ""  
                                                        }
                                                    />
                                                </Grid>
                                            </Tooltip>
                                                {(mode === "Margin" ? updatedInputValues.daily_purchase_rate.crm : updatedInputValues.daily_purchase_rate !== null) ?
                                                    null :
                                                    (
                                                        <Tooltip title="Tref: Reference rate calculated according to the consultant's costs to reach the required 35% margin rate">
                                                            <Grid item display="flex" flexDirection="column" alignItems="center" pl={2}>
                                                                <Typography variant="caption">
                                                                    Tref: 35%
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {mode === "Margin" ?
                                                                        // Vérification des critères pour déterminer l'affichage du prix de vente de référence
                                                                        updatedInputValues.office_presence &&
                                                                        updatedInputValues.daily_fees.margin !== '' &&
                                                                        !isNaN(updatedInputValues.daily_fees.margin) &&
                                                                        updatedInputValues.monthly_fees.margin !== '' &&
                                                                        !isNaN(updatedInputValues.monthly_fees.margin) &&
                                                                        updatedInputValues.employee.salary.monthly_gross_salary.margin !== '' &&
                                                                        !isNaN(updatedInputValues.employee.salary.monthly_gross_salary.margin) &&
                                                                        updatedInputValues.employee.salary.annual_bonus.margin !== '' &&
                                                                        !isNaN(updatedInputValues.employee.salary.annual_bonus.margin)
                                                                        ? refRate
                                                                        : "-- "
                                                                        :
                                                                        updatedInputValues.office_presence &&
                                                                        updatedInputValues.daily_fees !== '' &&
                                                                        !isNaN(updatedInputValues.daily_fees) &&
                                                                        updatedInputValues.monthly_fees !== '' &&
                                                                        !isNaN(updatedInputValues.monthly_fees) &&
                                                                        updatedInputValues.monthly_gross_salary !== '' &&
                                                                        !isNaN(updatedInputValues.monthly_gross_salary) &&
                                                                        updatedInputValues.annual_bonus !== '' &&
                                                                        !isNaN(updatedInputValues.annual_bonus)
                                                                        ? refRate
                                                                        : "-- "
                                                                    }
                                                                    {currencySymbol}
                                                                </Typography>
                                                            </Grid>
                                                        </Tooltip>
                                                    )
                                                }
                                            </Grid>

                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(mode === "Margin" ?
                                updatedInputValues.daily_purchase_rate.crm : updatedInputValues.daily_purchase_rate !== null)
                                ? (
                                    <Stack
                                    direction={isSmallScreen ? "column" : "row"}
                                    divider={<Divider orientation="vertical" flexItem sx={{height: "120px"}} />}
                                    spacing={2}
                                    width="100%"
                                    alignItems="flex-start"
                                    >
                                        <Grid item display='flex' alignItems="center" justifyContent="space-evenly" xs={12} sm={12} md={3}>
                                            <Grid item display="flex" flexDirection="column" alignItems="center">
                                                <FormLabel sx={{mx:2}} >Is Subcontractor an ES3 ? </FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={updatedInputValues.es3}
                                                    name="es3"
                                                    onChange={(event) => handleChangeChild(event)}
                                                    >
                                                    <FormControlLabel value='true' control={<Radio />} label="Yes" />
                                                    <FormControlLabel value='false' control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                        <Grid item display='flex' alignItems="center" justifyContent="space-evenly" xs={12} sm={12} md={3}>
                                            <Tooltip title="The purchase rate will only be taken into account if the provided value is the daily purchase rate. Monthly or hourly purchase rates will not be displayed">
                                                <Grid item>
                                                    <FormControl>
                                                        <TextField
                                                            size="small"
                                                            label="Daily Purchase Rate"
                                                            defaultValue={mode === "Margin" ? updatedInputValues.daily_purchase_rate.crm && updatedInputValues.purchase_time_unit === "Jour prest" ? updatedInputValues.daily_purchase_rate.crm : "" : updatedInputValues.daily_purchase_rate}
                                                            name="daily_purchase_rate"
                                                            value={mode === "Margin" ? updatedInputValues.daily_purchase_rate.margin : updatedInputValues.daily_purchase_rate}
                                                            onChange={handleChangeChild}
                                                            sx={{ my: 1 }}
                                                            required
                                                            InputProps={{
                                                                endAdornment: 
                                                                <InputAdornment position="end">
                                                                        {currencySymbol}
                                                                    </InputAdornment>
                                                            }}
                                                            error={mode === "Margin" ?
                                                                updatedInputValues.daily_purchase_rate.margin === "" || 
                                                                (updatedInputValues.purchase_time_unit !== "Jour prest" && 
                                                                updatedInputValues.daily_purchase_rate.margin === null) 
                                                                :
                                                                updatedInputValues.daily_purchase_rate === "" || 
                                                                updatedInputValues.daily_purchase_rate === null 
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Stack> 

                                ) : (
                                    <Stack
                                        direction={isSmallScreen ? "column" : "row"}
                                        divider={<Divider orientation="vertical" flexItem sx={{height: "120px"}}/>}
                                        spacing={2}
                                        width="100%"
                                        alignItems="flex-start"
                                        >
                                        <Grid item flexDirection="column" display="flex" alignItems="center"xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl fullwidth>
                                                    <Typography
                                                        variant="caption"
                                                        color="error"
                                                        >
                                                        {
                                                            mode === "Margin"
                                                            ? (
                                                                isNaN(updatedInputValues.employee.salary.monthly_gross_salary.margin) || updatedInputValues.employee.salary.monthly_gross_salary.margin === ""
                                                                || isNaN(updatedInputValues.employee.salary.annual_bonus.margin) || updatedInputValues.employee.salary.annual_bonus.margin === ""
                                                            )
                                                            ? "Please enter only digits"
                                                            : ""
                                                            : (
                                                                isNaN(updatedInputValues.monthly_gross_salary) || updatedInputValues.monthly_gross_salary === ""
                                                                || isNaN(updatedInputValues.annual_bonus) || updatedInputValues.annual_bonus === ""
                                                            )
                                                            ? "Please enter only digits"
                                                            : ""
                                                        }
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        required
                                                        label="Monthly gross salary"
                                                        defaultValue={mode === "Margin" ? (updatedInputValues.employee.salary.monthly_gross_salary.sap ? updatedInputValues.employee.salary.monthly_gross_salary.sap : 0 ) : updatedInputValues.monthly_gross_salary}
                                                        name="monthly_gross_salary"
                                                        value={mode === "Margin" ? updatedInputValues.employee.salary.monthly_gross_salary.margin : updatedInputValues.monthly_gross_salary}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                            !updatedInputValues.employee.salary.monthly_gross_salary.sap 
                                                            && updatedInputValues.employee.salary.monthly_gross_salary.margin === ""
                                                            :
                                                            !updatedInputValues.monthly_gross_salary && updatedInputValues.monthly_gross_salary === ""
                                                        }
                                                        />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl fullwidth>
                                                    <TextField
                                                        size="small"
                                                        label="Annual bonus"
                                                        defaultValue={mode === "Margin" ?( updatedInputValues.employee.salary.annual_bonus.sap ? updatedInputValues.employee.salary.annual_bonus.sap : 0) : updatedInputValues.annual_bonus}
                                                        name="annual_bonus"
                                                        value={mode === "Margin" ? updatedInputValues.employee.salary.annual_bonus.margin : updatedInputValues.annual_bonus}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                        !updatedInputValues.employee.salary.annual_bonus.sap &&
                                                        updatedInputValues.employee.salary.annual_bonus.margin === ""
                                                        :
                                                        !updatedInputValues.annual_bonus && updatedInputValues.annual_bonus === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        >
                                                    <Typography
                                                        variant="caption"
                                                        color="error"
                                                    >
                                                        {mode === "Margin" ?
                                                            (isNaN(updatedInputValues.monthly_fees.margin) || updatedInputValues.monthly_fees.margin ==="")
                                                            || (isNaN(updatedInputValues.daily_fees.margin) || updatedInputValues.daily_fees.margin ==="")
                                                            ? "Please enter only digits" 
                                                            : ""
                                                        :
                                                            (isNaN(updatedInputValues.monthly_fees) || updatedInputValues.monthly_fees ==="")
                                                            || (isNaN(updatedInputValues.daily_fees) || updatedInputValues.daily_fees ==="")
                                                            ? "Please enter only digits" 
                                                            : ""
                                                        }
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        label="Daily fees"
                                                        defaultValue={mode === "Margin" ? updatedInputValues.daily_fees.crm + updatedInputValues.daily_fees.country_params : updatedInputValues.daily_fees}
                                                        name="daily_fees"
                                                        value={mode === "Margin" ? updatedInputValues.daily_fees.margin : updatedInputValues.daily_fees}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                        !updatedInputValues.daily_fees.country_params && 
                                                        updatedInputValues.daily_fees.margin === ""
                                                        :
                                                        !updatedInputValues.daily_fees && 
                                                        updatedInputValues.daily_fees === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl>
                                                    <TextField
                                                        size="small"
                                                        label="Monthly fees"
                                                        defaultValue={mode === "Margin" ? updatedInputValues.monthly_fees.country_params ? updatedInputValues.monthly_fees.country_params : 0 : updatedInputValues.monthly_fees}
                                                        name="monthly_fees"
                                                        value={mode === "Margin" ? updatedInputValues.monthly_fees.margin : updatedInputValues.monthly_fees}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                        updatedInputValues.monthly_fees.country_params === undefined ||
                                                        updatedInputValues.monthly_fees.margin === ""
                                                        :
                                                        updatedInputValues.monthly_fees === undefined ||
                                                        updatedInputValues.monthly_fees === ""
                                                    }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid  item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={4}>
                                            <Grid item>
                                                <FormControl fullwidth>
                                                <Typography
                                                        variant="caption"
                                                        color="error"
                                                        >
                                                        {mode === "Margin" ?
                                                            (isNaN(updatedInputValues.daily_it_costs.margin) || 
                                                            updatedInputValues.daily_it_costs.margin === "") || 
                                                            (isNaN(updatedInputValues.daily_desk_costs.margin) || 
                                                            updatedInputValues.daily_desk_costs.margin === "")
                                                            ? "Please enter only digits" 
                                                            : ""
                                                            :
                                                            (isNaN(updatedInputValues.daily_it_costs) || 
                                                            updatedInputValues.daily_it_costs === "") || 
                                                            (isNaN(updatedInputValues.daily_desk_costs) || 
                                                            updatedInputValues.daily_desk_costs === "")
                                                            ? "Please enter only digits" 
                                                            : ""
                                                        }
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        label="Daily IT costs"
                                                        defaultValue={mode === "Margin" ? updatedInputValues.daily_it_costs.country_params ? updatedInputValues.daily_it_costs.country_params : 0 : updatedInputValues.daily_it_costs}
                                                        name="daily_it_costs"
                                                        value={mode === "Margin" ? updatedInputValues.daily_it_costs.margin : updatedInputValues.daily_it_costs}
                                                        onChange={handleChangeChild}
                                                        sx={{ my: 1 }}
                                                        required
                                                        InputProps={{
                                                            endAdornment: 
                                                            <InputAdornment position="end">
                                                                    {currencySymbol}
                                                                </InputAdornment>
                                                        }}
                                                        error={mode === "Margin" ?
                                                        !updatedInputValues.daily_it_costs.country_params && 
                                                        updatedInputValues.daily_it_costs.margin === ""
                                                        :
                                                        !updatedInputValues.daily_it_costs && 
                                                        updatedInputValues.daily_it_costs === ""
                                                        }
                                                        />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                            <FormControl fullwidth>
                                                <TextField
                                                    size="small"
                                                    label="Daily desk costs"
                                                    defaultValue={mode === "Margin" ? updatedInputValues.daily_desk_costs.country_params ? updatedInputValues.daily_desk_costs.country_params : 0 : updatedInputValues.daily_desk_costs}
                                                    name="daily_desk_costs"
                                                    value={mode === "Margin" ? updatedInputValues.daily_desk_costs.margin : updatedInputValues.daily_desk_costs}
                                                    onChange={handleChangeChild}
                                                    sx={{ my: 1 }}
                                                    required
                                                    InputProps={{
                                                        endAdornment: 
                                                        <InputAdornment position="end">
                                                                {currencySymbol}
                                                            </InputAdornment>
                                                    }}
                                                    error={mode === "Margin" ?
                                                        !updatedInputValues.daily_desk_costs.country_params &&
                                                        updatedInputValues.daily_desk_costs.margin === ""
                                                        :
                                                        !updatedInputValues.daily_desk_costs &&
                                                        updatedInputValues.daily_desk_costs === ""
                                                    }
                                                    />
                                            </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item display='flex' alignItems="center" justifyContent="center" xs={12} sm={12} md={4}>
                                            <Grid item flexDirection="column" display='flex' alignItems="center" xs={12} sm={12} md={12}>
                                                <Grid item width="100%">
                                                    <FormControl fullWidth >
                                                        <Autocomplete
                                                            options={selectTimeValue}
                                                            value={updatedInputValues.office_presence}
                                                            onChange={(event, newValue) => handleChangeChild({ target: { name: "office_presence", value: newValue } })}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                {...params}
                                                                label="Production from M|P site"
                                                                size="small"
                                                                required
                                                                sx={{ my: 1 }}
                                                                error={!updatedInputValues.office_presence}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                )
                            }
                        </Stack>
                    </Grid>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    </ThemeProvider>
    );
}, (prevProps, nextProps) => {
    if (prevProps.mode === "Margin") {
        return prevProps.teamCard.id === nextProps.teamCard.id &&
        prevProps.teamCard.worked_days === nextProps.teamCard.worked_days &&
        prevProps.teamCard.sold_days === nextProps.teamCard.sold_days &&
        prevProps.teamCard.daily_desk_costs.margin === nextProps.teamCard.daily_desk_costs.margin &&
        prevProps.teamCard.daily_fees.margin === nextProps.teamCard.daily_fees.margin &&
        prevProps.teamCard.daily_it_costs.margin === nextProps.teamCard.daily_it_costs.margin &&
        prevProps.teamCard.daily_sale_rate.margin === nextProps.teamCard.daily_sale_rate.margin &&
        prevProps.teamCard.daily_purchase_rate.margin === nextProps.teamCard.daily_purchase_rate.margin &&
        prevProps.teamCard.employee.salary.annual_bonus.margin === nextProps.teamCard.employee.salary.annual_bonus.margin &&
        prevProps.teamCard.monthly_fees.margin === nextProps.teamCard.monthly_fees.margin &&
        prevProps.teamCard.employee.salary.monthly_gross_salary.margin === nextProps.teamCard.employee.salary.monthly_gross_salary.margin &&
        prevProps.teamCard.office_presence === nextProps.teamCard.office_presence &&
        prevProps.teamCard.es3 === nextProps.teamCard.es3;
        }

    if (prevProps.mode === "Draft"){
        return prevProps.countrySettings === nextProps.countrySettings &&
        prevProps.teamCard.name === nextProps.teamCard.name &&
        prevProps.teamCard.worked_days === nextProps.teamCard.worked_days &&
        prevProps.teamCard.id === nextProps.teamCard.id &&
        prevProps.teamCard.sold_days === nextProps.teamCard.sold_days &&
        prevProps.teamCard.daily_desk_costs === nextProps.teamCard.daily_desk_costs &&
        prevProps.teamCard.daily_fees === nextProps.teamCard.daily_fees &&
        prevProps.teamCard.daily_it_costs === nextProps.teamCard.daily_it_costs &&
        prevProps.teamCard.daily_sale_rate === nextProps.teamCard.daily_sale_rate &&
        prevProps.teamCard.daily_purchase_rate === nextProps.teamCard.daily_purchase_rate &&
        prevProps.teamCard.annual_bonus === nextProps.teamCard.annual_bonus &&
        prevProps.teamCard.monthly_fees === nextProps.teamCard.monthly_fees &&
        prevProps.teamCard.monthly_gross_salary === nextProps.teamCard.monthly_gross_salary &&
        prevProps.teamCard.office_presence === nextProps.teamCard.office_presence &&
        prevProps.teamCard.es3 === nextProps.teamCard.es3;

    }
        });

export default TeamCard;