/*
c'est le composant qui vient apres INDEX.JS / APP.JS / APPLAYOUT.JS

 ce fichier permet de paramétrer les composants à afficher en fonction de l'url

 par ailleurs, il y a de la gestion des rôles.
 
 un utilisateur qui a des accès estarter_Limited uniquement, ne pourra pas gérer les plans de préventions (il pourra tout de meme assigner des engagements à un pp s'il n'est pas le consultant de l'engagement)

*/

import React from "react";
import { Routes, Route } from "react-router-dom";
import { Projects } from "./projects/components/Projects";
import { EngagementBrief } from "./projects/engagementBriefs/pages/EngagementBrief";
import { PreventionPlan } from "./projects/preventionPlans/pages/PreventionPlan";
import { PreventionPlanMgt } from "./preventionPlanManagement/pages/PreventionPlanMgt";
import { NoAccessPage } from "./preventionPlanManagement/pages/NoAccessPage";
import { Help } from "./help/pages/Help";
import { NotFoundPage } from "./connexion/pages/NotFoundPage";
import { Margin } from "./projects/margins/pages/Margin";
import { AdministratorSettings } from "./administratorSettings/pages/AdministratorSettings";

export function AppRouter(props) {
  const role = props.user_role;
  const noAccessMsg = "You don't have access to this page or this resource"

  return (
    <>
      <Routes>
        <Route path="/" exact element={<Projects />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/engagementbrief/:engagement_integration_key" element={<EngagementBrief />} />
        <Route exact path="/preventionplan/:engagement_integration_key" element={<PreventionPlan />} />
        <Route 
          exact 
          path="/administratorsettings" element={ role === "Admin" ? (
            <AdministratorSettings />
          ) : (
            <NoAccessPage msg={noAccessMsg}/>
            )} />
        <Route
          exact
          path="/margin/:project_id/:year"
          element={
            role === "Business" || role === "Admin" ? (
              <Margin />
              ) : (
                <NoAccessPage msg={noAccessMsg}/>
                )
              }
        />
        <Route
          exact
          path="/preventionplanmgt"
          element={
            role === "Business" || role === "Specific" || role === "Admin" ? (
              <PreventionPlanMgt />
              ) : (
                <NoAccessPage msg={noAccessMsg}/>
              )
            }
        />
        <Route path='*' element={<NotFoundPage />}/>
        <Route path="/accessdenied" element={<NoAccessPage msg={noAccessMsg}/>}/>
      </Routes>
    </>
  );
}
