import React from "react";
import { useSelector } from "react-redux";
import { Box, CssBaseline, Typography, Container } from "@mui/material";
import { CustomLoading } from "../../components/CustomLoading";

export function NoAccessPage(props) {
  const getUserStatus = useSelector((state) =>
  state.api.mutations["UserInfos"]
    ? state.api.mutations["UserInfos"].status
    : "loading",
);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      { getUserStatus !== "fulfilled" ? ( 
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <CustomLoading loadingText={"Retrieving user information..."} />
        </Container>
        ) : (
        <Typography>
            Access Denied: {props.msg}
        </Typography>
        )
        }
    </Box>
  );
}
