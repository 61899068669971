/*
c'est le composant enfant de ProjectList.js
il est le parent de FormEB.js

tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à cette page

dans ce composant, on affiche les informations du projet et de l'engagement et on fait appel au composant components/FormEB qui contient les informations de l'EB selectionné et le formulaire  

on charge également le fichier json paramEngagementBrief qui contient tous les inputs d'un engagement brief
les inputs sont ordonnancés par section
certains inputs demandent une signature du consultant, c'est des inputs obligatoires pour la soumission
il y a des inputs obligatoire pour la soumission mais s'ils sont changé, cela ne redemandera pas une nouvelle signature

*/

import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  Chip,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import FormEngagementBrief from "../components/FormEngagementBrief";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PlaceIcon from "@mui/icons-material/Place";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PersonIcon from "@mui/icons-material/Person";
import { truncateText } from "../../../commonFunction";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "underscore";
import { CustomLoading } from "../../../components/CustomLoading";
import { useParams, useNavigate } from "react-router-dom";
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";
import { useGetEngagementBriefMutation, useGetEngagementBriefFormParamsMutation, useGetProjectsMutation } from "../../../api/eStarterApi"

export function EngagementBrief() {
  const { engagementsActiveOnly, setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { engagement_integration_key } = useParams();
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [getEngagementBrief] = useGetEngagementBriefMutation({ fixedCacheKey: "GetEB" });
  const [getEngagementBriefFormParams] = useGetEngagementBriefFormParamsMutation({ fixedCacheKey: "GetEBFormParams" });
  const [fetchEngagementBriefData, setFetchEngagementBriefData] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const crm_projects = useSelector(
    (state) =>
    state.api.mutations["crm_projects"]
    ? state.api.mutations["crm_projects"].status === "fulfilled"
    ? state.api.mutations["crm_projects"].data
    : []
    : [],
    _.isEqual,
    );

  const filteredProject = crm_projects.filter((project) => {
    return project.engagements.some((engagement) => engagement.engagement_integration_key.toString() === engagement_integration_key);
  });

  const matchingProject = filteredProject.length === 0 ? false : true;


  let currentProject = [];
  let currentEngagement = [];
  let isCurrentUserIsCurrentConsultant = null;

  const getProjectStatus = useSelector((state) =>
  state.api.mutations["crm_projects"]
    ? state.api.mutations["crm_projects"].status
    : "loading",
);

  const initializeEngagementBriefData = useCallback(async () => {
    try {
      if (engagementsActiveOnly && !matchingProject) {
        postProjects({ remove_closed: false }).unwrap();
        setEngagementsActiveOnly(false);
      }
      
      let resultEngagementBriefFormParams = await getEngagementBriefFormParams({
        form_version: "1"   //TO BE MODIFIED LATER
      }).unwrap();
      dispatch({type: "selectedEngagementBriefFormParamsReducer/selectEngagementBriefFormParams", payload: resultEngagementBriefFormParams});
      
      let resultEngagementBrief = await getEngagementBrief({
        id: String(engagement_integration_key)
      }).unwrap();
      //on stocke le résultat de l'api dans un endroit précis du store pour le réutiliser dans la suite
      dispatch({ type: "selectedEngagementBriefReducer/selectEngagementBrief", payload: resultEngagementBrief });

    } catch (error) {
      if (error.status === 401) {
        navigate("/accessdenied")
      } else if (error.status === 404) {
        navigate("/404")
      } else {
        dispatch({
          type: "snackbarInfo/setNotification",
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
            "An error occurred during initializing the data. Please try again. Error: " +
            error.data.msg,
          },
        });
      }
    } finally {
      setFetchEngagementBriefData(false);
    }
  }, [engagement_integration_key, getEngagementBriefFormParams, getEngagementBrief, dispatch, setFetchEngagementBriefData, postProjects, engagementsActiveOnly, setEngagementsActiveOnly, matchingProject, navigate]);
  
  useEffect(() => {
    if (fetchEngagementBriefData && getProjectStatus) {
      initializeEngagementBriefData();
    }
  }, [getProjectStatus, fetchEngagementBriefData, initializeEngagementBriefData]);
  
  // information du store
  const apiStatus = useSelector((state) => {
    const getProjectStatus = state.api.mutations["crm_projects"]
      ? state.api.mutations["crm_projects"].status
      : "loading";
  
    const getEBStatus = state.api.mutations["GetEB"]
      ? state.api.mutations["GetEB"].status
      : "loading";
  
    const getEBFormParamsStatus = state.api.mutations["GetEBFormParams"]
      ? state.api.mutations["GetEBFormParams"].status
      : "loading";
  
    if (getProjectStatus === "fulfilled" && getEBStatus === "fulfilled" && getEBFormParamsStatus === "fulfilled") {
      return "fulfilled";
    } else {
      return "loading";
    }
  });
        
  const currentUser = useSelector(
    (state) =>
    state.api.mutations["UserInfos"]
    ? state.api.mutations["UserInfos"].status === "fulfilled"
    ? state.api.mutations["UserInfos"].data
    : []
    : [],
    _.isEqual,
  );
  const currentEngagementBrief = useSelector((state) => state.selectedEngagementBrief, _.isEqual);

  const currentFormParams = useSelector((state) => state.selectedEngagementBriefFormParams, _.isEqual);
    if (matchingProject) {
      currentProject = filteredProject[0];
      
      const filteredEngagement = currentProject.engagements.filter((engagement) => engagement.engagement_integration_key.toString() === engagement_integration_key);
      
      currentEngagement = filteredEngagement[0];
      
      isCurrentUserIsCurrentConsultant =
      currentUser.id === String(currentEngagement?.consultant_id);
    }

  //création d'un tableau par section de la forme sections=[{section:1,sectionName:toto,inputs:[{inputType:text, inputLabel:test....},{...}]} , {...}]
  const sections = Array.from(
    new Set(currentFormParams.map((config) => config.section)),
  ).map((section) => {
    const sectionInputs = currentFormParams.filter(
      (config) => config.section === section,
    );
    return {
      section,
      sectionName: sectionInputs[0].sectionName,
      inputs: sectionInputs,
    };
  });

  // creation d'un tableau avec les valeurs initiales
  const initialValues = {
    latest_saved_version: {},
    engagement_brief_details: {},
    files: currentEngagementBrief.files,
  };
  initialValues.signature_date = currentEngagementBrief.latest_saved_version?.signature_date;
  initialValues.engagement_brief_status = currentEngagementBrief.latest_saved_version?.engagement_brief_status;

  for (const item of sections) {
    for (const input of item.inputs) {
      if (input.signatureNeeded) {
        initialValues.latest_saved_version[input.inputName] =
          currentEngagementBrief.latest_saved_version
            ? currentEngagementBrief.latest_saved_version[input.inputName]
            : input.inputType === "Boolean"
            ? false
            : input.inputType === "Select"
            ? input.inputDetails[0]
            : input.inputType === "Multiple Select"
            ? []
            : input.inputType === "Radio"
            ? []
            : input.inputType === "Slider"
            ? 0
            : "";
      } else {
        initialValues.engagement_brief_details[input.inputName] = currentEngagementBrief.engagement_brief_details
          ? currentEngagementBrief.engagement_brief_details[input.inputName]
          : input.inputType === "Boolean"
          ? false
          : input.inputType === "Select"
          ? input.inputDetails[0]
          : input.inputType === "Multiple Select"
          ? []
          : input.inputType === "Radio"
          ? []
          : input.inputType === "Slider"
          ? 0
          : "";
      }
    }
  }
  return (
    <Box sx={{ display: "flex"}} my={isSmallScreen && 4}>
      <CssBaseline />
      {apiStatus !== "fulfilled" ? (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <CustomLoading loadingText={"Loading data..."} />
        </Container>
      ) : (
        <Container
          maxWidth="xl"
          py={2}
        >
          {/* ___________________________________________________________________________________________________affichage des informations du projet et de l'engagement selectionné */}
          <Grid >
            <Grid container my={3}>
              {isSmallScreen ? null : (
                <Typography variant="h4">
                  Engagement Brief |{" "}
                  {currentEngagement?.consultant_full_name +
                    " - " +
                    truncateText(currentEngagement?.project_legal_description, 50)}
                </Typography>
              )}
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h5">
                    {currentProject?.project_id +
                      " | " +
                      currentProject?.project_name}
                  </Typography>
                </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item >
                  <Tooltip title={`Consultant: ${currentEngagement?.consultant_full_name}`}>
                    <Chip
                      label={currentEngagement?.consultant_full_name}
                      icon={<PersonIcon />}
                      size="small"
                      color={isCurrentUserIsCurrentConsultant ? "success" : "default"}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`RC: ${currentEngagement?.project_commercial_owner_name}`}>
                    <Chip
                      label={currentEngagement?.project_commercial_owner_name}
                      icon={<SupervisorAccountIcon />}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Project Department: ${currentProject?.project_department}`}>
                    <Chip
                      label={currentProject?.project_department}
                      icon={<LocationCityIcon />}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title="Engagement duration">
                    <Chip
                      label={`${moment(currentEngagement?.engagement_start_date).format(
                        isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                      )} 
                          - ${moment(currentEngagement?.engagement_end_date).format(
                            isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                          )}`}
                      icon={<CalendarMonthIcon />}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Status: ${currentEngagement?.engagement_status}`}>
                    <Chip
                      label={currentEngagement?.engagement_status}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title={`Customer: ${currentProject?.customer_name}`}>
                    <Chip 
                      label={currentProject?.customer_name} 
                      size="small"
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Customer's full address">
                    <Chip
                      label={
                        isSmallScreen
                          ? truncateText(currentEngagement?.customer_full_address, 10)
                          : currentEngagement?.customer_full_address
                      }
                      icon={<PlaceIcon color="error" />}
                      component="a"
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        currentProject?.customer_name +
                        ", " +
                        currentEngagement?.customer_full_address
                      }`} //lien vers googlemap pour l'adresse du site
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ cursor: "pointer" }}
                      size="small"
                    />

                  </Tooltip>
                </Grid>
                {isSmallScreen ? null : (
                  <Grid item>
                    <Tooltip title={`Project Contact: ${currentEngagement?.project_contact}`}>
                      <Chip
                        label={currentEngagement?.project_contact}
                        icon={<ContactPhoneIcon />}
                        size="small"
                      />
                    </Tooltip>
                  </Grid>
                )}
                {isSmallScreen ? null : (
                  <Grid item>
                    <Tooltip title={`Activity sector: ${currentEngagement?.engagement_activity_sector}`}>
                      <Chip
                        label={currentEngagement?.engagement_activity_sector}
                        size="small"
                      />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
            <Grid item xs={12} sm={12} md={12} mb={10}>
              {/* ___________________________________________________________________________________________________affichage de l'EB selectionné */}
              <FormEngagementBrief
                sections={sections}
                initialValues={initialValues}
                id={currentEngagement.engagement_integration_key}
                currentEngagement={currentEngagement}
                isCurrentUserIsCurrentConsultant={
                  isCurrentUserIsCurrentConsultant
                }
                currentUser={currentUser}
                currentProject={currentProject}
              />
            </Grid>
        </Container>
      )}
    </Box>
  );
}
