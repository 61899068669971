import * as React from "react";
import Typography from "@mui/material/Typography";
import { SignInButton } from "../components/SignInButton";
import "../../LandingSharedStyle.css";
import logoMP from "../../media/migso.png";
import logoEStarter from "../../media/estarter.png";
import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import { NoAccessPage } from "../../preventionPlanManagement/pages/NoAccessPage";

export const ConnexionPage = () => {
  const { userContext } = useContext(UserContext);

  return (
    <div className="connexionPageContainer">
      <div className="topPageLogosContainer ">
          <div className="logoMP">
            <img src={logoMP} height="40px" alt="al"/>
          </div>
          <div className="logoEstarter">
            <img src={logoEStarter} height="15px" alt="al" style={{paddingRight:'15px'}}/>
          </div>
      </div>

      <div className="textContainer">
        <Typography
          variant="h2"
          sx={{ fontWeight: "bold", mb:1 }}
          className="title"
        >
          Welcome to e-Starter
        </Typography>
        {userContext.id !== 0 ?
        <div className="signInButton">
          <SignInButton /> 
        </div>
        :
        <div className="signInButton">
          <NoAccessPage msg={"You are not an active user. If you think this is an error, please contact HR department."}/>
        </div>
        }
      </div>

    </div>
  );
};
