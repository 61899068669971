/*
c'est le composant qui vient apres INDEX.JS / APP.JS / APPLAYOUT.JS / APP ROUTER.JS
il est le parent de ProjectList (dont EngagementBrief et PrenventionPlan sont les enfants)

c'est également la page d'accueil quand un utilisateur se connecte
tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à cette page

dans ce composant, on filtre la liste des projets uniquement, on les trie par client

pour pouvoir faire des actions sur cette liste de projets, il faut aller dans le composant ProjectList.js dans le dossier components/




*/

import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Container,
  TextField,
  InputAdornment,
  CssBaseline,
  Typography,
  IconButton,
  Button,
  TablePagination,
  Autocomplete,
  useMediaQuery,
  Paper,
  Stack,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import ProjectList from "./ProjectList";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import _ from "underscore";
import { useGetProjectsMutation } from "../../api/eStarterApi";
import ArchiveIcon from "@mui/icons-material/Archive";
import Notification from "../../components/Notification";
import { CustomLoading } from "../../components/CustomLoading";
import EngagementsActiveOnlyContext from "../../contexts/EngagementsActiveOnlyContext";
import UserContext from "../../contexts/UserContext";


export function Projects(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { engagementsActiveOnly, setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const { userContext } = useContext(UserContext);

  //paramètre
  const statusEngagementBrief = [
    "TO BE CREATED",
    "IN PROGRESS",
    "READY TO BE SIGNED",
    "SIGNED",
    "NOT APPLICABLE",
  ];
  const statusPreventionPlan = [
    "TO BE ASSIGNED",
    "READY TO BE SIGNED",
    "SIGNED",
    "NOT APPLICABLE"
  ];
  const statusMargin = [
    "TO BE CREATED",
    "IN PROGRESS",
    "SUBMITTED",
    "VALIDATED",
    "TO BE UPDATED",
    "NOT APPLICABLE"
  ]

  //changement du state
  // Barre de recherche sous les KPIs
  const [search, setSearch] = useState("");
  // Sélection du Project Department
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  // KPI des Engagement Briefs
  const [selectedStatusEngagementBrief, setSelectedStatusEngagementBrief] = useState([]);
  // KPIs des Prevention Plans
  const [selectedStatusPreventionPlan, setSelectedStatusPreventionPlan] = useState([]);
  // KPIs des Margins
  const [selectedStatusMargin, setSelectedStatusMargin] = useState([]);
  // Affichage des projets
  const [filterChange, setFilterChange] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const apiStatus = useSelector((state) =>
    state.api.mutations["crm_projects"]
      ? state.api.mutations["crm_projects"].status
      : "loading",
  );

  const crm_projects = useSelector(
    (state) =>
      state.api.mutations["crm_projects"]
        ? state.api.mutations["crm_projects"].status === "fulfilled"
          ? state.api.mutations["crm_projects"].data
          : []
        : [],
    _.isEqual,
  );
  //api pour avoir les engagements cloturés (on stocke dans le state si on a déjà fait l'appel pour le faire qu'une seule fois)
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });

  //fonction de changemenet d'état de pagination (on affiche qu'un certain nombre de projet par page pour éviter les lenteurs)
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); //si on change le nombre de lignes par page, on revient à la 1ère page
    setFilterChange(true);
  };

  const handleFilterChange = (value) => {
    setFilterChange(value);
  };
  // chargement des closed engagements
  const handleToggleClosedEngagements = () => {
    if (!engagementsActiveOnly) {
      postProjects({ remove_closed: true})
      setEngagementsActiveOnly(true);
      // setGetClosedEngagements(false);
    } else {
      postProjects({ remove_closed: false });
      setEngagementsActiveOnly(false);
      // setGetClosedEngagements(true);
    }
  };

  //changement d'etat de la barre de recherche
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
    setFilterChange(true);
  };
  const handleClearClick = () => {
    setSearch("");
    setPage(1);
    setFilterChange(true);
  };

  //filtrage des projets en fonction des inputs de filtrage (search bar, ou selection de BusinessUnit ou engagement_brief_status, prevention_plan_status)
  const filteredProjects = crm_projects.filter((project) => {
    const matchesSearch =
      search === "" ||
      project.project_name?.toLowerCase().includes(search.toLowerCase()) ||
      project.project_department?.toLowerCase().includes(search.toLowerCase()) ||
      project.project_id?.toLowerCase().includes(search.toLowerCase()) ||
      project.customer_name?.toLowerCase().includes(search.toLowerCase()) ||
      (Array.isArray(project.engagements) &&
        project.engagements.length > 0 &&
        project.engagements.some(
          (engagement) =>
            engagement.project_commercial_owner_name?.toLowerCase().includes(search.toLowerCase()) ||
            engagement.consultant_full_name?.toLowerCase().includes(search.toLowerCase()) ||
            engagement.project_legal_description?.toLowerCase().includes(search.toLowerCase()) ||
            engagement.engagement_integration_key?.toString().includes(search),
        ));

    const filterProjectDepartment =
      selectedBusinessUnit.length === 0 ||
      selectedBusinessUnit.includes(project.project_department);
    const filterStatusEngagementBrief =
      selectedStatusEngagementBrief.length === 0 ||
      (Array.isArray(project.engagements) &&
        project.engagements.length > 0 &&
        project.engagements.some((engagement) =>
          selectedStatusEngagementBrief.includes(engagement.engagement_brief_status),
        ));
    const filterStatusPreventionPlan =
      selectedStatusPreventionPlan.length === 0 ||
      (Array.isArray(project.engagements) &&
        project.engagements.length > 0 &&
        project.engagements.some((engagement) =>
          selectedStatusPreventionPlan.includes(engagement.prevention_plan_status),
        ));
        const filterStatusMargin =
        selectedStatusMargin.length === 0 ||
        selectedStatusMargin.includes(!project.changes_detected || project.current_year_margin_status === "TO BE CREATED" ? project.current_year_margin_status : "TO BE UPDATED");

    return (
      matchesSearch &&
      filterProjectDepartment &&
      filterStatusEngagementBrief &&
      filterStatusPreventionPlan &&
      filterStatusMargin
    );
  });

  //FILTER THE ENGAGEMENTS IN THE FILTERED PROJECTS TO GET MORE ACCURATE RESULTS
  const filteredProjectsEngagements = filteredProjects.map((project) => {
    const matchesSearchInProject =
      search === "" ||
      project.project_name?.toLowerCase().includes(search.toLowerCase()) ||
      project.project_department?.toLowerCase().includes(search.toLowerCase()) ||
      project.project_id?.toLowerCase().includes(search.toLowerCase()) ||
      project.customer_name?.toLowerCase().includes(search.toLowerCase());
  
      // CASE 1: MATCH FOR SEARCH IN A PROJECT
    if (matchesSearchInProject) {
      return {
        ...project,
        engagements: project.engagements.filter((engagement) => {
          const meetsEngagementStatusCondition =
            (!selectedStatusEngagementBrief.length ||
              selectedStatusEngagementBrief.includes(engagement.engagement_brief_status)) &&
            (!selectedStatusPreventionPlan.length ||
              selectedStatusPreventionPlan.includes(engagement.prevention_plan_status));
  
          return meetsEngagementStatusCondition;
        }),
      };
    }
    // CASE 2: MATCH IN THE ENGAGEMENTS AND NOT IN PROJECT
    const filteredEngagements = project.engagements.filter((engagement) => {
      const matchesSearchInEngagement =
      search === "" ||
      engagement.consultant_full_name?.toLowerCase().includes(search.toLowerCase()) ||
      engagement.project_commercial_owner_name?.toLowerCase().includes(search.toLowerCase()) ||
      engagement.engagement_integration_key?.toString().includes(search.toLowerCase()) ||
      engagement.project_legal_description?.toLowerCase().includes(search.toLowerCase());
      
      const meetsEngagementStatusCondition =
        (!selectedStatusEngagementBrief.length ||
          selectedStatusEngagementBrief.includes(engagement.engagement_brief_status)) &&
        (!selectedStatusPreventionPlan.length ||
          selectedStatusPreventionPlan.includes(engagement.prevention_plan_status));
  
      return matchesSearchInEngagement && meetsEngagementStatusCondition;
    });
  
    return {
      ...project,
      engagements: filteredEngagements,
    };
  });
  
  // REMOVE THE PROJECTS WITH NO ENGAGEMENTS
  const finalFilteredProjectsEngagements = filteredProjectsEngagements.filter(
    (project) => project.engagements.length > 0
  );
  
  //fonction pour avoir les différentes BusinessUnit des projets filtrés
  const allDepartments = crm_projects.reduce((uniqueDepartment, project) => {
    //Réduire l'array crm_projects à une seule donnée, uniqueDepartment, et la valeur par defaut est project (ce doit être une instance de crm_projects).
    // L'array retournera ainsi ainsi des instance uniques
    if (!uniqueDepartment.includes(project.project_department)) {
      uniqueDepartment.push(project.project_department);
    }
    return uniqueDepartment.sort();
  }, []);

  //indicateurs du nombre d'engagements par statut (EB ou PP)
  // READY TO BE SIGNED dans les KPIs
  const getStatusCount = (cas, status) => {
    let count = 0;
    finalFilteredProjectsEngagements.forEach((project) =>
      // Pour chaque projet trouvé
      project.engagements.forEach((engagement) => {
        // Pour chaque misison de chaque projet
        if (engagement.engagement_brief_status === status && cas === "EB") {
          count++;
        } else if (engagement.prevention_plan_status === status && cas === "PP") {
          count++;
        } 
      }),
    );
    return count;
  };
  const getMarginStatusCount = (status) => {
    let count = 0;
    finalFilteredProjectsEngagements.forEach((project) => {
      // IF A PROJECT HAS CHANGES_DETECTED, THE STATUS IS "TO BE UPDATED"
      if (status === "TO BE UPDATED") {
        if (project.changes_detected){
          count++;
        }
      } 
      // IF THERE'S NO CHANGE DETECTED, WE CAN COUNT THE STATUS
      else {
        if (project.current_year_margin_status === status && !project.changes_detected) {
          count++;
        } 
      }
      })

    return count;
  };
  //indicateur sur le nombre total d'engagement
  const getTotalEngagements = () => {
    let totalEngagements = 0;
    finalFilteredProjectsEngagements.forEach((project) => {
      totalEngagements += project.engagements.length;
    });
    return totalEngagements;
  };
  //indicateur sur le nombre total de projets sur lequel l'utilisateur à accès aux marges
  const getTotalMarginProjects = () => {
    let totalProjects = 0;
    finalFilteredProjectsEngagements.forEach((project) => {
      if (Object.keys(project).includes("current_year_margin_status")) {
        totalProjects += 1;
      }
    });
    return totalProjects;
  };  

  function getCompletionPercentage(category) {
    let categoryPercentage = 0
    let totalItemsCategory = 0
    if (category === "EB" || category === "PP") {
      totalItemsCategory = getTotalEngagements() - getStatusCount(category, "NOT APPLICABLE");

      categoryPercentage = parseFloat(
        ((getStatusCount(category, "SIGNED") / (totalItemsCategory)) * 100).toFixed(0)
      );
    }
    if (category === "Margin") {
      totalItemsCategory = getTotalMarginProjects() - getMarginStatusCount("NOT APPLICABLE");

      categoryPercentage = parseFloat(
        ((getMarginStatusCount("VALIDATED") / (totalItemsCategory)) * 100).toFixed(0)
      );
    }

    let formattedCategoryPercentage = "0 %"
    if (totalItemsCategory !== 0 && categoryPercentage !== 0) {
      formattedCategoryPercentage = `${categoryPercentage} %`;
    }

    return formattedCategoryPercentage
  }


  const marginAuthorizedRoles = ['Admin', 'Business']
  const hasMarginAccess = marginAuthorizedRoles.includes(userContext.user_role) 
  

  return (
    <Box sx={{mb:6}}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 10 }}>
        {/* on affiche la notification (s'il y en a ) elle s'affiche grâce à snackbarInfo reducer, voir dans le dossier component/Notification.js */}
        <Notification />

        {/* tant que l'api des projets n'est pas terminé, on affiche une barre de chargement */}
        {apiStatus === "rejected" ? (
          "Get Project: Error 500"
        ) : apiStatus !== "fulfilled" ? (
          <CustomLoading loadingText={"Loading data..."} />
        ) : (
          <Grid container>
            {/* ________________________________________________________________________________________________________________________________ ENTETE avec le titre et les KPIs */}
            {isSmallScreen ? null : (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4">
                  Welcome {userContext.employee_first_name},
                </Typography> 
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Manage your engagements here. You also have access to your teams
                  scope (managerial and RC hierarchy line).
                  <br /> If you have any doubt regarding the process or potential bugs, please
                  check the HELP page.
                </Typography>
              </Grid>
            )}
            {isSmallScreen ? null : (
              <Grid container spacing={2} sx={{ mt: 1, alignSelf: "stretch" }}>
                <Grid item xs={hasMarginAccess ? 4 : 6}>
                  <Paper elevation={0} sx={{border: '1px solid lightgray', boxShadow:'1px 1px 3px lightgray'}}>
                    <Grid item sx={{ p: 2 }}>
                      <Stack
                        divider={<Divider orientation="vertical" flexItem />}
                        direction="row"
                        spacing={2}
                      >
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                          <Typography
                            variant="h4"
                            color={
                              getStatusCount("EB", "SIGNED") /
                                (getTotalEngagements() - getStatusCount("EB", "NOT APPLICABLE")) ===
                              1
                                ? "success.main"
                                : "error.main"
                            }
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            align="center"
                            height="110px"
                          >
                            {getCompletionPercentage("EB")}
                            <Typography variant="body2" color="initial">
                              Signed Engagement Briefs
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          item
                          spacing={0}
                          sx={{ alignItems: "center" }}
                        >
                          {statusEngagementBrief.map((status) => (
                            <Grid container item sx={{ alignItems: "center" }}>
                              <Grid item sx={hasMarginAccess ? { width: "20%" } : { width: "30%" }}>
                                <Typography
                                  variant="body2"
                                  color={`${status.replace(/ /g, "")}.main`}
                                  sx={{ pr: 2 }}
                                  display="grid"
                                  justifyContent="flex-end"
                                >
                                  {getStatusCount("EB", status)}
                                </Typography>
                              </Grid>
                              <Grid item sx={{ justifyContent: "flex-start" }}>
                                <Typography
                                  variant="body2"
                                  color="initial"
                                  align="right"
                                >
                                  {status.charAt(0).toUpperCase() +
                                    status.substring(1).toLowerCase()}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={hasMarginAccess ? 4 : 6}>
                  <Paper elevation={0} sx={{border: '1px solid lightgray', boxShadow:'1px 1px 3px lightgray'}}>
                    <Grid item sx={{ p: 2 }}>
                      <Stack
                        divider={<Divider orientation="vertical" flexItem />}
                        direction="row"
                        spacing={2}
                      >
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                          <Typography
                            variant="h4"
                            color={
                              getStatusCount("PP", "SIGNED") /
                              (getTotalEngagements() - getStatusCount("PP", "NOT APPLICABLE")) ===
                              1
                                ? "success.main"
                                : "error.main"
                            }
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            align="center"
                            height="110px"
                          >
                            {getCompletionPercentage("PP")}
                            <Typography variant="body2" color="initial">
                              Signed Prevention Plans
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid container direction="column" item spacing={0.25}>
                          {statusPreventionPlan.map((status) => (
                            <Grid container item>
                              <Grid item sx={hasMarginAccess ? { width: "20%" } : { width: "30%" }}>
                                <Typography
                                  variant="body2"
                                  color={`${status.replace(/ /g, "")}.main`}
                                  sx={{ pr: 2 }}
                                  display="grid"
                                  justifyContent="flex-end"
                                  align="center"
                                >
                                  {getStatusCount("PP", status)}
                                </Typography>
                              </Grid>
                              <Grid item sx={{ justifyContent: "flex-start" }}>
                                <Typography
                                  variant="body2"
                                  color="initial"
                                  align="right"
                                >
                                  {status.charAt(0).toUpperCase() +
                                    status.substring(1).toLowerCase()}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    </Grid>
                  </Paper>
                </Grid>
                {hasMarginAccess ? <Grid item xs={4}>
                  <Paper elevation={0} sx={{border: '1px solid lightgray', boxShadow:'1px 1px 3px lightgray'}}>
                    <Grid item sx={{ p: 2 }}>
                      <Stack
                        divider={<Divider orientation="vertical" flexItem />}
                        direction="row"
                        spacing={2}
                      >
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                          <Typography
                            variant="h4"
                            color={
                              (getMarginStatusCount("VALIDATED")) / (getTotalMarginProjects() - getMarginStatusCount("NOT APPLICABLE")) === 1
                                ? "success.main"
                                : "error.main"
                            }
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            align="center"
                            height="110px"
                          >
                            {getCompletionPercentage("Margin")}
                            <Typography variant="body2" color="initial">
                              Validated Margins
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid container direction="column" item spacing={0} sx={{ maxHeight: '110px' }}>
                          {statusMargin.map((status) => (
                            <Grid container item>
                              <Grid item sx={{ width: "20%" }}>
                                <Typography
                                  variant="body2"
                                  color={`${status.replace(/ /g, "")}.main`}
                                  sx={{ pr: 2, fontSize: "12.8px" }}
                                  display="grid"
                                  justifyContent="flex-end"
                                  align="center"
                                >
                                  {getMarginStatusCount(status)}
                                </Typography>
                              </Grid>
                              <Grid item sx={{ justifyContent: "flex-start" }}>
                                <Typography
                                  variant="body2"
                                  color="initial"
                                  align="right"
                                  sx={{ fontSize: "12.8px"}}
                                >
                                  {status.charAt(0).toUpperCase() +
                                    status.substring(1).toLowerCase()}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    </Grid>
                  </Paper>
                </Grid> : null }
              </Grid> 
            )}
            {/* ________________________________________________________________________________________________________________________________ FIN DE L'ENTETE*/}
            {/* ____________________________________________________________________________________________________________SEARCH BAR et selection des filtre et bouton pour avoir les engagements clôturés*/}
            <Grid item xs={12} sm={12} md={12} sx={{ mb: 2, mt: 3 }}>
              <Box display="flex" flexWrap="wrap" gap={1}>
                <TextField
                  label="Search"
                  size="small"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ width: isSmallScreen ? "100%" : "auto" }}
                  // Elément MUI
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: search !== "" ? "visible" : "hidden",
                        }}
                        size="small"
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  options={allDepartments}
                  size="small"
                  sx={{
                    ml: isSmallScreen ? 0 : 1,
                    width: isSmallScreen
                      ? "100%"
                      : hasMarginAccess
                      ? "210px"
                      : "300px",
                  }}
                  value={selectedBusinessUnit}
                  onChange={(event, newValue) => {
                    setSelectedBusinessUnit(newValue);
                    setPage(1);
                    setFilterChange(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Department"
                      size="small"
                    />
                  )}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  size="small"
                  sx={{
                    ml: isSmallScreen ? 0 : 1,
                    width: isSmallScreen
                      ? "100%"
                      : hasMarginAccess
                      ? "220px"
                      : "260px",
                  }}
                  options={statusEngagementBrief}
                  value={selectedStatusEngagementBrief}
                  onChange={(event, newValue) => {
                    setSelectedStatusEngagementBrief(newValue);
                    setPage(1);
                    setFilterChange(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Engagement Brief Status"
                      size="small"
                    />
                  )}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  size="small"
                  sx={{
                    ml: isSmallScreen ? 0 : 1,
                    width: isSmallScreen
                      ? "100%"
                      : hasMarginAccess
                      ? "220px"
                      : "260px",
                  }}
                  options={statusPreventionPlan}
                  value={selectedStatusPreventionPlan}
                  onChange={(event, newValue) => { 
                    setSelectedStatusPreventionPlan(newValue);
                    setPage(1);
                    setFilterChange(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Prevention Plan Status"
                      size="small"
                    />
                  )}
                />
                {hasMarginAccess ? <Autocomplete
                  disableCloseOnSelect
                  multiple
                  size="small"
                  sx={{
                    ml: isSmallScreen ? 0 : 1,
                    width: isSmallScreen
                      ? "100%"
                      : "220px",
                  }}
                  options={statusMargin}
                  value={selectedStatusMargin}
                  onChange={(event, newValue) => { 
                    setSelectedStatusMargin(newValue);
                    setPage(1);
                    setFilterChange(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Margin Status"
                      size="small"
                    />
                  )}
                /> : null }

                {/* si on a déjà fait l'appel d'api, on n'affiche pas le bouton */}               
                  <Button
                    variant="text"
                    color="info"
                    sx={{ ml: isSmallScreen ? 0 : 1 }}
                    size="small"
                    onClick={handleToggleClosedEngagements}
                    startIcon={<ArchiveIcon />}
                  >
                    {engagementsActiveOnly ? "Load Closed Engagements": "Hide Closed Engagements"}
                  </Button>
              </Box>
            </Grid>
            {/* ______________________________________________________________________________________________________________________FIN SEARCH BAR et selection des filtres et bouton api clôturés*/}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                {" "}
                {/* _______________________________________________________________________________________AFFICHAGE DES PROJECTS (à voir components/ProjectList.js) */}
                {finalFilteredProjectsEngagements
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage) //on divise l'affichage avec la pagination
                  .sort((a, b) => a.customer_name.localeCompare(b.customer_name)) // on trie par customer
                  .map((project, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <ProjectList
                        key={project.project_id}
                        project={project}
                        index={index}
                        filterChange={filterChange}
                        onFilterChange={handleFilterChange}
                      />
                    </Grid>
                  ))}
              </Grid>
              {/* ______________________________________________________________________________________________________________________FIN D'AFFICHAGE DES PROJECTS */}
              {/* //_____________________________________________________________________________________________________________________________ PAGINATION */}
              {/* on affiche la pagination que si le nombre de projet est supérieur au nombre de ligne par page */}
              {finalFilteredProjectsEngagements.length > rowsPerPage || rowsPerPage === 25 ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  style={{ mt: 2, display: "flex", justifyContent: "center" }}
                  labelRowsPerPage={isSmallScreen ? "" : "Rows per page"} 
                  page={page - 1}
                  onPageChange={(event, newPage) =>
                    handleChangePage(event, newPage + 1)
                  }
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={finalFilteredProjectsEngagements.length}
                />
              ) : null}
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
