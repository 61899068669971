/*
c'est le composant enfant de EngagementBrief.js
c'est le composant le plus important pour les engagement brief
c'est le parent de DialogEBDuplicate.js (pour dupliquer les informations d'un autre engagement brief) et DialogEBSignature (pour la signature du consultant)

tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à cette page
attention, les consultants peuvent ouvrir leur engagement brief que si son statut <> TO BE CREATED mais ils peuvent le signer que si le statut = READY TO BE SIGNED (voir détails plus bas)

dans ce composant, on affiche les informations l'EB selectionné et le formulaire  

on charge également le fichier json paramEngagementBrief qui contient tous les inputs d'un engagement brief
les inputs sont ordonnancés par section
certains inputs demandent une signature du consultant, c'est des inputs obligatoires pour la soumission
il y a des inputs obligatoire pour la soumission mais s'ils sont changé, cela ne redemandera pas une nouvelle signature


affichage des boutons suivants les utilisateurs et des statuts 
  si l'utilisteur n'est pas le consultant
      si le statut (currentStatus==="TO BE CREATED"||currentStatus==="IN PROGRESS") alors on affiche NOT APPLICABLE FOR THIS ENGAGEMENT?
        au clic, on passe le statut à NOT APPLICABLE (on ne perd pas les informations déjà enregistré mais on change le statut)

      si le statut currentStatus==="NOT APPLICABLE" alors on affiche APPLICABLE?
        au clic, on passe le statut à IN PROGRESS

      si le statut n'est pas NOT APPLICABLE, alors on affiche le bouton de duplication => c'est possible de dupliquer à n'importe quel moment

      si le statut=SIGNED, on affiche le bouton EXPORT PDF (qui télécharge le PDF)

      si le statut n'est pas NOT APPLICABLE, on affiche SAVE et SUBMIT (le submit a des conditions de disabled car il faut remplir tous les inputs nécessaires)

  si l'utilisateur est le consultant
    si le statut = SIGNED, on affiche le bouton EXPORT PDF (qui télécharge le PDF)
    si le statut = READY TO BE SIGNED, on affiche le bouton de signature
    le consultant peut lire son engement brief si le statut = IN PROGRESS ou NOT APPLICABLE...

    le consultant ne peut pas ajouter des fichiers, il peut seulement les lire
      

*/

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Grid,
  TextField,
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Chip,
  Slider,
  RadioGroup,
  Radio,
  FormLabel,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveIcon from "@mui/icons-material/Save";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  useSubmitEngagementBriefMutation,
  useGetProjectsMutation,
  useCreateFilesMutation,
} from "../../../api/eStarterApi";
import { useSelector, useDispatch } from "react-redux";
import _ from "underscore";
import { DialogEngagementBriefSignature } from "../dialogs/DialogEngagementBriefSignature";
import Notification from "../../../components/Notification";
import moment from "moment";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DialogEngagementBriefDuplicate } from "../dialogs/DialogEngagementBriefDuplicate";
import { AttachmentInput } from "../../components/AttachmentInput";
import PdfEngagementBrief from "./PdfEngagementBrief";
import { CustomLoading } from "../../../components/CustomLoading";
import { useNavigate  } from "react-router-dom";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";

const FormEngagementBrief = (props) => {
  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  // changement du state
  const sections = props.sections;
  const [openSections, setOpenSections] = useState([sections[0].section]); //par défaut on ouvre que la premiere
  const isCurrentUserIsCurrentConsultant =
    props.isCurrentUserIsCurrentConsultant;
  const [latestSavedVersionValues, setLatestSavedVersionValues] = useState(
    props.initialValues.latest_saved_version,
  );
  const [updatedEngagementBriefDetailsValues, setUpdatedEngagementBriefDetailsValues] = useState(
    props.initialValues.engagement_brief_details,
  );
  const [updatedFiles, setUpdatedFiles] = useState(props.initialValues.files);
  const [filesToCreate, setFilesToCreate] = useState([]); //par défaut pas de fichier à créer
  const [loading, setLoading] = useState(false);
  const [openDialogSignature, setOpenDialogSignature] = useState(false);
  const [openDialogDuplicate, setOpenDialogDuplicate] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  //api
  const dispatch = useDispatch();
  const [postSubmitEngagementBrief] = useSubmitEngagementBriefMutation();
  const [apiCreateFiles] = useCreateFilesMutation();
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });

  const currentEngagement = props.currentEngagement;
  const currentProject = props.currentProject;

  //information du store
  const currentEngagementBrief = useSelector((state) => state.selectedEngagementBrief, _.isEqual);
  
  const currentFormParams = useSelector(
    (state) => state.selectedEngagementBriefFormParams, 
    _.isEqual
  );
  const currentStatus = currentEngagementBrief.latest_saved_versionhandleChange
    ? currentEngagementBrief.latest_saved_version.engagement_brief_status
    : currentEngagement.engagement_brief_status;

  //charger les données au chargement de la page
  useEffect(() => {
    setLatestSavedVersionValues(props.initialValues.latest_saved_version);
    setUpdatedEngagementBriefDetailsValues(props.initialValues.engagement_brief_details);
    setUpdatedFiles(props.initialValues.files);
  }, [props.initialValues]);
  //changement du state pour les accordions des sections
  const handleToggleSection = (section) => {
    const isOpen = openSections.includes(section);
    setOpenSections(
      isOpen
        ? openSections.filter((s) => s !== section)
        : [...openSections, section],
    );
  };


  // changement du state en fonction des inputs (si une nouvelle signature est nécessaire on met à jour latestSavedVersionValues)
  const handleChange = (inputName, value, signatureNeeded) => {
    if (signatureNeeded) {
      setLatestSavedVersionValues({
        ...latestSavedVersionValues,
        [inputName]: value,
      });
    } else {
      setUpdatedEngagementBriefDetailsValues({
        ...updatedEngagementBriefDetailsValues,
        [inputName]: value,
      });
    }
    setUnsavedChanges(true);
  };

  const handleClose = () => {
    navigate("/");
  };

  //changement du state pour les fichiers
  const handleFileChange = (value) => {
    setFilesToCreate(value.filter((v) => v instanceof File));
    setUpdatedFiles(value.filter((v) => !(v instanceof File)));
  };

  //fonction pour savoir si les inputs qui nécessitent une nouvelle signature ont changé
  const hasSignatureNeededInputChanged = () => {
    if (currentEngagementBrief.latest_saved_version) {
      return currentFormParams.some(
        (input) =>
          input.signatureNeeded &&
          currentEngagementBrief.latest_saved_version[input.inputName] !==
            latestSavedVersionValues[input.inputName],
      );
    } else {
      return true;
    }
  };

  // sauvegarde des changement (NOT APPLICABLE, APPLICABLE, SAVE or SUBMIT)
  const handleSubmit = async (action) => {
    setLoading(true);
    try {
      let resultApi = null;
      let resultfiles = null;
      let submitCase = "Submit";
      // on enregistre l'adresse mail si on doit envoyer un mail au consultant
      let sendEngagementBrief = {
        id: String(props.id),
        consultant_email: currentEngagement.consultant_email,
      };

      // en cas de submit, on enregistre l'utilisteur qui a soumis et on met à jour la valeur de sendNotif
      if (action === "SUBMIT") {
        sendEngagementBrief = {
          ...sendEngagementBrief,
          send_notification: true,
          submittedBy: props.currentUser,
        };
        submitCase = "Submit";
      } else {
        sendEngagementBrief = { ...sendEngagementBrief, send_notification: false };
        submitCase = "Save"
      }

      //on teste s'il faut créer des fichiers
      if (filesToCreate.length > 0) {
        //s'il faut créer des fichiers, on exécute l'api qui créer des fichiers dans le storage dans le bon dossier (id de l'engagement et AttachedFiles)
        resultfiles = await apiCreateFiles({
          files: filesToCreate,
          folderName: "EngagementBrief/" + String(props.id) + "/AttachedFiles",
        });
        sendEngagementBrief = { ...sendEngagementBrief, files: updatedFiles.concat(resultfiles.data) };
      } else {
        //sinon on laisse la liste des anciens fichiers
        sendEngagementBrief = { ...sendEngagementBrief, files: updatedFiles };
      }

      //ensuite on teste si les données qui redemandent une signature ont été modifiées
      if (hasSignatureNeededInputChanged()) {
        //si les données ont été modifiées, on demande une nouvelle signature (sauf si ce n'est pas un submit, dans ce cas on met le bon statut)
        sendEngagementBrief = {
          ...sendEngagementBrief,
          latest_saved_version: {
            ...latestSavedVersionValues,
            engagement_brief_status:
              action === "NOT APPLICABLE"
                ? "NOT APPLICABLE"
                : action === "APPLICABLE"
                ? "IN PROGRESS"
                : action === "SUBMIT"
                ? "READY TO BE SIGNED"
                : "IN PROGRESS",
          },
          latest_signed_version: currentEngagementBrief["latest_signed_version"], //on garde les anciennes données déjà signées (historique)
          engagement_brief_details: { ...updatedEngagementBriefDetailsValues },
        };
      } else {
        // on garde les anciennes données
        sendEngagementBrief = {
          ...sendEngagementBrief,
          latest_saved_version: {
            ...currentEngagementBrief["latest_saved_version"],
            engagement_brief_status:
              action === "NOT APPLICABLE"
                ? "NOT APPLICABLE"
                : action === "APPLICABLE"
                ? "IN PROGRESS"
                : action === "SUBMIT"
                ? "READY TO BE SIGNED"
                : currentEngagementBrief["latest_saved_version"]
                ? currentEngagementBrief["latest_saved_version"].engagement_brief_status
                : "IN PROGRESS",
          },
          latest_signed_version: currentEngagementBrief["latest_signed_version"],
          engagement_brief_details: { ...updatedEngagementBriefDetailsValues },
        };
      }

      // puis on exécute l'api qui update cosmos db
      resultApi = await postSubmitEngagementBrief({engagement_brief: sendEngagementBrief, case: submitCase}).unwrap();

      // on met à jour le state et on affiche la notif
      const updateEngagement = {
        ...currentEngagement,
        engagement_brief_status: sendEngagementBrief.latest_saved_version?.engagement_brief_status,
      };

      dispatch({ type: "selectedEngagementBriefReducer/selectEngagementBrief", payload: sendEngagementBrief });
      dispatch({
        type: "selectedEngagementReducer/selectEngagement",
        payload: updateEngagement,
      });
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultApi.status,
          snackbarMessage: resultApi.msg,
        },
      });
      setFilesToCreate([]);
      postProjects({ remove_closed: true });
      setEngagementsActiveOnly(true);
      setUnsavedChanges(false);
    } catch (error) {
      //on affiche les erreurs
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
          "An error occurred during submission. Please try again. Error: " +
          error.data.msg,
        },
      });
    } finally {
      //on arrete la barre de chargement
      setLoading(false);
    }
  };

  //calcul des sliders pour savoir si la somme = 100%
  const isSumSliderCorrect = (idSlider) => {
    const inputsConcerned = sections
      .reduce((acc, section) => acc.concat(section.inputs), [])
      .filter((input) => input.idSlider === idSlider);
    const sum = inputsConcerned.reduce((acc, input) => {
      let valueToAdd = 0;

      if (input.signatureNeeded) {
        valueToAdd = latestSavedVersionValues[input.inputName]
          ? latestSavedVersionValues[input.inputName]
          : 0;
      } else {
        valueToAdd = updatedEngagementBriefDetailsValues[input.inputName]
          ? updatedEngagementBriefDetailsValues[input.inputName]
          : 0;
      }

      return acc + valueToAdd;
    }, 0);

    return sum === 100;
  };
  // fonction qui renvoie les inputs mandatory qui n'ont pas de valeur => on ne peut pas soumettre si la réponse n'est pas vide
  const getUnfilledRequiredInputs = () => {
    const requiredInputs = sections
      .reduce((acc, section) => acc.concat(section.inputs), [])
      .filter(
        (input) =>
          (input.signatureNeeded || input.mandatory) &&
          input.inputType !== "Boolean",
      );

    // Trouver les inputs nécessaires qui n'ont pas de valeur mise à jour non vide
    const unfilledRequiredInputs = requiredInputs.filter((requiredInput) => {
      const updatedValue =
        latestSavedVersionValues[requiredInput.inputName] ||
        updatedEngagementBriefDetailsValues[requiredInput.inputName];

      // Si c'est un slider, on verifie juste la somme, sinon si une valeur mise à jour est trouvée et qu'elle n'est pas vide, renvoie false (pour exclure cet input)

      return requiredInput.inputType === "Slider"
      ? !isSumSliderCorrect(requiredInput.idSlider) 
      : requiredInput.inputType === "Multiple Select" || "Select" || "Radio"
      ? Array.isArray(updatedValue) 
      ? updatedValue.length === 0 
      : requiredInput.inputType === "Text"
      ? !updatedValue || updatedValue === "" 
      : false 
      : false;
    });

    return unfilledRequiredInputs;
  };

  //fonction pour télécharger l'engagement brief en
  const downloadPDF = () => {
    const element = document.getElementById("pdf-content");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4");
      // Calculez la taille de l'image et le nombre de pages
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const numPages = Math.ceil(imgHeight / pageHeight);

      // Ajoutez l'image à chaque page du PDF
      for (let i = 0; i < numPages; i++) {
        if (i > 0 ) {
          doc.addPage();
        }
        const imgPageHeight = i * pageHeight;
        doc.addImage(imgData, "PNG", 0, -imgPageHeight, imgWidth, imgHeight);
      }
      // Générez le fichier PDF
      doc.save(
        `EB_${currentEngagement.consultant_full_name}_E0${
          String(props.id) + "_" + String(moment().format("YYYY-MM-DD"))
        }.pdf`,
      );
    });
  };

  // sauvegarder le ficher dans le storage une fois qu'il est signé
  const saveEngagementBrieftoStorage = async () => {
    const element = document.getElementById("pdf-content");

    html2canvas(element).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4");
      // Calculez la taille de l'image et le nombre de pages
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const numPages = Math.ceil(imgHeight / pageHeight);

      // Ajoutez l'image à chaque page du PDF
      for (let i = 0; i < numPages; i++) {
        if (i !== 0) {
          doc.addPage();
        }
        const imgPageHeight = i * pageHeight;
        doc.addImage(imgData, "PNG", 0, -imgPageHeight, imgWidth, imgHeight);
      }

      // Convertir le PDF en Blob
      const pdfBlob = doc.output("blob");

      // Créer un objet File à partir du Blob
      const fileName = `EB_${currentEngagement.consultant_full_name}_E0${
        String(props.id) + "_" + String(moment().format("YYYY-MM-DD"))
      }.pdf`;
      const pdfFile = new File([pdfBlob], fileName, {
        type: "application/pdf",
      });

      await apiCreateFiles({
        files: [pdfFile],
        folderName: "EngagementBrief/" + String(props.id),
      }).unwrap();
    });
  };
  return loading ? (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <CustomLoading loadingText="Processing" />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1} p={0} my={4} justifyContent='flex-start'>
      <Notification />
      {/* affichage caché du pdf */}
      <PdfEngagementBrief
        project={currentProject}
        engagement={currentEngagement}
        engagementBrief={currentEngagementBrief}
        latestSavedVersionValues={latestSavedVersionValues}
        inputs={sections.reduce((acc, section) => {
          const filteredInputs = section.inputs.filter(
            (input) => input.signatureNeeded,
          );
          return [...acc, ...filteredInputs];
        }, [])}
      />

      {openDialogSignature ? (
        <DialogEngagementBriefSignature
          openDialog={openDialogSignature}
          setOpenDialog={setOpenDialogSignature}
          saveEngagementBrief={saveEngagementBrieftoStorage}
          currentEngagement={currentEngagement}
        />
      ) : null}
      {openDialogDuplicate ? (
        <DialogEngagementBriefDuplicate
          openDialog={openDialogDuplicate}
          setOpenDialog={setOpenDialogDuplicate}
          EngagementBriefStatus={
            currentEngagementBrief.latest_saved_version
              ? currentEngagementBrief.latest_saved_version.engagement_brief_status
              : currentEngagement.engagement_brief_status
          }
          currentProject={currentProject}
        />
      ) : null}
      <Grid container mt={4} display='flex' flexDirection='row' justifyContent='space-between' ml={1}>
        <Grid item display='flex' flexDirection='row' alignItems='center'>
          <Typography variant="h6">
            {isSmallScreen ? "Status:" : "Engagement Brief Status:"}
          </Typography>
          <Typography variant="button">
            <Chip
              sx={{ mr: 1, ml: 1 }}
              size="small"
              color={currentStatus?.replace(/ /g, "")}
              label={`${currentStatus}${
                currentStatus === "SIGNED"
                  ? " on " + currentEngagementBrief.latest_saved_version?.signature_date
                  : ""
              }`}
            />
          </Typography>
        </Grid>
        {/* _____________________________________________________________________________________________________________ AFFICHAGE des boutons en fonction des status et des utilisateurs (voir détails en entete du fichier ) */}
        <Grid item sx={{width:`${isSmallScreen && '100%'}`}} mt={isSmallScreen && 1} >
          <Box display="flex" alignItems="center" justifyContent={isSmallScreen ? "space-between" : "flex-end"}>
            {(currentStatus === "TO BE CREATED" ||
              currentStatus === "IN PROGRESS") &&
            !isCurrentUserIsCurrentConsultant ? (
              <Button
                color="primary"
                sx={{ mr: isSmallScreen ? 0 : 2 }}
                onClick={() => handleSubmit("NOT APPLICABLE")}
                variant="outlined"
                size="small"
              >
                {isSmallScreen
                  ? "NOT APPLICABLE?"
                  : "NOT APPLICABLE FOR THIS ENGAGEMENT?"}
              </Button>
            ) : currentStatus === "NOT APPLICABLE" &&
              !isCurrentUserIsCurrentConsultant ? (
              <Button
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => handleSubmit("APPLICABLE")}
                variant="outlined"
                size="small"
              >
                APPLICABLE?
              </Button>
            ) : null}

            {currentStatus === "NOT APPLICABLE" ||
            isCurrentUserIsCurrentConsultant ? null : (
              <Button
                onClick={() => setOpenDialogDuplicate(true)}
                startIcon={<ContentCopyIcon />}
                size="small"
                sx={{ mr: 2 }}
              >
                {isSmallScreen ? "Duplicate" : "Import from existing"}
              </Button>
            )}
            {currentStatus === "SIGNED" ? (
              <Button
                onClick={() => downloadPDF()}
                startIcon={<PictureAsPdfIcon />}
                size="small"
              >
                {" "}
                Export PDF
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>

      {/* FORMULAIRE ENGAGEMENT BRIEF-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}

      {currentStatus === "NOT APPLICABLE"
        ? null
        : sections.map((section) => (
            <Grid
              key={section.section}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Accordion
                expanded={openSections.includes(section.section)}
                onChange={() => handleToggleSection(section.section)}
                sx={{ bgcolor: "grey.100" }}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems="baseline">
                    <Grid item>
                      <Typography variant="h6">
                        {section.sectionName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="error">
                        {getUnfilledRequiredInputs().some(
                          (a) => a.section === section.section,
                        )
                          ? " *"
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {section.inputs.map((input) => (
                      <Grid
                        key={input.inputName}
                        item
                        xs={12}
                        sm={input.inputType === "Slider" ? 12 : 4}
                        md={input.inputType === "Slider" ? 12 : 4}
                      >
                        {/* TEXT-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Text" && (
                          <TextField
                            size="small"
                            helperText={input.inputDescription}
                            label={
                              input.signatureNeeded ? (
                                <Box display="flex" alignItems="center">
                                  {`${input.inputLabel} *`}
                                  <Tooltip title="if this input changes, it will ask another consultant review">
                                    <WarningAmberIcon
                                      size="small"
                                      sx={{ ml: 1 }}
                                      />
                                  </Tooltip>
                                </Box>
                              ) : (
                                input.inputLabel
                              )
                            }
                            multiline
                            rows={input.rows}
                            variant="outlined"
                            disabled={isCurrentUserIsCurrentConsultant}
                            fullWidth
                            error={
                              input.mandatory &&
                              !(
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName]
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ]) || ""
                              )
                            }
                            required={input.mandatory && !input.signatureNeeded}
                            margin="normal"
                            value={
                              (input.signatureNeeded
                                ? latestSavedVersionValues?.[input.inputName]
                                : updatedEngagementBriefDetailsValues?.[input.inputName]) ||
                              ""
                            }
                            onChange={(e) =>
                              handleChange(
                                input.inputName,
                                e.target.value,
                                input.signatureNeeded,
                              )
                            }
                          />
                        )}
                        {/* SELECT-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Select" && (
                          <FormControl
                            fullWidth
                            disabled={isCurrentUserIsCurrentConsultant}
                            margin="normal"
                            variant="outlined"
                            error={
                              input.mandatory &&
                              !(
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName]
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ]) || []
                              )
                            }
                          >
                            <Autocomplete
                              disabled={isCurrentUserIsCurrentConsultant}
                              size="small"
                              id={input.inputName}
                              options={input.inputDetails}
                              onChange={(e, newValue) =>
                                handleChange(
                                  input.inputName,
                                  newValue,
                                  input.signatureNeeded,
                                )
                              }
                              disableClearable={true}
                              getOptionLabel={(option) => option}
                              value={
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName]
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ]) || []
                              }
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={input.inputLabel}
                                  required={input.mandatory}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                        {/* MULTI SELECT-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Multiple Select" && (
                          <FormControl
                            sx={{width:'100%', marginTop:1}}
                            disabled={isCurrentUserIsCurrentConsultant}
                            label={input.inputLabel}
                          >
                            <Autocomplete
                              disabled={isCurrentUserIsCurrentConsultant}
                              size="small"
                              multiple
                              disableCloseOnSelect
                              id={input.inputName}
                              options={input.inputDetails}
                              onChange={(e, newValue) =>
                                handleChange(
                                  input.inputName,
                                  newValue,
                                  input.signatureNeeded,
                                )
                              }
                              getOptionLabel={(option) => option}
                              value={
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName]
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ]) || []
                              }
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={input.mandatory}
                                  label={input.inputLabel}
                                  error={input.mandatory && (
                                    (input.signatureNeeded
                                      ? latestSavedVersionValues?.[input.inputName].length === 0
                                      : updatedEngagementBriefDetailsValues?.[
                                          input.inputName
                                        ]).length === 0
                                  )
                                  }
                                />
                              )}
                            />
                          </FormControl>
                        )}
                        {/* DATE-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Date" && (
                          <TextField
                            label={input.inputLabel}
                            size="small"
                            type="date"
                            disabled={isCurrentUserIsCurrentConsultant}
                            required={input.mandatory}
                            variant="outlined"
                            value={
                              (input.signatureNeeded
                                ? latestSavedVersionValues &&
                                  latestSavedVersionValues[input.inputName]
                                : updatedEngagementBriefDetailsValues &&
                                  updatedEngagementBriefDetailsValues[input.inputName]) || ""
                            }
                            onChange={(e) =>
                              handleChange(
                                input.inputName,
                                e.target.value,
                                input.signatureNeeded,
                              )
                            }
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}

                        {/* BOOLEEN-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}

                        {input.inputType === "Boolean" && (
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isCurrentUserIsCurrentConsultant}
                                edge={"start"}
                                checked={
                                  !!(input.signatureNeeded
                                    ? latestSavedVersionValues &&
                                      latestSavedVersionValues[input.inputName]
                                    : updatedEngagementBriefDetailsValues &&
                                      updatedEngagementBriefDetailsValues[
                                        input.inputName
                                      ]) || false
                                }
                                onChange={(e) =>
                                  handleChange(
                                    input.inputName,
                                    e.target.checked,
                                    input.signatureNeeded,
                                  )
                                }
                              />
                            }
                            labelPlacement="top"
                            label={
                              <Typography>
                                {input.inputLabel}
                                {input.inputDescription
                                  ? " " + input.inputDescription
                                  : " "}
                              </Typography>
                            }
                          />
                        )}

                        {/* RADIO-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Radio" && (
                          <FormControl
                            sx={{ mb: 1 }}
                            disabled={isCurrentUserIsCurrentConsultant}
                            focused
                            required={input.mandatory}
                            error={
                              input.mandatory &&
                              (
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName].length === 0
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ].length === 0)
                              )
                            }
                          >
                            <FormLabel sx={{ mb: 1 }}>
                              {input.signatureNeeded ? (
                                <Box display="flex" alignItems="center">
                                  {input.inputLabel}
                                  <Tooltip title="if this input changed, it will ask another consultant review">
                                    <WarningAmberIcon
                                      size="small"
                                      sx={{ ml: 1 }}
                                    />
                                  </Tooltip>
                                </Box>
                              ) : (
                                input.inputLabel
                              )}
                            </FormLabel>
                            <RadioGroup
                              sx={{ ml: 2 }}
                              row={input.inputDetails.length < 3}
                              value={
                                (input.signatureNeeded
                                  ? latestSavedVersionValues?.[input.inputName]
                                  : updatedEngagementBriefDetailsValues?.[
                                      input.inputName
                                    ]) || []
                              }
                              onChange={(e) =>
                                handleChange(
                                  input.inputName,
                                  e.target.value,
                                  input.signatureNeeded,
                                )
                              }
                            >
                              {input.inputDetails.map((detail) => (
                                <FormControlLabel
                                  key={detail}
                                  value={detail}
                                  control={<Radio size="small" sx={{padding:1}} />}
                                  label={detail}
                                  style={{ height: "fit-content"}}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        )}
                        {/* SLIDER-------------------------------------------------------------------------------------------------------------------------------------------------------------------       */}
                        {input.inputType === "Slider" && (
                          <Box sx={{ maxWidth: 600}} pr={1}>
                            <Grid container>
                              <Grid item>
                                <Typography
                                  variant="caption"
                                  color="error"
                                >
                                  {!isSumSliderCorrect(input.idSlider)
                                    ? "Be careful, the sum should be = 100% |"
                                    : " "}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="caption">
                                  {input.inputLabel}:{" "}
                                  {(input.signatureNeeded
                                    ? latestSavedVersionValues &&
                                      latestSavedVersionValues[input.inputName]
                                    : updatedEngagementBriefDetailsValues &&
                                      updatedEngagementBriefDetailsValues[input.inputName]) || 0}
                                  %
                                </Typography>
                              </Grid>

                            </Grid>
                            <Slider
                              disabled={isCurrentUserIsCurrentConsultant}
                              sx={{ ml: isSmallScreen ? 0 : 3}}
                              step={5}
                              valueLabelDisplay="auto"
                              valueLabelFormat={(value) => `${value}%`}
                              color={
                                isSumSliderCorrect(input.idSlider)
                                  ? "primary"
                                  : "error"
                              }
                              max={100}
                              min={0}
                              marks={
                                isSmallScreen
                                  ? [
                                      { value: 0, label: '0%' },
                                      { value: 100, label: '100%'},
                                    ]
                                  : Array.from({ length: 21 }, (_, index) => ({
                                      value: index * 10,
                                      label: `${index * 10}%`,
                                    }))
                              }
                              value={
                                (input.signatureNeeded
                                  ? latestSavedVersionValues &&
                                    latestSavedVersionValues[input.inputName]
                                  : updatedEngagementBriefDetailsValues &&
                                    updatedEngagementBriefDetailsValues[input.inputName]) ||
                                0
                              }
                              onChange={(e) =>
                                handleChange(
                                  input.inputName,
                                  e.target.value,
                                  input.signatureNeeded,
                                )
                              }
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}

      {/* _________________________________________________________________________________________________ affichage des fichiers */}
      {currentStatus === "NOT APPLICABLE" ? null : (
        <Grid item xs={12} sm={12} md={12}>
          <Accordion
            expanded={openSections.includes("Attachments")}
            onChange={() => handleToggleSection("Attachments")}
            sx={{ bgcolor: "grey.100" }}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems="baseline">
                <Grid item>
                  <Typography variant="h6">Attachments</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} sm={12} md={12}>
                <AttachmentInput
                  editMod={isCurrentUserIsCurrentConsultant ? "VIEW" : "EDIT"}
                  onChange={(e) => handleFileChange(e)}
                  initialFiles={updatedFiles}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      {/* _____________________________________________________________________________________________________________ AFFICHAGE des boutons en fonction des status et des utilisateurs (voir détails en entete du fichier ) */}
      <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mr: isSmallScreen ? 0 : 4, mt: 1 }} mb={isSmallScreen && 10}>
        {currentStatus ===
        "NOT APPLICABLE" ? null : isCurrentUserIsCurrentConsultant ? (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              onClick={() => setOpenDialogSignature(true)}
              variant="contained"
              disabled={
                currentEngagementBrief.latest_saved_version?.engagement_brief_status !== "READY TO BE SIGNED"
              }
              startIcon={<EditAttributesIcon />}
            >
              Sign my Engagement Brief
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            { unsavedChanges ?
              <Button color="error" sx={{ mr: isSmallScreen ? 0 : 2 }} onClick={() => setConfirmDialogOpen(true)}>
                {isSmallScreen ? "Cancel" : "Cancel and go back"}
              </Button>
              :
              <Button color="error" sx={{ mr: isSmallScreen ? 0 : 2 }} onClick={() => handleClose()}>
                {isSmallScreen ? "Cancel" : "Cancel and go back"}
              </Button>
            }

            <Button
              onClick={() => handleSubmit("SAVE")}
              startIcon={isSmallScreen ? null : <SaveIcon />}
              color="success"
            >
              {" "}
              Save
            </Button>
            <Button
              onClick={() => handleSubmit("SUBMIT")}
              disabled={
                getUnfilledRequiredInputs().length > 0 ||
                (!hasSignatureNeededInputChanged() &&
                  (currentStatus === "SIGNED" ||
                    currentStatus === "READY TO BE SIGNED"))
              }
            >
              Save & Submit
            </Button>
            {confirmDialogOpen ? (
              <ConfirmDialog
              title="Are you sure you want to cancel ?"
              description="Any unsaved changes will be lost."
              mod="unsavedChanges"
              open={confirmDialogOpen}
              setOpen={setConfirmDialogOpen}
              onConfirm={handleClose} // Pass the handleClose function
            />
            ): null }
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default FormEngagementBrief;
