import React, { useCallback, useEffect, useMemo, useState } from "react";
import { 
    Typography, 
    Accordion, 
    AccordionSummary, 
    useMediaQuery, 
    Grid, 
    AccordionDetails, 
    List, 
    Table, 
    TableBody, 
    TableContainer, 
    TableRow,
    TableCell,
    Chip,
    CssBaseline,
    Box,
    Tooltip,
    Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TeamCard from "./TeamCard";
// import MarginDraftsTabsContext from "../../../contexts/MarginDraftsTabsContext";
import { v4 as uuidv4 } from 'uuid';


const Team = React.memo((props) => {
    const currentProject= props.currentProject;
    const projectMargins = props.projectMargins;
    const countrySettings = props.countrySettings;
    const projectCurrency = countrySettings.currency;
    const setTeamValues = props.setEngagementTeamValues;
    const mode = props.mode;
    const setTeamTotalRevenue = mode === "Margin" ? props.setTeamTotalRevenue : null;
    
    const engagementTeamValues = props.engagementTeamValues;
// ________________________________________________________________________ States ________________________________________________________________________
    const [updatedInputValues, setUpdatedInputValues] = useState([]);
    const [initializeFormValues, setInitializeFormValues] = useState(true);
    const [revenuesByCard, setRevenuesByCard] = useState({});
    const teamCards = mode === "Margin" ? projectMargins[0].engagement_margin.team : engagementTeamValues;
    // const { selectedMarginDraftTab } = useContext(MarginDraftsTabsContext);

    const updateTotalRevenue = useCallback((cardId, revenue) => {
        setRevenuesByCard(prevRevenues => ({
            ...prevRevenues,
            [cardId]: revenue
        }));
    }, [setRevenuesByCard]);
    
    if (initializeFormValues) {
        setUpdatedInputValues(teamCards);
        // Initialise TeamCards on Drafts when Tab changes
        if (mode === "Draft"){
            setTeamValues(teamCards)
        }
        setInitializeFormValues(false);
    };

    
    useEffect(() => {
        if (mode === "Draft") {
            setUpdatedInputValues(teamCards)
        }
        // eslint-disable-next-line
        }, [mode]);
        

    // Calculer le total des revenus à afficher
    const totalRevenue = useMemo(()=> {
        return Object.values(revenuesByCard).reduce((total, revenue) => total + revenue, 0);
    }, [revenuesByCard]); 

    const draftTotalRevenue = mode === "Draft" 
    ? updatedInputValues.reduce((total, card) => {
      if (card.sold_days > 0 && card.daily_sale_rate > 0) {
        return total + (card.sold_days * card.daily_sale_rate);
      }
      return total;
    }, 0)
    : null;
    
    // ________________________________________________________________________CSS ________________________________________________________________________
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    // ________________________________________________________________________ Accordions ________________________________________________________________________
    // Créer un tableau d'états pour chaque accordéon
    const [open, setOpen] = useState(true);
    // On stocke un state qui enregistre les cartes ventes qui sont ouvertes
    const handleToggleTeamCards = () => {
        setOpen(!open);
    };

    const handleChangeParent = useCallback((event, teamCardId) => {
        let { name, value } = event.target;
        let numericValue = value;
        if (value && !isNaN(value) && value.slice(-2) !== ".0" && value.slice(-1) !== ".") {
            numericValue = parseFloat(value);
            if (isNaN(numericValue)) {
                numericValue = '';
            }
        } else if (value.slice(-2) === ".0") {
            // Gardez la chaîne telle quelle si elle se termine par ".0"
            numericValue = value;
        } else if (value === '') {
            numericValue = '';
        }

        if (mode === "Margin"){
            setUpdatedInputValues(currentValues =>
                currentValues.map(card => {
                    if (card.id === teamCardId) { 
                        switch (name) {
                            case "es3":
                            const es3Value = value === "true" ? true : false;
                            return { ...card, [name]: es3Value };
                            case "monthly_gross_salary":
                                case "annual_bonus":
                                    return {
                                        ...card,
                                        employee: {
                                    ...card.employee,
                                    salary: {
                                        ...card.employee.salary,
                                        [name]: {
                                            ...card.employee.salary[name],
                                            margin: numericValue
                                        }
                                    }
                                }
                            };
                        case "worked_days":
                        case "sold_days":
                            return { ...card, [name]: numericValue };
                        case "office_presence":
                            return { ...card, [name]: value };
                            default:
                                return { ...card, [name]: {
                                    ...card[name],
                                    margin: numericValue 
                                } 
                            };
                        }
                }
                return card;
            })
        );
    }   
    if (mode === "Draft") {
        setUpdatedInputValues(currentValues => 
            currentValues.map((card) => {
                if (card.id === teamCardId) {
                    return {...card, [name]: numericValue};
                }
                return card;
            })
        );
    }
        props.setUnsavedChanges(true);
    },[props, mode]);

    const handleConsultantClick = () => {
        let newConsultantCard = {
            id: uuidv4(),
            name: "",
            worked_days: 0,
            sold_days: 0,
            daily_sale_rate: 0,
            daily_purchase_rate: null,
            monthly_gross_salary: 0,
            annual_bonus: 0,
            monthly_fees: 0,
            daily_fees: 0,
            daily_it_costs: 0,
            daily_desk_costs: 0,
            office_presence: null,
            es3: null
        };
        setTeamValues(currentCards => [...currentCards, newConsultantCard]); 
        setUpdatedInputValues(currentCards => [...currentCards, newConsultantCard]); 
        setInitializeFormValues(true)
        props.setUnsavedChanges(true);
    }
    const handleSubcontractorClick = () => {
        let newSubcontractorCard = {
            id: uuidv4(),
            name: "",
            worked_days: 0,
            sold_days: 0,
            daily_sale_rate: 0,
            daily_purchase_rate: 0,
            monthly_gross_salary: 0,
            annual_bonus: 0,
            monthly_fees: 0,
            daily_fees: 0,
            daily_it_costs: 0,
            daily_desk_costs: 0,
            office_presence: null,
            es3: false
        };
        setTeamValues(currentCards => [...currentCards, newSubcontractorCard]);
        setUpdatedInputValues(currentCards => [...currentCards, newSubcontractorCard]);
        setInitializeFormValues(true)
        props.setUnsavedChanges(true);
    }

    const handleDeleteDraftCard = (teamCardId) => {
        setTeamValues(currentCards => currentCards.filter(card => card.id !== teamCardId));
        setUpdatedInputValues(currentCards => currentCards.filter(card => card.id !== teamCardId));
            // Remove the revenue associated with the deleted card
        setRevenuesByCard(prevRevenues => {
            const newRevenues = {...prevRevenues};
            delete newRevenues[teamCardId];
        return newRevenues;
    });
        setInitializeFormValues(true)
        props.setUnsavedChanges(true);
    }

    const handleChangeDraftCardName = (teamCardId, newName) => {
        setTeamValues(currentCards => {
            const updatedCards = currentCards.map(card => 
                card.id === teamCardId ? { ...card, name: newName } : card
            );
            return updatedCards;
        });
        setUpdatedInputValues(currentCards => {
            const updatedCards = currentCards.map(card => 
                card.id === teamCardId ? { ...card, name: newName } : card
            );
            return updatedCards;
        });
    
        setInitializeFormValues(true);
        props.setUnsavedChanges(true);
    };
    

    useEffect(() => {
        setTeamValues(updatedInputValues)
        if (mode === "Margin") {
            setTeamTotalRevenue(totalRevenue);
        }
    }, [updatedInputValues, setTeamValues, setTeamTotalRevenue, totalRevenue, mode]);

    return(
    <Box sx={{ display: "flex", my: 2}}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} minWidth={"100%"}>
            <Accordion 
                defaultExpanded={true} 
                onChange={handleToggleTeamCards} 
                elevation={0} 
                sx={{ border: '1px solid lightgray'}}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Tooltip title="The data pre-loaded in the Team cards is directly extracted from MyBU and is updated several times a day. You can update the values except for the consultant's name, surname, dates and locations.">
                            <Grid item maxWidth="50%" sx={{display:"flex", flexDirection: "row"}} alignItems="center">
                                <Typography variant={isSmallScreen ? "subtitle2" : "h6"}>
                                    TEAM
                                </Typography>
                                {!props.isTeamComplete(updatedInputValues) && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
                            <PeopleAltOutlinedIcon fontSize="small" sx={{marginLeft:1}} />
                            </Grid>
                        </Tooltip>
                        <Grid item sx={{ mr: 2 }}>
                            <Tooltip title="Sum of all revenues from Team Cards">
                                <Chip
                                    size="medium"
                                    label={mode === "Margin" ?
                                        <Typography variant="subtitle2">
                                            {`REVENUE: \u00A0 ${parseFloat(totalRevenue).toLocaleString('en-US', {minimumFractionDigits: 2})} ${projectCurrency.symbol}`}
                                        </Typography>
                                        :
                                        <Typography variant="subtitle2">
                                            {`REVENUE: \u00A0 ${parseFloat(draftTotalRevenue).toLocaleString('en-US', {minimumFractionDigits: 2})} ${projectCurrency.symbol}`}
                                        </Typography>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        <TableContainer sx={{ borderRadius: 1 }}>
                            <Table>
                                <TableBody>
                                   {mode === "Margin" ? 
                                   updatedInputValues
                                        .slice()
                                        .sort((a, b) => a.employee.last_name.localeCompare(b.employee.last_name))
                                        .map((teamCard, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ padding: 0.5, border: 'none' }}>
                                                        <TeamCard
                                                            mode={mode}
                                                            teamCard={teamCard}
                                                            countrySettings={countrySettings}
                                                            currentProject={currentProject}
                                                            handleChangeParent={handleChangeParent}
                                                            updateTotalRevenue={updateTotalRevenue}
                                                            isCardComplete={props.isTeamCardComplete(teamCard)}
                                                        />
                                                </TableCell>
                                            </TableRow>
                                        )) 
                                        :
                                        updatedInputValues
                                        .slice()
                                        .map((teamCard, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ padding: 0.5, border: 'none' }}>
                                                        <TeamCard
                                                            mode={mode}
                                                            teamCard={teamCard}
                                                            countrySettings={countrySettings}
                                                            currentProject={currentProject}
                                                            handleChangeParent={handleChangeParent}
                                                            updateTotalRevenue={updateTotalRevenue}
                                                            isCardComplete={props.isTeamCardComplete(teamCard)}
                                                            handleDeleteDraftCard={handleDeleteDraftCard}
                                                            handleChangeDraftCardName={handleChangeDraftCardName}
                                                            setUnsavedChanges={props.setUnsavedChanges}
                                                            index={index}
                                                        />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </List>
                    {mode === "Draft" ?
                    <>
                    <Button onClick={handleConsultantClick}>+ CONSULTANT</Button>
                    <Button onClick={handleSubcontractorClick}>+ SUBCONTRACTOR/ES3</Button>
                    </>
                    : null
                    }

                </AccordionDetails>
            </Accordion>
        </Grid>
    </Box>
    )
}, (prevProps, nextProps) => {
    return prevProps.engagementTeamValues === nextProps.engagementTeamValues 
    &&
    prevProps.countrySettings === nextProps.countrySettings;
});

export default Team;
