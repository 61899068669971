import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { 
    Autocomplete, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    FormControl, 
    TextField, 
    Typography 
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useGetCountriesSettingsMutation, useSubmitCountriesSettingsMutation } from "../../api/eStarterApi";
import { useDispatch } from "react-redux";
import { CustomLoading } from "../../components/CustomLoading";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import dayjs from 'dayjs';

export default function DialogBankHolidays(props) {
    const countrySettings = props?.countrySettings;
    const selectedCountryId = props?.selectedCountryId;
    const setInitializeData = props.setInitializeData;
    
    const [getCountriesSettings] = useGetCountriesSettingsMutation({ fixedCacheKey: "countries_settings" });
    const [postSubmitCountriesSetting] = useSubmitCountriesSettingsMutation();
    
    const [loading, setLoading] = useState(false);
    const country_departments = countrySettings["departments"]; 
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
    const [newHolidayDate, setNewHolidayDate] = useState("");
    const [newHolidayDescription, setNewHolidayDescription] = useState("");
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [initializeDataToDelete, setInitializeDataToDelete] = useState(true)
    
    const dispatch = useDispatch();

    if (props.editMod === "Delete" && initializeDataToDelete) {
        setNewHolidayDate(props.selectedHolidayForDeletion.day)
        setSelectedBusinessUnits(props.selectedHolidayForDeletion.businessUnits.split(','))
        setNewHolidayDescription(props.selectedHolidayForDeletion.description)
        setInitializeDataToDelete(false)
    }
    
    let businessUnitsNames = []
    businessUnitsNames.push("National")
    country_departments.forEach((business_unit) => {
        businessUnitsNames.push(business_unit)
    })
    
    const handleClose = () => {
        props.setOpenDialog(false);
        props.setSelectedHolidayForDeletion(null);
        setSelectedBusinessUnits([]);
        setNewHolidayDate(null);
        setNewHolidayDescription("");
    };

    const handleBusinessUnitsChange = (event, newValue) => {
        setSelectedBusinessUnits(newValue);
        setUnsavedChanges(true);
    };

    const handleDescriptionChange = (event) => {
        setNewHolidayDescription(event.target.value);
        setUnsavedChanges(true);
    };

    const setDate = (newValue) => {
        const formattedDate = newValue.format("YYYY-MM-DD");
        setNewHolidayDate(formattedDate);
        setUnsavedChanges(true);
    };

    const handleSubmit = async (action) => {
        setLoading(true);
        let response = {}
        let submitCase = null
        if (action === "Submit") {
            submitCase = "Submit"
        } else {
            submitCase = "Delete"
        }
        try { 
            if (( submitCase === "Delete" && selectedBusinessUnits.includes("General")) || (submitCase === "Submit" && selectedBusinessUnits.includes("National"))  ) { 
                response = await postSubmitCountriesSetting({
                    id: selectedCountryId,
                    case: submitCase,
                    day: newHolidayDate,
                    description: newHolidayDescription
                }).unwrap();
            } else if (selectedBusinessUnits.length > 0) {
                response = await postSubmitCountriesSetting({
                    id: selectedCountryId,
                    case: submitCase,
                    business_units: selectedBusinessUnits.map((businessUnit) => ({
                        name: businessUnit,
                        days: {
                            day: newHolidayDate,
                            description: newHolidayDescription
                        }
                    })
                )}).unwrap();
            }
            getCountriesSettings();
            setInitializeData(true)
            dispatch({
                type: "snackbarInfo/setNotification",
                payload: {
                  snackbarOpen: true,
                  snackbarType: response.status,
                  snackbarMessage: response.msg,
                },
              });
        } catch (error) {
            console.log(error)
            dispatch({
                type: "snackbarInfo/setNotification",
                payload: {
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage:
                    "An error occurred during saving. Please try again. Error: " +
                    error.data.msg,
                },
            });
        }  finally {
            // dans tous les cas, on arrête le chargement des données et on
            setLoading(false);
        }
    };
      
    return loading ? (
        <Dialog open={props.openDialog} maxWidth="md" fullWidth>
            <CustomLoading loadingText={"Saving..."} />
        </Dialog>
    ) : (
        <Dialog
            open={props.openDialog}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <Notification />
            
            <DialogContent>
                <Typography variant="h4">
                    {props.editMod} a bank holiday here
                </Typography>
                    <FormControl sx={{ mt: 2 }} fullWidth>
                        <Autocomplete
                            options={businessUnitsNames}
                            disableCloseOnSelect
                            multiple
                            size="small"
                            sx={{ my: 1 }}
                            disabled={props.editMod === "Delete"}
                            value={selectedBusinessUnits.map(unit => unit === "General" ? "National" : unit)}
                            onChange={handleBusinessUnitsChange}
                            getOptionDisabled={(option) => 
                                (selectedBusinessUnits.includes("National") && option !== "National") ||
                                (selectedBusinessUnits.length > 0 && option === "National")
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="National or business unit bank holiday"
                                    size="small"
                                />
                            )}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                required
                                size="small"
                                sx={{ my: 1 }}
                                format="DD/MM/YYYY"
                                label="Bank holiday date *"
                                disabled={props.editMod === "Delete"}
                                onChange={(e) => setDate(e)}
                                value={props.selectedHolidayForDeletion && dayjs(newHolidayDate)}
                            />
                        </LocalizationProvider>
                        <TextField
                            size="small"
                            required
                            sx={{ my: 1 }}
                            label="Description"
                            variant="outlined"
                            disabled={props.editMod === "Delete"}
                            value={newHolidayDescription}
                            onChange={handleDescriptionChange}
                        />
                    </FormControl>  
            </DialogContent>
            {
                props.editMod === "Add" ? (
                    <DialogActions>
                        <Button
                            onClick={()=> {handleSubmit("Submit")}}
                            autoFocus
                            color="success"
                            disabled={!newHolidayDescription || !selectedBusinessUnits || !newHolidayDate}
                        >
                        Save
                        </Button>
                        {unsavedChanges ? 
                            <Button onClick={() => setConfirmDialogOpen(true)} color="error">
                                Cancel
                            </Button> 
                            :<Button onClick={handleClose} color="error">
                                Cancel
                            </Button>
                        }
                        {confirmDialogOpen ? (
                            <ConfirmDialog
                                title="Are you sure you want to cancel ?"
                                description="Any unsaved changes will be lost."
                                mod="unsavedChanges"
                                open={confirmDialogOpen}
                                setOpen={setConfirmDialogOpen}
                                onConfirm={handleClose}
                            />
                            ): null 
                        }
                    </DialogActions>
                ) : null
            }{ props.editMod === "Delete"?
                (
                    <DialogActions>
                        <Button
                            onClick={()=> {setConfirmDialogOpen(true)}}
                            autoFocus
                            color="primary"
                            disabled={!newHolidayDescription || !selectedBusinessUnits || !newHolidayDate }
                        >
                            Delete
                        </Button>
                        <Button onClick={handleClose} color="error">
                            Cancel
                        </Button>

                        {confirmDialogOpen ? (
                            <ConfirmDialog
                                title="Are you sure you want to delete this bank holiday ?"
                                description="This action is permanent."
                                mod= "Delete"
                                yesButtonText = "Delete"
                                open={confirmDialogOpen}
                                setOpen={setConfirmDialogOpen}
                                onConfirm={()=> {handleSubmit("Delete")}}
                            />
                            ): null 
                        }
                    </DialogActions>

                ):null
            }
        </Dialog>
    )
}