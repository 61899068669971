/*

Navbar pour les utilisateurs qui ont un petit écran (mobile compris)
il y a des liens vers les différents compostants si on a les bons accès
les détails des bons accès sont dans Approuter

limited: on ne peut voir que EB & PP (donc les pages Project, EngagementBrief, PreventionPlan et leurs enfants)


*/
import React, { useState } from "react";
import { Paper, 
  BottomNavigation, 
  BottomNavigationAction, 
  // Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions 
} from "@mui/material";
// import DialpadIcon from '@mui/icons-material/Dialpad';
import ListIcon from '@mui/icons-material/List';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "underscore";
// import MarginDrafts from "../../drafts/MarginDrafts";

export default function MobileNavBar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const user_role = useSelector(
    (state) =>
      state.api.mutations["UserInfos"]
        ? state.api.mutations["UserInfos"].status === "fulfilled"
          ? state.api.mutations["UserInfos"].data.user_role
          : ""
        : "",
    _.isEqual,
  );
  const [url, setUrl] = useState(currentPath);

  const handleChange = (newUrlPath) => {
    setUrl(newUrlPath);
  };
  // MARGIN DRAFTS
  // const [open, setOpen] = useState(false);
  // const handleClickOpen = async () => {
  //   setOpen(true);
  // }

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const marginLocked = true

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        url={url}
        onChange={(e, newUrlPath) => handleChange(e, newUrlPath)}
      >
        <BottomNavigationAction
          label= "PROJECTS"
          url="/projects"
          icon={<ListIcon />}
          component={Link}
          to="/projects"
        />
        {user_role === "Business" ||
        user_role === "Specific" ||
        user_role === "Admin" ? (
          <BottomNavigationAction
            label="PP MGT"
            url="/preventionplanmgt"
            icon={<PostAddOutlinedIcon />}
            component={Link}
            to="/preventionplanmgt"
          />
        ) : null}
        {/* {(user_role === "Admin" || user_role === "Business") && !marginLocked ? (
          <BottomNavigationAction
              label="CALC"
            icon={<DialpadIcon size="small" onClick={handleClickOpen} />}
            />
        ) : null} */}
        {/* <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Margin Estimations Calculator</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Here, you can create teams and add as many consultants as you need. You can create up to 5 different estimations. <br/>The estimations are not connected to any margins or projects therefore cannot be imported into a margin.
            </DialogContentText>
            <MarginDrafts />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
        <BottomNavigationAction
          label="HELP"
          url="/help"
          icon={<QuestionMarkOutlinedIcon size="small"/>}
          component={Link}
          to="/help"
        />
      </BottomNavigation>
    </Paper>
  );
}
