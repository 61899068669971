/*
c'est le composant qui vient apres INDEX.JS / APP.JS / APPLAYOUT.JS / APP ROUTER.JS
il est le parent des dialog suivant : DialogPPFile (pour la création ou l'édition des prévention plan) et DialogPPMgtAssignment (pour l'assignation du pp à des engagements)

il faut un rôle particulier pour pouvoir ajouter des prevention plan : Specific, Business ou Admin
On doit sélectionner un client pour pouvoir ajouter un plan de prévention, donc on ne peut ajouter des plans de prevention uniquement sur son scope d'engagements

attention, seuls les admins et Specific peuvent créer ou éditer un pp
les business managers peuvent assigner des prevention plan en masse, c'est pourquoi ils ont accès à cette page



*/

import React, { useState } from "react";
import {
  Box,
  Grid,
  Container,
  TextField,
  InputAdornment,
  CssBaseline,
  Typography,
  IconButton,
  Chip,
  Card,
  CardActions,
  Button,
  CardContent,
  Tooltip,
  TablePagination,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PlaceIcon from "@mui/icons-material/Place";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import EditIcon from "@mui/icons-material/Edit";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { DialogPreventionPlanFile } from "../dialogs/DialogPreventionPlanFile";
import { DialogPreventionPlanMgtAssignment } from "../dialogs/DialogPreventionPlanMgtAssignment";
import {
  useGetEngagementsPreventionPlanAssignmentMutation,
  useGetFileContentMutation,
} from "../../api/eStarterApi";
import Notification from "../../components/Notification";
import moment from "moment";
import { CustomLoading } from "../../components/CustomLoading";

export function PreventionPlanMgt(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  //changemnt du state
  const [editMod, setEditMod] = useState("Create");
  const [search, setSearch] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [validPreventionPlans, setValidPreventionPlans] = useState(true);
  const [openDialogFile, setOpenDialogFile] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [testLoading, testSetLoading] = useState(false);
  const [openDialogAssignment, setOpenDialogAssignment] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  //information du store
  const user_role = useSelector(
    (state) =>
      state.api.mutations["UserInfos"]
        ? state.api.mutations["UserInfos"].status === "fulfilled"
          ? state.api.mutations["UserInfos"].data.user_role
          : ""
        : "",
    _.isEqual,
  );
  const apiStatus = useSelector((state) =>
    state.api.mutations["prevention_plan_files"]
      ? state.api.mutations["prevention_plan_files"].status
      : "loading",
  );

  const prevention_plan_files = useSelector(
    (state) =>
      state.api.mutations["prevention_plan_files"]
        ? state.api.mutations["prevention_plan_files"].status === "fulfilled"
          ? state.api.mutations["prevention_plan_files"].data.prevention_plan_files
          : []
        : [],
    _.isEqual,
    );

  const allCustomers = useSelector(
    (state) =>
      state.api.mutations["prevention_plan_files"]
        ? state.api.mutations["prevention_plan_files"].status === "fulfilled"
          ? state.api.mutations["prevention_plan_files"].data.customers_list
          : []
        : [],
    _.isEqual,
    );

  //api
  const dispatch = useDispatch();
  const [getFileContent] = useGetFileContentMutation();
  const [getEngagementsPreventionPlanAssignment] = useGetEngagementsPreventionPlanAssignmentMutation({
    fixedCacheKey: "prevention_plan_assignments",
  });

  //pagination
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  //filtre => changement du state
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1); //remettre à la première page la pagination quand on change un fitre
  };
  const handleClearClick = () => {
    setSearch("");
    setPage(1); //remettre à la première page la pagination quand on change un fitre
  };

  
  //filtre sur tous les pp files en fonction des éléments selectionnés ou de la search bar
  const filteredPreventionPlanFiles = prevention_plan_files.filter((preventionPlanFile) => {
    const matchesSearch =
      search === "" ||
      preventionPlanFile.prevention_plan_name?.toLowerCase().includes(search.toLowerCase()) ||
      preventionPlanFile.customer_site?.toLowerCase().includes(search.toLowerCase());

    const filterCustomer =
      selectedCustomers.length === 0 ||
      selectedCustomers.includes(preventionPlanFile.customer_name);
      
    const validityDatePreventionPlans =
      validPreventionPlans ? moment(preventionPlanFile?.validity_date, "YYYY-MM-DD").isAfter(moment(), "day") : preventionPlanFile

    return matchesSearch && filterCustomer && validityDatePreventionPlans;
  });


  // const allCustomers = initial_customers_list
  const downloadFile = async (file) => {
    setLoading(true);
    if (file) {
      try {
        const response = await getFileContent({ blob: file.blob });

        if (response.data) {
          const content = response.data.content;
          const mime_type = response.data.mime_type;
          const fileUrl = `data:${mime_type};base64,${content}`;

          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(`Error fetching blob content: ${response.statusText}`);
        }
      } catch (error) {
        console.error("Error fetching file from Azure function:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleToggleClosedEngagements = () => {
    if (validPreventionPlans) {
      setValidPreventionPlans(false);
    } else {
      setValidPreventionPlans(true);
      // setGetClosedEngagements(true);
    }
  }

  return loading ? ( //affichage de la barre de chargement
    <CustomLoading loadingText={"Dowloading..."} />
  ) : (
    <Box sx={{ display: "flex", mb: 3}}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Notification />
        {/* affichage des dialogues en fonction du state */}
        {openDialogFile ? (
          <DialogPreventionPlanFile
            editMod={editMod}
            openDialog={openDialogFile}
            setOpenDialog={setOpenDialogFile}
            setOpenDialogAssignment={setOpenDialogAssignment}
            allCustomers={allCustomers}
          />
        ) : null}
        {openDialogAssignment ? (
          <DialogPreventionPlanMgtAssignment
            openDialog={openDialogAssignment}
            setOpenDialog={setOpenDialogAssignment}
          />
        ) : null}
        {apiStatus !== "fulfilled" ? (
          <CustomLoading loadingText={"Loading data.. "} />
        ) : (
          <Grid container spacing={1}>
            {isSmallScreen ? null : (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4">Prevention Plan Management</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Manage your Prevention Plan here. You have access to your
                  Customer scope (according to your roles).
                  <br /> If you have any doubt regarding the process, please
                  check the help section
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} sx={{ mb: 2, mt: 2 }} display="flex" flexDirection={isSmallScreen && "column"} justifyContent="space-between" alignItems="center">
              <Box display="flex" flexWrap="wrap" gap={1}>
                {/* _____________________________________________________________________________________________________________________________les filtres */}
                <TextField
                  label="Search"
                  size="small"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ width: isSmallScreen ? "100%" : "300px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: search !== "" ? "visible" : "hidden",
                        }}
                        size="small"
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  options={allCustomers}
                  size="small"
                  sx={{
                    ml: isSmallScreen ? 0 : 1,
                    width: isSmallScreen ? "100%" : "300px",
                  }}
                  value={selectedCustomers}
                  onChange={(event, newValue) => {
                    setSelectedCustomers(newValue);
                    setPage(1);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Customer" size="small" />
                  )}
                />
                <Button
                  size="small"
                  onClick={() => {
                    setEditMod("Create");
                    dispatch({
                      type: "selectedPreventionPlanFileReducer/selectPreventionPlanFile",
                      payload: {},
                    });
                    setOpenDialogFile(true);
                  }}
                >
                  + Add a new prevention plan
                </Button>
              </Box>
              <Box sx={isSmallScreen && {width:"100%", display:"flex", alignItems:"flex-start"}}>
                <Button
                  variant="text"
                  color="info"
                  sx={{ ml: isSmallScreen ? 0 : 1 }}
                  size="small"
                  onClick={handleToggleClosedEngagements}
                  startIcon={<ArchiveIcon />}
                >
                  {validPreventionPlans ? "Load all prevention plans": "Hide Inactive Prevention Plans"}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* _______________________________________________________________________________________________________ les fichiers prevention plan*/}
              <Grid
                container
                spacing={1}
                alignItems="stretch"
                justifyContent="center"
                direction="row"
              >
                {filteredPreventionPlanFiles
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .sort((a, b) => a.customer_name.localeCompare(b.customer_name)) // __________________________________________________ on trie par client
                  .map((preventionPlanFile, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} display="flex">
                      <Card
                        variant="outlined"
                        sx={{ p: 0, cursor:'pointer' }}
                        elevation={0}
                      >
                        <CardContent>
                          <Grid 
                            container 
                            spacing={1}
                            alignItems="center" 
                            sx={{height: isSmallScreen ?  '120px' : '150px'}}
                            onClick={() => {
                              setEditMod("Edit");
                              dispatch({
                                type: "selectedPreventionPlanFileReducer/selectPreventionPlanFile",
                                payload: preventionPlanFile,
                              });
                              
                              getEngagementsPreventionPlanAssignment({
                                prevention_plan_id: preventionPlanFile.id,
                                customer_name: preventionPlanFile.customer_name,
                                remove_closed: false,
                              });
                              setOpenDialogFile(true);
                            }}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <Typography variant={isSmallScreen ? "subtitle2" : "h6"} noWrap>
                                {" "}
                                {preventionPlanFile.prevention_plan_name}{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Chip
                                label={preventionPlanFile.customer_name}
                                size="small"
                                icon={<EngineeringIcon />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Chip
                                label={preventionPlanFile.customer_site}
                                size="small"
                                icon={<PlaceIcon />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Tooltip title="Validity Date">
                                <Chip
                                  label={moment(preventionPlanFile.validity_date, "YYYY-MM-DD", true).isValid()
                                  ? moment(preventionPlanFile.validity_date, "YYYY-MM-DD").format("DD/MM/YYYY")
                                  : preventionPlanFile.validity_date}
                                  size="small"
                                  icon={<EventRepeatIcon />}
                                  color={
                                    moment(preventionPlanFile.validity_date, "YYYY-MM-DD", true).isValid() &&
                                    moment(preventionPlanFile.validity_date, "YYYY-MM-DD").isBefore(moment(), "day")
                                      ? "error"
                                      : "default"
                                  }
                                />
                              </Tooltip>
                              <Tooltip title="Attach files (click to dowload)">
                                <Chip
                                  label={preventionPlanFile.files ? preventionPlanFile.files.length : 0}
                                  size="small"
                                  onClick={() => {
                                    preventionPlanFile.files.map((file) =>
                                      downloadFile(file),
                                    );
                                  }}
                                  disabled={preventionPlanFile.files ? false : true}
                                  icon={<AttachFileIcon />}
                                  color="default"
                                  sx={{ ml: 1 }}
                                />
                              </Tooltip>
                              <Tooltip title="Engagements Assigned">
                                <Chip
                                  label={preventionPlanFile.assignments_count}
                                  size="small"
                                  icon={<AssignmentIndIcon />}
                                  sx={{ ml: 1 }}
                                  color={
                                    preventionPlanFile.assignments_count > 0 ? "success" : "error"
                                  }
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions
                          sx={{ display: 'flex', justifyContent:'flex-end'}}
                        
                        >
                          {/* __________________________________________________________________________________________________________________les boutons  */}
                          <Button
                            onClick={() => {
                              setEditMod("Edit");
                              dispatch({
                                type: "selectedPreventionPlanFileReducer/selectPreventionPlanFile",
                                payload: preventionPlanFile,
                              });
                              getEngagementsPreventionPlanAssignment({
                                prevention_plan_id: preventionPlanFile.id,
                                customer_name: preventionPlanFile.customer_name,
                                remove_closed: false,
                              });
                              setOpenDialogFile(true);
                            }}
                            size="small"
                            disabled={
                              user_role === "Admin" || user_role === "Specific"
                                ? false
                                : true
                            } //seul les admins ou les specifics peuvent éditer un prevention plan
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Tooltip
                            title={
                              preventionPlanFile.files
                                ? ""
                                : "You can't assign a Prevention Plan if there is no file attached"
                            }
                          >
                            <span>
                              <Button
                                onClick={() => {
                                  getEngagementsPreventionPlanAssignment({
                                    prevention_plan_id: preventionPlanFile.id,
                                    customer_name: preventionPlanFile.customer_name,
                                    remove_closed: false,
                                  });
                                  dispatch({
                                    type: "selectedPreventionPlanFileReducer/selectPreventionPlanFile",
                                    payload: preventionPlanFile,
                                  });
                                  setOpenDialogAssignment(true);
                                }}
                                size="small"
                                disabled={preventionPlanFile.files ? false : true} // on peut assigner que s'il y a bien un fichier joint  dans le futur
                                startIcon={<AssignmentIndIcon />}
                              >
                                Assign{" "}
                              </Button>
                            </span>
                          </Tooltip>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              {filteredPreventionPlanFiles.length > rowsPerPage || rowsPerPage === 24 ? (
                <TablePagination
                  rowsPerPageOptions={[6, 12, 24]}
                  component="div"
                  style={{ mt: 2, display: "flex", justifyContent: "center" }}
                  count={filteredPreventionPlanFiles.length}
                  page={page - 1}
                  onPageChange={(event, newPage) =>
                    handleChangePage(event, newPage + 1)
                  }
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
