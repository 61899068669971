/* 
      ___________________________________________________________ APP.JS

      c'est le composant qui vient apres INDEX.JS

      si l'utilisateur n'est pas connecté alors on affiche la page de connexion
      puis 
        si l'utilisateur à bien le rôle "Basic" dans ses rôles alors il peut accèder au reste de l'application
        sinon page Access Denied

      fichier de confiuration du theme


*/

import "./App.css";
import React, { useState } from "react";
import { AccessDenied } from "./connexion/pages/AccessDenied";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createTheme, ThemeProvider } from "@mui/material";
import { AppLayout } from "./AppLayout";
import { ConnexionPage } from "./connexion/pages/ConnexionPage";
import { ErrorPage } from "./connexion/pages/ErrorPage";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import  initializeAppInsights from "./AppInsights";
import EngagementsActiveOnlyContext from "./contexts/EngagementsActiveOnlyContext";
import UserContext from "./contexts/UserContext";
import MarginStatusContext from "./contexts/MarginStatusContext";
import FloatingButtonChatbot from "./chatbot/FloatingButtonChatbot";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [engagementsActiveOnly, setEngagementsActiveOnly] = useState(true);
  const [ userContext, setUserContext ] = useState([]);
  const [marginStatusContext, setMarginStatusContext] = useState([])
  const eStarterRoles = ["Basic", "Admin", "Specific", "Business"];
  const allowedURL = 'https://estarter-mp.azurewebsites.net/';
  const isAllowedURL = window.location.href.startsWith(allowedURL);

  // Conditionally initialize App Insights plugin
  const { reactPlugin } = isAllowedURL ? initializeAppInsights(accounts[0]?.username) : {};
  // on a accès que si on a au moins Basic dans nos rôles
  var haveAccess = accounts.map((account) =>
    //on boucle sur tous les comptes mais on prend que la premiere valeur
    account.idTokenClaims.roles === undefined
      ? //si on a aucun role alors on a n'a pas accès
        false
      : //sinon il faut qu'on ait au moins Basic
      account.idTokenClaims.roles.some((role) => eStarterRoles.includes(role))
      )[0];

      const hasChatbotAccess = accounts.some((account) =>
        account.idTokenClaims.roles.includes("chatbot_access")
      );
      
  
  return (
  <EngagementsActiveOnlyContext.Provider value={{ engagementsActiveOnly: engagementsActiveOnly, setEngagementsActiveOnly: setEngagementsActiveOnly }}>
    <UserContext.Provider value= {{ userContext: userContext, setUserContext: setUserContext }}>
        <MarginStatusContext.Provider value={{marginStatus: marginStatusContext, setMarginStatus: setMarginStatusContext}}>
          <div>
            {!isAuthenticated  || userContext.id === 0 ? (
              // si on n'est pas identifié, on affichage la page de connexion
              <ConnexionPage />
              ) : (
                // sinon on regarde si on a les droits d'acces
                <div>
                {haveAccess ? (
                  <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
                    <ThemeProvider theme={mdTheme}>
                      <AppLayout accounts={accounts} />
                      {hasChatbotAccess ? <FloatingButtonChatbot /> : null}
                    </ThemeProvider>
                  </AppInsightsErrorBoundary>
                ) : (
                  <AccessDenied />
                  )}
              </div>
            )}
          </div>
        </MarginStatusContext.Provider>
  </UserContext.Provider>
</EngagementsActiveOnlyContext.Provider>
  );
}
export default App;

const mdTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    button: {
      fontSize: "0.9rem",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  
  palette: {
    mode: "light", //'dark',
    background: {
      default: "white",
    },
    
    gray: {
      light: "#C0C0C0",
      main: "#9e9e9e",
      dark: "#424242",
      contrastText: "#FFFFFF",
    },
    primary: {
      light: "#4B4F8F",
      main: "#27245b",
      dark: "#1A1A2E",
      contrastText: "#FFFFFF",
      accent: "#FFA500",
    },
    secondary: {
      light: "#FFA500",
      main: "#02A9db",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },

    //ENGAGEMENT BRIEF STATUS
    TOBELAUNCHED: {
      light: "#FFA500",
      main: "#d32f2f",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    INPROGRESS: {
      light: "#FFA500",
      main: "#FCC608",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    READYTOBESIGNED: {
      light: "#42ce42",
      main: "#42ce42",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    SIGNED: {
      light: "#FFA500",
      main: "#006400",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    NOTAPPLICABLE: {
      light: "#C0C0C0",
      main: "#9e9e9e",
      dark: "#424242",
      contrastText: "#FFFFFF",
    },
    APPLICABLE: {
      light: "#C0C0C0",
      main: "#9e9e9e",
      dark: "#424242",
      contrastText: "#FFFFFF",
    },
    //PREVENTION PLAN STATUS
    TOBEASSIGNED: {
      light: "#FFA500",
      main: "#d32f2f",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    OUTDATED: {
      light: "#FFA500",
      main: "#d32f2f",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    // MARGIN STATUS
    TOBECREATED: {
      light: "#FFA500",
      main: "#d32f2f",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    TOBEUPDATED: {
      light: "#FFA500",
      main: "#d32f2f",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    SUBMITTED: {
      light: "#42ce42",
      main: "#42ce42",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
    VALIDATED: {
      light: "#FFA500",
      main: "#006400",
      dark: "#121212",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "white",
            backgroundColor: "#02A9db",
          },
          "&:hover": {
            color: "black",
            backgroundColor: "##8c8c8c",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "black",
          },
        },
      },
    },
  },
});
