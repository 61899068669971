/*
c'est un composant enfant de FormEB
c'est un dialog de duplication des EB

il affiche la liste des autres engagements brief et l'utilisateur n'a plus qu'à selectionner une engagement pour dupliquer les informations
on affiche que les EB qui ont les statuts READY TO BE SIGNED ou SIGNED
il peut les modifier et les sauvegarder


*/
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  Chip,
  List,
  Typography,
  Dialog,
  DialogContent,
  ListItem,
  Tooltip,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { truncateText } from "../../../commonFunction";
import { useSelector, useDispatch } from "react-redux";
import _ from "underscore";
import { useGetEngagementBriefMutation } from "../../../api/eStarterApi";
import { Search } from "@mui/icons-material";

export function DialogEngagementBriefDuplicate(props) {
  //changement du state
  const [search, setSearch] = useState("");

  //information du store
  const projects = useSelector(
    (state) =>
      state.api.mutations["crm_projects"]
        ? state.api.mutations["crm_projects"].status === "fulfilled"
          ? state.api.mutations["crm_projects"].data
          : []
        : [],
    _.isEqual,
  );

  //api
  const dispatch = useDispatch();
  const [getEngagementBrief] = useGetEngagementBriefMutation({ fixedCacheKey: "GetEB" });

  //filtrer les projets et engagements
  const filteredProjects = projects.filter(
    (project) =>
      project.customer_name === props.currentProject.customer_name &&
      Array.isArray(project.engagements) &&
      project.engagements.length > 0 &&
      project.engagements.filter(
        (engagement) =>
          engagement.engagement_brief_status === "READY TO BE SIGNED" ||
          engagement.engagement_brief_status === "SIGNED",
      ).length > 0 &&
      (search === "" ||
        project.project_name?.toLowerCase().includes(search.toLowerCase()) ||
        project.project_department?.toLowerCase().includes(
          search.toLowerCase(),
        ) ||
        project.project_id?.toLowerCase().includes(search.toLowerCase()) ||
        project.customer_name?.toLowerCase().includes(search.toLowerCase()) ||
        (Array.isArray(project.engagements) &&
          project.engagements.length > 0 &&
          project.engagements.some(
            (engagement) =>
              engagement.project_commercial_owner_name?.toLowerCase().includes(search.toLowerCase()) ||
              engagement.consultant_full_name?.toLowerCase().includes(
                search.toLowerCase(),
              ) ||
              engagement.project_legal_description?.toLowerCase().includes(
                search.toLowerCase(),
              ),
          ))),
  );

  //changment du state pour fermer le dialog
  const handleClose = () => {
    props.setOpenDialog(false);
  };
  //changement du state pour le filtre
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const handleClearClick = () => {
    setSearch("");
  };

  // on charge les informations de l'engagement brief selectionné (attention on ne change pas de engagement, juste les informations de l'eb)
  const onClickDuplicate = async (engagementId) => {
    let resultApi = null;
    resultApi = await getEngagementBrief({ id: String(engagementId) }).unwrap();


    if (resultApi) {
      dispatch({
        type: "selectedEngagementBriefReducer/selectEngagementBrief",
        payload: {
          ...resultApi,
          latest_saved_version: {
            ...resultApi.latest_saved_version,
            engagement_brief_status: "IN PROGRESS",
          },
        },
      });
      props.setOpenDialog(false);
    }
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
        {" "}
        Import data from existing Engagement Brief
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, ml: 2 }}>
        {" "}
        You can import data from this project only{" "}
      </Typography>
      <DialogContent>
        <Box sx={{ m: 2 }}>
          <Box sx={{ m: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={10} md={10}>
                <TextField
                  label="Search"
                  size="small"
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: search !== "" ? "visible" : "hidden",
                        }}
                        size="small"
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ m: 2, height: 250, overflow: "auto" }}>
            <Paper>
              <List>
                {" "}
                {filteredProjects.length === 0 ? (
                  <ListItem>The list is empty.</ListItem>
                ) : (
                  filteredProjects.map((project) =>
                    project.engagements.filter(
                      (engagement) =>
                        engagement.engagement_brief_status === "READY TO BE SIGNED" ||
                        engagement.engagement_brief_status === "SIGNED",
                    ).map((engagement, index) => (
                      <ListItem key={index}>
                        <Grid container alignItems="center">
                          <Grid item xs={11} sm={11} md={11}>
                            <Tooltip
                              title={`Engagement Status: ${engagement.engagement_status}`}
                            >
                              <Chip
                                label={engagement.engagement_status}
                                size="small"
                                color={
                                  engagement.engagement_status === "Active"
                                    ? "success"
                                    : "default"
                                }
                                sx={{ mr: 1, ml: 1 }}
                              />
                            </Tooltip>
                            <Chip
                              label={engagement.consultant_full_name}
                              icon={<PersonIcon />}
                              size="small"
                              sx={{ mr: 1 }}
                            />
                            <Chip
                              label={
                                project.project_id +
                                "|" +
                                project.project_name +
                                " | " +
                                truncateText(
                                  engagement.project_legal_description,
                                  20,
                                )
                              }
                              size="small"
                              sx={{ mr: 1 }}
                            />
                            <Chip
                              label={engagement.engagement_brief_status}
                              color={engagement.engagement_brief_status?.replace(/ /g, "")}
                              size="small"
                              sx={{ mr: 1 }}
                            />
                          </Grid>
                          <Grid item xs={1} sm={1} md={1}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  onClickDuplicate(
                                    engagement.engagement_integration_key,
                                  )
                                }
                              >
                                Duplicate
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )),
                  )
                )}
              </List>
            </Paper>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
