import React, { useState, useCallback, useContext, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  CssBaseline,
  Chip,
  Typography,
  Skeleton,
  Tooltip,
  useMediaQuery,
  Badge
} from "@mui/material";
import { TabPanel, TabList, TabContext } from '@mui/lab';
import _ from "underscore";
import LanguageIcon from "@mui/icons-material/Language";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EuroIcon from "@mui/icons-material/Euro";
import PoundIcon from "@mui/icons-material/CurrencyPound";
import DollarIcon from "@mui/icons-material/AttachMoney";
import EngagementsActiveOnlyContext from "../../../contexts/EngagementsActiveOnlyContext";
import moment from "moment";
import { useGetProjectsMutation, useGetMarginByProjectMutation } from "../../../api/eStarterApi";
import { useSelector, useDispatch } from "react-redux";
import { MarginForm } from "../components/MarginForm";
import { CustomLoading } from "../../../components/CustomLoading";
import Tab from '@mui/material/Tab';
import Notification from "../../../components/Notification";

export function Margin() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [postProjects] = useGetProjectsMutation({ fixedCacheKey: "crm_projects" });
  const [getMarginByProject] = useGetMarginByProjectMutation({ fixedCacheKey: "project_margins"})
  
  const [fetchMarginData, setFetchMarginData] = useState(true);

  const [initializeTabSelector, setInitalizeTabSelector] = useState(true);
  
  // Select the appropriate year as initial State for the opening tab
  
  const [marginValue, setMarginValue] = useState()
  
  // Wait for the GetMarginMutation call to reset to get the right tab (case if multiple margins are opened)
  const [marginDataReceived, setMarginDataReceived] = useState(false);

  const { setEngagementsActiveOnly } = useContext(EngagementsActiveOnlyContext);
  const {project_id, year} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const crm_projects = useSelector(
    (state) =>
    state.api.mutations["crm_projects"]
    ? state.api.mutations["crm_projects"].status === "fulfilled"
    ? state.api.mutations["crm_projects"].data
    : []
    : [],
    _.isEqual,
  );
    const projectMarginsList = useSelector(
      (state) =>
      state.api.mutations["project_margins"]
      ? state.api.mutations["project_margins"].status === "fulfilled"
      ? state.api.mutations["project_margins"].data.project_margins_list
      : []
      : [],
      _.isEqual,
      );
    const marginCountrySettings = useSelector(
      (state) =>
      state.api.mutations["project_margins"]
      ? state.api.mutations["project_margins"].status === "fulfilled"
      ? state.api.mutations["project_margins"].data.country_settings
      : []
      : [],
      _.isEqual,
      );
      const marginDeliveryManagers = useSelector(
        (state) =>
        state.api.mutations["project_margins"]
        ? state.api.mutations["project_margins"].status === "fulfilled"
        ? state.api.mutations["project_margins"].data.delivery_managers_list
        : []
        : [],
        _.isEqual,
      );
      // We get the loader ready
      const getMarginStatus = useSelector((state) =>
      state.api.mutations["project_margins"]
      ? state.api.mutations["project_margins"].status
      : "loading",
      );

      const handleTabChange = (event, newValue) => {
        setMarginValue(newValue);
        navigate(`/margin/${project_id}/${newValue}`);
      };
      
      let currentProject = [];
      
      const filteredProject = crm_projects.filter((project) => {
        return project.project_id === project_id;
      });
      
      const matchingProject = filteredProject.length === 0 ? false : true;

      const getProjectStatus = useSelector((state) =>
      state.api.mutations["crm_projects"]
      ? state.api.mutations["crm_projects"].status
      : "loading",
      );
      
      const initializeMarginData = useCallback(async () => {
        try {
            await getMarginByProject({
              id: project_id
            }).unwrap();
            
          } catch (error) {
            if (error.status === 401) {
              navigate("/accessdenied")
            } else if (error.status === 404) {
              navigate("/404")
            } else {
              dispatch({
                type: "snackbarInfo/setNotification",
                payload: {
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage:
                  "An error occurred during initializing the data. Please try again. Error: " +
                  error.data.msg,
                },
              });
              navigate("/")
            }
          } finally {
            setMarginDataReceived(true);
            setFetchMarginData(false);
          }
  }, [project_id, getMarginByProject, dispatch, navigate]);
  
  const loadClosedEngagements = useCallback(async () => {
    try {
      postProjects({ remove_closed: false }).unwrap();
      setEngagementsActiveOnly(false);
      
        } catch (error) {
          if (error.status === 401) {
            navigate("/accessdenied")
          } else if (error.status === 404) {
            navigate("/404")
      } else {
        dispatch({
          type: "snackbarInfo/setNotification",
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
            "An error occurred during initializing the data. Please try again. Error: " +
            error.data.msg,
          },
        });
      }
    } finally {
    }
  }, [setEngagementsActiveOnly, postProjects, dispatch, navigate]);
  
  let initialYearFound = false;
  let tabSelectorInitialValue = 0;
  if (projectMarginsList !== null && projectMarginsList !== undefined && getMarginStatus === "fulfilled") {
    if (projectMarginsList.length > 0){

      tabSelectorInitialValue = projectMarginsList.reduce((marginYearToDisplay, margin) => {
        if (margin.year === year) {
          initialYearFound = true;
          return margin.year;
        }
        if (!initialYearFound && Number(margin.year) > marginYearToDisplay) {
          return margin.year;
        }
        return marginYearToDisplay;
      }, 0); 
    } else {
      tabSelectorInitialValue = 1
    }
  }
  
  
  let projectDates = []
  if (matchingProject) {
    currentProject = filteredProject[0];
    
    // Determine the earliest start date and latest end date of all engagements to get the project dates
    projectDates = currentProject.engagements.reduce((latestEngagement, currentEngagement) => {
      if (!latestEngagement) {
        return {
          projectStartDate: currentEngagement.engagement_start_date,
          projectEndDate: currentEngagement.engagement_end_date
        };
      }
      
      const startDateComparison = moment(currentEngagement.engagement_start_date).isBefore(latestEngagement.projectStartDate);
      const endDateComparison = moment(currentEngagement.engagement_end_date).isAfter(latestEngagement.projectEndDate);
      
      return {
        projectStartDate: startDateComparison ? currentEngagement.engagement_start_date : latestEngagement.projectStartDate,
        projectEndDate: endDateComparison ? currentEngagement.engagement_end_date : latestEngagement.projectEndDate
      };
    }, null);
    
  }
  
  useEffect(() => {
    if (fetchMarginData && (crm_projects.length > 0)) {
      if (matchingProject) {
        initializeMarginData();
      } else if (!matchingProject) {
        loadClosedEngagements()
        initializeMarginData();
      }
    }
    if (marginDataReceived && initializeTabSelector && (getMarginStatus === "fulfilled") && tabSelectorInitialValue !== 0) {
      setInitalizeTabSelector(false);
      navigate(`/margin/${project_id}/${tabSelectorInitialValue}`)
      setMarginValue(tabSelectorInitialValue);
    }
    // Change the tab selector to handle previous & next page changes
    if (marginValue !== year && !initializeTabSelector) {
      setMarginValue(year);
    }
  }, [getProjectStatus, getMarginStatus, fetchMarginData, marginDataReceived, initializeMarginData, navigate, project_id, initializeTabSelector, tabSelectorInitialValue, marginValue, year, matchingProject, loadClosedEngagements, crm_projects.length]);

  return (
    <Box sx={{ display: "flex" }} my={isSmallScreen && 4} mb={10}>
      <CssBaseline />
      {getProjectStatus !== "fulfilled" ? (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Notification />
          <CustomLoading loadingText={"Loading Project Data..."} />
        </Container>
      ) : (
        <Container maxWidth="xl" py={2}>
          <Notification />
        <Grid container my={3}>
              {isSmallScreen ? null : (
                <Grid display="flex" alignItems="center">
                  <Typography variant="h4">
                    Margin |{" "}
                    {currentProject?.project_name}
                  </Typography>
                </Grid>
              )}
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h5">
                    {currentProject?.project_id +
                      " | " +
                      currentProject?.project_commercial_owner_name}
                  </Typography>
                </Grid>
        </Grid>
        <Grid container my={2} justifyContent='flex-start'> 
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title={`Project Country: ${currentProject?.project_country}`}>
                    <Chip
                      label={
                        currentProject ? (
                          currentProject.project_country
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          icon={<LanguageIcon />}
                          size="small"
                          />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Project Department: ${currentProject?.project_department}`}>
                    <Chip
                      label={
                        currentProject ? (
                          currentProject.project_department
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          icon={<LocationCityIcon />}
                          size="small"
                          />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Customer: ${currentProject?.customer_name}`}>
                    <Chip
                      label={
                        currentProject ? (
                          currentProject.customer_name
                          ) : (
                            <Skeleton width={100} />
                            )
                          }
                          size="small"
                          />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title="Project Dates">
                    <Chip
                     label={getProjectStatus === "fulfilled" ? (`${moment(projectDates?.projectStartDate).format(
                       isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                       )} 
                       - ${moment(projectDates?.projectEndDate).format(
                         isSmallScreen ? "DD/MM/YY" : "dddd DD MMMM YYYY",
                         )}`) : ( <Skeleton width={200} />)}
                         icon={<CalendarMonthIcon />}
                         size="small"
                         />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container my={0} justifyContent='flex-start' spacing={1}>
                <Grid item>
                  <Tooltip title={`Project IQP: ${currentProject.project_iqp}`}>
                    <Chip 
                      label={currentProject ? currentProject.project_iqp : <Skeleton width={100} />} 
                      size="small" 
                      />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Project Billing Type: ${currentProject.project_billing_type}`}>
                    <Chip 
                      label={currentProject ? currentProject.project_billing_type : <Skeleton width={100} />} 
                      size="small" 
                      />
                  </Tooltip>
                </Grid>
                {getMarginStatus === "fulfilled" ? 
                <Grid item>
                  {/* REPLACE LATER WITH CURRENCY FROM MARGIN PARAMS */}
                  <Tooltip title={`Currency: ${marginCountrySettings.currency.id}`}>
                    <Chip 
                      label={marginCountrySettings.currency.id} 
                      icon={marginCountrySettings.currency.id === "EUR" || marginCountrySettings.currency.id === "RON" ? <EuroIcon /> :
                      marginCountrySettings.currency.id === "GBP" ? <PoundIcon /> :
                      marginCountrySettings.currency.id === "USD" ? <DollarIcon /> : null }
                      size="small"
                      />
                  </Tooltip>
                </Grid> : null }
              </Grid>
            </Grid>
          {/* --------------------------------------------------------------------- YEAR SELECTOR -------------------------------------------------------------------------- */}
          {!initializeTabSelector ?
            tabSelectorInitialValue === 1 ? 
              <Container>
                <Typography>There were no margin data found for this project. If you think this is an error, please contact an administrator</Typography> 
              </Container>
                :
            <TabContext value={marginValue} >
              <TabList
                value={marginValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{mb:3}}
                >
                {projectMarginsList.slice().sort((a, b) => a['year'] - b['year']).map((margin, index) => (
                  <Tab 
                  key={index} 
                  value={margin['year']} 
                      label={
                        <Badge 
                        badgeContent={margin.changes_detected ? "!" : undefined}
                        color={margin.changes_detected ? "error" : "default"}
                        >
                          <Typography variant="h6">
                            {margin['year']}
                          </Typography>
                        </Badge>
                      }
                      />
                      )
                      )}
              </TabList>
              {
                projectMarginsList.map((margin, index) => (
                  <TabPanel key={index} value={margin['year']} sx={{padding: 0, margin: 0}}>
                      <MarginForm 
                        currentProject={currentProject}
                        projectMargins={projectMarginsList.filter((margin) => margin.year === year)}
                        countrySettings={marginCountrySettings}
                        marginDeliveryManagers={marginDeliveryManagers}
                        setInitalizeTabSelector={setInitalizeTabSelector}
                        setFetchMarginData={setFetchMarginData}
                        setMarginDataReceived={setMarginDataReceived}
                        />
                    </TabPanel>
                ))
              }
            </TabContext>
          :
           (<Container sx={{ mt: 2, mb: 2 }}>
             <CustomLoading loadingText={`Loading Margin Data`} />
            </Container>) }
          </Container>
        )}
      </Box>
  );
  
}
