import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, TextField, Typography, List, ListItem, ListItemText, Link, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';

const Chatbot = ({ messages, setMessages, threadId, setThreadId, accessToken, setOpen }) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (!loading) {
            scrollToBottom();
        }
    }, [messages, loading]);

    const updateBotMessage = (botMessage) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            if (updatedMessages[updatedMessages.length - 1]?.sender === 'bot') {
                updatedMessages[updatedMessages.length - 1].text = botMessage;
            } else {
                updatedMessages.push({ text: botMessage, sender: 'bot' });
            }
            return updatedMessages;
        });
    };

    const handleSendMessage = async () => {
        if (input.trim()) {
            const newMessages = [...messages, { text: input, sender: 'user' }];
            setMessages(newMessages);
            setInput('');
            setLoading(true);
            const requestBody = { prompt: input };

            if (threadId) {
                requestBody.thread_id = threadId;
            }

            try {
                const response = await fetch('https://estarter-support-bot.azurewebsites.net/api/stream_assistant_response', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!response.body) {
                    throw new Error('ReadableStream not yet supported in this browser.');
                }

                const newThreadId = response.headers.get('thread_id');
                if (newThreadId) {
                    setThreadId(newThreadId);
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let botMessage = '';

                while (!done) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    const chunk = decoder.decode(value, { stream: true });

                    // Check for thread_id in the first message
                    if (chunk.startsWith('***thread_id=')) {
                        const endOfThreadId = chunk.indexOf('***', 13);
                        if (endOfThreadId !== -1) {
                            const newThreadId = chunk.substring(13, endOfThreadId);
                            setThreadId(newThreadId);
                            botMessage += chunk.substring(endOfThreadId + 3); // Remove the thread_id part
                        }
                    } else {
                        botMessage += chunk;
                    }

                    updateBotMessage(botMessage);

                    if (done) {
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.error('Error receiving response:', error);
                setMessages(prevMessages => [...prevMessages, { text: 'Error: Unable to fetch response.', sender: 'bot' }]);
                setLoading(false);
            }
        }
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !loading) {
            handleSendMessage();
        }
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                eStarter Support 
            </Typography>
     
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                <Alert severity="info">Please not that this is an experimental feature and some results may not be accurate. 
                    Conversations will be saved for analysis purposes.<br/>
                    <Link href="/help" target="blank" sx={{textDecoration: "none", color: "inherit" }}>
                        To access to the HELP page or to report a bug, click here.
                    </Link>
                </Alert>
            </Box>
            <List sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, border: '1px solid #ccc', borderRadius: 1, p: 1 }}>
                {messages.map((message, index) => (
                    <ListItem key={index} sx={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                        <ListItemText 
                            primary={message.text} 
                            sx={{ 
                                backgroundColor: message.sender === 'user' ? '#e0f7fa' : '#f1f8e9', 
                                borderRadius: 2, 
                                px: 2, 
                                py: 1 
                            }} 
                        />
                    </ListItem>
                ))}
                {loading && (
                    <ListItem sx={{ justifyContent: 'center' }}>
                        <ListItemText primary="Loading..." sx={{ textAlign: 'center' }} />
                    </ListItem>
                )}
                <div ref={messagesEndRef} />
            
            </List>
            <Box display="flex" flexDirection={isSmallScreen ? "column": "row"}>
                <TextField 
                    value={input} 
                    onChange={(e) => setInput(e.target.value)} 
                    variant="outlined" 
                    fullWidth 
                    size="small" 
                    placeholder="Type a message..." 
                    disabled={loading}
                    onKeyDown={handleKeyDown}
                />
                <Box sx={isSmallScreen ? {display:"flex", justifyContent: "flex-end", mt: 1} : null}>
                    <Button 
                        onClick={handleSendMessage} 
                        variant="contained" 
                        color="primary" 
                        sx={{ ml: 1 }}
                        disabled={loading}
                    >
                        Send
                    </Button>
                    {isSmallScreen ? <Button 
                        onClick={handleClose} 
                        variant="text" 
                        color="primary" 
                        sx={{ ml: 1 }}
                        disabled={loading}
                    >
                        Close
                    </Button> 
                    : null
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default Chatbot;
