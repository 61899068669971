import * as React from "react";
import Typography from "@mui/material/Typography";

import "../../LandingSharedStyle.css";
import logoMP from "../../media/migso.png";
import logoEStarter from "../../media/estarter.png";

export const AccessDenied = () => {
  return (
    <div className="img2">
      <div className="top-left">
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold" }}
          color="white"
        >
          <img src={logoMP} height="40px" alt="al" />
        </Typography>
      </div>

      <div className="top-right">
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold" }}
          color="white"
        >
          <img src={logoEStarter} height="15px" alt="al" />
        </Typography>
      </div>

      <div className="centered">
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          color="blackTitle.dark"
        >
          Access Denied
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold" }}
          color="blackTitle.dark"
        >
          You dont have access to this app :(
        </Typography>
      </div>
    </div>
  );
};
