import React, { useCallback, useEffect, useState } from "react";
import { FormControl, Grid, InputAdornment, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import { useGetCountriesSettingsMutation, useSubmitCountriesSettingsMutation } from "../../api/eStarterApi";
import { CustomLoading } from "../../components/CustomLoading";

export default function MarginCountrySettingsForm (props) {
    const selectedCountryId = props?.selectedCountryId;
    const handleChangeParent = props?.handleChangeParent;
    const countrySettingsFormValues = props.countrySettingsFormValues;
    const setInitializeData = props.setInitializeData;

    const [updatedMarginSettingsValues, setUpdatedMarginSettingsValues] = useState(props.countrySettingsFormValues.margin_settings);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const [getCountriesSettings] = useGetCountriesSettingsMutation({ fixedCacheKey: "countries_settings" });
    const [postSubmitCountriesSetting] = useSubmitCountriesSettingsMutation();
    
    const dispatch = useDispatch();

    const handleChangeLocally = useCallback((event) => {
        const { name, value } = event.target;
        const regex = /^-?\d*\.?\d*$/;
        if (regex.test(value) || value === "") { 
            setUnsavedChanges(true)
            handleChangeParent(event, countrySettingsFormValues.id);
        } else {
            console.log("Invalid input for", name);
        }
    }, [handleChangeParent, countrySettingsFormValues.id]);

    const handleSubmit = async (action) => {
        setLoading(true)    
        let response = {}
        let submitCase = null
        let parsedUpdatedMarginSettingsValues = null

        if (action === "Submit") {
            submitCase = "Submit"
            parsedUpdatedMarginSettingsValues = Object.keys(updatedMarginSettingsValues).reduce((acc, key) => {

                const value = updatedMarginSettingsValues[key];
                const parsedValue = isNaN(parseFloat(value)) ? value : parseFloat(value);

                acc[key] = parsedValue;
                
                return acc;
            }, {});
        };

        if(submitCase === "Submit") {
            try {
                response = await postSubmitCountriesSetting({
                    id: selectedCountryId,
                    case: submitCase,
                    margin_settings: parsedUpdatedMarginSettingsValues
                }).unwrap();
                getCountriesSettings();
                setInitializeData(true);
                setUnsavedChanges(false);
                dispatch({
                    type: "snackbarInfo/setNotification",
                    payload: {
                    snackbarOpen: true,
                    snackbarType: response.status,
                    snackbarMessage: response.msg,
                    },
                });
            } catch (error) {
                console.log(error)
                dispatch({
                    type: "snackbarInfo/setNotification",
                    payload: {
                    snackbarOpen: true,
                    snackbarType: "error",
                    snackbarMessage:
                        "An error occurred during saving. Please try again. Error: " +
                        error.data.msg,
                    },
                });
            }  finally {
                // dans tous les cas, on arrête le chargement des données et on
                setLoading(false);
            }
        }
    }
    
    useEffect(() => {
        setUpdatedMarginSettingsValues(props.countrySettingsFormValues.margin_settings)
    }, [props.countrySettingsFormValues.margin_settings]);


    return loading ? (
        <Grid 
            container
            style={{
                position: 'fixed',
                top: 60,
                left: 0,
                width:"100vw",
                height:"90vh",
                backgroundColor: 'white',
                zIndex: 9999,
            }}
        >
            <CustomLoading loadingText={"Updating margin general settings..."} />
        </Grid>
    ) : (
        selectedCountryId !== undefined ? (
            <Grid item display="flex" flexDirection="column" justifyContent="space-between">
                <Grid item >
                    <FormControl>
                        {
                            confirmDialogOpen ?  (
                                <ConfirmDialog 
                                    title="Are you sure you want to save these margin settings?"
                                    description="These changes will impact all margins with 'TO BE CREATED' status."
                                    mode="Submit"
                                    yesButtonText="Save"
                                    open={confirmDialogOpen}
                                    setOpen={setConfirmDialogOpen}
                                    onConfirm={()=> {handleSubmit("Submit")}}
                                />
                            ) : null
                        }
                        <Grid container spacing={2}>
                            {Object.keys(updatedMarginSettingsValues).map((key, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index} display="flex">
                                    <TextField
                                        size="small"
                                        label={key === "daily_it_costs" ? "Daily IT Costs" : key.replace(/_/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase())}
                                        name={key}
                                        sx={{ my: 1 }}
                                        required={updatedMarginSettingsValues[key] === null || updatedMarginSettingsValues[key] === undefined}
                                        fullWidth
                                        value={updatedMarginSettingsValues[key] === null ? '' : updatedMarginSettingsValues[key]}
                                        onChange={handleChangeLocally}
                                        InputProps={{
                                            endAdornment: 
                                                <InputAdornment position="end">
                                                    {countrySettingsFormValues.currency.symbol}
                                                </InputAdornment>
                                        }}
                                        error={!updatedMarginSettingsValues[key] && updatedMarginSettingsValues[key] !== 0} 
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormControl>
                </Grid>
                <Grid item sx={{width:"100%", height:"100%"}} display="flex" justifyContent="flex-end" alignItems="flex-end" mb={2}>
                    <Button
                        disabled={!unsavedChanges}
                        onClick={()=>{setConfirmDialogOpen(true)}}
                    >
                        Save margin settings
                    </Button>
                </Grid>
            </Grid>
        ) : null
    )

}