/*
c'est le composant enfant de PreventionPlanMgt.js
il faut etre soit un business, soit un specific soit un admin pour pouvoir accéder à ce dialog

dans ce dialog, on recoit tous les engagements 
        sur le client du pp selectionné
        avec un statut si la engagement est assigné au pp selecionné

on peut donc voir les engagements qui sont assignés et voir si le consultant firstEngagement bien signé 
on peut voir les engagements qui ne sont pas assignés et donc les assigner au pp selectionné
        il est possible de selectionner plusieurs à la fois en filtrant et en cliquant sur Assign all

*/

import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Chip,
  List,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  ListItem,
  Tooltip,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import {
  useGetPreventionPlanFilesMutation,
  useGetEngagementsPreventionPlanAssignmentMutation,
  useSubmitPreventionPlanAssignmentMutation,
  useRemovePreventionPlanAssignmentMutation,
  
} from "../../api/eStarterApi";
import { CustomLoading } from "../../components/CustomLoading";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import moment from "moment";


export function DialogPreventionPlanMgtAssignment(props) {
  //changement du state
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [checkAssignment, setCheckAssignment] = useState([]);
  const [assignAll, setAssignAll] = useState(false);
  const [search, setSearch] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedEngagementForDeletion, setSelectedEngagementForDeletion] = useState(null);
  
  //information du store
  const currentPreventionPlanFile = useSelector((state) => state.selectedPreventionPlanFile, _.isEqual);
  
  const engagementsPreventionPlanLoading = useSelector((state) =>
  state.api.mutations["prevention_plan_assignments"]
  ? state.api.mutations["prevention_plan_assignments"].status !== "fulfilled"
  : true,
  );
  
  const prevention_plan_assignments = useSelector(
    (state) =>
    state.api.mutations["prevention_plan_assignments"]
    ? state.api.mutations["prevention_plan_assignments"].status === "fulfilled"
    ? state.api.mutations["prevention_plan_assignments"].data
    : []
    : [],
    _.isEqual,
  );
    
    //api
    const dispatch = useDispatch();
    const [postSubmitPreventionPlanAssignment] = useSubmitPreventionPlanAssignmentMutation();
    const [getPreventionPlanFiles] = useGetPreventionPlanFilesMutation({ fixedCacheKey: "prevention_plan_files" });
    const [getEngagementsPreventionPlanAssignment] = useGetEngagementsPreventionPlanAssignmentMutation({
      fixedCacheKey: "prevention_plan_assignments",
    });
    const [removePreventionPlanAssignment] = useRemovePreventionPlanAssignmentMutation();
    
    //fermeture du dialog
    const handleClose = () => {
      props.setOpenDialog(false);
    };
    
    //changement du filtre
    const handleSearchChange = (event) => {
      handleAssignAll(false);
      setSearch(event.target.value);
    };
    const handleClearClick = () => {
      setSearch("");
      handleAssignAll(false);
    };
    
    //navigation entre les engagements assignés au pp ou non
    const handleTabChange = (e, newValue) => {
      setTab(newValue);
    };
    
    // selection de la engagement pour l'assigner au pp
    const handleAssign = (e, engagement_id) => {
      handleAssignAll(false);
    setCheckAssignment({ ...checkAssignment, [engagement_id]: e.target.checked });
  };
  
  //RETIRER L'ASSIGNATION D'UN PP
  const handleOpenConfirmationDialog = (engagement_id) => {
    setConfirmDialogOpen(true);
    setSelectedEngagementForDeletion(engagement_id);
  };
  const fetchAssignments = useCallback(async () => {
    try {
      await getEngagementsPreventionPlanAssignment({
        prevention_plan_id: currentPreventionPlanFile.id,
        customer_name: currentPreventionPlanFile.customer_name,
        remove_closed: false,
      });
      // Update state or perform other actions with the fetched data
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  }, [currentPreventionPlanFile.id, currentPreventionPlanFile.customer_name, getEngagementsPreventionPlanAssignment]);

  useEffect(() => {
    fetchAssignments().catch(console.error);
  }, [fetchAssignments]);

  const handleUnassign = async (engagement_id) => {
    let resultApi = null
    setLoading(true);
    try {
      resultApi = await removePreventionPlanAssignment({ engagement_id: engagement_id }).unwrap();
      // Call fetchAssignments after removing assignment
      await fetchAssignments();
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultApi.status,
          snackbarMessage: resultApi.msg,
        },
      });
    } catch (error) {
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
        },
      });
      console.log("error", error)
    } finally {
      await getPreventionPlanFiles({ id: "ALL", case: "Management" }).unwrap();
      setLoading(false);
    }
  };
  
  // selection de tous les engagements filtrés
  const handleAssignAll = (value) => {
    let newCheckAssignment = { ...checkAssignment };
    setAssignAll(value);
    prevention_plan_assignments.filter(
      (engagement) =>
        engagement.prevention_plan_status === "NOT ASSIGNED TO THIS" &&
        (search === "" ||
          engagement.project_name?.toLowerCase().includes(search.toLowerCase()) ||
          engagement.consultant_full_name?.toLowerCase().includes(search.toLowerCase()) ||
          engagement.prestation_site?.toLowerCase().includes(search.toLowerCase())),
    ).forEach(
      (engagement) =>
        (newCheckAssignment[engagement.engagement_integration_key] = value),
    );
    setCheckAssignment(newCheckAssignment);
  };

  // fonction pour avoir tous les engagements qui ont été checkés (dans le cas où on selectionne une engagement et apres on la deselectionne)
  const getSelectedEngagementIds = () => {
    return Object.entries(checkAssignment)
      .filter(([id, isChecked]) => isChecked)
      .map(([id]) => id);
  };

  // sauvegarde de l'assignement
  const handleSubmit = async (action) => {
    setLoading(true);
    try {
      let resultApi = null;
      let submitCase = null;
      //on concatene tous les engagements dans le body de l'api
      //on change le statut à READY TO BE SIGNED donc on demande au consultant une signature.
      // à noter que si la engagement avait déjà un assignement, il sera écraser et on redemandera une signature
      // en effet, l'api upsert les données dans estarterPPAssignment et l'id est l'id de l'engagement (pour en avoir qu'un par engagement)
      let preventionPlanAssignments = {
        prevention_plan_id: currentPreventionPlanFile.id,
        engagement_ids: getSelectedEngagementIds(),
        prevention_plan_status: "READY TO BE SIGNED",
      };
      if (action === "Save") {
        submitCase = "Save"
      }
      resultApi = await postSubmitPreventionPlanAssignment({prevention_plan_assignment: preventionPlanAssignments, case: submitCase}).unwrap();
      //on refresh les données
      getPreventionPlanFiles({ id: "ALL", case: "Management" });
      // on notifie que tout s'est bien passé
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: resultApi.status,
          snackbarMessage: resultApi.msg,
        },
      });
      props.setOpenDialog(false);
    } catch (error) {

      //traitement des erreurs
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return loading || engagementsPreventionPlanLoading ? ( //affichage de la barre de chargement
    <Dialog open={props.openDialog} maxWidth="md" fullWidth>
      <CustomLoading
        loadingText={engagementsPreventionPlanLoading ? "Loading..." : "Saving..."}
      />
    </Dialog>
  ) : (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Typography variant={isSmallScreen ? "h5" : "h3"} sx={{ mt: 2 }}>
          Assign Prevention Plan
        </Typography>
        <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ mt: 2 }}>
          {currentPreventionPlanFile.prevention_plan_name}
        </Typography>

        <Box>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            alignItems="center"
            justifyContent="center"
          >
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab 
                label={isSmallScreen ? "Assigned" : "Assigned Prevention Plans"} 
                wrapped 
                value="1" 
                sx={isSmallScreen ? { flex: '1' } : {}}
              />
              {moment(currentPreventionPlanFile.validity_date, "YYYY-MM-DD").isAfter(moment(), "day") ?
              <Tab
              label={isSmallScreen ? "To Assign" : "Prevention Plans to assign"}
              wrapped
              value="2"
              disabled={moment(currentPreventionPlanFile.validity_date, "YYYY-MM-DD").isAfter(moment(), "day") ? false : true}
              sx={isSmallScreen ? {flex:'1'} : {}}
            />
              : null
              }
            </Tabs>
          </Box>
          <Box mt={2}>
            <Grid 
              container 
              alignItems="center" 
              sx={isSmallScreen ? {flexDirection:"column"} : {}}>
              <Grid item xs={9} sm={9} md={9}>
                {/* filtre sur les engagements */}
                <TextField
                  label="Search"
                  size="small"
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        sx={{
                          visibility: search !== "" ? "visible" : "hidden",
                        }}
                        size="small"
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item >
                {tab === "2" ? (
                  <Button
                    onClick={() => handleAssignAll(!assignAll)}
                    color="secondary"
                  >
                    {assignAll ? "Reset all" : "Assign all"}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ m: 1, height: 250, overflow: "auto" }}>
            <List>
              {/* on affiche tous les engagements du client concerné */}
              {prevention_plan_assignments.filter(
                  (engagement) =>
                    search === "" ||
                    engagement.project_name?.toLowerCase().includes(search.toLowerCase()) ||
                    engagement.consultant_full_name?.toLowerCase().includes(search.toLowerCase()) ||
                    engagement.prestation_site?.toLowerCase().includes(search.toLowerCase())
                )
                .sort((firstEngagement, secondEngagement) => {
                  //________________________________________________________________________________________ on trie par engagement active, puis par customersite, puis par non de projet
                  if (
                    firstEngagement.engagement_status === "Active" &&
                    secondEngagement.engagement_status !== "Active"
                  ) {
                    return -1;
                  } else if (
                    firstEngagement.engagement_status !== "Active" &&
                    secondEngagement.engagement_status === "Active"
                  ) {
                    return 1;
                  } else if (firstEngagement.prestation_site === currentPreventionPlanFile.customer_site) {
                    return -1;
                  } else {
                    // Si AltenSite est égal ou non défini, trier par Project_Name
                    if (
                      firstEngagement.prestation_site === secondEngagement.prestation_site ||
                      !firstEngagement.prestation_site ||
                      !secondEngagement.prestation_site
                    ) {
                      return firstEngagement.project_name.localeCompare(secondEngagement.project_name);
                    } else {
                      return firstEngagement.prestation_site.localeCompare(secondEngagement.prestation_site);
                    }
                  }
                })
                .filter((engagement) =>
                  tab === "1"
                    ? engagement.prevention_plan_status !== "NOT ASSIGNED TO THIS"
                    : engagement.prevention_plan_status === "NOT ASSIGNED TO THIS",
                ) //en fonction de la navigation on affiche les engagements assignés au pp selectionné ou non
                .map((engagement, index) => (
                  <ListItem 
                    key={index} 
                    style={
                      isSmallScreen
                        ? 
                        {
                          backgroundColor: index % 2 === 0
                            ? listItemStyles.backgroundColorEven
                            : listItemStyles.backgroundColorOdd,
                        }
                        :
                        {}
                    }
                  >
                    <Grid container display="flex" alignItems="center" >
                      <Grid container sx={{width:'80%'}} spacing={0.5}>
                        {/* {tab === "1" ? (
                          <Chip
                            label={engagement.prevention_plan_status}
                            color={engagement.prevention_plan_status?.replace(/ /g, "")}
                            size="small"
                            sx={{ mr: 1 }}
                          />
                        ) : null} */}
                        <Grid item>
                          <Tooltip title={`Prestation site: ${engagement.prestation_site}`}>
                            <Chip
                              label={engagement.prestation_site
                                ? engagement.prestation_site.includes('/')
                                  ? engagement.prestation_site.split('/')[1]
                                  : engagement.prestation_site.split(/ - | \//).pop()
                                : "UNDEFINED"}
                              icon={<LocationCityIcon/>}
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        {/* <Tooltip
                          title={`Engagement Status: ${engagement.engagement_status}`}
                        >
                          <Chip
                            label={engagement.engagement_status}
                            size="small"
                            color={
                              engagement.engagement_status === "Active"
                                ? "success"
                                : "default"
                            }
                            sx={{ mr: 1 }}
                          />
                        </Tooltip> */}
                        <Grid item>
                          <Tooltip title={`Consultant: ${engagement.consultant_full_name}`}>
                            <Chip
                              label={engagement.consultant_full_name}
                              icon={<PersonIcon />}
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={`Project name: ${engagement.project_name}`}>
                            <Chip
                              sx={{maxWidth:150}}
                              label={
                                <Typography variant='caption'>
                                  {engagement.project_name}
                                </Typography>
                              }
                              icon={<AttachFileIcon/>}
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {tab === "1" ? (
                        <Box 
                          sx={isSmallScreen ? {width:'100%'} : {}} 
                          mt={isSmallScreen ? 1 : {}}
                          display='flex' 
                          alignItems='center' 
                          justifyContent={isSmallScreen ? 'flex-end' : {}}
                        >
                          <Chip
                            label="Assigned"
                            size="small"
                            icon={<AssignmentIndIcon />}
                            color="secondary"
                          />
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleOpenConfirmationDialog(engagement.engagement_integration_key)}
                          >
                            <DeleteIcon 
                              color="error" 
                              size="small" 
                            />
                          </IconButton>
                          {confirmDialogOpen && selectedEngagementForDeletion === engagement.engagement_integration_key ? (
                            <ConfirmDialog
                              title="Are you sure you want to detach this prevention plan?"
                              description='By detaching this prevention plan from this engagement, its status will be set to "TO BE ASSIGNED" and you will need to assign a new prevention plan.'
                              mod="Detach"
                              yesButtonText="Detach"
                              open={confirmDialogOpen}
                              setOpen={setConfirmDialogOpen}
                              onConfirm={() => handleUnassign(engagement.engagement_integration_key.toString())}
                            />
                          ) : null }
                        </Box>
                      ) : (
                        <Grid container
                          sx={{width:'fit-content'}}
                          justifyContent={isSmallScreen ? "flex-end" : "flex-start"}
                        >
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    checkAssignment[
                                      engagement.engagement_integration_key
                                    ] || false
                                  }
                                  onChange={(e) =>
                                    handleAssign(
                                      e,
                                      engagement.engagement_integration_key,
                                    )
                                  }
                                  
                                  p={0}
                                  m={0}
                                  color="secondary"
                                  size="small"
                                />
                              }
                              label="Assign"
                              sx={{margin:0, padding:0}}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {tab === "1" ? null : (
          <Button
            onClick={() => handleSubmit("Save")}
            autoFocus
            color="success"
            disabled={getSelectedEngagementIds().length === 0}
          >
            {" "}
            Save assignment
          </Button>
        )}
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const listItemStyles = {
  backgroundColorEven: "#f4f4f4",
  backgroundColorOdd: "#f9f9f9",
};