// test si c'est en local ou non
const REDIRECT_URI = process.env.REACT_APP_MSAL_REDIRECT_URL;
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export const msalConfig = {
  auth: {
    clientId: "e7512556-a757-47a2-858c-0dfd22492fc3",
    authority:
      "https://login.microsoftonline.com/9bc3d1cd-55ca-4e13-b5a2-a9e9deaeba3f",
    redirectUri: isLocalhost
      ? "http://localhost:3000"
      : REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.

export const loginRequest = {
  scopes: ["api://e7512556-a757-47a2-858c-0dfd22492fc3/azurefunction.login"],
};
