/*
c'est le composant enfant de Project.js
il est le parent de EngagementBrief et PrenventionPlan 

tous les utilisteurs qui ont accès à l'application (avec au minimumm le role estarter_Limited) ont accès à cette page

dans ce composant, on affiche un projet avec ces engagements (s'il en a), le statut des EB et des PP

   si on clique sur le statut de l'engagement brief, on charge le composant EngagmentBrief.js (avec le changement d'url avec Link de react-router-dom)

   si on clique sur le statut du PP, l'affichage dépend du statut: 
        si le statut = TO BE ASSIGNED ou NOT APPLICABLE
            si c'est le consultant, rien ne se passe
            si ce n'est pas le consultant, on affiche le dialog DialogPPAssignment pour assigner l'engagement concerné à un pp
        
        si le statut n'est pas TO BE ASSIGNED ou NOT APPLICABLE

            on affiche le composant components/PreventionPlan pour l'affichage des détails du plan de prévention (notamment les fichiers) et pour la signature du consultant

*/

import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  List,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Badge,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AssignementIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import NumbersIcon from '@mui/icons-material/Numbers';
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "underscore";
import { useSelector } from "react-redux";
import { DialogPreventionPlanAssignment } from "../dialogs/DialogPreventionPlanAssignment";
import { truncateText } from "../../commonFunction";
import MarginStatusContext from "../../contexts/MarginStatusContext";


const ProjectList = (props) => {
  const { marginStatus } = useContext(MarginStatusContext);
  const project = props.project;
  const filterChange = props.filterChange;
  const onFilterChange = props.onFilterChange
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const currentYear = moment().year()
  const contextMatch = marginStatus.filter((margin) => (margin.id === project.project_id && margin.year === currentYear.toString()))
  const contextNewStatus = contextMatch.length > 0 ? contextMatch[0].status : null;
  //changement du state
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEngagementIntegrationKey, setCurrentEngagementIntegrationKey] = useState(null);

  
  // store informations
  const currentUser = useSelector(
    (state) =>
    state.api.mutations["UserInfos"]
    ? state.api.mutations["UserInfos"].status === "fulfilled"
    ? state.api.mutations["UserInfos"].data
    : []
    : [],
    _.isEqual,
  );


  const projectBusinessManagerList = project.rls_project_business_manager === null ? [] : project.rls_project_business_manager
  

  const hasMarginAccess =
    (
      currentUser.user_role === "Admin" ||
      (currentUser.user_role === "Business" &&(projectBusinessManagerList.length > 0 &&
        projectBusinessManagerList.includes(Number(currentUser.id)))
      )
  );

  useEffect(() => {
    if (filterChange) {
      setOpen(false);
      onFilterChange(false);
    }
  }, [filterChange, onFilterChange]);

  //changement du state de l'accordéon
  const handleToggleEngagements = () => {
    setOpen(!open);
  };
  
  return (
    <Accordion expanded={open} onChange={handleToggleEngagements} elevation={0} sx={{ border: '1px solid lightgray'}}>
      {/* affichage du dialog pour assigner un plan de prévention */}
      {openDialog ? (
        <DialogPreventionPlanAssignment
          engagement_integration_key={currentEngagementIntegrationKey}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      ) : null}

      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{userSelect: 'text'}}>
        {/* _______________________________________________________________________________________________PROJECT GLOBAL INFORMATION*/}
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item maxWidth={"80%"}>
          <Grid item>
            <Typography variant={isSmallScreen ? "subtitle2" : "h6"} sx={{ mb: 1 }}>
              {" "}
              {project.project_id} | {project.project_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} mb={1}>
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
              <Tooltip title={`Customer's name: ${project.customer_name} `}>
                <Chip 
                  label={<Typography variant='caption' noWrap>
                    {truncateText(project.customer_name, isSmallScreen ? 20 : {})}
                </Typography>}
                  size="small" 
                  color="primary"
                  />
              </Tooltip>
                {project.engagements.length > 0 ? (
                <Tooltip title={`RC: ${project.engagements[0].project_commercial_owner_name}`}>
                {/* <Tooltip title={`RC: ${project.project_commercial_owner_name}`}> */}
                  <Chip
                    label={project.engagements[0].project_commercial_owner_name}
                    // label={project.project_commercial_owner_name}
                    icon={<SupervisorAccountIcon />}
                    size="small"
                    />
                </Tooltip>
              ) : null}
              {isSmallScreen ? null : (
                <Chip
                label={project.project_department}
                icon={< LocationOnIcon/>}
                  size="small"
                />
                )}
              {isSmallScreen ? null : (
                <Chip
                label={`${project.engagements.length} Engagement${
                  project.engagements.length > 1 ? "s" : ""
                }`}
                size="small"
                />
                )}
              
                <Badge
                  badgeContent={
                    project.engagements.filter(
                      (engagement) =>
                      engagement.engagement_brief_status !== "SIGNED" &&
                      engagement.engagement_brief_status !== "NOT APPLICABLE",
                      ).length
                    }
                    color="error"
                >
                  <Chip
                    label="Engagement Brief"
                    icon={<AssignementIcon size="small" />}
                    size="small"
                    />
                </Badge>
                <Badge
                  badgeContent={
                    project.engagements.filter(
                      (engagement) =>
                      engagement.prevention_plan_status !== "SIGNED" &&
                      engagement.prevention_plan_status !== "NOT APPLICABLE",
                      ).length
                    }
                  color="error"
                >
                  <Chip
                    label="Prevention Plan"
                    icon={<AssignmentTurnedInIcon size="small" />}
                    size="small"
                    />
                </Badge>
                {/* </Box> */}
              </Box>
              </Grid>
            </Grid>

            {/* {hasMarginAccess ? */}
            {hasMarginAccess ? 
              <Grid item style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight: "10px"}}>
                <Badge
                  // TO BE CHANGED WITH MARGIN DIFFERENCES RETURN
                  badgeContent={contextNewStatus || !project.changes_detected || project.current_year_margin_status === "TO BE CREATED" ? "" : "!"}
                  color={contextNewStatus || !project.changes_detected || project.current_year_margin_status === "TO BE CREATED"  ? "default" : "error"}
                >
                  <Link
                    to={`/margin/${project.project_id}/${currentYear}`}
                    >
                    <Button
                    // TO BE CHANGED WITH CURRENT YEAR MARGIN STATUS
                    disabled={false} // TODO SET ADMIN + RCnX
                    color={contextNewStatus ? contextNewStatus?.replace(/ /g, "") 
                      : ((!project.changes_detected || project.current_year_margin_status === "TO BE CREATED") 
                      ? project.current_year_margin_status : project.current_year_margin_status = "TO BE UPDATED")?.replace(/ /g, "" )}
                      variant="contained"
                      sx= {{boxShadow:"none", fontSize:"12px"}}
                      size="small"
                    >
                      {`Margin: ${contextNewStatus ? contextNewStatus : !project.changes_detected || project.current_year_margin_status === "TO BE CREATED" ? project.current_year_margin_status : project.current_year_margin_status = "TO BE UPDATED"}`}

                    </Button>
                  </Link>
                </Badge>
              </Grid> 
            : null }
        </Grid>
      </AccordionSummary>
      {/* ____________________________________________________________________________________________________________________________ FIN PROJECT GLOBAL INFORMATION*/}

      {project.engagements ? (
        <AccordionDetails
          sx={{ m:0 }}
        >
          {/* ____________________________________________________ PROJECT DETAILS : engagement INFORMATION*/}
          <List>
            <TableContainer sx={{borderRadius:1}}>
              <Table>
                <TableBody>
                  {project.engagements.slice()
                    //on trie sur les statuts actifs en premier et par le nom du consultant (affichage en 1er les statuts actifs, puis les consultants alphabetiquement)
                    .sort((firstEngagement, secondEngagement) => {
                      if (
                        firstEngagement.engagement_brief_status === "Active" &&
                        secondEngagement.engagement_brief_status !== "Active"
                      ) {
                        return -1;
                      } else if (
                        firstEngagement.engagement_brief_status !== "Active" &&
                        secondEngagement.engagement_brief_status === "Active"
                      ) {
                        return 1;
                      } else {
                        return firstEngagement.consultant_full_name.localeCompare(secondEngagement.consultant_full_name);
                      }
                    })
                    .map((
                      engagement, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? listItemStyles.backgroundColorEven
                              : listItemStyles.backgroundColorOdd,
                        }}
                      >
                        <TableCell>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              style={isSmallScreen ? {minWidth: '100%'} : { maxWidth: 70 }}
                            >
                              {isSmallScreen ? null : 
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {/* affichage du statut de la engagement en 1er */}
                                  <Tooltip
                                    title={`Engagement Status: ${engagement.engagement_brief_status}`}
                                  >
                                    <Chip
                                      label={engagement.engagement_status}
                                      size="small"
                                      color={
                                        engagement.engagement_status === "Active"
                                          ? "success"
                                          : "default"
                                      }
                                    />
                                  </Tooltip>
                                </Box>
                              }
                            </Grid>
                            <Grid item xs={10} sm={10} md={7}>
                              {" "}
                              {/* affichage des dates, consultant, site client, la description de la engagement*/}
                              {isSmallScreen ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexWrap="wrap"
                                >
                                  <Chip
                                    label={engagement.consultant_full_name}
                                    icon={<PersonIcon />}
                                    size="small"
                                  />
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexWrap="wrap"
                                >
                                  <Chip
                                    label={`${moment(
                                      engagement.engagement_start_date,
                                    ).format("DD MMM YY")} - ${moment(
                                      engagement.engagement_end_date,
                                    ).format("DD MMM YY")}`}
                                    icon={<CalendarMonthIcon />}
                                    size="small"
                                    sx={{ mr: 1 }}
                                  />
                                  <Chip
                                    label={engagement.consultant_full_name}
                                    icon={<PersonIcon />}
                                    size="small"
                                    sx={{ mr: 1 }}
                                  />
                                  <Tooltip title={`Prestation site : ${engagement.prestation_site}`}>
                                    <Chip
                                      label={truncateText(engagement.prestation_site, 20)}
                                      icon={<LocationCityIcon/>}
                                      size="small"
                                      sx={{ mr: 1 }}
                                    />
                                  </Tooltip>
                                  <Tooltip title={`Engagement number: ${engagement.engagement_integration_key}`}>
                                    <Chip
                                      label={engagement.engagement_integration_key}
                                      icon={<NumbersIcon/>}
                                      size="small"
                                    />
                                  </Tooltip>
                                </Box>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              {" "}
                              {/*________________________________________________________________________________________ affichage des boutons de statut des EB et PP*/}
                              <Grid container justifyContent="flex-end" display={isSmallScreen && "block"}>
                                <Grid item xs={12} sm={12} md={6} >
                                  <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row-reverse" : "column"}
                                    justifyContent={isSmallScreen ? "space-between" : "center"}
                                    alignItems="center"
                                    mt={isSmallScreen ? 2 : 1}
                                  >                                    
                                    {/* on bloque le bouton si l'EB n'est pas encore créé et que l'utilisateur = le consultant */}
                                    <Link
                                      to={
                                        engagement.engagement_brief_status === "TO BE CREATED"
                                          ? currentUser.id ===
                                            String(engagement.consultant_id)
                                            ? "#"
                                            : `/engagementbrief/${engagement.engagement_integration_key}`
                                          : `/engagementbrief/${engagement.engagement_integration_key}`
                                      }
                                    >
                                      <Button
                                        disabled={
                                          engagement.engagement_brief_status === "TO BE CREATED"
                                            ? currentUser.id ===
                                              String(engagement.consultant_id)
                                            : false
                                        }
                                        color={engagement.engagement_brief_status?.replace(
                                          / /g,
                                          "",
                                        )}
                                        variant="contained"
                                        sx= {{boxShadow:"none"}}
                                        size="small"
                                      >
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                          justifyContent="center"
                                          position="relative"
                                        >
                                          <Typography
                                            variant="caption"
                                            component="div"
                                          >
                                            {engagement.engagement_brief_status}
                                          </Typography>
                                        </Box>
                                      </Button>
                                    </Link>
                                    <Typography
                                      variant={ isSmallScreen ? "button" : "caption"}
                                      component="div"
                                      sx={{ textAlign: isSmallScreen ? "center" : "left" }}
                                    >
                                      {isSmallScreen ? "EB" : "Engagement Brief"}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row-reverse" : "column"}
                                    justifyContent={isSmallScreen ? "space-between" : "center"}
                                    alignItems="center"
                                    mt={ isSmallScreen ? 2 : 1 }
                                  >
                                    
                                    {/* on bloque le changement d'url si le statut = to be assigned ou not applicable */}
                                    <Link
                                      to={
                                        engagement.prevention_plan_status !== "TO BE ASSIGNED" &&
                                        engagement.prevention_plan_status !== "NOT APPLICABLE"
                                          ? `/preventionplan/${engagement.engagement_integration_key}`
                                          : "#"
                                      }
                                    >
                                      <Button
                                        onClick={() => {
                                          setCurrentEngagementIntegrationKey(engagement.engagement_integration_key);
                                          setOpenDialog(
                                            engagement.prevention_plan_status ===
                                              "TO BE ASSIGNED" ||
                                              engagement.prevention_plan_status ===
                                                "NOT APPLICABLE",
                                          );
                                        }}
                                        disabled={
                                          engagement.prevention_plan_status ===
                                            "TO BE ASSIGNED" ||
                                          engagement.prevention_plan_status === "NOT APPLICABLE"
                                            ? currentUser.id ===
                                              String(engagement.consultant_id)
                                            : false
                                        }
                                        color={engagement.prevention_plan_status?.replace(
                                          / /g,
                                          "",
                                        )}
                                        variant="contained"
                                        size="small"
                                        sx= {{boxShadow:"none"}}
                                      >
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                          alignItems="center"
                                          justifyContent="center"
                                          position="relative"
                                        >
                                          <Typography
                                            variant="caption"
                                            component="div"
                                          >
                                            {engagement.prevention_plan_status}
                                          </Typography>
                                        </Box>
                                      </Button>
                                    </Link>
                                    <Typography
                                      variant={isSmallScreen ? "button" : "caption"}
                                      component="div"
                                      sx={{ textAlign: isSmallScreen ? "center" : "left" }}
                                    >
                                      {isSmallScreen ? "PP" : "Prevention Plan"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};
export default ProjectList;

const listItemStyles = {
  backgroundColorEven: "#f4f4f4",
  backgroundColorOdd: "#f9f9f9",
};