import React, { useMemo, useState } from "react";
import moment from "moment";
import {
    FormControl,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Button,
    TextField,
    Autocomplete,
  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogBankHolidays from "../dialogs/DialogBankHolidays";
  
export default function MarginBankHolidaysSettings(props) {
    const selectedCountryId = props.selectedCountryId;
    const setInitializeData = props.setInitializeData;
    const countriesSettingsFormValues = props.countriesSettingsFormValues;
    const countrySettings = countriesSettingsFormValues?.find(country => country.id === selectedCountryId);
    const countryHolidays = countrySettings?.holidays;
    const [editMod, setEditMod] = useState("Add");
    const [openDialogBankHolidays, setOpenDialogBankHolidays] = useState(false);
    const [selectedHolidayForDeletion, setSelectedHolidayForDeletion] = useState(null);
    const [filteredBusinessUnit, setFilteredBusinessUnit] = useState([]);
    const [filteredYear, setFilteredYear] = useState([]);
    const [initialYear, setInitialYear] = useState(true)

    const years = []

    if (initialYear) {
        const currentYear = moment().year().toString();
        setFilteredYear([currentYear]);
        setInitialYear(false);
    };

    // Prepare list of names to display
    let businessUnitsNames = []
    countryHolidays["business_unit"].forEach((business_unit) => {
        businessUnitsNames.push(business_unit.name)
    })
    businessUnitsNames.push("National")

    //   useMemo to avoid recalculation at every country change
    const groupedHolidays = useMemo(() => {
        // initiate empty objects to display countryHolidays
        const holidaysList = {};

        // Add general holidays
        countryHolidays?.general?.forEach(holiday => {
            if (!holidaysList[holiday.day]) {
                holidaysList[holiday.day] = { description: holiday.description, businessUnits: ['General'] };
            } else {
                // If the date doesn't exist in the holidaysList, we then push it
                if (!holidaysList[holiday.day].businessUnits.includes('General')) {
                    holidaysList[holiday.day].businessUnits.push('General');
                }
            }
        });

        // Add buinessUnits bank holidays
        countryHolidays?.business_unit?.forEach(businessUnit => {
            businessUnit.days.forEach(holiday => {
                if (!holidaysList[holiday.day]) {
                    holidaysList[holiday.day] = { description: holiday.description, businessUnits: [businessUnit.name] };
                } else {
                    holidaysList[holiday.day].businessUnits.push(businessUnit.name);
                    // Ensure only one business unit name :
                    holidaysList[holiday.day].businessUnits = [...new Set(holidaysList[holiday.day].businessUnits)];
                }
            });
        });
        
        // return results
        return Object.entries(holidaysList).map(([day, { description, businessUnits }]) => ({
            day,
            description,
            businessUnits: businessUnits.join(', '),
        }));
    }, [countryHolidays]);
    
    // Extract years for filter
    groupedHolidays.forEach((holiday) => {
        const holidayYear = holiday.day.slice(0,4)
        if (!years.includes(holidayYear)){
            years.push(holidayYear)
        }
    })
    // Convert the name from general to national 
    const businessUnitsForFiltering = Array.isArray(filteredBusinessUnit) 
    ? filteredBusinessUnit.map(unit => unit === "National" ? "General" : unit) 
    : [];

    // filtered holidays
    const filteredHolidays = groupedHolidays.filter(holiday => {
        if (filteredYear.length === 0 && filteredBusinessUnit.length === 0) {
            return groupedHolidays;
        } else {
            const holidayYear = moment(holiday.day).format("YYYY").toString();
            const isYearMatch = filteredYear.length === 0 || filteredYear.includes(holidayYear);
            const isBusinessUnitMatch = businessUnitsForFiltering.length === 0 || businessUnitsForFiltering.some(unit => holiday.businessUnits.includes(unit));
            return isYearMatch && isBusinessUnitMatch;
        }
    });

    return(
        <Grid container overflow="auto">
            <FormControl fullWidth>
                <Grid container display="flex" alignItems="center" spacing={2}>
                    <Grid item>
                        <Autocomplete
                            style={{width:"200px"}}
                            size="small"
                            options={years}
                            multiple
                            onChange={(e, newValue) => {setFilteredYear(newValue)}}
                            renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Choose a year"
                                  size="small"
                                />
                              )}
                        >
                        </Autocomplete>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            style={{width:"200px"}}
                            size="small"
                            options={businessUnitsNames}
                            multiple
                            onChange={(e, newValue) => setFilteredBusinessUnit(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a location"
                                    size="small"
                                />
                            )}
                        >
                        </Autocomplete>
                    </Grid>
                    <Grid item>
                        <Button
                            sx={{ marginBlock: 3 }}
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                setEditMod("Add")
                                setOpenDialogBankHolidays(true);
                            }}
                        >
                            + Add a new bank holiday
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
            {
                openDialogBankHolidays ? 
                (
                    <DialogBankHolidays 
                        editMod={editMod}
                        selectedCountryId={selectedCountryId}
                        countrySettings={countrySettings}
                        openDialog={openDialogBankHolidays}
                        setOpenDialog={setOpenDialogBankHolidays}
                        setInitializeData={setInitializeData}
                        selectedHolidayForDeletion={selectedHolidayForDeletion}
                        setSelectedHolidayForDeletion={setSelectedHolidayForDeletion}
                    />
                ) : null
            }
            <FormControl fullWidth>
                <TableContainer >
                    <Table aria-label="simple table" style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"> Date </TableCell>
                                <TableCell align="center"> Description </TableCell>
                                <TableCell align="center"> Type </TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredHolidays.sort((a, b) => new Date(a.day) - new Date(b.day)).map((holiday, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center"> {moment(holiday.day).format("DD MMMM YYYY")} </TableCell>
                                    <TableCell align="center"> {holiday.description} </TableCell>
                                    <TableCell align="center"> {holiday.businessUnits === "General" ? "National": holiday.businessUnits} </TableCell>
                                    <TableCell align="center"> 
                                        <DeleteIcon 
                                            color = "error"
                                            cursor="pointer" 
                                            onClick={() => {
                                                setEditMod("Delete")
                                                setSelectedHolidayForDeletion(holiday)
                                                setOpenDialogBankHolidays(true);
                                        }}/> 
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </FormControl>
        </Grid>
    );
}
