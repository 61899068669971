/* 
      ___________________________________________________________ STORE.JS

      fichier de configuration du store

      les api sont configurées dans le dossier api/

*/

import { configureStore, createSlice } from "@reduxjs/toolkit";
import { apiCreateFiles, eStarterApi } from "./api/eStarterApi";

// REDUCER pour les notifications et leur message
export const snackbarInfo = createSlice({
  name: "snackbarInfo",
  initialState: {
    snackbarOpen: false,
    snackbarType: "success",
    snackbarMessage: "",
  },
  reducers: {
    setNotification: (state, action) => {
      state = {
        snackbarOpen: action.payload.snackbarOpen,
        snackbarType: action.payload.snackbarType,
        snackbarMessage: action.payload.snackbarMessage,
      };

      return state;
    },
  },
});

// REDUCER pour la selection d'un projet
export const selectedProject = createSlice({
  name: "selectedProjectReducer",
  initialState: [],
  reducers: {
    selectProject: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection d'un engagement
export const selectedEngagement = createSlice({
  name: "selectedEngagementReducer",
  initialState: [],
  reducers: {
    selectEngagement: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection d'un engagement brief
export const selectedEngagementBrief = createSlice({
  name: "selectedEngagementBriefReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectEngagementBrief: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection d'un engagement brief
export const selectedEngagementBriefFormParams = createSlice({
  name: "selectedEngagementBriefFormParamsReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectEngagementBriefFormParams: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection d'un plan de prévention
export const selectedPreventionPlanFile = createSlice({
  name: "selectedPreventionPlanFileReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectPreventionPlanFile: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection de la data d'une assignation de plan de prévention
export const selectedPreventionPlanAssignmentData = createSlice({
  name: "selectedPreventionPlanAssignmentDataReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectPreventionPlanAssignmentData: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// REDUCER pour la selection de la data d'une marge projet
export const selectedProjectMargin = createSlice({
  name: "selectedProjectMarginReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectProjectMargin: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
// REDUCER pour la selection d'un margin draft
export const selectedMarginDraftByUserId = createSlice({
  name: "selectedMarginDraftByUserIdReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectMarginDraftByUserId: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
// REDUCER pour la selection d'un Country Settings
export const selectedCountrySettings = createSlice({
  name: "selectedCountrySettingsReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectCountrySettings: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
// REDUCER pour la selection d'un Specific User
export const selectedSpecificUser = createSlice({
  name: "selectedSpecificUserReducer",
  initialState: [],
  reducers: {
    //select one and push to array
    selectSpecificUser: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

// configuration standard du store avec tous les reducers et les api
export const store = configureStore({
  reducer: {
    api: eStarterApi.reducer,
    apiCreateFiles: apiCreateFiles.reducer,
    snackbarInfo: snackbarInfo.reducer,
    selectedProject: selectedProject.reducer,
    selectedEngagement: selectedEngagement.reducer,
    selectedEngagementBrief: selectedEngagementBrief.reducer,
    selectedEngagementBriefFormParams: selectedEngagementBriefFormParams.reducer,
    selectedPreventionPlanFile: selectedPreventionPlanFile.reducer,
    selectedPreventionPlanAssignmentData: selectedPreventionPlanAssignmentData.reducer,
    selectedProjectMargin: selectedProjectMargin.reducer,
    selectedMarginDraftByUserId: selectedMarginDraftByUserId.reducer,
    selectedCountrySettings: selectedCountrySettings.reducer,
    selectedSpecificUser: selectedSpecificUser.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(eStarterApi.middleware)
      .concat(apiCreateFiles.middleware),
});
