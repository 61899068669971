import React, { useEffect } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

// notification pour tous les composants de l'application
export default function Notification(props) {
  const dispatch = useDispatch();
  const appLayout = props.appLayout;
  const snackbarInfo = useSelector((state) => state.snackbarInfo);

  const handleClose = (event, reason) => {
    dispatch({
      type: "snackbarInfo/setNotification",
      payload: {
        snackbarOpen: false,
        snackbarType: snackbarInfo.snackbarType,
        snackbarMessage: snackbarInfo.snackbarMessage,
      },
    });
  };
  useEffect(() => {
    if (appLayout) {
      dispatch({
        type: "snackbarInfo/setNotification",
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Get data successed",
        },
      });
    }
  }, [dispatch, appLayout]);

  return (
    <Snackbar
      open={snackbarInfo.snackbarOpen}
      onClose={handleClose}
      autoHideDuration={snackbarInfo.snackbarType === "error" ? 400000 : 3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        severity={
          snackbarInfo.snackbarType === ""
            ? "success"
            : snackbarInfo.snackbarType
        }
        onClose={handleClose}
      >
        <AlertTitle>{snackbarInfo.snackbarType?.toUpperCase()}</AlertTitle>
        {snackbarInfo.snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
