import React, { useEffect, useState, useRef } from "react";
import { Tab, Grid, Button, useMediaQuery, Typography, Autocomplete, TextField } from "@mui/material";
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { useSelector } from "react-redux";
import _ from "underscore";
import { CustomLoading } from "../components/CustomLoading";
import MarginSynthesis from "../projects/margins/components/MarginSynthesis";
import ConfirmDialog from "../components/dialog/ConfirmDialog";
import { useDispatch } from "react-redux";
import { useSubmitDraftMutation, useGetMarginDraftsByUserIdMutation } from "../api/eStarterApi";
import TeamDraft from "./components/TeamDraft";
// import Team from "../projects/margins/components/Team";

const MarginDrafts = React.memo((props) => {
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const filteredMarginDrafts = props.filteredMarginDrafts;
  const filteredCountrySettings = props.filteredCountrySettings;
  const handleCalculatorClose = props.handleCalculatorClose;
  const setInitializeDraftData = props.setInitializeDraftData;
  const setFilteredCountrySettings = props.setFilteredCountrySettings;
  
  const selectedMarginDraftTab = props.selectedMarginDraftTab;
  const setSelectedMarginDraftTab = props.setSelectedMarginDraftTab;
  const performTabChange = props.performTabChange;
  
  
  const [engagementTeamValues, setEngagementTeamValues] = useState(props.filteredMarginDrafts.team); 
    // const [moduleValue, setModuleValue] = useState();
    const [allDrafts, setAllDrafts] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [confirmDialogOpenOnCancel, setConfirmDialogOpenOnCancel] = useState(false);
    const [confirmDialogOpenTab, setConfirmDialogOpenTab] = useState(false);
    const [nextTab, setNextTab] = useState(null);
    const [initializeDraftTab, setInitializeDraftTab ] = useState(false)
    
    const [editableDraftIndex, setEditableDraftIndex] = useState(-1); 
    
    const [currentDraftName, setCurrentDraftName] = useState("");
    
    const [loading, setLoading] = useState(false);


    const [postSubmitDraft] = useSubmitDraftMutation();
    const [getMarginDraftsByUserId] = useGetMarginDraftsByUserIdMutation({ fixedCacheKey: "margin_drafts"});
    
    const marginDraftsByUserIdLoading = useSelector((state) => {
      const mutationStatus = state.api.mutations["margin_drafts"];
      return mutationStatus ? mutationStatus.status !== "fulfilled" : true;
    });
    
    const marginDraftsByUserId = useSelector((state) => {
      const mutationStatus = state.api.mutations["margin_drafts"];
      return mutationStatus
      ? mutationStatus.status === "fulfilled"
      ? mutationStatus.data
      : []
      : [];
      }, _.isEqual);
      
      const countriesSettings = useSelector(
        (state) =>
        state.api.mutations["countries_settings"]
        ? state.api.mutations["countries_settings"].status === "fulfilled"
        ? state.api.mutations["countries_settings"].data
        : []
        : [],
        _.isEqual,
      );
      
      const getCountriesSettingsStatus = useSelector((state) =>
      state.api.mutations["countries_settings"]
      ? state.api.mutations["countries_settings"].status
      : "loading",
    );
    
    let countriesNames = []
    if (getCountriesSettingsStatus === "fulfilled"){
      countriesNames = countriesSettings.map(item => item.id);
    }


    const handleCountryChange = (newValue) => {
      const matchingCountrySettings = countriesSettings.filter(
        (country) => country.id === newValue
      );
      setFilteredCountrySettings(matchingCountrySettings[0])
    };
    

    
  const handleTabChange = (event, newValue) => {
    if (unsavedChanges) {
      setNextTab(newValue);
      setConfirmDialogOpenTab(true);
    } else {
      performTabChange(newValue);
    }
  };
  

useEffect(() => {
  if (selectedMarginDraftTab && !marginDraftsByUserIdLoading) {
      setEngagementTeamValues(filteredMarginDrafts.team);
      setAllDrafts(marginDraftsByUserId[0].drafts);
  }
}, [selectedMarginDraftTab, filteredMarginDrafts.team, marginDraftsByUserId, marginDraftsByUserIdLoading]);


    const handleConfirmCloseTab = () => {
      setSelectedMarginDraftTab(nextTab);
      // setModuleValue(nextTab);
      setEngagementTeamValues(filteredMarginDrafts.team);
      setUnsavedChanges(false);
    };

    const handleSave = async () => {
      setLoading(true);
      
      try {
        let resultMarginDraft = null;
        const targetDraft = allDrafts.filter((draft) => draft.slot === selectedMarginDraftTab)
        const newSlotName = targetDraft[0].name
        const draftCountry = filteredCountrySettings.id
        let sendMarginDraft = {
          draft_slot: selectedMarginDraftTab,
          name: newSlotName,
          country: draftCountry,
          team: engagementTeamValues,
        };
    
        resultMarginDraft = await postSubmitDraft(sendMarginDraft).unwrap();
        
        getMarginDraftsByUserId();
        setUnsavedChanges(false);
        dispatch({
          type: "snackbarInfo/setNotification",
          payload: {
            snackbarOpen: true,
            snackbarType: resultMarginDraft.status,
            snackbarMessage: resultMarginDraft.msg,
          },
        });
      } catch (error) {
        //on affiche les erreurs
        dispatch({
          type: "snackbarInfo/setNotification",
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
            "An error occurred during saving. Please try again. Error: " +
            error.data.msg,
          },
        });
      } finally {
        setLoading(false);
        setInitializeDraftData(true);
      }
    };

    function isTeamCardComplete(card) {
        if ((card.worked_days === null || card.worked_days === "") || (card.sold_days === null || card.sold_days === "")) {
          return false;
        }
      
        // ES3 / SUBCONTRACTOR
        if (card.daily_purchase_rate !== null) {
          if (
            card.es3 === null || 
            ((card.daily_sale_rate === null) || card.daily_sale_rate === "") || 
            ((card.daily_purchase_rate === null) || card.daily_purchase_rate === "")
            ) {
            return false;
          }
        } 
        
        // CONSULTANT 
        else {
          if (
            ((card.daily_desk_costs === null) || card.daily_desk_costs === "") ||
            ((card.daily_fees === null) || card.daily_fees === "") ||
            ((card.daily_it_costs === null) || card.daily_it_costs === "") ||
            ((card.daily_sale_rate === null) || card.daily_sale_rate === "") ||
            ((card.annual_bonus === null) || card.annual_bonus === "") ||
            ((card.monthly_gross_salary === null) || card.monthly_gross_salary === "") ||
            ((card.monthly_fees === null) || card.monthly_fees === "") ||
            (card.office_presence === null || card.office_presence === "")
          ) {
            return false;
          }
        }
        return true;
      }

      function isTeamComplete(team) {
        let result = []
        team.forEach((card) => result.push(isTeamCardComplete(card)))
        return !result.includes(false);
      }

    const processMargin = (marginTeams) => {
        let engagementMarginResults = {es3SoldDays: 0, subcontractorsSoldDays: 0, consultantsSoldDays: 0, es3Revenues: 0, subcontractorsRevenues: 0, consultantsRevenues: 0, es3Costs: 0, subcontractorsCosts: 0, consultantsCosts: 0};

        let dailyAnnualBasis = filteredCountrySettings.margin_settings.day_annual_basis;
        let monthlyAnnualBasis = filteredCountrySettings.margin_settings.month_annual_basis;
        let chargesCoefficient = filteredCountrySettings.margin_settings.charges_coefficient;

        marginTeams.forEach((teamCard) => {
          let result = engagementMarginResults;
          if (!isTeamCardComplete(teamCard)) {
            return;
          }
      
            let rate = teamCard.daily_sale_rate;
            let purchaseRate = teamCard.daily_purchase_rate;
            let monthlyGrossSalary = teamCard.monthly_gross_salary;
            let annualBonus = teamCard.annual_bonus;
            let monthlyFees = teamCard.monthly_fees;
            let dailyFees = teamCard.daily_fees;
            let dailyITCosts = teamCard.daily_it_costs;
            let dailyDeskCosts = teamCard.daily_desk_costs;
            let officePresence = parseFloat(teamCard.office_presence);
          
          const employeeCosts = ((((monthlyGrossSalary * monthlyAnnualBasis) + annualBonus) * chargesCoefficient ) / dailyAnnualBasis) + dailyFees  + ((monthlyFees * 12) / dailyAnnualBasis);
          const officeCosts =  dailyITCosts + (dailyDeskCosts * (officePresence/100));
          const productionCostConsultant = employeeCosts + officeCosts;

          if (purchaseRate != null) {
            if (teamCard.es3 === "true") {
              result.es3Revenues += (rate * teamCard.sold_days);
              result.es3SoldDays += teamCard.sold_days;
              result.es3Costs += (purchaseRate * teamCard.worked_days);
            } else {
              result.subcontractorsRevenues += (rate * teamCard.sold_days);
              result.subcontractorsSoldDays += teamCard.sold_days;
              result.subcontractorsCosts += (purchaseRate * teamCard.worked_days);
            }
          } else {
            result.consultantsRevenues += (rate * teamCard.sold_days);
            result.consultantsSoldDays += teamCard.sold_days;
            result.consultantsCosts += (productionCostConsultant * teamCard.worked_days);
          }
        });  
        return engagementMarginResults;
      };
    
      
    
      const calculateMarginRates = (margin) => {
        let subcontractorsDailyRateAverage = 0;
        let es3DailyRateAverage = 0;
        let consultantDailyRateAverage = 0;
    
        let subcontractorsRevenues = 0;
        let es3Revenues = 0;
        let consultantsRevenues = 0;
    
        let subcontractorsMarginAverage = 0;
        let es3MarginAverage = 0;
        let consultantsMarginAverage = 0;
        
        let totalRate = 0;
        let totalRevenue= 0;
        let totalCost = 0;
        let totalMarginRate = 0;
    
        // DAILY RATE
        subcontractorsDailyRateAverage = margin.subcontractorsSoldDays > 0 ? (margin.subcontractorsRevenues / margin.subcontractorsSoldDays) : 0;
        es3DailyRateAverage = margin.es3SoldDays > 0 ? (margin.es3Revenues / margin.es3SoldDays) : 0;
        consultantDailyRateAverage = margin.consultantsSoldDays > 0 ? (margin.consultantsRevenues / margin.consultantsSoldDays) : 0;
    
        // REVENUE
        subcontractorsRevenues = margin.subcontractorsRevenues;
        es3Revenues = margin.es3Revenues;
        consultantsRevenues = margin.consultantsRevenues;
    
        // ENGAGEMENT MARGIN
        subcontractorsMarginAverage = (margin.subcontractorsRevenues - margin.subcontractorsCosts) / margin.subcontractorsRevenues * 100;
        es3MarginAverage = (margin.es3Revenues - margin.es3Costs) / margin.es3Revenues * 100;
        consultantsMarginAverage = (margin.consultantsRevenues - margin.consultantsCosts) / margin.consultantsRevenues * 100;
    
        // TOTAL
        totalRate = ((margin.subcontractorsRevenues + margin.es3Revenues + margin.consultantsRevenues) / (margin.subcontractorsSoldDays + margin.es3SoldDays + margin.consultantsSoldDays)).toFixed(2);
        totalRevenue = (subcontractorsRevenues + es3Revenues + consultantsRevenues).toFixed(2);
        totalCost = (margin.es3Costs + margin.subcontractorsCosts + margin.consultantsCosts).toFixed(2);
        totalMarginRate = ((totalRevenue - totalCost) / totalRevenue) * 100;
        return {
          subcontractorsDailyRateAverage,
          es3DailyRateAverage,
          consultantDailyRateAverage,
          subcontractorsRevenues,
          es3Revenues,
          consultantsRevenues,
          subcontractorsMarginAverage,
          es3MarginAverage,
          consultantsMarginAverage,
          totalRate,
          totalRevenue,
          totalCost,
          totalMarginRate,
        };
      }
    
      // PROCEED TO CALCULATE
    const engagementMargin = processMargin(engagementTeamValues, "engagementMargin");
    const engagementMarginResults = calculateMarginRates(engagementMargin);

    const handleDraftNameChange = (event) => {
      setCurrentDraftName(event.target.value);
    };
    
    const handleDraftNameKeyDown = (event, index) => {
      if (event.key === 'Enter') {
        handleDraftNameSave(index, event.target.value);
        setUnsavedChanges(true);
        event.preventDefault(); // Prevent form submission if within a form
      }
    };
    
    const handleDraftNameSave = (index, newName) => {
      const newDrafts = [...allDrafts];
      const updatedDraft = { ...newDrafts[index], name: newName };
      newDrafts[index] = updatedDraft;
      setAllDrafts(newDrafts);
      setEditableDraftIndex(-1); 
      setCurrentDraftName(""); 
    };

        
    
    return (marginDraftsByUserIdLoading || loading) ? (
        <CustomLoading loadingText="Loading..." />
        ) : (
          <>
            <TabContext value={selectedMarginDraftTab}>
                <TabList
                    value={selectedMarginDraftTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    >
                    {
                        allDrafts.length === 0 ? 
                        <CustomLoading loadingText="Initializing Draft Slots..." />
                    :
                        allDrafts.map((draft, index) => (
                          <Tab
                          key={index}
                          value={draft.slot}
                          label={
                            editableDraftIndex === index ? (
                              <input
                                ref={inputRef}
                                type="text"
                                value={currentDraftName}
                                onChange={handleDraftNameChange}
                                onKeyDown={(event) => handleDraftNameKeyDown(event, index)}
                                // onBlur={() => handleDraftNameSave(index, currentDraftName)} // Also save on blur
                                autoFocus
                              />
                            ) : (
                              draft.name === "" ? draft.slot : draft.name
                            )
                          }
                          onDoubleClick={() => {
                            setEditableDraftIndex(index);
                            setCurrentDraftName(draft.name); // Initialize the temporary state with the current draft name
                          }}
                        />
                        
                                ))
                              }
                </TabList>
                        {
                allDrafts.map((draft, index) => (
                  <TabPanel key={index} value={draft.slot} sx={{padding: 0, margin: 0}}>

                        <Grid item>
                          <Typography variant="body2" mr={2} mt={2}>
                            Select country settings:
                          </Typography>
                        </Grid>
                        <Grid item style={{width:"25%"}}>
                          <Autocomplete
                            options={countriesNames}
                            // defaultValue={countriesNames[0]}
                            value={filteredCountrySettings.id}
                            onChange={(event, newValue) => handleCountryChange(newValue)}
                            disableClearable
                            freeSolo={false} 
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country"
                                size="small"
                                sx={{ my: 1 }}
                                />
                              )}
                              />
                        </Grid>

                            <MarginSynthesis
                            mode={"Draft"}
                            countrySettings={filteredCountrySettings}
                            engagementTeamValues={engagementTeamValues}
                            engagementMarginResults={engagementMarginResults}
                            />
                            
                            <TeamDraft 
                            countrySettings={filteredCountrySettings}
                            setUnsavedChanges={setUnsavedChanges}
                            isTeamComplete={isTeamComplete}
                            isTeamCardComplete={isTeamCardComplete} 
                            engagementTeamValues={engagementTeamValues}
                            setEngagementTeamValues={setEngagementTeamValues}
                            initializeDraftTab={initializeDraftTab}
                            setInitializeDraftTab={setInitializeDraftTab}
                            />

                    </TabPanel>
                ))
              }

                </TabContext>

                {confirmDialogOpenTab && (
                  <ConfirmDialog
                    title="Are you sure you want to leave ?"
                    description="You are about to leave this margin draft. Any unsaved changes will be lost"
                    mod="unsavedChanges"
                    open={confirmDialogOpenTab}
                    setOpen={setConfirmDialogOpenTab}
                    onConfirm={() => handleConfirmCloseTab()}
                  />
                )}

                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mb: 2}} >
                  <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                    { unsavedChanges ?
                      <Button color="error" sx={{ mr: isSmallScreen ? 0 : 3 }} onClick={() => setConfirmDialogOpenOnCancel(true)}>
                        Close
                      </Button>
                      :
                      <Button color="error" sx={{ mr: isSmallScreen ? 0 : 3 }} onClick={() => handleCalculatorClose()}>
                        Close
                      </Button>
                    }

                    <Button
                      onClick={() => handleSave()}
                      color="success"
                      disabled={!unsavedChanges}
                    >
                      {" "}
                      Save
                    </Button>

                    {confirmDialogOpenOnCancel ? (
                      <ConfirmDialog
                      title="Are you sure you want to leave ?"
                      description="You are about to leave this margin draft. Any unsaved changes will be lost."
                      mod="unsavedChanges"
                      open={confirmDialogOpenOnCancel}
                      setOpen={setConfirmDialogOpenOnCancel}
                      onConfirm={handleCalculatorClose}
                    />
                    ): null }
                  </Grid>
              </Grid>
            </>
          );
        // }
        }, (prevProps, nextProps) => {
                return prevProps.filteredCountrySettings === nextProps.filteredCountrySettings &&
                prevProps.selectedMarginDraftTab === nextProps.selectedMarginDraftTab &&
                prevProps.filteredMarginDrafts === nextProps.filteredMarginDrafts;
                
                });
        
        export default MarginDrafts;
