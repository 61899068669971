import React, { useEffect, useState } from "react";
import { 
Box, 
Chip,
Grid,  
Accordion,
AccordionSummary,
AccordionDetails, 
CssBaseline,
Paper,
Table, 
TableBody, 
TableCell, 
TableContainer, 
TableHead, 
TableRow,
FormControl,
FormLabel,
RadioGroup,
FormControlLabel,
Radio,
TextField,
Stack, 
Divider,
Typography,
Autocomplete,
Tooltip} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

const MarginSynthesis = React.memo((props) => {
    const projectMargin = props.projectMargins;
    const deliveryManagers = props.deliveryManagers;
    const engagementMarginResults = props.engagementMarginResults;
    const offerMarginResults = props.offerMarginResults;
    const setTotalDailyRate = props.setTotalDailyRate;
    const currencySymbol = props.countrySettings.currency.symbol;
    const mode = props.mode;
    const [localMarginSynthesisValues, setLocalMarginSynthesisValues] = useState(props.marginSynthesisValues);

    // DAILY RATE
    const subcontractorsDailyRateAverage = engagementMarginResults.subcontractorsDailyRateAverage;
    const es3DailyRateAverage = engagementMarginResults.es3DailyRateAverage;
    const consultantDailyRateAverage = engagementMarginResults.consultantDailyRateAverage;

    // REVENUE
    const subcontractorsRevenues = engagementMarginResults.subcontractorsRevenues;
    const es3Revenues = engagementMarginResults.es3Revenues
    const consultantsRevenues = engagementMarginResults.consultantsRevenues;

    // ENGAGEMENT MARGIN
    const subcontractorsEngagementMarginAverage = engagementMarginResults.subcontractorsMarginAverage;
    const es3EngagementMarginAverage = engagementMarginResults.es3MarginAverage
    const consultantsEngagementMarginAverage = engagementMarginResults.consultantsMarginAverage;

    // OFFER MARGIN
    const subcontractorsOfferMarginAverage = mode === "Margin" ? offerMarginResults.subcontractorsMarginAverage : null;
    const es3OfferMarginAverage = mode === "Margin" ? offerMarginResults.es3MarginAverage : null;
    const consultantsOfferMarginAverage = mode === "Margin" ? offerMarginResults.consultantsMarginAverage : null;
    
    // TOTALS
    const totalRate = engagementMarginResults.totalRate;
    const totalRevenue = engagementMarginResults.totalRevenue;
    const totalEngagementMarginRate = engagementMarginResults.totalMarginRate;
    const totalOfferMarginRate = mode === "Margin" ? offerMarginResults.totalMarginRate : null;

    let marginStatus = mode === "Margin" ? projectMargin[0].status : null;
    const deliveryManagersFullNames = mode === "Margin" ? deliveryManagers
        .slice()
        .sort((a, b) => a.employee_last_name.localeCompare(b.employee_last_name))
        .map((manager) => `${manager.employee_first_name} ${manager.employee_last_name}`) : null;
    const contractsLists = ["M|P master contract", "Alten master contract with Alten invoicing (portage)", "Alten master contract with M|P invoicing", "No master contract"]
    const marginTypesList = ["New", "Modification", "End", "Margin Update"]

    const handleChangeLocally = (event) => {
       props.handleChange(event);
       props.setUnsavedChanges(true);
    };

    const rows = mode === "Margin" ? [
        { id: 'name', label: '' },
        { id: 'dailyRate', label: 'Daily Rate' },
        { id: 'revenue', label: 'Revenue' },
        { id: 'offer_margin', label: 'Offer Margin' },
        { id: 'engagement_margin', label: 'Eng. Margin' },
    ] 
    :
    [
        { id: 'name', label: '' },
        { id: 'dailyRate', label: 'Daily Rate' },
        { id: 'revenue', label: 'Revenue' },
        { id: 'engagement_margin', label: 'Eng. Margin' },
    ];

    const columns = mode === "Margin" ? 
    [
        {
            name: "Consultants",
            dailyRate: consultantDailyRateAverage > 0 ? parseFloat(consultantDailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: consultantsRevenues > 0 ? parseFloat(consultantsRevenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            offer_margin: consultantsOfferMarginAverage > 0 ? consultantsOfferMarginAverage.toFixed(1) : 0,
            engagement_margin: consultantsEngagementMarginAverage > 0 ? consultantsEngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "Subcontractors",
            dailyRate: subcontractorsDailyRateAverage > 0 ? parseFloat(subcontractorsDailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: subcontractorsRevenues > 0 ? parseFloat(subcontractorsRevenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            offer_margin: subcontractorsOfferMarginAverage > 0 ? subcontractorsOfferMarginAverage.toFixed(1) : 0,
            engagement_margin: subcontractorsEngagementMarginAverage > 0 ? subcontractorsEngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "ES3",
            dailyRate: es3DailyRateAverage > 0 ? parseFloat(es3DailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: es3Revenues > 0 ? parseFloat(es3Revenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            offer_margin: es3OfferMarginAverage > 0 ? es3OfferMarginAverage.toFixed(1) : 0,
            engagement_margin: es3EngagementMarginAverage > 0 ? es3EngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "Total",
            dailyRate: totalRate > 0 ? parseFloat(totalRate).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: totalRevenue > 0 ? parseFloat(totalRevenue).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            offer_margin: totalOfferMarginRate  > 0 ? totalOfferMarginRate.toFixed(1) : 0,
            engagement_margin: totalEngagementMarginRate > 0 ? totalEngagementMarginRate.toFixed(1) : 0
        },
    ]
    :
    [
        {
            name: "Consultants",
            dailyRate: consultantDailyRateAverage > 0 ? parseFloat(consultantDailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: consultantsRevenues > 0 ? parseFloat(consultantsRevenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            engagement_margin: consultantsEngagementMarginAverage > 0 ? consultantsEngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "Subcontractors",
            dailyRate: subcontractorsDailyRateAverage > 0 ? parseFloat(subcontractorsDailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: subcontractorsRevenues > 0 ? parseFloat(subcontractorsRevenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            engagement_margin: subcontractorsEngagementMarginAverage > 0 ? subcontractorsEngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "ES3",
            dailyRate: es3DailyRateAverage > 0 ? parseFloat(es3DailyRateAverage).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: es3Revenues > 0 ? parseFloat(es3Revenues).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            engagement_margin: es3EngagementMarginAverage > 0 ? es3EngagementMarginAverage.toFixed(1) : 0
        },
        {
            name: "Total",
            dailyRate: totalRate > 0 ? parseFloat(totalRate).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            revenue: totalRevenue > 0 ? parseFloat(totalRevenue).toLocaleString('en-US', {minimumFractionDigits: 2}) : 0,
            engagement_margin: totalEngagementMarginRate > 0 ? totalEngagementMarginRate.toFixed(1) : 0
        },
    ];

    useEffect(() => {
        if (mode === "Margin"){
        setLocalMarginSynthesisValues(props.marginSynthesisValues)
            setTotalDailyRate(totalRate)
        }
    }, [props.marginSynthesisValues, setTotalDailyRate, totalRate, mode]);


    return (
    <Box sx={{ display: "flex", my: 2}}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} minWidth={"100%"}>
            <Accordion
                defaultExpanded={true}
                sx={{border: '1px solid lightgray', height: "auto", width: "100%"}}
                elevation={0}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container display="flex">
                        <Tooltip title="KPIs calculations, based on team cards data. Also includes necessary inputs for Declaratives mails">
                            <Grid item display="flex" alignItems="center">
                                <Typography variant="h6">{mode === "Margin" ? "MARGIN SYNTHESIS" : "MARGIN DRAFT SYNTHESIS"}</Typography>
                                {mode === "Margin" ? (!localMarginSynthesisValues.contract || !localMarginSynthesisValues.note) && <span style={{ color: 'red', marginLeft: 3 }}>*</span> : null}
                                <CalculateOutlinedIcon fontSize="small" sx={{marginLeft:1}}/>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    {mode === "Margin" ?
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid item sx={{ display: "flex", mr: 2, flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 3 }}>
                            <Typography variant="subtitle2" sx={{display: "flex", flexDirection: "row", fontWeight: "bold", width: "100px"}}>
                                Margin Status:
                            </Typography>
                            <Chip
                                size="medium"
                                label={
                                    <Typography variant="subtitle2">
                                        {`${!projectMargin[0].changes_detected || marginStatus === "TO BE CREATED" ? marginStatus : marginStatus = "TO BE UPDATED" }`}
                                    </Typography>
                                }
                                color={marginStatus.replace(
                                    / /g,
                                    "",
                                )}
                            />
                        </Grid>
                    </Grid> : null}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container display="flex"spacing={2}>
                        <Stack
                         divider={<Divider orientation="vertical" flexItem />}
                         direction="row"
                         spacing={2}
                         sx={{width:"100%"}} 
                        >
                            <Grid item xs={mode === "Margin" ? 6 : 12}>
                                <TableContainer component={Paper} sx={{ border: "none"}} elevation={0}>
                                    <Table sx={{ border: "none" }}>
                                        <TableHead>
                                            <TableRow sx={{ mb: 2 }}> 
                                                <TableCell sx={{ border: "none" }}></TableCell>
                                                {columns.map((column, index) => (
                                                    <TableCell key={index} align="center" sx={{ fontWeight: 'bold', border: "none" }}>
                                                        {column.name}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.filter(row => row.id !== 'name').map((row) => (
                                                <TableRow key={row.id} sx={{ border: "none" }}>
                                                        <TableCell scope="row" align="center" sx={{ border: "none", fontWeight: "bold" }}>
                                                            <Tooltip title={row.id === "dailyRate" ? "Daily rate = SUM(Revenues) / SUM(SoldDays)" : row.id === "revenue" ? "Revenue = SUM(Revenues) / SUM(Sold days)" : row.id === "offer_margin" ? "Represents your first reference margin (can be overridden with 'Save as offer margin' button at the end of the form)": "Actual project margin = (SUM(Revenues) - SUM(Costs)) / SUM(Revenues))"} >
                                                                {row.label}
                                                            </Tooltip>
                                                        </TableCell>
                                                    {columns.map((column, index) => (
                                                            <TableCell key={index} align="center" sx={{ border: "none", fontWeight: column.name === "Total" ? "bold" : "normal" }}>
                                                                {row.id === 'dailyRate' || row.id === 'revenue' ? 
                                                                    `${column[row.id]} ${currencySymbol}` : 
                                                                    row.id === 'offer_margin' || row.id === 'engagement_margin' ? 
                                                                    `${column[row.id]}%` : 
                                                                    column[row.id]}
                                                            </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            {mode === "Margin" ?
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <FormControl>
                                    <Grid item mb={1}>
                                        <Tooltip title="If there are any rebillable bills, please indicate them in the comment section at the end of the margin form">
                                            <Grid item display="flex" alignItems="center" >
                                                    <FormLabel sx={{mr: 2}}>Rebillable expenses:</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="rebillable_bills"
                                                        value={localMarginSynthesisValues.rebillable_bills ? "true" : "false"}
                                                        onChange={(event) => handleChangeLocally(event)}
                                                        required
                                                    >
                                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </Grid>
                                        </Tooltip>
                                            <Grid item>
                                                {
                                                    localMarginSynthesisValues.rebillable_bills ?
                                                        <Typography variant="caption">
                                                            Please indicate the nature and amount of the <br/> expenses in the comments
                                                        </Typography>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Autocomplete
                                            options={deliveryManagersFullNames}
                                            value={localMarginSynthesisValues.delivery_manager}
                                            onChange={(event, newValue) => handleChangeLocally({ target: { name: "delivery_manager", value: newValue } })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Delivery Manager"
                                                    size="small"
                                                        sx={{ my: 1 }}
                                                    />
                                            )}
                                        />
                                        <Autocomplete
                                            options={contractsLists}
                                            value={localMarginSynthesisValues.contract}
                                            onChange={(event, newValue) => handleChangeLocally({ target: { name: "contract", value: newValue } })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Contract Type"
                                                    size="small"
                                                    required
                                                    sx={{ my: 1 }}
                                                    error={!localMarginSynthesisValues.contract}
                                                />
                                            )}
                                        />
                                            <Autocomplete
                                                options={marginTypesList}
                                                value={localMarginSynthesisValues.margin_type}
                                                onChange={(event, newValue) => handleChangeLocally({ target: { name: "margin_type", value: newValue } })}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Margin Type"
                                                        size="small"
                                                        required
                                                        sx={{ my: 1 }}
                                                        error={!localMarginSynthesisValues.margin_type}
                                                    />
                                                )}
                                            />
                                        <TextField
                                            name="note"
                                            label="Offer Number"
                                            value={localMarginSynthesisValues.note}
                                            onChange={(e) => handleChangeLocally(e)}
                                            required
                                            sx={{ my: 1 }}
                                            size="small"
                                            error={!localMarginSynthesisValues.note}
                                        />
                                </FormControl>
                            </Grid> : null}
                        </Stack>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Box>
  );
}, (prevProps, nextProps) => {
    return prevProps.marginSynthesisValues === nextProps.marginSynthesisValues &&
       prevProps.engagementMarginResults === nextProps.engagementMarginResults &&
       prevProps.countrySettings === nextProps.countrySettings &&
       prevProps.offerMarginResults === nextProps.offerMarginResults;
});

export default MarginSynthesis;
