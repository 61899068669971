import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";

const initializeAppInsights = (username) => {

  const browserHistory = createBrowserHistory({ basename: '' });
  const reactPlugin = new ReactPlugin();
  
    // *** Ajoutez le plugin Click Analytics. ***
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
      autoCapture: true
    }; 

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_FRONTEND_APP_INSIGHT_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
          [clickPluginInstance.identifier]: clickPluginConfig
        }
      }
    });

    // User's mail adress to identify who has a problem
    const telemetryInitializer = (envelope) => { 
      envelope.data.username = username;
    }
  
    appInsights.addTelemetryInitializer(telemetryInitializer);
  
    appInsights.loadAppInsights();
  
  
    return { reactPlugin, appInsights };
};

export default initializeAppInsights;