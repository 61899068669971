import React, { useState, useEffect } from 'react';
import { Fab, Box, Drawer, useMediaQuery } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { getToken } from '../api/getToken';
import Chatbot from "./Chatbot";

const FloatingButtonChatbot = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [accessToken, setAccessToken] = useState(null);


    useEffect(() => {
        const fetchToken = async () => {
            try {
                let response = await getToken();
                let access_token = response;
                setAccessToken(access_token);
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        fetchToken();
    }, []);



    return (
        <div>
            <Fab 
                color="primary" 
                aria-label="chat" 
                onClick={() => setOpen(true)} 
                sx={{ 
                    position: 'fixed', 
                    bottom: 16, 
                    right: 16 
                }}

            >
                <ContactSupportIcon/>
            </Fab>
            <Drawer 
                anchor="right" 
                open={open} 
                onClose={() => setOpen(false)}
                sx={isSmallScreen ?
                    { 
                        '& .MuiDrawer-paper': {
                            height: '100%',
                            width: 300,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    } : { 
                    '& .MuiDrawer-paper': {
                        height: '100%',
                        width: 500,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }
                }}
            >
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Chatbot 
                        messages={messages}
                        setMessages={setMessages}
                        threadId={threadId}
                        setThreadId={setThreadId}
                        accessToken = {accessToken}
                        setOpen = {setOpen}
                    />
                </Box>
            </Drawer>
        </div>
    );
};

export default FloatingButtonChatbot;





    /* 
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    */